import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import TopHeader from '../common/TopHeader';
import { CLIENTS, fetch_get, Pagination, fetch_post, USER, Alert, Alert_model } from '../common';
import { Link } from 'react-router-dom';
import AuthenticationModel from './AuthenticationModel';
import DeleteUser from './DeleteUser';

export default class Clients extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activecClientsList: [],
      inActiveClientsList: [],
      deleteId: null,
      activecurrentPage: 1,
      activenumOfPages: 0,
      inActivecurrentPage: 1,
      inActivenumOfPages: 0,
      active: true,
      filter: false,
      superuser:localStorage.getItem('SuperUser'),
      restore: false,
      permanentDelete:false, 
      alertmessage:'',
      numberofrows:100,
      count:0,
      username_count:0,
      balance_count:0,
      email_count:0,
      total_spent_count:0,
      total_orders_count:0,
      date_joined_count:0,
      last_login_count:0,
      last_order_count:0,
      plan_count:0,
      tags:[],
      selectedTags:[],
    }
    this.clientsList = this.clientsList.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.clientsList();
    this.clearStates()
  }

  clearStates(filter=false) {
    this.setState({
      id: '',
      email: '',
      firstname: '',
      lastname: '',
      searchText: '',
      selectedTags:[]
    }, () => { filter && this.clientFilter()})
  }

  clientsList(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage) {
    fetch_get(`${CLIENTS}list/?page1=${page1}&page2=${page2}`) 
    .then((response) => {
      let tags=[]
      response.tags.map((tag)=>{
        tags.push({ label: tag.name, value: tag.id })
      })
      if(!response.error) {
        this.setState({ 
          activeClientsList: response.active_clients_list, 
          inactiveClientsList: response.inactive_clients_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inActivenumOfPages: response.inactive_num_pages,
          tags:tags
        })
        let model = document.getElementById("filterModel");
        model.click();
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
  //   let formData = new FormData()
  //   this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   formData.append('page1',this.state.activecurrentPage)
  //   formData.append('page2',this.state.inactivecurrentPage)
  //   fetch_post(`${CLIENTS}list/`,formData)
  //   .then((response) => {
  //     if(!response.error){
  //       this.setState({
  //         activeClientsList: response.active_clients_list, 
  //         inactiveClientsList: response.inactive_clients_list, 
  //         deleteId: null,
  //         activenumOfPages: response.active_num_pages, 
  //         inActivenumOfPages: response.inactive_num_pages
  //       })                   
  //     }
  //   })
  // }  

  clientFilter(page1=this.state.activecurrentPage, page2=this.state.inactivecurrentPage, searchText=this.state.searchText) {
    let formData = new FormData()
    formData.append('page1', page1)
    formData.append('page2', page2)
    this.state.searchText && formData.append('username', searchText)
    this.state.email && formData.append('email', this.state.email)
    this.state.firstname && formData.append('first_name', this.state.firstname)
    this.state.lastname && formData.append('last_name', this.state.lastname)
    // formData.append('user_id', this.state.id)
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    let tags = [];
    this.state.selectedTags.length > 0 && this.state.selectedTags.map((tag) => 
      tags.push(tag.value)
    )
    if(tags.length > 0) {
      formData.append('tags', JSON.stringify(tags));
    }    

    if(this.state.svalue == 'id'){
      if(this.state.count%2){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'username'){
      if(this.state.username_count%2){
        formData.append('column_name_order','username')
      }
      else{
        formData.append('column_name_order','-username')
      }
    }
    if(this.state.svalue == 'email'){
      if(this.state.email_count%2){
        formData.append('column_name_order','email')
      }
      else{
        formData.append('column_name_order','-email')
      }
    }   
    if(this.state.svalue == 'total_spent'){
      if(this.state.total_spent_count%2){
        formData.append('column_name_order','total_spent')
      }
      else{
        formData.append('column_name_order','-total_spent')
      }
    }
    if(this.state.svalue == 'total_orders'){
      if(this.state.total_orders_count%2){
        formData.append('column_name_order','total_orders')
      }
      else{
        formData.append('column_name_order','-total_orders')
      }
    }    
    if(this.state.svalue == 'date_joined'){
      if(this.state.date_joined_count%2){
        formData.append('column_name_order','date_joined')
      }
      else{
        formData.append('column_name_order','-date_joined')
      }
    }  
    if(this.state.svalue == 'last_login'){
      if(this.state.last_login_count%2){
        formData.append('column_name_order','last_login')
      }
      else{
        formData.append('column_name_order','-last_login')
      }
    }
    if(this.state.svalue == 'last_order_date'){
      if(this.state.last_order_count%2){
        formData.append('column_name_order','last_order_date')
      }
      else{
        formData.append('column_name_order','-last_order_date')
      }
    }
    if(this.state.svalue == 'plan'){
      if(this.state.plan_count%2){
        formData.append('column_name_order','used_available_searches')
      }
      else{
        formData.append('column_name_order','-used_available_searches')
      }
    }
    if(this.state.svalue == 'balance'){
      if(this.state.balance_count%2){
        formData.append('column_name_order','balance_amount')
      }
      else{
        formData.append('column_name_order','-balance_amount')
      }
    }    

    fetch_post(`${CLIENTS}list/`, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          activeClientsList: response.active_clients_list, 
          inactiveClientsList: response.inactive_clients_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inActivenumOfPages: response.inactive_num_pages
        },window.scroll(0,0))
        let model = document.getElementById("filterModel");
        model.click();
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page1',currentPage)
    // formData.append('page2',currentPage)
    // fetch_post(`${CLIENTS}list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       activeClientsList: response.active_clients_list, 
    //       inactiveClientsList: response.inactive_clients_list, 
    //       deleteId: null,
    //       activenumOfPages: response.active_num_pages, 
    //       inActivenumOfPages: response.inactive_num_pages
    //     })                   
    //   }
    // })    
    if(this.state.active) {
      this.setState({activecurrentPage: currentPage},this.clientFilter)
    } else {
      this.setState({ inActivecurrentPage:currentPage},this.clientFilter)
    }
  }

  search(searchText) {
    this.setState({ searchText }, this.clientFilter(this.state.activecurrentPage, this.state.inactivecurrentPage, searchText))
  }

  changeStatus(id) {
    fetch_get(`${USER}${id}/status/`) 
    .then((response) => {
      if(!response.error) {
        // alert("Client restored successfully")
        this.setState({restore:true,permanentDelete:false})
        this.clientsList()
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  permanentDelete(id){
    let formData = new FormData()
    formData.append('permanent_delete',true)
    fetch_post(`${USER}${id}/delete/`, formData)
    .then((response) => {
      if(response.error){
        // alert(response.message)
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.clientsList()
      }
      else{
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.clientsList()        
      }      
    })
  }


  rendorClients() {
    if (this.state.activeClientsList && this.state.inactiveClientsList) {
      let clients = this.state.active ? this.state.activeClientsList : this.state.inactiveClientsList
      let page = this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage
      return(
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({count:this.state.count + 1,svalue:'id'},this.clientFilter)}>Id <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({username_count:this.state.username_count + 1,svalue:'username'},this.clientFilter)}>Username <i className="fas fa-sort"></i></th> 
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({email_count:this.state.email_count + 1,svalue:'email'},this.clientFilter)}>Email <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({total_spent_count:this.state.total_spent_count + 1,svalue:'total_spent'},this.clientFilter)}>Total Spent <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({total_orders_count:this.state.total_orders_count + 1,svalue:'total_orders'},this.clientFilter)}>Total Orders <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({date_joined_count:this.state.date_joined_count + 1,svalue:'date_joined'},this.clientFilter)}>Created <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({last_login_count:this.state.last_login_count + 1,svalue:'last_login'},this.clientFilter)}>Last Login <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({last_order_count:this.state.last_order_count + 1,svalue:'last_order_date'},this.clientFilter)}>Last Order Date <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({plan_count:this.state.plan_count + 1,svalue:'plan'},this.clientFilter)}>plan <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({balance_count:this.state.balance_count + 1,svalue:'balance'},this.clientFilter)}>balance <i className="fas fa-sort"></i></th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.length > 0 ? clients.map((clients, index) => 
              <tr key={clients.id}>
                <th 
                  scope="row"
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.id}
                </th>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.username}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.email}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.order_details.total_amount.amount__sum}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.order_details.orders_count}
                </td>                
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.date_joined}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.last_login ? clients.last_login : '--'}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push(`/admin/clients/${clients.id}/profile`) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.last_order_date ? clients.last_order_date : '--'}
                </td>
                <td>
                  {clients.user_subscription_details != null ? clients.user_subscription_details.subscription_name == 'Pro' ? 'PRO' : 'FREE(' + clients.user_subscription_details.used_available_searches +'/50)' : null}
                </td>
                <td
                  onClick={() => this.state.active ? this.props.history.push({pathname:'/admin/tasks/', client:{details: {order_status:'Invoiced',created_by: clients.id}}}) : {}}
                  style={{ cursor: this.state.active ? 'pointer' : ''}}
                >
                  {clients.order_details.balance_amount.amount__sum}
                </td>                
                <td>
                  {this.state.active ?
                    <Fragment>
                      <Link to={{pathname:`/admin/clients/${clients.id}/profile`}}>
                        <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                      </Link>
                      <DeleteUser userRole="Client" id={this.state.deleteId} updateList={this.clientsList} />
                      <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: clients.id, restore: false })}>
                        <i className="fa fa-trash"></i>
                      </span>
                    </Fragment>
                  :
                    <Fragment>
                      <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(clients.id)}>
                        <span style={{paddingRight:20 }}><i className="fa fa-sync"></i></span>
                      </a>
                      {localStorage.getItem('SuperUser') === 'true' ? 
                        <span data-toggle="modal" data-target="#alert">
                        <a onClick={() => this.permanentDelete(clients.id)}>
                          <span style={{paddingRight:20 }}><i className="fa fa-trash"></i></span>
                        </a>
                        </span>
                      : null}  
                    </Fragment>                  
                  }
                </td>
              </tr>
            ) 
            :
              <tr>
                <th scope="row">No Records Found</th>
              </tr>
            }
          </tbody>
        </table>
      )
    }
  }

  render(){
    return(
      <div className="main-content client">
      {this.state.restore && this.state.permanentDelete ? <Alert msg={this.state.alertmessage}/> : this.state.restore ? <Alert msg={"Client restored Successfully"}/> : <Alert msg={"Client Deleted Successfully"}/>} 
      <Alert_model msg={'Client Created Successfully' }/>
        <TopHeader search={this.search} isSearch={true} />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
          <div className="col-lg-12">
            <div className="col">
              <div className="card shadow website_detail_profile">
                <div className="card-header row bg-white border-0">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.clientFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Clients
                  <div className="">
                   <label className="custom-toggle mb-0 ml-3">
                    <input type="checkbox"/> 
                    <span className="custom-toggle-slider" onClick={() => this.setState({ active: !this.state.active})}> {this.state.active ? 'Active' : 'Inactive'} </span>
                  </label>
                  </div>
                  </h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Create Client</button>
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>
                    {this.state.filter &&
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <div className="filter_result">
                              <form>
                                <div className="row">
                                  {/*<div className="col-md-2">
                                    <div className="form-group">
                                      <input 
                                        type="number" 
                                        className="form-control" 
                                        placeholder="ID"
                                        value={this.state.id}
                                        onChange={(e) => this.setState({ id: e.target.value }, this.clientFilter)}
                                      />
                                    </div>
                                  </div>*/}
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input 
                                        type="text" 
                                        className="form-control" 
                                        value={this.state.searchText}
                                        onChange={(e) => this.setState({ searchText: e.target.value }, this.clientFilter)}
                                        placeholder="Username"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input 
                                        type="email" 
                                        className="form-control" 
                                        placeholder="Email" 
                                        value={this.state.email}
                                        onChange={(e) => this.setState({ email: e.target.value }, this.clientFilter)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group mar-bot-5">
                                      <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="First name" 
                                        value={this.state.firstname}
                                        onChange={(e) => this.setState({ firstname: e.target.value }, this.clientFilter)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Last name"
                                        value={this.state.lastname}
                                        onChange={(e) => this.setState({ lastname: e.target.value }, this.clientFilter)}
                                      />
                                    </div>
                                  </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <Select 
                                      className="form-control"
                                      isMulti
                                      value={this.state.selectedTags}
                                      onChange={(e) => this.setState({ selectedTags: e}, this.clientFilter)}
                                      options={this.state.tags}
                                      placeholder="tags"
                                    />
                                  </div>
                                </div>                                  
                                </div>
                              </form>
                              <div className="col-lg-12 text-center">
                                  <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                                </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      }
                </div>
                <div className="table-responsive">
                  {this.rendorClients()}
                </div>
                <AuthenticationModel userRole="Client" updateList={this.clientsList} />
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage}
                          totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
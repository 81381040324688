import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import Alert_selfmodel from '../common/Alert_selfmodel'
import { VENDOR_SIGNUP, ADMIN_SIGNUP, fetch_post, fetch_get, ORDER, SIGNUP, Alert_model } from '../common';

export default class ConfirmationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      serviceList:[],   
      selectedPrice:'', 
      websiteData:{},
      services:[],
      selecetedService:'',
      projectsList:'',
      current_pid:'',       
    };
  }

  updateList(){
    this.setState({loading:false})
    this.props.uncheckwebsites()
  }

  componentDidMount(){  
    fetch_get('/api/article_services/list/')
    .then((response) => { 
      if(!response.error){
        let services=[]
        response.services_list && response.services_list.map((service)=>
          services.push({label:service.service_name,price:service.price,id:service.id}))
        this.setState({serviceList:response.services_list,services:services})
      }

    })
    this.projectsList()  
  } 

  // selectArticle(e,wid,index){
  //   if(!(wid in this.state.websiteData)){
  //     this.state.websiteData[wid]=e.id
  //   }
  //     this.props.orderlist1[index]['total'] = parseInt(this.props.orderlist1[index].price) + parseInt(e.price)
  //   this.setState({})
  // }

  projectsList() {
    if(localStorage.getItem('role') == 'admin'){
      const data = new FormData();
      data.append('client_id', this.props.clientid ? this.props.clientid.value : null);
      fetch_get(`/api/projects/list/`)
      .then((response) => {
        if(!response.error) {
          this.setState({ projects_list:response.projects_list  })
        } else {
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
    else{
      if(localStorage.getItem('subscription') == 'Pro'){
        fetch_get(`/api/projects/list/`)
        .then((response) => {
          if(!response.error) {
            this.setState({ projects_list:response.projects_list  })
          } else {
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
      }
    }
  }

  selectArticle(sid,wid,index){
    if(!(wid in this.state.websiteData)){
      this.state.websiteData[wid]=sid
    }    
    var article = this.state.serviceList.find(article => article.id == sid);
    if(article){
      this.props.orderlist1[index]['total'] = parseInt(this.props.orderlist1[index].price) + parseInt(article.price)
    }
    else{
      this.props.orderlist1[index]['total'] = parseInt(this.props.orderlist1[index].price)
    }
    this.setState({})
  }     

  createOrder() {
    this.props.orderlist1.map((order,i)=>{
      if(!(order.websiteId in this.state.websiteData)){
        this.state.websiteData[order.websiteId]=""
      }
    })
    if(this.props.orderlist1.length>0) {
      let websites = []
      let data = new FormData();
      data.append('websites', JSON.stringify(this.state.websiteData))
      this.state.current_pid && data.append('project_id',this.state.current_pid)
      this.props.role === 'admin' && this.props.clientid.value && data.append('client_id', this.props.clientid.value) 
      fetch_post(`${ORDER}/create/`, data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          // alert(response.message)
          localStorage.setItem('orderid',response.order_id);
          this.setState({loading:false})
          let b = document.getElementById("alert_self");
          b.click();
          let a = document.getElementById("createModal");
          a.click();
          // this.props.uncheckwebsites()
          // this.props.history.push('/websites/orders/create', { id: response.order_id })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
    else {
      alert('please select atleast one website to create order')
      let a = document.getElementById("createModal");
      a.click();
    }
  }


  render() {
    const { errors } = this.state;
    return (
      <div className="modal fade" id="ConfirmationSingleModal" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
        <div className="modal-dialog col-md-12" role="document">
          <div className="col-lg-12">
            <div className="filter_result mt-5">
             <h3 className="mb-4 col-md-12 text-center">Your Websites Cart</h3>
            <div className="row" style={{marginLeft:'40rem'}}>
              <h8>Select Project : </h8>
              <span className="col-md-3" style={{marginLeft:"0.1rem"}}>
                <div className="form-group">
                  <select 
                    onChange={(e) => this.setState({current_pid:e.target.value})}
                    value={this.state.current_pid}
                    className="form-control"
                  >
                    <option value="">Select Project</option>
                    {this.state.projects_list && this.state.projects_list.map((project)=>
                      <option value={project.id}>{project.name}</option>
                    )}
                  </select>                      
                </div>
              </span>
            </div>                                                                                          
             <div className="row justify-content-center">
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">website name</th>
                      <th scope="col">website adtype</th>
                      {this.props.userrole == 'client' ? 
                        <Fragment> 
                        <th scope="col">price</th>
                        <th scope="col">articlewriting</th>
                        <th scope="col">total</th>
                        </Fragment>:
                        <Fragment>
                        <th scope="col">caluclatedprice</th>
                        <th scope="col">originalprice</th>
                        <th scope="col">articlewriting</th>
                        <th scope="col">total</th>                        
                        </Fragment>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.orderlist1.length > 0 ? this.props.orderlist1.map((order, index) => 
                      <tr style={{ cursor: 'pointer' }} key={order.id}>
                        <td>
                          {order.domain}
                        </td>
                        <td>
                          {order.ad_type}
                        </td>
                        {this.props.userrole == 'client' ?
                          <Fragment>
                          <td>
                            {order.price}
                          </td>
                          <td>
                            { order.ad_type == 'Article' ?
                            <select 
                            className="form-control"
                            placeholder="services"
                            onChange={(e)=>this.selectArticle(e.target.value,order.websiteId,index)}
                            >
                            <option value="">select service</option>
                            {this.state.serviceList.map((service)=>
                              <option value={service.id}>{service.service_name}(+${service.price})</option>
                              )}
                            </select>
                            :null}                           
                          </td>
                          <td>
                            {order.total}
                          </td>
                          </Fragment>: 
                          <Fragment>
                          <td>
                            {order.price}
                          </td>                          
                          <td>
                            {order.originalprice}
                          </td>
                          <td>
                            { order.ad_type == 'Article' ?
                            <select 
                            className="form-control"
                            placeholder="services"
                            onChange={(e)=>this.selectArticle(e.target.value,order.websiteId,index)}
                            >
                            <option value="">select service</option>
                            {this.state.serviceList.map((service)=>
                              <option value={service.id}>{service.service_name}(+${service.price})</option>
                              )}
                            </select>                            
                            :null}                           
                          </td>
                          <td>
                            {order.total}
                          </td>                           
                          </Fragment>
                        }                                                                     
                      </tr>
                    )
                    :
                      <tr>
                        <td>No Records found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>               
            <div className="row justify-content-center">
              <span data-toggle="modal" data-target="#alert_selfmodel" id="alert_self"></span>
              {this.state.loading == false ?
              <button type="button" className="btn btn-info" onClick={()=>{this.setState({loading:true},this.createOrder)}}>confirm</button>:
              <Fragment>
                <div>
                  <div className="spinner task">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                  </div>
                </div>
              </Fragment>
              }              
              <button type="button" className="btn btn-default" id="createModal" data-dismiss="modal" onClick={() => this.updateList()} >Cancel</button>
            </div>              
            </div>
          </div>
        </div>
      </div>

    );
  }
}

import React, { Component, Fragment } from 'react';
import { VENDOR_SIGNUP, ADMIN_SIGNUP, CREATOR_SIGNUP, fetch_post, SIGNUP, Alert_model } from '../common';

export default class AuthenticationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      errors: {},
      name: '',
      lastName: '',
      username: '',
      paypalAddress: '',
      skypeId: ''
    };
  }

  removeModalErrors(){
    if(document.getElementById('sendemail')){
      document.getElementById('sendemail').checked = false
    }
    this.setState({
      errors:'',email:'',name:'',lastName:'',username:'',paypalAddress:'',skypeId:''
    })
  }

  handleSubmit(event) {
    // let a = document.getElementById("createModel");
    // a.click();    
    event.preventDefault();
    const { email,name, lastName, username, paypalAddress, skypeId } = this.state;
    let formData = new FormData()
    let url = ''
    if(this.props.userRole === 'Admin') {
      url = ADMIN_SIGNUP
    } else if(this.props.userRole === 'Vendor'){
      url = VENDOR_SIGNUP
      // formData.append('user_role', this.props.role === 'Vendor' ? 'VENDOR' : 'CLIENT')
    }
      else if(this.props.userRole === 'Creator'){
        url = CREATOR_SIGNUP
      }
     else {
      url = SIGNUP
    }
    formData.append('email', email)
    formData.append('last_name', lastName)
    formData.append('first_name', name)
    formData.append('username', username)
    if(this.props.userRole === 'Vendor' || this.props.userRole === 'Creator') {
      formData.append('paypal_address', paypalAddress)
      formData.append('skype_id', skypeId)
    }
    if(this.props.userRole === 'Vendor' || this.props.userRole === 'Creator' || this.props.userRole === 'Client' ) {
      if(document.getElementById('sendemail').checked){
        formData.append('send_email', true)
      }
    }
    fetch_post(url,formData)
    .then((response) => {
      if (!response.error) {
        // let b = document.getElementById("success");
        // b.click();
        this.props.updateList()
        let a = document.getElementById("success");
        a.click();
        let b = document.getElementById("createModel");
        b.click();        
        // alert(response.message);
        // document.getElementsById("form1").reset()
      } else {
        this.setState({ errors: response.errors });
      }
    });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        {this.props.userRole === 'Admin' ? <Alert_model msg={'Admin Created Successfully' }/> : this.props.userRole === 'Vendor' ? <Alert_model msg={'Vendor Created Successfully' }/> : this.props.userRole === 'Creator' ? <Alert_model msg={'Creator Created Successfully' }/> : <Alert_model msg={'Client Created Successfully' }/> }
        <div className="modal-dialog" role="document">
          <div className="col-md-5 m-auto">
            <div className="modal-content">
              <h5 className="modal-title text-center pt-3 pb-3" id="exampleModalLabel">{this.props.userRole} create</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              </button>
              <div className="modal-body">
                <form id="form1">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-hat-3"></i></span>
                        </div>
                        <input 
                          className="form-control" 
                          placeholder="User Name" 
                          type="text" 
                          onChange={(event)=>{ this.setState({ username: event.target.value }) }} value={this.state.username}
                        />
                        <div className="error-text">*
                        </div>
                      </div> 
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {errors.username ? errors.username[0] : null}
                      </div> 
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                        </div>
                        <input 
                          className="form-control" 
                          placeholder="Email" 
                          type="email" 
                          onChange={(event)=>{ this.setState({ email:event.target.value }) }} value={this.state.email}
                        />
                        <div className="error-text">*
                        </div>                                                
                      </div>
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {errors.email ? errors.email[0] : null}
                      </div>                          
                    </div>                      
                    <div className="col-md-12 form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-hat-3"></i></span>
                        </div>
                        <input 
                          className="form-control" 
                          placeholder="First Name" 
                          type="text" 
                          onChange={(event)=>{this.setState({ name:event.target.value })}} value={this.state.name}
                        />
                        <div className="error-text">*
                        </div>                        
                      </div>
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {errors.first_name ? errors.first_name[0] : null}
                      </div>  
                    </div>
                    <div className="col-md-12 form-group">
                      <div className="input-group input-group-alternative mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text"><i className="ni ni-hat-3"></i></span>
                        </div>
                        <input 
                          className="form-control" 
                          placeholder="Last Name" 
                          type="text" 
                          onChange={(event)=>{ this.setState({ lastName: event.target.value }) }} value={this.state.lastName}
                        />
                      </div>
                    </div>
                    {(this.props.userRole === 'Vendor' || this.props.userRole === 'Creator') &&
                      <Fragment> 
                        <div className="col-md-12 form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-hat-3"></i></span>
                            </div>
                            <input 
                              className="form-control" 
                              placeholder="Paypal address" 
                              type="text" 
                              onChange={(event)=>{ this.setState({ paypalAddress: event.target.value }) }} value={this.state.paypalAddress}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-hat-3"></i></span>
                            </div>
                            <input 
                              className="form-control" 
                              placeholder="Skype ID" 
                              type="text" 
                              onChange={(event)=>{ this.setState({ skypeId: event.target.value }) }} value={this.state.skypeId}
                            />
                          </div>
                        </div> 
                      </Fragment>
                    }
                    {this.props.userRole === 'Vendor' ||  this.props.userRole === 'Client' || this.props.userRole === 'Creator' ?
                      <label style={{ paddingLeft: '25px'}}>
                        <input 
                          placeholder="Send an email about new account" 
                          id="sendemail"
                          type="checkbox" 
                        /> Send an email about new account 
                      </label>
                    : null                   
                    }                      
                    <div className="text-danger" style={{ fontSize: '1rem' }}>
                      {errors.__all__ ? errors.__all__[0] : null }
                      {errors.non_field_errors ? errors.non_field_errors[0] : null}
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-md-12 text-center pb-4">
                <span data-toggle="modal" data-target="#Alert_model" id="success" onClick={this.removeModalErrors.bind(this)}></span>
                <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={this.removeModalErrors.bind(this)}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)}>Create</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

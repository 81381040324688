import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { fetch_get, fetch_post,DOMAIN_NAME } from '../common';


export default class FilterLink extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],   
    }
  }

  componentWillMount() {
    fetch(`${DOMAIN_NAME}/api/link/${this.props.match.params.id}/details/`, {
      method: 'get',
    })
    .then(response => response.json())
    .then((response) => {
      if(!response.error) {
        localStorage.setItem('UserToken',response.token)
        localStorage.setItem('role','client')
        // localStorage.setItem('UserId',2)
        // localStorage.setItem('subscription','Free')
      }
      this.getUser(response.shared_links.filtered_data); 
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getUser(filtered__data){
    let formData = new FormData()
    formData.append('user_token', localStorage.getItem('UserToken'))
    fetch_post('/api/get_user/',formData)
    .then((response)=>{
      if(!response.error) {
        localStorage.setItem('UserId',response.user);
        localStorage.setItem('subscription',response.current_subscription.name)
        this.props.history.push({pathname:'/websites', data:{filtered_data:filtered__data}})
      }
    })

  }

  render() {
    return(
      <div className="main-content">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="col mt-5">
          <div className="card shadow">
          </div>
          </div>
        </div>
      </div>
    )
  }
}
import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { USER, REMOVE_PROFILE_PIC, fetch_get, fetch_post, SUBSCRIPTIONS, USER_SUBSCRIPTIONS, DOMAIN_NAME, Alert } from '../common';
import TopHeader from '../common/TopHeader';

let url=''
export default class Profile extends Component{
  constructor(props) {
    super(props);
    this.state = {
      userDetails: {},
      first_name:'',
      last_name:'',
      email2:'',
      error:'',
      errors:{},
      imgfile:null,
      profile_pic:'',
      subscriptions: [],
      id: this.props.match.params.id ? this.props.match.params.id  : localStorage.getItem('UserId'),
      precedence: '',
      presentPlan: '',
      redirectUrl: '',
      currentPlan: '',
      login_user:false,
      paypalAddress: '',
      skypeId: '',
      vendor: false,
      creator:false,
      delete : false,
      password:'',
      tags:[],
      selectedTags:[],
      subscription: '',
      free_sub_id:'',
      is_cancelled_subscription:'',
      company:'',
      billing_email:'',
      address_line1:'',
      address_line2:'',
      city_town:'',
      country:'',
      additional_info:'',
      notes:'',
      makeOrder:false
    };
    this.redirectPage = React.createRef();
    this.handleSelectedTags = this.handleSelectedTags.bind(this)
  }

  componentDidMount() {
    if (!localStorage.getItem('UserToken')) {
      this.props.history.push('/signin')
    } else {
      this.fetchDetails()
      this.fetchTags()
      localStorage.getItem('role') === 'client' && this.getSubscriptions()
    }
  }

  componentDidUpdate(prevProps,prevState){
    if(this.props.match.url !== prevProps.match.url){
      this.setState({id: localStorage.getItem('UserId'),login_user:true})
    }
    if(this.state.login_user){
      this.fetchDetails()
    } 
  }

  fetchDetails() {
    fetch_get(USER+this.state.id+'/details/')
    .then((response) => {
      if(response.user_details) {
        let tags = []
        let selectedTags = []
        if(response.user_details.tags){
          response.user_details.tags.map((tag)=>{
            selectedTags.push({ label: tag.name, value: tag.id })
          }) 
        } 
        this.setState({ userDetails: response.user_details,
          first_name:response.user_details.first_name,
          company:response.user_details.company ? response.user_details.company : '',
          billing_email: response.user_details.billing_email ? response.user_details.billing_email : '',
          address_line1: response.user_details.Address_line1 ? response.user_details.Address_line1 : '',
          address_line2: response.user_details.Address_line2 ? response.user_details.Address_line2 : '',
          city_town: response.user_details.city_town ? response.user_details.city_town : '',
          country: response.user_details.country ? response.user_details.country : '',
          additional_info: response.user_details.additional_info ? response.user_details.additional_info : '',
          notes: response.user_details.notes ? response.user_details.notes : '',
          email2:response.user_details.alternative_email ? response.user_details.alternative_email : '' ,
          last_name:response.user_details.last_name,
          profile_pic:response.user_details.profile_pic ? response.user_details.profile_pic : '/img/brand/default.png',
          login_user:false,
          selectedTags:selectedTags,
          subscription: response.subscription || ""
        })
         url = this.props.location.pathname.split('/')[2]
         this.props.location.pathname.split('/')[2] === "clients" && this.getSubscriptions()
        if(localStorage.getItem('role') === 'vendors' || url === 'vendors') {
          this.setState({ 
            vendor: true,
            paypalAddress: response.user_details.paypal_address,
            skypeId: response.user_details.skype_id
          })
        }
        else if(localStorage.getItem('role') === 'creators' || url === 'creators') {
          this.setState({ 
            creator: true,
            paypalAddress: response.user_details.paypal_address,
            skypeId: response.user_details.skype_id
          })
        }        
        else{
          this.setState({
            vendor:false
          })
        }
      }
      if(parseInt(localStorage.getItem('UserId')) === response.user_details.id){
        localStorage.setItem('profile_Pic',response.user_details.profile_pic ? response.user_details.profile_pic : '/img/brand/default.png')
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  fetchTags(){
    fetch_get(USER+this.state.id+'/update/')
    .then((response) => {
      if(!response.error) {
        let tags = []
        let selectedCategories = []
        response.tags.map((tag)=>{
          tags.push({ label: tag.name, value: tag.id })
        })
        this.setState({tags:tags})  
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  getSubscriptions() {
    fetch_get(USER_SUBSCRIPTIONS)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          subscriptions: response.subscriptions,
          presentPlan: response.current_subscription,
          is_cancelled_subscription:response.is_cancelled_subscription,
        })
        response.subscriptions.map((sub) => {
          if (sub.name == response.current_subscription.name) {
            this.setState({ precedence: sub.precedence, presentPlan: sub.name })
          }
          if(sub.name == 'Free'){
            this.setState({free_sub_id:sub.id})
          }
        })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }
  
  profilePicDelete(){
    let formData = new FormData()
    formData.append('user_id', this.state.id)
    fetch_post(REMOVE_PROFILE_PIC, formData)
    .then((response) => {
      if(response.error){
        this.setState({ error: response.error,errormessage:response.message,delete:true });
      }
      else{
        // alert("Profilepic removed  Successfully")
        let b = document.getElementById("success")
        b.click()
        this.fetchDetails()
      }
    })
  }

  profileDataUpdate(file) {
    this.setState({delete : false})
    const { first_name, last_name, paypalAddress, skypeId, password, company, address_line1, address_line2, billing_email, city_town, country, additional_info, notes } = this.state;
    let formData = new FormData()
    let tags = [];
    this.state.selectedTags.length > 0 && this.state.selectedTags.map((tag) => 
      tags.push(tag.value)
    )
    if(tags.length > 0) {
      formData.append('tags', JSON.stringify(tags));
    } 
    formData.append('first_name', first_name)
    formData.append('last_name', last_name)
    this.state.password && formData.append('password', password)
    if(file){
      formData.append('profile_pic', file[0])
    }
    if(this.state.vendor) {
      formData.append('paypal_address', paypalAddress)
      formData.append('skype_id', skypeId)
      formData.append('alternative_email',this.state.email2)
    }
    if(url === "clients") {
      formData.append("subscription_id", this.state.subscription)
      formData.append('company', company)
      formData.append('Address_line1', address_line1)
      formData.append('Address_line2', address_line2)
      formData.append('billing_email', billing_email)
      formData.append('city_town', city_town)
      formData.append('country', country)
      formData.append('additional_info', additional_info)
      formData.append('notes', notes)
    }
    if(this.state.creator) {
      formData.append('paypal_address', paypalAddress)
      formData.append('skype_id', skypeId)
    }    

    

    fetch_post(USER+this.state.id+'/update/',formData) 
    .then((response) => {
      if(response.error){
        this.setState({ errors: response.errors });
      }
      else{
        // alert("Profile Updated Successfully")
        let a = document.getElementById("success")
        a.click();
        this.setState({ errors: '' })
        this.fetchDetails()
      }
    })
  }

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  handleDelete(e){
    this.setState({imgfile:null,delete:true})
    this.profilePicDelete()
  }

  handleSelectedTags(value){
   this.setState({selectedTags:value})
  }

  changePassword(){
    fetch_get(USER+this.state.id+'/update/?genarate_password=true')
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          password: response.password
        })
      }
    })    
  }

  changeSubscription(id) {
    if(window.navigator.onLine){
      let formData = new FormData()
      formData.append('is_cancelled',false)
      fetch_post(`${SUBSCRIPTIONS}${id}/`)
      .then((response) => {
        if(!response.error) {
          if(response.redirectUrl){
            this.setState({ 
              redirectUrl: response.redirect_url
            })
            this.redirectPage.current.click();
          }
          else{
           let a = document.getElementById('close_subscriptions')
           a.click();
           if(response.current_subscription){
            localStorage.setItem('subscription',response.current_subscription.name)
            alert('Subscription Changed Successfully')
            window.location.reload()
           }
          }
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
    }
    else{
      alert('please check your network connection and try again')
    }
  }

  cancelSubscription(id) {
    if(window.navigator.onLine){
      let formData = new FormData()
      formData.append('is_cancelled',true)
      fetch_post(`${SUBSCRIPTIONS}${id}/`,formData)
      .then((response) => {
        if(!response.error) {
          if(response.redirectUrl){
            this.setState({
              redirectUrl: response.redirect_url
            })
            this.redirectPage.current.click();
          }
          else{
           let a = document.getElementById('close_subscriptions')
           a.click();
           if(response.current_subscription){
            localStorage.setItem('subscription',response.current_subscription.name)
            alert('Subscription Cancelled Successfully')
            window.location.reload()
           }
          }
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
    }
    else{
      alert('please check your network connection and try again')
    }
  }

  render(){
    const { userDetails, errors, } = this.state;
    console.log('localStorage.getItem',localStorage.getItem('subscription'))
    return(
      <Fragment>
        <div className="main-content">
          <TopHeader />
          {this.state.delete ? <Alert msg={"profile deleted Successfully"}/> : this.state.makeOrder ? <Alert msg={'Select any website to create an order'} makeOrder={this.state.makeOrder}/> :<Alert msg={"profile updated Successfully"}/> }
          <div className="header bg-gradient-primary  pb-9">            
          </div>          
          <div className="header pb-3 pt-5 pt-lg-7 d-flex align-items-center" >
            <span className="mask opacity-8"></span>
            <div className="container-fluid mt-0">
              <div className="row justify-content-center">
                <div className="col-xl-8">
                  <div className="card shadow mt-7 profile_card">
                    <div className="card-body bg_secondary">
                      <div className="profile_pic">
                        <div className="pic_actions">
                          <span className="picture"><img alt="" src={DOMAIN_NAME+this.state.profile_pic}/> 
                            <span className="actions">
                              <label htmlFor="imgUpload">                              
                                <button type="button" htmlFor="imgUpload" onClick={this.handleClick.bind(this)} className="btn btn-default btn-sm"><i className="fas fa-upload"></i>
                                </button>
                              </label>
                              <input id="imgUpload" ref="fileUploader" type="file" accept="image/*" style = {{ display: 'none' }} name="pic" onChange={(e)=>{this.setState({imgfile:e.target.files})}}/>
                              <label htmlFor="imgDelete">
                                <button type="button" onClick={this.handleDelete.bind(this)} className="btn btn-warning btn-sm"><i className="fas fa-trash"></i></button>
                              </label>
                            </span>
                          </span>
                        </div>  
                      </div>
                      <form className="pt-4">
                        <div className="pl-lg-4">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-username">First Name <span className="error-text">*</span> </label>
                                <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="First Name" onChange={(event)=>{this.setState({first_name:event.target.value})}} value={this.state.first_name}/>
                                <div className="text-danger">
                                {errors.first_name ? errors.first_name[0] : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Last Name</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Last Name" onChange={(event)=>{this.setState({last_name:event.target.value})}} value={this.state.last_name}/>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address">Email Address</label>
                                <input id="input-address" className="form-control form-control-alternative" placeholder="Home Address" value={userDetails.email || ""} type="text" disabled />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Username</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Last Name" value={userDetails.username || ""} disabled/>
                              </div>
                            </div>
                          </div>
                          {(this.state.vendor) && 
                           <Fragment>
                              <div className="row"> 
                                <div className="col-lg-6">
                                  <div className="form-group text-left">
                                    <label className="form-control-label" htmlFor="input-username">Email 2</label>
                                    <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="Alternative Email" onChange={(event)=>{this.setState({email2:event.target.value})}} value={this.state.email2}/>
                                    <div className="text-danger">
                                      {errors.alternative_email ? errors.alternative_email : null}
                                    </div>                                    
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group text-left">
                                    <label className="form-control-label" htmlFor="input-address">Paypal address</label>
                                    <input 
                                      id="input-address" 
                                      className="form-control form-control-alternative" 
                                      placeholder="Paypal address" 
                                      value={this.state.paypalAddress}
                                      onChange={(e) => this.setState({ paypalAddress: e.target.value })} 
                                      type="text" 
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {(localStorage.getItem('role') == 'admin') && !(this.state.creator)   ?
                                  <div className="col-lg-6">
                                    <div className="form-group mar-bot-5">
                                      <label className="form-control-label" htmlFor="input-email">Tag</label>
                                      <div className="input-group mb-3 dropdown no-caret">
                                      <Select 
                                        className="form-control"
                                        isMulti
                                        value={this.state.selectedTags}
                                        onChange={this.handleSelectedTags}
                                        options={this.state.tags}
                                      />                              
                                     </div>                             
                                    </div>
                                  </div> :null
                                }                              
                                <div className="col-lg-6">
                                  <div className="form-group text-left">
                                    <label className="form-control-label" htmlFor="input-email">Skype ID</label>
                                    <input 
                                      type="text" 
                                      id="input-email" 
                                      className="form-control form-control-alternative" 
                                      placeholder="Skype ID" 
                                      value={this.state.skypeId}
                                      onChange={(e) => this.setState({ skypeId: e.target.value })}  
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                {localStorage.getItem('role') == 'admin' ?
                                  <Fragment>
                                  <div className="col-lg-6">
                                    <div className="form-group text-left">
                                      <label className="form-control-label" htmlFor="input-username">Password</label>
                                      <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="password" onChange={(event)=>{this.setState({password:event.target.value})}} value={this.state.password}/>                                   
                                    </div>
                                    <button type="button" onClick={() => this.changePassword()} className="btn btn-click">Generate Password</button>
                                  </div>
                                  </Fragment>
                                  :null
                                }                              
                              </div>                              
                            </Fragment>
                          }
                          {(this.state.creator) &&
                           <Fragment>
                              <div className="row"> 
                                <div className="col-md-6">
                                  <div className="form-group text-left">
                                    <label className="form-control-label" htmlFor="input-address">Paypal address</label>
                                    <input 
                                      id="input-address" 
                                      className="form-control form-control-alternative" 
                                      placeholder="Paypal address" 
                                      value={this.state.paypalAddress}
                                      onChange={(e) => this.setState({ paypalAddress: e.target.value })} 
                                      type="text" 
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group text-left">
                                    <label className="form-control-label" htmlFor="input-email">Skype ID</label>
                                    <input 
                                      type="text" 
                                      id="input-email" 
                                      className="form-control form-control-alternative" 
                                      placeholder="Skype ID" 
                                      value={this.state.skypeId}
                                      onChange={(e) => this.setState({ skypeId: e.target.value })}  
                                    />
                                  </div>
                                </div>                                
                              </div>
                              <div className="row">
                                {localStorage.getItem('role') == 'admin' ?
                                  <Fragment>
                                  <div className="col-lg-6">
                                    <div className="form-group text-left">
                                      <label className="form-control-label" htmlFor="input-username">Password</label>
                                      <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="password" onChange={(event)=>{this.setState({password:event.target.value})}} value={this.state.password}/>                                   
                                    </div>
                                    <button type="button" onClick={() => this.changePassword()} className="btn btn-click">Generate Password</button>
                                  </div>
                                  </Fragment>
                                  :null
                                }                              
                              </div>                              
                            </Fragment>
                          }
                          {
                            localStorage.getItem('role') == 'admin' && !(this.state.vendor) && !(this.state.creator) ?
                              <Fragment>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group text-left">
                                      <label className="form-control-label" htmlFor="input-username">Password</label>
                                      <input type="text" id="input-username" className="form-control form-control-alternative" placeholder="password" onChange={(event)=>{this.setState({password:event.target.value})}} value={this.state.password}/>                                    
                                    </div>
                                  <button type="button" onClick={() => this.changePassword()} className="btn btn-click">Generate Password</button>
                                  </div>
                                  { url == 'clients' && 
                                    <Fragment>
                                      <div className="col-md-6">
                                        <div className="form-group mar-bot-5">
                                          <label className="form-control-label" htmlFor="input-email">Subscriptions</label>
                                          <select 
                                            className="form-control"
                                            value={this.state.subscription}
                                            onChange={(e) => this.setState({ subscription: e.target.value })}
                                          >
                                            <option value="">select</option>
                                            {this.state.subscriptions && this.state.subscriptions.length > 0 && this.state.subscriptions.map((subscription) => 
                                              <option value={subscription.id}>{subscription.name}</option>
                                            )}
                                          </select>                             
                                       </div> 
                                      </div>
                                                                            <div className="col-md-6">
                                        <div className="form-group mar-bot-5">
                                          <label className="form-control-label" htmlFor="input-email">Tag</label>
                                          <div className="input-group mb-3 dropdown no-caret">
                                          <Select 
                                            className="form-control"
                                            isMulti
                                            value={this.state.selectedTags}
                                            onChange={this.handleSelectedTags}
                                            options={this.state.tags}
                                          />                              
                                         </div>                             
                                        </div>
                                      </div>
                                    </Fragment>
                                  }
                                </div>                                                                  
                              </Fragment>:null                            
                          }
                          { url == 'clients' && 
                          <>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address" style={{fontSize:"25px"}}>Billing Info</label>
                              </div>
                            </div>
                            
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address">Company</label>
                                <input id="input-address" className="form-control form-control-alternative" placeholder="Company" onChange={(event)=>{this.setState({company:event.target.value})}} value={this.state.company} type="text" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Billing Email</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Billing Email" onChange={(event)=>{this.setState({billing_email:event.target.value})}} value={this.state.billing_email}/>
                                <div className="text-danger">
                                  {errors.billing_email ? errors.billing_email[0] : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address">Address line 1</label>
                                <input id="input-address" className="form-control form-control-alternative" placeholder="Address line 1" onChange={(event)=>{this.setState({address_line1:event.target.value})}} value={this.state.address_line1} type="text" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Address line 2</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Address line 2" onChange={(event)=>{this.setState({address_line2:event.target.value})}} value={this.state.address_line2}/>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address">City/Town</label>
                                <input id="input-address" className="form-control form-control-alternative" placeholder="City/Town" onChange={(event)=>{this.setState({city_town:event.target.value})}} value={this.state.city_town} type="text" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Country</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Country" onChange={(event)=>{this.setState({country:event.target.value})}} value={this.state.country}/>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-address">Additional Info</label>
                                <input id="input-address" className="form-control form-control-alternative" placeholder="Additional Info" onChange={(event)=>{this.setState({additional_info:event.target.value})}} value={this.state.additional_info} type="text" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group text-left">
                                <label className="form-control-label" htmlFor="input-email">Notes</label>
                                <input type="email" id="input-email" className="form-control form-control-alternative" placeholder="Notes" onChange={(event)=>{this.setState({notes:event.target.value})}} value={this.state.notes}/>
                              </div>
                            </div>
                          </div></>}
                          {/*<div className="row">
                          {(localStorage.getItem('role') === 'client') &&  
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Content Subscription : </label>
                              </div>
                            </div>
                          }
                          {(localStorage.getItem('role') === 'client') &&  
                            <div className="col-lg-8 subscription_modal">
                              <div id="emailHelp" className="text-muted text-right">
                                {this.state.presentPlan}
                                <button type="button" className="btn-info btn mr-0 ml-2" data-toggle="modal" data-target="#exampleModal">Change Subscription</button>
                                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Subscription Plans</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="row mt-3 subscriptions">
                                          {this.state.subscriptions.length > 0 && this.state.subscriptions.map((sub) =>
                                            <div className="col-lg-4" key={sub.id}>
                                              <div className="card shadow">
                                                <div className="card-header border-0">
                                                  <h3 className="mb-0 text-center"><span className="orange">{sub.name}</span></h3>
                                                  <div className="price">
                                                    ${sub.price}
                                                    <span>For {sub.frequency_interval} {sub.frequency}S</span>
                                                  </div>
                                                  <div className="points">
                                                    <ul>
                                                      <li><b>Posts Pricing:</b> price + ${sub.post_additional_pricing} + {sub.commission}%</li>
                                                      <li><b>Advanced metrics:</b> {sub.advanced_metrics ? 'Yes' : 'No'}</li>
                                                      <li><b>Discount:</b> {sub.discount && sub.discount} {sub.discount && '%'} {!sub.discount && 'No'} </li>
                                                      <li><b>
                                                      Free Link/Post replacement if website goes down with similar metrics website or money-back guarantee:
                                                      </b>
                                                      {sub.post_replacement_days} Days
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="subscribe orange">
                                                  {(this.state.precedence === sub.precedence) ?
                                                    <a >
                                                      Currently Subscribed
                                                    </a>
                                                  :
                                                    <a onClick={() => this.changeSubscription(sub.id)}>
                                                    {(this.state.precedence < sub.precedence) ? 'Upgrade' : 'Downgrade'}
                                                    </a>
                                                  }
                                                  <a href={this.state.redirectUrl} ref={this.redirectPage} style={{ display: 'none' }}></a>
                                                </div>             
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-info" data-dismiss="modal">Go Back</button>
                                        <button type="button" className="btn btn-primary">Proceed</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          </div>*/}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="modal fade col-md-6 m-auto" id="delete_subscription" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content m-auto">
                        <div className="modal-header">
                          <h5 className="modal-title" id="deleteModalLabel">Cancel Subscription</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          Are you Sure You want to Cancel Subscription ?
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-primary" data-toggle="modal" data-dismiss="modal" onClick={() => this.cancelSubscription(this.state.free_sub_id)}>Yes</button>
                          <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">No</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {localStorage.getItem('role') == 'client' && 
                    <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog" role="document" style={{maxWidth:"700px"}}>
                        <div className="modal-content">
                          <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Subscription Plans</h5> */}
                            <h5 className="modal-title" id="exampleModalLabel">PRO Account Benefits</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row mt-3 subscriptions">
                              {/* {this.state.subscriptions.length > 0 && this.state.subscriptions.map((sub) =>
                                <div className="col-lg-4" key={sub.id}>
                                  <div className="card shadow">
                                    <div className="card-header border-0">
                                      <h3 className="mb-0 text-center"><span className="orange">{sub.name}</span></h3>
                                      <div className="price">
                                        ${sub.price}
                                        <span>For 1 Month</span>
                                      </div>
                                      <div className="points">
                                        <ul>
                                          <li><b>Projects:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                                          <li><b>Backlinks Monitor:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                                          <li><b>
                                          Free Link/Post replacement or money-back guarantee:
                                          </b>
                                          {sub.name == 'Free' ? ' 3 Months' : ' 12 Months'}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="subscribe orange">
                                      {(this.state.precedence === sub.precedence) ? this.state.is_cancelled_subscription ?
                                        <a >
                                          Cancelled Subscription
                                        </a>:
                                        <a >
                                          Currently Subscribed
                                        </a>
                                      :
                                        <a style={{cursor:'pointer'}} onClick={() => this.changeSubscription(sub.id)}>
                                        {sub.precedence > this.state.precedence ? 'Upgrade' : 'downgrade'}
                                        </a>
                                      }
                                      
                                    </div>             
                                  </div>
                                </div>
                              )} */}
                              <div className="col-lg-12" >
                                  <div className="card shadow">
                                    <div className="card-header border-0">
                                      <h3 className="mb-0 text-center"><span className="orange">After you make your first plan</span></h3>
                                      <div className="price">
                                        Pro
                                        <span>WE WILL MAKE YOU PRO AUTOMATICALLY</span>
                                      </div>
                                      <div className="points">
                                        <ul>
                                          <li><b>Organize your orders in Projects</b></li>
                                          <li><b>Unlimited Website Searches</b></li>
                                          <li><b>Unlimited Saved Filter (Receive email every time we add new website that match your filter)</b></li>
                                          <li><b>Enjoy 12 months Free Link replacement or money-back guarantee</b></li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="subscribe orange">
                                        <a style={{cursor:'pointer'}} 
                                        data-toggle="modal" data-target="#alert"
                                        data-dismiss="modal"
                                        onClick={()=>this.setState({makeOrder:true})}
                                        >
                                        Make Order
                                        </a>
                                    </div>             
                                  </div>
                                 </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-info" data-dismiss="modal" id="close_subscriptions">Go Back</button>
                          </div>
                        </div>
                      </div>
                    </div> 
                  }
                  <div className="row justify-content-center">
                    <div className="col-lg-8 d-flex justify-content-center mt-3">
                      <span data-toggle="modal" data-target="#alert" id="success"/>
                      {/* {localStorage.getItem('role') == 'client' && localStorage.getItem('subscription') == "Pro" && 
                      <Fragment>
                      {this.state.is_cancelled_subscription ?
                      <button type="button" className="btn btn-click " disabled >Cancel Subscription</button>:
                      <button type="button" className="btn btn-click "  data-toggle="modal" data-target="#delete_subscription">Cancel Subscription</button>}
                      <button type="button" className="btn btn-click "  data-toggle="modal" data-target="#exampleModal">Change Subscription</button>
                      {localStorage.getItem('subscription') == "Free" && <button type="button" className="btn btn-click "  data-toggle="modal" data-target="#exampleModal">Change Subscription</button>}
                      </Fragment>} */}
                      <button type="button" className="btn btn-click " onClick={() => this.profileDataUpdate(this.state.imgfile)}>Update Profile</button>
                      <button type="button" className="btn btn-click" onClick={() => this.props.history.goBack()}>Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

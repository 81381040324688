import React, { Component, Fragment } from 'react';
import { LOGOUT, fetch_get } from '../common';
import { Link, withRouter } from 'react-router-dom';
import AdminLeftNav from '../admin/LeftNav';
import VendorLeftNav from '../vendors/LeftNav';
import CreatorLeftNav from '../creator/LeftNav';
import ClientLeftNav from '../client/LeftNav';
import PublicLeftNav from '../Public/LeftNav';

class Header extends Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedNav: window.location.pathname.split('/')[1],
      signin: window.location.pathname.split('/')[2],
      role: localStorage.getItem('role'),
      userid:localStorage.getItem('UserId'),
      isAdmin: false,
      isVendor: false,
      isClient: false,
      isPublic: false,
      show:false,
    };
    this.show = this.show.bind(this)
  }

  updateDimensions = () => {
  this.setState({})
  }

  componentDidMount() {
    this.renderNav()
    window.addEventListener("resize", this.updateDimensions);

  }

  show(val){
    this.setState({show:val})
  }

  logout() {
    const { role, selectedNav } = this.state;
    fetch_get(LOGOUT)
    .then((response) => {
      if(!response.error) {
        localStorage.clear()
        if(selectedNav === 'vendors' && role === 'vendors') {
          this.props.history.push('/vendors/signin/')
        } else if(selectedNav === 'admin' && role === 'admin'){
          this.props.history.push('/admin/signin/')
        } else if(selectedNav === 'creator' && role === 'creator'){
          this.props.history.push('/creator/signin/')
        }else {
          if(role === 'client') {
            this.props.history.push('/signin/')
          }
        }
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }  

  renderNav() {
    const { selectedNav, role, signin } = this.state;
    if(selectedNav === 'vendors') {
      if(localStorage.getItem('role') !== 'vendors' || signin === 'signin') {
        this.props.history.push('/vendors/signin')
      } else {
        this.setState({ isVendor: true })
      }
    } else if(selectedNav === 'admin') {
      if(role !== 'admin' || signin === 'signin') {
        this.props.history.push('/admin/signin')
      } else {
        this.setState({ isAdmin: true })
      }
    } else if(selectedNav === 'creator') {
      if(role !== 'creator' || signin === 'signin') {
        this.props.history.push('/creator/signin')
      } else {
        this.setState({ isCreator: true })
      }
    }else if(selectedNav === 'public') {
        this.setState({ isPublic: true })
      // }
    }else {
      if((selectedNav !== 'vendors' || selectedNav !== 'admin') && (role !== 'client' || selectedNav === 'signin')) {
        this.props.history.push('/signin')

      } else {
        this.setState({ isClient: true })
      }
    }
  }
  
  render(){
    const { selectedNav } = this.state;
    let leftnav = '';
    if(selectedNav === 'admin') {
      leftnav =  <AdminLeftNav show={this.show} />
    } else if(selectedNav === 'vendors') {
      leftnav = <VendorLeftNav show={this.show} />
    } else if(selectedNav === 'creator') {
      leftnav = <CreatorLeftNav show={this.show} />
    }else if(selectedNav === 'public') {
      leftnav = <PublicLeftNav show={this.show} />
    }
     else {
      leftnav = <ClientLeftNav show={this.show} />
    }
    return( 
      <Fragment> 
        {(this.state.isVendor || this.state.isAdmin || this.state.isClient || this.state.isCreator) &&
          <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
            <div className="container-fluid">
             <button className="border-0 navbar-toggler" type="button" onClick={()=> this.setState({show:!this.state.show})} data-toggle="collapse" aria-controls="sidenav-main" aria-expanded="true" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
              <a className="navbar-brand pt-0 pb-0 ">
                <img src="/img/brand/logo.png" className="navbar-brand-img" alt="..."/>
              </a>
              <ul className="nav align-items-center d-md-none">
                <li className="nav-item dropdown">
                  <a className="nav-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="media align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <img src="/img/theme/team-1-800x800.jpg" alt=""/>
                      </span>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right border-0">
                    <div className=" dropdown-header noti-title">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </div>
                    <Link to={ this.state.selectedNav === 'admin' ? '/admin/profile': this.state.selectedNav === 'vendors' ? '/vendors/profile' : '/profile' } className="dropdown-item">
                      <i className="ni ni-single-02"></i>
                      <span>My profile</span>
                    </Link>
                    <Link to={this.state.selectedNav === 'admin' ? `/admin/${this.state.userid}/change-password` : this.state.selectedNav === 'vendors' ? `/vendors/${this.state.userid}/change-password` : `/user/${this.state.userid}/change-password` } className="dropdown-item">
                      <i className="ni ni-key-25"></i>
                      <span>Change Password</span>
                    </Link>
                    <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.logout()}>
                      <i className="ni ni-user-run"></i>
                      <span>Log out</span>
                    </span>
                  </div>
                </li>
              </ul>
            {window.screen.width <= 767 ?
            this.state.show &&
             <div className="navbar-collapse collapse show" id="left">
                <div className="navbar-collapse-header d-md-none">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <a href>
                        <img src="/img/brand/logo.png" alt=""/>
                      </a>
                    </div>
                    <div className="col-6 collapse-close">
                      <button type="button" className="navbar-toggler" onClick={()=>this.setState({show:false})}>
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
                <form className="mt-4 mb-3 d-md-none">
                  <div className="input-group input-group-rounded input-group-merge">
                    <input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search"/>
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span className="fa fa-search"></span>
                      </div>
                    </div>
                  </div>
                </form>
                {leftnav}
             </div>
            :
            this.state.show &&
             <div className="navbar-collapse collapse show" id="left">
                <div className="navbar-collapse-header d-md-none">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <a href>
                        <img src="/img/brand/logo.png" alt=""/>
                      </a>
                    </div>
                    <div className="col-6 collapse-close">
                      <button type="button" className="navbar-toggler" data-toggle="collapse" onClick={()=>{this.setState({show:!this.state.show})}} aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
                <form className="mt-4 mb-3 d-md-none">
                  <div className="input-group input-group-rounded input-group-merge">
                    <input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search"/>
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span className="fa fa-search"></span>
                      </div>
                    </div>
                  </div>
                </form>
                {leftnav}
             </div>
            }
            <div className="navbar-collapse collapse" id="left">
                <div className="navbar-collapse-header d-md-none">
                  <div className="row">
                    <div className="col-6 collapse-brand">
                      <a href>
                        <img src="/img/brand/logo.png" alt=""/>
                      </a>
                    </div>
                    <div className="col-6 collapse-close">
                      <button type="button" className="navbar-toggler" data-toggle="collapse" onClick={()=>{this.setState({show:!this.state.show})}} aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
                <form className="mt-4 mb-3 d-md-none">
                  <div className="input-group input-group-rounded input-group-merge">
                    <input type="search" className="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search"/>
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <span className="fa fa-search"></span>
                      </div>
                    </div>
                  </div>
                </form>
                {leftnav}
            </div>
            </div>
          </nav>
        }
      </Fragment>
    )
  }
}

export default withRouter(Header);



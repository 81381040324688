import React, { Component } from 'react';

export default class Unauthorized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role:localStorage.getItem('role')
    }
  }
  render() {
    const { role  } = this.state;
    return (
      <div>
        <nav className="navbar fixed-left navbar-light bg-white" id="sidenav-main">
          <div className="container-fluid">
            <a href className="navbar-brand pt-0">
              <img src="/img/brand/logo.png" className="navbar-brand-img" alt="..."/>
            </a>
          </div>
        </nav>
        <i style={{color:"#144571",fontSize:80,display:"flex",justifyContent: "center"}} className="far fa-frown"></i>
        <h1 style={{fontSize:60, color:"#94c11f",textAlign:"center"}}>401</h1>
        <h4 style={{textAlign:"center"}}>Access Denied</h4>
        <center><a href style={{ textAlign:"center", cursor: 'pointer' }} onClick={() =>{
          if(localStorage.getItem('UserToken')){
            if(role === 'vendors') {
              this.props.history.push('/vendors/signin')
            }
            else if(role === 'admin'){
              this.props.history.push('/admin/signin')
            }
            else if(role === 'creator'){
              this.props.history.push('/creator/signin')
            }            
            else{
              this.props.history.push('/signin')
            }
          }
        }
        }> Click To Go To Dashboard </a> </center>
      </div>
    );
  }
}

import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import OrderConfirmationModal from './OrderConfirmationModal';
import OrderConfirmationSingleModal from './OrderConfirmationSingleModal';
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from '../common/TopHeader';
import Alert_selfmodel from '../common/Alert_selfmodel';
import { WEBSITES, ORDER, USERWEBSITE, fetch_get, fetch_post, Pagination, CLIENT_WEBSITES, SUBSCRIPTIONS, USER_SUBSCRIPTIONS, Alert_model, Alert, DELETEFILTER } from '../common';

let filter_categories = []
let token = ''
let options = [];
let item = {};
export default class Websites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      displayid: '',
      selectedCategories: [],
      files: [],
      errors: {},
      filter_nameerrors: {},
      websites: [],
      selectedWebsites: [],
      activeWebsites: [],
      activecurrentPage: this.props.location.state ? this.props.location.state.detail.detail : 1,
      activenumOfPages: 0,
      timeStamp: null,
      metricDate: '',
      searchText: '',
      active: true,
      clientId: '',
      clientsList: [],
      websitesIds: this.props.location.state ? this.props.location.state.detail.websiteids : [],
      filter: false,
      domain: [],
      price: this.props.location.state ? this.props.location.state.detail.prices : [],
      ordersList: this.props.location.state ? this.props.location.state.detail.ordersList : [],
      ordersList1: this.props.location.state ? this.props.location.state.detail.ordersList1 : [],
      websiteselected: false,
      // alexalow:'',
      // alexahigh:'',
      display: false,
      displayid: '',
      linksType: '',
      searchText: '',
      pricelow: '',
      pricehigh: '',
      dalow: '',
      dahigh: '',
      drlow: '',
      drhigh: '',
      adType: '',
      numberofrows: '',
      idtoggle: false,
      svalue: '',
      domaintoggle: false,
      accesstoggle: false,
      // alexatoggle:false,
      datoggle: false,
      patoggle: false,
      drtoggle: false,
      urtoggle: false,
      adtypetoggle: false,
      linktypetoggle: false,
      pricetoggle: false,
      categorytoggle: false,
      traffictoggle: false,
      organic_traffic_low: '',
      organic_traffic_high: '',
      filtered_data: {},
      name: '',
      saved_filters: [],
      savedFiltersShow: null,
      language: '',
      languages: [],
      updateFilter: { status: false, id: null },
      addNewFilter: true,
      userSubscriptionDetails: {},
      usedFilters: 0,
      display_table: false,
      search_clicked: false,
      subscriptions: [],
      redirectUrl: '',
      projectsList: [],
      projects_list: [],
      categoriesFilterOpenMenu: false,
      is_cancelled_subscription: '',
      organic_country: '',
      organicPositionToggle: false,
      Organic_keywords: '',
      min_organic_position: '',
      max_organic_position: '',
      referring_domainsToggle: false,
      ahrefs_backlinksToggle: false,
      websiteDetails: '',
      webCategories: [],
      historicalData: [],
      promo_price:false

    }
    this.redirectToPage = React.createRef();
    this.websitesList = this.websitesList.bind(this);
    this.search = this.search.bind(this);
    this.uncheckwebsites = this.uncheckwebsites.bind(this)
    // this.selectSelectedFilters = this.selectSelectedFilters.bind(this)
    var url_string = window.location.href
  }

  componentWillMount() {
    // if(this.props.location.data){
    //   this.selectSelectedFilters(this.props.location.data.filtered_data)
    // }
    var url_string = window.location.href
    var url = new URL(url_string);
    var path = url_string.substring(0, url_string.indexOf('?'))
    let token = url.searchParams.get("autologin");
    if (token) {
      localStorage.setItem('websites_page', path.split('/')[3])
      let etoken = token.slice(0, -1)
      localStorage.removeItem('UserId')
      if (!(localStorage.getItem('role'))) {
        localStorage.setItem('role', 'client');
        localStorage.setItem('UserToken', etoken);
      }
      this.getUser()
      this.setState({})
    }
  }

  getUser() {
    let formData = new FormData()
    formData.append('user_token', localStorage.getItem('UserToken'))
    fetch_post('/api/get_user/', formData)
      .then((response) => {
        if (!response.error) {
          localStorage.setItem('UserId', response.user);
        }
      })

  }


  componentDidMount() {
    if (this.props.location.data) {
      this.selectSelectedFilters(this.props.location.data.filtered_data)
    }
    else {
      this.websitesList()
    }
    this.savedFilters()
    this.getSubscriptions()
    this.projectsList()
    this.getIabCategoriesData()
    // if(this.props.location.state){
    // this.setState({ 
    //   linksType: this.props.location.state.detail.linksType,
    //   language:this.props.location.state.detail.language,
    //   selectedCategories: this.props.location.state.detail.selectedCategories,
    //   searchText: this.props.location.state.detail.searchText,
    //   pricelow: this.props.location.state.detail.pricelow,
    //   pricehigh:this.props.location.state.detail.pricehigh,
    //   dalow:this.props.location.state.detail.dalow,
    //   dahigh:this.props.location.state.detail.dahigh,
    //   drlow:this.props.location.state.detail.drlow,
    //   drhigh:this.props.location.state.detail.drhigh,
    //   adType:this.props.location.state.detail.adtype,
    //   alexalow:this.props.location.state.detail.alexalow,
    //   alexahigh:this.props.location.state.detail.alexahigh,
    //   filter:this.props.location.state.detail.filter,
    //   organic_traffic_low:this.props.location.state.detail.organic_traffic_low,
    //   organic_traffic_high:this.props.location.state.detail.organic_traffic_high,
    //   numberofrows:this.props.location.state.detail.numberofrows
    // }, this.websitesFilter)      
    // }    
  }

  uncheckwebsites() {
    this.setState({ ordersList: [], ordersList1: [], websitesIds: [], price: [] })
  }

  getSubscriptions() {
    fetch_get(USER_SUBSCRIPTIONS)
      .then((response) => {
        if (!response.error) {
          this.setState({
            subscriptions: response.subscriptions,
            presentPlan: response.current_subscription,
            is_cancelled_subscription: response.is_cancelled_subscription,
          })
          response.subscriptions.map((sub) => {
            if (sub.name == response.current_subscription.name) {
              this.setState({ precedence: sub.precedence, presentPlan: sub.name })
            }
          })
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
  }


  changeSubscription(id) {
    fetch_post(`${SUBSCRIPTIONS}${id}/`)
      .then((response) => {
        if (!response.error) {
          this.setState({
            redirectUrl: response.redirect_url
          })
          if (this.state.redirectUrl) {
            this.redirectToPage.current.click();
          }
        }
      })
      .catch(() => {
        this.setState({ error: 'Something went wrong, Please try again' });
      });
  }

  createFilter(id) {
    let i = 0, url = '', formData = new FormData();
    // let final_data={}
    for (i in this.state.filtered_data) {
      if (this.state.filtered_data[i] == '' || this.state.filtered_data[i] == []) {
        delete this.state.filtered_data[i]
      }
    }
    // final_data['name'] = this.state.name
    // final_data['filtered_data'] = this.state.filtered_data 
    if (id) {
      url = `/api/update_filter/${id}/`;
    } else {
      formData.append('name', this.state.name)
      url = '/api/create_filter/';
    }
    formData.append('filtered_data', JSON.stringify(this.state.filtered_data))
    fetch_post(url, formData)
      .then((response) => {
        if (!response.error) {
          this.savedFilters()
          this.websitesList()
          if (id) {
            this.setState({ updateFilter: { status: false, id: null }, addNewFilter: true })
          }
          this.setState({ filter_nameerrors: '', name: '', filter_nameerrors: {} })
          let model = document.getElementById("exampleModal2");
          model.click();
        }
        else {
          this.setState({ filter_nameerrors: response.errors })
        }
      })
  }

  projectsList() {
    if (localStorage.getItem('subscription') == 'Pro') {
      fetch_get(`/api/projects/list/`)
        .then((response) => {
          if (!response.error) {
            this.setState({ projects_list: response.projects_list })
          } else {
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  }

  savedFilters() {
    fetch_get('/api/filters/list/')
      .then((response) => {
        if (!response.error) {
          this.setState({
            saved_filters: response.filters,
            // usedFilters: response.filters.length
          })
        }

      })
  }

  selectSelectedFilters(filters, id, dt) {
    // this.clearFilter()
    if ('domain' in filters) {
      this.setState({ searchText: filters.domain, filtered_data: filters })
    }
    if ('pricemin' in filters) {
      this.setState({ pricelow: filters.pricemin, filtered_date: filters })
    }
    if ('pricemax' in filters) {
      this.setState({ pricehigh: filters.pricemax, filtered_date: filters })
    }
    if ('damin' in filters) {
      this.setState({ dalow: filters.damin, filtered_data: filters })
    }
    if ('damax' in filters) {
      this.setState({ dahigh: filters.damax, filtered_data: filters })
    }
    if ('drmin' in filters) {
      this.setState({ drlow: filters.drmin, filterd_data: filters })
    }
    if ('drmax' in filters) {
      this.setState({ drhigh: filters.drmax, filtered_data: filters })
    }
    // if('alexamin' in filters){
    //   this.setState({alexalow:filters.alexamin,filtered_data:filters})
    // } 
    // if('alexamax' in filters){
    //   this.setState({alexahigh:filters.alexamax,filtered_data:filters})
    // }
    if ('niche_categories' in filters) {
      this.setState({ selectedCategories: filters.niche_categories, filtered_data: filters })
    }
    if ('linksType' in filters) {
      this.setState({ linksType: filters.linksType, filtered_data: filters })
    }
    if ('adType' in filters) {
      this.setState({ adType: filters.adType, filtered_data: filters })
    }
    if ('organictrafficmin' in filters) {
      this.setState({ organic_traffic_low: filters.organictrafficmin, filtered_data: filters })
    }
    if ('organictrafficmax' in filters) {
      this.setState({ organic_traffic_high: filters.organictrafficmax, filtered_data: filters })
    }
    if ('language' in filters) {
      this.setState({ language: filters.language, filtered_data: filters })
    }
    if ('organicpositionmin' in filters) {
      this.setState({ min_organic_position: filters.organicpositionmin, filtered_data: filters })
    }
    if ('organicpositionmax' in filters) {
      this.setState({ max_organic_position: filters.organicpositionmax, filtered_data: filters })
    }
    if ('promo_price' in filters) {
      this.setState({ promo_price: filters.promo_price, filtered_data: filters })
    }
    if (this.props.location.data) {
      this.setState({ display_table: true }, this.websitesFilter)
    }
    if (dt) {
      this.setState({ display_table: false })
    }
    this.setState({ updateFilter: { status: true, id }, savedFiltersShow: null, addNewFilter: false })
    // this.createFilter(id)
    let selected = document.getElementById('exampleModal2')
    if (selected) {
      selected.click()
    }
  }

  checkOrder(websiteId = null, price = null) {
    if (this.state.websitesIds.length > 0) {
      this.setState({ websiteselected: false })
      let model = document.getElementById("cart");
      model.click();
    } else {
      this.setState({ websiteselected: true })
      let a = document.getElementById("success1");
      a.click();
      // alert('please select atleast one website to create order')
    }
  }

  websitesFilter(page1 = this.state.activecurrentPage, searchText = this.state.searchText, search = false, alert = false) {
    // const { userSubscriptionDetails } = this.state;
    // let alert =  parseInt(userSubscriptionDetails.used_available_searches) >= parseInt(userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.monthly_available_searches)
    // console.log("alert",alert)
    let i = 0;
    for (i in this.state.filtered_data) {
      if (this.state.filtered_data[i] == '' || this.state.filtered_data[i] == []) {
        delete this.state.filtered_data[i]
      }
    }
    if (alert && this.state.userSubscriptionDetails.subscription.name !== 'Pro') {
      window.alert("Your search criteria has been finished")
    } else {
      let formData = new FormData()
      formData.append('domain', searchText)
      this.state.linksType && formData.append('links_type', this.state.linksType)
      this.state.pricelow && formData.append('min_price', this.state.pricelow)
      this.state.pricehigh && formData.append('max_price', this.state.pricehigh)
      this.state.organic_traffic_low && formData.append('min_organic_traffic', this.state.organic_traffic_low)
      this.state.organic_traffic_high && formData.append('max_organic_traffic', this.state.organic_traffic_high)
      this.state.min_organic_position && formData.append('min_organic_position', this.state.min_organic_position)
      this.state.max_organic_position && formData.append('max_organic_position', this.state.max_organic_position)
      this.state.organic_country && formData.append('organic_country', this.state.organic_country)
      this.state.dalow && formData.append('min_moz_da', this.state.dalow)
      this.state.dahigh && formData.append('max_moz_da', this.state.dahigh)
      this.state.drlow && formData.append('min_dr', this.state.drlow)
      this.state.drhigh && formData.append('max_dr', this.state.drhigh)
      this.state.adType && formData.append('ad_type', this.state.adType)
      this.state.promo_price && formData.append('promo_price', this.state.promo_price)
      // this.state.alexalow && formData.append('min_alexa_rank', this.state.alexalow)
      // this.state.alexahigh && formData.append('max_alexa_rank', this.state.alexahigh)
      this.state.language && formData.append('language', this.state.language)
      formData.append('search_data', JSON.stringify(this.state.filtered_data))
      this.state.numberofrows && formData.append('custom_num_pages', this.state.numberofrows)
      let categories = [];
      this.state.selectedCategories.length > 0 && this.state.selectedCategories.map((category) =>
        categories.push(category.value)
      )
      if (categories.length > 0) {
        formData.append('niche_category', JSON.stringify(categories));
      }
      // this.state.selectedCategories && formData.append('categories', this.state.selectedCategories)
      formData.append('page', page1)
      if (this.state.svalue == 'id') {
        if (this.state.idtoggle) {
          formData.append('column_name_order', 'id')
        }
        else {
          formData.append('column_name_order', '-id')
        }
      }
      if (this.state.svalue == 'domain') {
        if (this.state.domaintoggle) {
          formData.append('column_name_order', 'website__domain')
        }
        else {
          formData.append('column_name_order', '-website__domain')
        }
      }
      if (this.state.svalue == 'da') {
        if (this.state.datoggle) {
          formData.append('column_name_order', 'website__moz_da')
        }
        else {
          formData.append('column_name_order', '-website__moz_da')
        }
      }
      if (this.state.svalue == 'pa') {
        if (this.state.patoggle) {
          formData.append('column_name_order', 'website__moz_pa')
        }
        else {
          formData.append('column_name_order', '-website__moz_pa')
        }
      }
      if (this.state.svalue == 'dr') {
        if (this.state.drtoggle) {
          formData.append('column_name_order', 'website__ahrefs_domain_rating_dr')
        }
        else {
          formData.append('column_name_order', '-website__ahrefs_domain_rating_dr')
        }
      }
      if (this.state.svalue == 'ur') {
        if (this.state.urtoggle) {
          formData.append('column_name_order', 'website__ahrefs_url_rating_ur')
        }
        else {
          formData.append('column_name_order', '-website__ahrefs_url_rating_ur')
        }
      }
      if (this.state.svalue == 'adtype') {
        if (this.state.adtypetoggle) {
          formData.append('column_name_order', 'ad_type')
        }
        else {
          formData.append('column_name_order', '-ad_type')
        }
      }
      if (this.state.svalue == 'price') {
        if (this.state.pricetoggle) {
          formData.append('column_name_order', 'price')
        }
        else {
          formData.append('column_name_order', '-price')
        }
      }
      // if(this.state.svalue == 'alexa'){
      //   if(this.state.alexatoggle){
      //     formData.append('column_name_order','website__alexa_rank')
      //   }
      //   else{
      //     formData.append('column_name_order','-website__alexa_rank')
      //   }
      // }
      if (this.state.svalue == 'organic') {
        if (this.state.traffictoggle) {
          formData.append('column_name_order', 'website__organic_traffic')
        }
        else {
          formData.append('column_name_order', '-website__organic_traffic')
        }
      }
      if (this.state.svalue == 'organicPosition') {
        if (this.state.organicPositionToggle) {
          formData.append('column_name_order', 'website__organic_keywords')
        }
        else {
          formData.append('column_name_order', '-website__organic_keywords')
        }
      }
      if (this.state.svalue == 'ahrefs_backlinks') {
        if (this.state.ahrefs_backlinksToggle) {
          formData.append('column_name_order', 'website__ahrefs_backlinks')
        }
        else {
          formData.append('column_name_order', '-website__ahrefs_backlinks')
        }
      }
      if (this.state.svalue == 'ahrefs_referring_domains') {
        if (this.state.referring_domainsToggle) {
          formData.append('column_name_order', 'website__ahrefs_referring_domains')
        }
        else {
          formData.append('column_name_order', '-website__ahrefs_referring_domains')
        }
      }
      if (this.state.searchText == '' && this.state.pricelow == '' && this.state.pricehigh == '' && this.state.dalow == '' && this.state.dahigh == '' &&
        this.state.drlow == '' && this.state.drhigh == '' && this.state.min_organic_position == '' && this.state.max_organic_position == ''
        // && this.state.alexalow == '' && this.state.alexahigh == '' 
        && this.state.categories.length == 0 && this.state.linksType == '' && this.state.adType == '' && this.state.organic_traffic_low == '' && this.state.organic_traffic_high == '' && this.state.language == '') {
        formData.append('filter', false)
      }
      else {
        formData.append('filter', true)
      }
      fetch_post(`${CLIENT_WEBSITES} `, formData)
        .then((response) => {
          this.setState({
            activeWebsites: response.active_websites,
            activenumOfPages: response.num_pages,
            userSubscriptionDetails: response.user_subscription_details,
            usedFilters: response.user_subscription_details.used_available_filters,
            usedSearches: response.user_subscription_details.used_available_searches,
            // addNewFilter: true
            // activecurrentPage: response.page1,
            // inActivecurrentPage: response.page2,
          }, window.scroll(0, 0))
          if (this.state.search_clicked) {
            this.setState({ display_table: true })
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  }

  clearFilter() {
    this.getIabCategoriesData()
    this.setState({
      linksType: '',
      selectedCategories: [],
      searchText: '',
      pricelow: '',
      pricehigh: '',
      dalow: '',
      dahigh: '',
      drlow: '',
      drmax: '',
      drhigh: '',
      adType: '',
      // alexalow:'',
      // alexahigh:'',
      organic_traffic_low: '',
      organic_traffic_high: '',
      min_organic_position: '',
      max_organic_position: '',
      filtered_data: {},
      language: '',
      organic_country: '',
      popupCategories: [],
      promo_price:false
    }, this.websitesFilter)
  }

  websitesList(page1 = this.state.activecurrentPage) {
    fetch_get(`${CLIENT_WEBSITES}?page=${page1}`
    )
      .then((response) => {
        // let categories=[]
        // response.categories.map((category)=>{
        //   categories.push({ label: category.categories__name, value: category.categories__id })
        // })
        this.setState({
          activeWebsites: response.active_websites,
          activenumOfPages: response.num_pages,
          // categories:categories,
          languages: response.languages,
          userSubscriptionDetails: response.user_subscription_details,
          usedFilters: response.user_subscription_details.used_available_filters,
          usedSearches: response.user_subscription_details.used_available_searches,
          // activecurrentPage: response.page1,
          // inActivecurrentPage: response.page2,
          deleteId: null,
        })
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  // rows_count(){
  //   // let formData = new FormData()
  //   // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   // formData.append('page',this.state.activecurrentPage)   
  //   // fetch_post(`${CLIENT_WEBSITES}`,formData)
  //   // .then((response) => {
  //   //   if(!response.error){
  //   //     this.setState({
  //   //     activeWebsites: response.active_websites, 
  //   //     activenumOfPages: response.num_pages,
  //   //     deleteId: null,
  //   //     })                   
  //   //   }
  //   // })
  //   this.websitesFilter()
  // }  

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${CLIENT_WEBSITES}`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //     activeWebsites: response.active_websites, 
    //     activenumOfPages: response.num_pages,
    //     deleteId: null,
    //     })                   
    //   }
    // })    
    this.setState({ activecurrentPage: currentPage }, this.websitesFilter)
  }

  search(searchText) {
    this.setState({ searchText }, this.websitesFilter(this.state.activecurrentPage, searchText))
  }

  upgrade(upgrade) {
    let u = document.getElementById('up')
    u.click()
  }

  updateSingleWebsite(websiteId, domain, price, ad_type) {
    let orderData1 = {}
    orderData1['websiteId'] = websiteId
    orderData1['domain'] = domain
    orderData1['price'] = price
    orderData1['ad_type'] = ad_type
    orderData1['total'] = price
    if (this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      // this.state.websitesIds.splice(indexx, 1);
      // this.state.ordersList1.splice(indexx, 1);
      this.state.ordersList.splice(indexx, 1);
      this.setState({ ordersList1: this.state.ordersList1, websitesIds: [parseInt(websiteId)] })
      // if(this.state.ordersList.length === 0){
      //   this.setState({websiteIds:[],ordersList:[],})
      //   let b = document.getElementById('success1')
      //   b.click()
      // }
      //   this.setState({websitesIds:[...this.state.websitesIds.filter((data)=>{
      //   if(data.websiteId == websiteId){
      //     console.log("dataew",data)
      //     return data
      //   }
      // })]}) 
      this.setState({
        ordersList1: [...this.state.ordersList1.filter((data) => {
          if (data.websiteId == websiteId) {
            return data
          }
        })]
      })
    }
    else {
      this.setState({ websitesIds: [parseInt(websiteId)], price: [...this.state.price, price], ordersList1: [orderData1] })
      // let b = document.getElementById('confirmationsinglemodal')
      // b.click()
    }
    if (websiteId) {
      this.setState({ websiteselected: false })
      let model = document.getElementById("confirmationsinglemodal");
      model.click();
    } else {
      alert('please select atleast one website to create order')
    }
  }

  updateWebsitesList(websiteId, domain, price, ad_type) {
    let orderData = {}
    orderData['websiteId'] = websiteId
    orderData['domain'] = domain
    orderData['price'] = price
    orderData['ad_type'] = ad_type
    orderData['total'] = price
    if (this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      this.state.websitesIds.splice(indexx, 1);
      this.state.domain.splice(indexx, 1);
      this.setState({ websitesIds: this.state.websitesIds })
      this.state.price.splice(indexx, 1);
      // this.state.ordersList.splice(indexx, 1);
      this.setState({
        ordersList: [...this.state.ordersList.filter((data) => {
          if (data.websiteId != websiteId) {
            return data
          }
        })]
      })
      this.setState({ websitesIds: this.state.websitesIds })
    } else {
      this.setState({ websitesIds: [...this.state.websitesIds, parseInt(websiteId)], price: [...this.state.price, price], ordersList: [...this.state.ordersList, orderData], ordersList1: [...this.state.ordersList1, orderData] })
    }
  }

  mainContent() {
    if (this.state.display) {
      this.setState({ display: !this.state.display })
    }
  }

  deleteFilter(id) {
    fetch_get(`${DELETEFILTER}${id}/`)
      .then((response) => {
        this.savedFilters()
        alert(response.message)
      })
      .catch((error) =>
        console.log(error)
      )
  }

  addOrUpdateFilter() {
    // var result = window.confirm("Would you like to update this filter? ")
    // console.log(this.state.updateFilter.status)
    // this.setState({ savedFiltersShow: null })
    // if(this.state.updateFilter.status) {
    //   var result = window.confirm("Would you like to update this filter? ")
    //   if (result === true) {
    //     this.createFilter(this.state.updateFilter.id)
    //   } else {
    //     // this.setState({ updateFilter: false })
    //     console.log('in')
    //     let filter = document.getElementById("exampleModal2")
    //     filter.className = ("modal fade show");
    //     filter.style.display = "block";
    //     this.setState({ savedFiltersShow:false })
    //   }
    // } else {
    //   console.log('in')
    //   // this.setState({ updateFilter: false })
    //   let filter = document.getElementById("exampleModal2")
    //   filter.className = ("modal fade show");
    //   filter.style.display = "block";
    this.setState({ savedFiltersShow: false }, () => this.websitesList)

    // }
  }

  rendorProject(project, websiteid) {
    let projectName = project
    if (!projectName) {
      projectName = ''
    }
    return (
      <td style={{ paddingLeft: 0, paddingRight: 0 }}>
        <select
          value={projectName}
          onChange={(e) => this.updateProject(websiteid, e.target.value, project)}
          className="form-control"
        >
          <option value="">Select</option>
          {this.state.projects_list.map((project) =>
            <option value={project.id}>{project.name}</option>
          )}
        </select>
      </td>
    )
  }

  updateProject(websiteid, project, previous) {
    if (project == '') {
      if (previous) {
        let formData = new FormData()
        formData.append('user_website_id', websiteid)
        { previous && formData.append('prev_project_id', previous) }
        fetch_post(`${CLIENT_WEBSITES}`, formData)
          .then((response) => {
            if (!response.error) {
              let a = document.getElementById('remove_modal')
              a.click()
              this.setState({
                activeWebsites: response.active_websites,
                activenumOfPages: response.num_pages,
                // categories:categories,
                languages: response.languages,
                userSubscriptionDetails: response.user_subscription_details,
                usedFilters: response.user_subscription_details.used_available_filters,
                // activecurrentPage: response.page1,
                // inActivecurrentPage: response.page2,
                deleteId: null,
              })
            }
          })
          .catch((error) => {
            this.setState({ error });
          });
      }
    }
    else {
      let formData = new FormData()
      if (project != previous) {
        { previous && formData.append('prev_project_id', previous) }
      }
      formData.append('user_website_id', websiteid)
      fetch_post(`/api/project/${project}/details/ `, formData)
        .then((response) => {
          if (!response.error) {
            let a = document.getElementById('remove_modal')
            a.click()
            this.websitesFilter()
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
  }

  addWebsite(pid, wid) {
    if (pid == undefined) {
      pid = ''
    }
    let a = document.getElementById('project_modal')
    this.setState({ pid: pid, current_pid: pid, wid: wid }, a.click())
    // let a = document.getElementById('project_modal')
  }

  removeErrors() {
    this.setState({ errors: {} })
  }

  createProject() {
    let key = this.state.keywords ? this.state.keywords.split(',') : null
    // this.setState({key:key})
    let formData = new FormData()
    formData.append('user_website_id', this.state.wid)
    formData.append('name', this.state.name)
    formData.append('target_url', this.state.target_url)
    key && formData.append('keywords', JSON.stringify(key))
    fetch_post('/api/project/create/', formData)
      .then((response) => {
        if (!response.error) {
          this.setState({ wid: '', name: '', target_url: '', keywords: '', showProjectForm: false, pid: '', wid: '', current_pid: '' })
          let a = document.getElementById('remove_modal');
          a.click();
          this.projectsList()
          this.websitesFilter();
        }
        else {
          this.setState({ errors: response.errors })
        }
      })
  }

  getIabCategoriesData() {
    let parent = ''
    fetch_get('/api/niche-category/list/')
      .then((response) => {
        if (!response.error) {
          let niche_categories = []
          response.categories_list.map((category) => {
            // if(true){
            if (category.parent == null) {
              parent = category.name
              options = []
              item = {}
            }
            if (category.parent == parent) {
              options.push({ label: category.name, value: category.id })
            }
            else {
              item = {
                label: <a onClick={() => { this.onOptionsClick(category, response.categories_list) }}><h6 style={{ color: 'black', cursor: 'pointer' }}>{category.name}</h6></a>,
                options: options
              }
              niche_categories.push(item)
            }
            // }

          })
          this.setState({ nicheCategories: niche_categories })
        }
      })

  }

  onOptionsClick(category, categoryList) {
    categoryList.map((obj) => {
      if (category.name == obj.parent) {
        let found = this.state.selectedCategories.some(sc => sc.label == obj.name)
        if (!found) {
          this.state.selectedCategories.push({ label: obj.name, value: obj.id })
        }
      }
    })
    let nicheCategories = this.state.nicheCategories.filter((e, i) => {
      if (e.label.props.children.props.children !== category.name) {
        return e
      }
    })
    this.setState({ selectedCategories: this.state.selectedCategories, nicheCategories: nicheCategories })
  }
  websitesDetails(id, categories) {
    this.setState({popupCategories: categories}) 
    fetch_get(`${USERWEBSITE}/${id}/details/ `)
      .then((response) => {
        this.setState({
          websiteDetails: response.user_website_serializer,
          webCategories: response.user_website_serializer.website.categories,
          historicalData: response.user_website_serializer.website.historical_data,
          adTypesData: response.user_website_serializer.ad_types_data,
        })
      })
      .catch((error) => {
        this.setState({ error });
      });
  }


  render() {
    const { userSubscriptionDetails, websiteDetails, webCategories, historicalData, adTypesData } = this.state;
    const txtStyleH = { color: "#0000006b", fontSize: "11px", fontWeight: 400 }
    const txtStyle = { color: "#100e4d", fontSize: "22px", fontWeight: 500 }
    const txtStyleH1 = { color: "white", fontWeight: "bolder", marginLeft: "15px" }
    const txtStyleH2 = { fontWeight: 500, fontSize: "24px", color: "#100e4d", backgroundColor: "white", borderRadius: "inherit" }
    return (
      <div className="main-content client_web" onClick={() => this.mainContent()}>
        <TopHeader search={this.search} upgrade={this.upgrade} isSearch={true} isupgrade={true} />
        <Alert_selfmodel msg={'order created successfully'} role='client' />
        <a href={this.state.redirectUrl} ref={this.redirectToPage} style={{ display: 'none' }}></a>
        {this.state.websiteselected ? <Alert_model msg={'please select atleast one website to create order'} /> : <Alert_model msg={'order created successfully'} />}
        <Alert msg={'please select atleast one website to create order'} />
        <div class="modal fade" id="exampleModal2" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <center>{!this.state.savedFiltersShow ? 'Save Filter' : 'Saved Filters'}</center>
                <button type="button" class="close" id="#exampleModal2" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                <form id="form1">
                  {!this.state.savedFiltersShow &&
                    <div className="row">
                      {(this.state.updateFilter.status && !this.state.addNewFilter) &&
                        <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                          <div className="form-group">
                            <label>Do you want to update this filter ?? </label>
                            <br />
                            <div className="row justify-content-center">
                              <button type="button" className="btn btn-info" onClick={() => this.createFilter(this.state.updateFilter.id)}>Yes</button>
                              <button type="button" className="btn btn-info" onClick={() => this.setState({ addNewFilter: true })}>No</button>
                            </div>
                          </div>
                        </div>
                      }
                      {this.state.addNewFilter &&
                        <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.name}
                              onChange={(e) => this.setState({ name: e.target.value, filter_nameerrors: {} })}
                              placeholder="Name"
                            />
                            <div className="text-danger">
                              {this.state.filter_nameerrors && this.state.filter_nameerrors.name}
                            </div>
                            <br />
                            <div className="row justify-content-center">
                              <button type="button" className="btn btn-info" onClick={() => this.createFilter()}>Save Filter</button>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>{this.state.usedFilters}/{userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.monthly_available_filters} filters used</div>
                          </div>
                        </div>
                      }
                    </div>
                  }

                  {this.state.savedFiltersShow &&
                    <div className="row">
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th width="3%"></th>
                              <th colSpan="2">saved filters  {this.state.usedFilters}/{userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.monthly_available_filters} filters used</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.saved_filters.length > 0 ? this.state.saved_filters.map((val) =>
                              <tr style={{ cursor: 'pointer' }} key={val.id} onClick={() => this.selectSelectedFilters(val.filtered_data, val.id, true)}>
                                <td>
                                  <input
                                    type="radio"
                                    onChange={() => this.selectSelectedFilters(val.filtered_data, val.id, true)}
                                  />
                                </td>
                                <td>
                                  {val.name}
                                </td>
                                <td style={{ textAlign: 'center' }} onClick={() => this.deleteFilter(val.id)}>
                                  <i style={{ fontSize: '18px' }} className="fas fa-times" ></i>
                                </td>
                              </tr>
                            )
                              :
                              <tr>
                                <th scope="row">No Saved Filters Found</th>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document" style={{ maxWidth: "800px" }}>
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Subscription Plans</h5> */}
                <h5 className="modal-title" id="exampleModalLabel">PRO Account Benefits</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row mt-3 subscriptions">
                  {/* {this.state.subscriptions.length > 0 && this.state.subscriptions.map((sub) =>
                    <div className="col-lg-4" key={sub.id}>
                      <div className="card shadow">
                        <div className="card-header border-0">
                          <h3 className="mb-0 text-center"><span className="orange">{sub.name}</span></h3>
                          <div className="price">
                            ${sub.price}
                            <span>For 1 Month</span>
                          </div>
                          <div className="points">
                            <ul>
                              <li><b>Projects:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                              <li><b>Backlinks Monitor:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                              <li><b>
                                Free Link/Post replacement or money-back guarantee:
                              </b>
                                {sub.name == 'Free' ? ' 3 Months' : ' 12 Months'}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="subscribe orange">
                          {(this.state.precedence === sub.precedence) ? this.state.is_cancelled_subscription ?
                            <a>
                              Cancelled Subscription
                            </a> :
                            <a >
                              Currently Subscribed
                            </a>
                            :
                            <a style={{ cursor: 'pointer' }} onClick={() => this.changeSubscription(sub.id)}>
                              {sub.precedence > this.state.precedence ? 'Upgrade' : 'downgrade'}
                            </a>
                          }

                        </div>
                      </div>
                    </div>
                  )} */}
                  <div className="col-lg-12" >
                                  <div className="card shadow">
                                    <div className="card-header border-0">
                                      <h3 className="mb-0 text-center"><span className="orange">After you make your first plan</span></h3>
                                      <div className="price">
                                        Pro
                                        <span>WE WILL MAKE YOU PRO AUTOMATICALLY</span>
                                      </div>
                                      <div className="points">
                                        <ul>
                                          <li><b>Organize your orders in Projects</b></li>
                                          <li><b>Unlimited Website Searches</b></li>
                                          <li><b>Unlimited Saved Filter (Receive email every time we add new website that match your filter)</b></li>
                                          <li><b>Enjoy 12 months Free Link replacement or money-back guarantee</b></li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="subscribe orange">
                                        <a style={{cursor:'pointer'}} 
                                        // data-toggle="modal" data-target="#alert"
                                        data-dismiss="modal"
                                        // onClick={()=>this.setState({makeOrder:true})}
                                        onClick={()=>this.checkOrder()}
                                        >
                                        Make Order
                                        </a>
                                    </div>             
                                  </div>
                                 </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-info" data-dismiss="modal">Go Back</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="projectModal" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">Add to Project and order later</h3>
                <div className="row justify-content-center">
                </div>
                <div className="row">
                  <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                    <div className="form-group">
                      <label>Choose project </label>
                      <select
                        onChange={(e) => this.setState({ current_pid: e.target.value })}
                        value={this.state.current_pid}
                        className="form-control"
                      >
                        <option value="">Select</option>
                        {this.state.projects_list && this.state.projects_list.map((project) =>
                          <option value={project.id}>{project.name}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <button type="button" className="btn btn-default" onClick={() => this.updateProject(this.state.wid, this.state.current_pid, this.state.pid)}>add</button>
                  <button type="button" className="btn btn-info" onClick={() => this.setState({ showProjectForm: true })} >Add to New Project</button>
                  <span id="remove_modal" data-dismiss="modal"></span>
                </div>
                {this.state.showProjectForm &&
                  <div style={{ paddingTop: "3rem" }}>
                    <form id="form1">
                      <div className="row">
                        <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                          <div className="form-group">
                            <label>Project Name <div className="error-text">*</div></label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.name}
                              onChange={(e) => this.setState({ name: e.target.value })}
                              placeholder="Name"
                            />
                            <div className="text-danger">
                              {this.state.errors && this.state.errors.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                          <div className="form-group">
                            <label>Target Url <div className="error-text">*</div></label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.target_url}
                              onChange={(e) => this.setState({ target_url: e.target.value })}
                              placeholder="Target Url"
                            />
                            <div className="text-danger">
                              {this.state.errors && this.state.errors.target_url}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                          <div className="form-group">
                            <label>keywords <div className="error-text">*</div></label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.keywords}
                              onChange={(e) => this.setState({ keywords: e.target.value })}
                              placeholder="enter , seperated values"
                            />
                            <div className="text-danger">
                              {this.state.errors && this.state.errors.keywords}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center" style={{ paddingBottom: "3rem" }}>
                        <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                        <button type="button" className="btn btn-info" onClick={() => this.createProject()} >Create and Add</button>
                        <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={() => this.removeErrors()}>Cancel</button>
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="websiteModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content" style={{ background: 'linear-gradient(90deg, rgba(61,127,236,1) 24%, rgba(1,198,246,1) 83%)', borderRadius: "8px" }} >
              <div class="modal-header" style={{ border: "none" }}>
                <h1 class="modal-title" id="exampleModalLabel" style={txtStyleH1}>{websiteDetails && websiteDetails.website.domain}</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="container">
                  <div class="card shadow" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0" style={txtStyleH2}>Domain Information </div>
                    <div class="row justify-content-center mt-3 mb-3">
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>AGE</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.domain_age === null && "---" || websiteDetails && websiteDetails.website.domain_age}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>GOOGLE INDEXES</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.google_indexed === null && "---" || websiteDetails && websiteDetails.website.google_indexed}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>CONTENT LANGUAGE</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.language === null && "---" || websiteDetails && websiteDetails.website.language}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>ENCRYPTED</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.protocols === null && "---" || websiteDetails && websiteDetails.website.protocols}</div>
                      </div>
                    </div>
                  </div>
                  <div class="card shadow mt-2" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0" style={txtStyleH2}>Publication Informations</div>
                    <div class="row justify-content-center mt-3 mb-3">
                      <div class="col">
                        <div class="col mb-4" style={txtStyleH}>LINK TYPE</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.links_type === null && "---" || websiteDetails && websiteDetails.links_type}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-4" style={txtStyleH}>POST DURATION</div>
                        <div class="col mt-1" style={txtStyle}>{(websiteDetails && websiteDetails.permanent_article === false && "---") || (websiteDetails && websiteDetails.permanent_article === true && "Permanent")}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>MINIMUM WORDS</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.minimum_word_count === null && "---" || websiteDetails && websiteDetails.minimum_word_count}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-4" style={txtStyleH}>SPONSORED TAG</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.links_type === null && "No" || websiteDetails && websiteDetails.links_type === "Sponsored" && "Yes" || websiteDetails && websiteDetails.links_type === "Nofollow" && "No" || websiteDetails && websiteDetails.links_type === "Dofollow" && "No" || websiteDetails && websiteDetails.links_type === "UGC" && "No"}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>MAX LINKS IN POSTS</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.maximum_number_of_links_in_post === null && '---' || websiteDetails && websiteDetails.maximum_number_of_links_in_post}</div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3 mb-3">
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>EXAMPLE POST</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.example_post === '' && '---' || websiteDetails && websiteDetails.example_post}</div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3 mb-3">
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>SPECIAL NOTES OR REQUIREMENT</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.client_note === null && "---" || websiteDetails && websiteDetails.client_note}</div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3 mb-3">
                      {adTypesData && adTypesData !== null && adTypesData.length && adTypesData.map((a, i) => <div key={i} class="col"> <div class="col mb-3" style={txtStyleH}>{a.ad_type}</div><div class="col mt-1" style={txtStyle}>${a.min_price}</div> </div>)}
                    </div>
                  </div>
                  <div class="card shadow mt-2" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0" style={txtStyleH2}>Categories</div>
                    <div className='mt-1 mb-2' style={{display:'flex',flexWrap:'wrap',justifyContent:'flex-start',width: '100%',padding:'0px 20px 10px 20px'}}>
                      {this.state.popupCategories && this.state.popupCategories.length ? this.state.popupCategories.map((category, index) => 
                      <div key={category} style={{ backgroundColor: "rgba(97, 112, 228, 0.68)", borderRadius: "5px",padding: "0.2rem 0.3rem",marginRight: "2px", fontSize: "12px" ,marginBottom:'6px'}}>
                        <span>{category}</span>
                      </div>)
                      : <div class="col">
                          <div class="col mt-1 ml--4" style={txtStyle}>---</div>
                        </div>
                      }
                    </div>
                  </div> 
                  <div class="card shadow mt-2" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0" style={{ backgroundColor: "white", borderRadius: "inherit" }}>
                      <div className='row '>
                        <div className='col ' style={{ fontWeight: 500, fontSize: "24px", color: "#100e4d", backgroundColor: "black" }}><img height={"60px"} src="/img/brand/ahrefs.png" alt="ahrefs" /></div>
                        <div className='col' style={{ backgroundColor: "black" }}></div><div className='col' style={{ backgroundColor: "black" }}></div>
                        <div className='col'>
                          <div className='col' style={txtStyleH}>AHREFS RANK</div>
                          <div className='col' style={txtStyle}>{websiteDetails && websiteDetails.website.ahrefs_rank_of_domain === null && "---" || websiteDetails && websiteDetails.website.ahrefs_rank_of_domain}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="row justify-content-center mt-3 mb-3">
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>DOMAIN RATING(DR)</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.ahrefs_domain_rating_dr === null && "---" || websiteDetails && websiteDetails.website.ahrefs_domain_rating_dr}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>TOTAL BACKLINKS</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.ahrefs_backlinks === null && "---" || websiteDetails && websiteDetails.website.ahrefs_backlinks}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>REFERRING DOMAINS</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.ahrefs_referring_domains === null && "---" || websiteDetails && websiteDetails.website.ahrefs_referring_domains}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>URL RATING(UR)</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.ahrefs_url_rating_ur === null && "---" || websiteDetails && websiteDetails.website.ahrefs_url_rating_ur}</div>
                        </div>
                      </div>
                      <div class="row justify-content-center mt-3 mb-3">
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>ORGANIC KEYWORDS</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.organic_keywords === null && "---" || websiteDetails && websiteDetails.website.organic_keywords}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.organic_traffic === null && "---" || websiteDetails && websiteDetails.website.organic_traffic}</div>
                        </div>

                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>US ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || (historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "United States" ? <div key={i}>{h.traffic_data__traffic}</div> : ''))}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>UK ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "Great Britain" ? <div key={i}>{h.traffic_data__traffic}</div> : '')}</div>
                        </div>
                      </div>
                      <div class="row justify-content-center mt-3 mb-3">
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>CA ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "Canada" ? <div key={i}>{h.traffic_data__traffic}</div> : '')}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>DE ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "Germany" ? <div key={i}>{h.traffic_data__traffic}</div> : '')}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>IN ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "India" ? <div key={i}>{h.traffic_data__traffic}</div> : '')}</div>
                        </div>
                        <div class="col">
                          <div class="col mb-3" style={txtStyleH}>AU ORGANIC TRAFFIC</div>
                          <div class="col mt-1" style={txtStyle}>{historicalData === null && "---" || historicalData && historicalData !== null && historicalData.length && historicalData.map((h, i) => h.country === "Australia" ? <div key={i}>{h.traffic_data__traffic}</div> : '')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card shadow mt-2" style={{ borderRadius: "10px" }}>
                    <div className="card-header border-0" style={{ backgroundColor: "white", borderRadius: "inherit" }}>
                      <div className='row '>
                        <div className='col' style={txtStyleH2}><img width={"100px"} src="/img/brand/moz.png" alt="MOZ" /></div>
                        <div className='col '></div><div className='col '></div>
                        <div className='col '>
                          <div className='col' style={txtStyleH}>DOMAIN AUTHORITY</div>
                          <div className='col' style={txtStyle}>{websiteDetails && websiteDetails.website.moz_da === null && "---" || websiteDetails && websiteDetails.website.moz_da}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3 mb-3">
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>PAGE AUTHORITY</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.moz_pa === null && "---" || websiteDetails && websiteDetails.website.moz_pa}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>LINKING DOMAINS</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.moz_linking_domains === null && "---" || websiteDetails && websiteDetails.website.moz_linking_domains}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>INBOUND LINKS</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.moz_inbound_links === null && "---" || websiteDetails && websiteDetails.website.moz_inbound_links}</div>
                      </div>
                      <div class="col">
                        <div class="col mb-3" style={txtStyleH}>SPAM SCORE</div>
                        <div class="col mt-1" style={txtStyle}>{websiteDetails && websiteDetails.website.moz_spam_score === null && "---" || websiteDetails && websiteDetails.website.moz_spam_score}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{ border: "none" }}>
                <div class="mr-7" style={{ color: "white", fontWeight: 500, fontSize: "large" }}>Publish {websiteDetails && websiteDetails.ad_type} on {websiteDetails && websiteDetails.website.domain} for ${websiteDetails && websiteDetails.promo_price > 0 ?websiteDetails.promo_price : websiteDetails.sell_price}</div>
                <button type="button" className="btn btn-info" data-dismiss="modal" style={{ backgroundColor: "#25a82bb3", fontWeight: 400, marginRight: "15px", border: "none" }}
                  onClick={() => this.updateSingleWebsite(websiteDetails.id, websiteDetails.website.domain, websiteDetails.promo_price > 0 ?websiteDetails.promo_price : websiteDetails.sell_price, websiteDetails.ad_type)} >Order Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7 ">
          <div className="col">
            <div className="row main_list_container">
              <div className="col-md-12 m-auto client_websites">
                <div className="card shadow">
                  <div className="row card-header bg-white border-0">
                    <h3 className="mb-0 pt-2 col-md-2 d-flex">show
                      <div>
                        <label className="custom-toggle mb-0">
                          <select
                            placeholder="show"
                            value={this.state.numberofrows}
                            onChange={(e) => this.setState({ numberofrows: e.target.value }, this.websitesFilter)}
                          >
                            <option value="">select</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </h3>
                    <h3 className="mb-0 pt-2 col-md-3 d-flex">Websites</h3>
                    {(this.state.filter && (userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.name !== 'Pro')) ?
                      <h3 className="mb-0 pt-2 col-md-3 d-flex">
                        {userSubscriptionDetails.used_available_searches}/{userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.monthly_available_searches} searches used
                      </h3>
                      :
                      <h3 className="mb-0 pt-2 col-md-3 d-flex"></h3>
                    }
                    <div className="col-md-4 text-right website_filter">
                      <button type="button" className="btn btn-info" style={{ backgroundColor: "#17a2b8" }} onClick={() => this.checkOrder()} >Create Order</button>
                      <button type="button" className="btn btn-info" style={{ backgroundColor: "#17a2b8" }} data-toggle="modal" data-target="#exampleModal2" onClick={() => this.setState({ savedFiltersShow: true, filter: true })} >Saved filters</button>
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result p-3">
                            <form>
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Domain"
                                      name="domain"
                                      value={this.state.searchText}
                                      onChange={(e) => this.setState({ searchText: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group mar-bot-5">
                                    <select
                                      className="form-control"
                                      name="organic_country"
                                      onChange={(e) => this.setState({ organic_country: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                      value={this.state.organic_country}
                                    >
                                      <option value="">Organic Traffic Country</option>
                                      <option value="United States">United States - us</option>
                                      <option value="Great Britain">Great Britain - gb</option>
                                      <option value="Australia">Australia - au</option>
                                      <option value="Canada">Canada - ca</option>
                                      <option value="Germany">Germany - de</option>
                                      <option value="India">India - in</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Organictraffic (Min)"
                                      name="organictrafficmin"
                                      onChange={(e) => this.setState({ organic_traffic_low: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.organic_traffic_low}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Organictraffic (Max)"
                                      name="organictrafficmax"
                                      onChange={(e) => this.setState({ organic_traffic_high: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.organic_traffic_high}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Keywords(Min)"
                                      name="organicPositionMin"
                                      onChange={(e) => this.setState({ min_organic_position: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.min_organic_position}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="keywords(Max)"
                                      name="organicPositionMax"
                                      onChange={(e) => this.setState({ max_organic_position: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.max_organic_position}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Price (Min)"
                                      name="pricemin"
                                      onChange={(e) => this.setState({ pricelow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.pricelow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Price (Max)"
                                      name="pricemax"
                                      onChange={(e) => this.setState({ pricehigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.pricehigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="DA (Min)"
                                      name="damin"
                                      onChange={(e) => this.setState({ dalow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.dalow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="DA (Max)"
                                      name="damax"
                                      onChange={(e) => this.setState({ dahigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.dahigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="DR (Min)"
                                      name="drmin"
                                      onChange={(e) => this.setState({ drlow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.drlow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="DR (Max)"
                                      name="drmax"
                                      onChange={(e) => this.setState({ drhigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.drhigh}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Min)"
                                      name="alexamin"
                                      onChange={(e) => this.setState({ alexalow: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value} })}
                                      value={this.state.alexalow}
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Max)"
                                      name="alexamax"
                                      onChange={(e) => this.setState({ alexahigh: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value} })}
                                      value={this.state.alexahigh}
                                    />
                                  </div>
                                </div>                                                                                                                                                                 */}
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <Select
                                      className="form-control"
                                      value={this.state.selectedCategories}
                                      onChange={(e) => { if (e.length == 0) { this.getIabCategoriesData() }; this.setState({ selectedCategories: e, filtered_data: { ...this.state.filtered_data, ['niche_categories']: e } }, this.websitesFilter) }}
                                      options={this.state.nicheCategories}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      placeholder="IAB Categories"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={this.state.linksType}
                                      name="linksType"
                                      onChange={(e) => this.setState({ linksType: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                    >
                                      <option value="">LinksType</option>
                                      <option value="None">None</option>
                                      <option value="Dofollow">DoFollow</option>
                                      <option value="Nofollow">No Follow</option>
                                      <option value="Sponsored">Sponsored</option>
                                      <option value="UGC">UGC</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group mar-bot-5">
                                    <select
                                      className="form-control"
                                      name="adType"
                                      onChange={(e) => this.setState({ adType: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                      value={this.state.adType}
                                    >
                                      <option value="">Ad Type</option>
                                      <option value="Article">Article</option>
                                      <option value="Link insertion">Link insertion</option>
                                      <option value="Gambling Link">Gambling Link</option>
                                      <option value="CBD Marijuana Link">CBD Marijuana Link</option>
                                      <option value="Adult Link">Adult Link</option>
                                      <option value="Weight Loss Link">Weight Loss Link</option>
                                      <option value="Partners Page Link">Partners Page Link</option>
                                      <option value="Crypto">Crypto</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      value={this.state.language}
                                      name="language"
                                      onChange={(e) => this.setState({ language: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } })}
                                    >
                                      <option value=""> Select Language </option>
                                      {this.state.languages.map((lang) =>
                                        <option value={lang}>{lang}</option>
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <label className="form-control-label" htmlFor="input-email" style={{display:'flex',alignItems:'center'}}> promo price 
                                        <input
                                          type="checkbox"
                                          id="promo_price"
                                          checked={this.state.promo_price}
                                          onChange={(e) => this.setState({ promo_price: !this.state.promo_price, filtered_data: { ...this.state.filtered_data, ['promo_price']: e.target.checked }}, this.websitesFilter)}
                                          style={{marginLeft:'5px'}}
                                        /> 
                                        </label>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-12 text-center">
                                <button
                                  type="button"
                                  className="btn btn-primary botton_b1 "
                                  onClick={() => {
                                    this.setState({ search_clicked: true })
                                    this.websitesFilter(this.state.activecurrentPage, this.state.searchText, true, parseInt(userSubscriptionDetails.used_available_searches) >= 50)
                                  }
                                  }
                                >
                                  Search
                                </button>
                                {parseInt(this.state.usedFilters) >= (userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.monthly_available_filters)
                                  ?
                                  <button
                                    type="button"
                                    className="btn btn-primary botton_b1 "
                                    onClick={() => window.alert("Your filter criteria has been finished")}
                                  >
                                    Save this filter
                                  </button>
                                  :
                                  <button
                                    type="button"
                                    className="btn btn-primary botton_b1 "
                                    data-toggle="modal"
                                    data-target="#exampleModal2"
                                    onClick={() => this.addOrUpdateFilter()}
                                  >
                                    Save this filter
                                  </button>
                                }
                                <button type="button" className="btn btn-primary botton_b1 " onClick={() => this.setState({ display_table: false, search_clicked: false }, this.clearFilter)}>Clear</button>
                                {localStorage.getItem('subscription') == 'Free' ? <div className="row" style={{ marginTop: '20px' }} ><div className="col-lg-12 text-center">{this.state.usedSearches}/50 searches used <span style={{ paddingLeft: "5px", color: "blue", cursor: 'pointer' }} data-toggle="modal" data-target="#exampleModal" id="up"> Upgrade to Pro</span></div></div> : null}
                              </div>
                            </form>
                          </div>
                        </div>
                        {!(this.state.display_table) && <h9 style={{ paddingLeft: '32rem' }}> Fill the filter and press search button to display the results</h9>}
                      </div>
                    </div>
                  </div>
                  {this.state.display_table &&
                    <div className="table-responsive">
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th width="2%"></th>
                            {/* <th width='1%' style={{ cursor: 'pointer' }} onClick={() => this.setState({ idtoggle: !this.state.idtoggle, svalue: 'id' }, this.websitesFilter)}>ID <i className="fas fa-sort"></i></th> */}
                            <th width='5% ' style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ domaintoggle: !this.state.domaintoggle, svalue: 'domain' }, this.websitesFilter)}>Domain <i className="fas fa-sort"></i></th>
                            {/* <th width="8%">categories</th> */}
                            {/* <th width="8%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ adtypetoggle: !this.state.adtypetoggle, svalue: 'adtype' }, this.websitesFilter)}>Ad type <i className="fas fa-sort"></i></th> */}
                            {/* <th width="8%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ linktypetoggle: !this.state.linktypetoggle, svalue: 'links_type' }, this.websitesFilter)}>link type <i className="fas fa-sort"></i></th> */}
                            {/* <th width="4%" style={{cursor:'pointer'}} onClick={()=>this.setState({alexatoggle:!this.state.alexatoggle,svalue:'alexa'},this.websitesFilter)}>Alexa <i className="fas fa-sort"></i></th> */}
                            <th width="2%" style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ datoggle: !this.state.datoggle, svalue: 'da' }, this.websitesFilter)}>Moz DA <i className="fas fa-sort"></i></th>
                            <th width="2%" style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ drtoggle: !this.state.drtoggle, svalue: 'dr' }, this.websitesFilter)}>Ahrefs DR <i className="fas fa-sort"></i></th>
                            {/* <th width='2% ' style={{ cursor: 'pointer',textTransform: "capitalize" }} onClick={() => this.setState({ patoggle: !this.state.patoggle, svalue: 'pa' }, this.websitesFilter)}>PA <i className="fas fa-sort"></i></th> */}
                            <th width='2% ' style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ ahrefs_backlinksToggle: !this.state.ahrefs_backlinksToggle, svalue: 'ahrefs_backlinks' }, this.websitesFilter)}>Ahrefs<br />Backlinks <i className="fas fa-sort"></i></th>
                            <th width='2% ' style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ referring_domainsToggle: !this.state.referring_domainsToggle, svalue: 'ahrefs_referring_domains' }, this.websitesFilter)}>Referring<br />Domains <i className="fas fa-sort"></i></th>
                            {/* <th width="2%" style={{ cursor: 'pointer',textTransform: "capitalize" }} onClick={() => this.setState({ urtoggle: !this.state.urtoggle, svalue: 'ur' }, this.websitesFilter)}>UR <i className="fas fa-sort"></i></th> */}
                            <th width="2%" style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ organicPositionToggle: !this.state.organicPositionToggle, svalue: 'organicPosition' }, this.websitesFilter)}>Organic<br /> Keywords <i className="fas fa-sort"></i></th>
                            <th width="2%" style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ traffictoggle: !this.state.traffictoggle, svalue: 'organic' }, this.websitesFilter)}>Ahrefs Organic<br />Monthly Traffic <i className="fas fa-sort"></i></th>
                            <th width="3%" style={{ cursor: 'pointer', textTransform: "capitalize" }} onClick={() => this.setState({ pricetoggle: !this.state.pricetoggle, svalue: 'price' }, this.websitesFilter)}>Price <i className="fas fa-sort"></i></th>
                            <th width="5%">Action</th>
                          </tr>
                        </thead>

                        {this.state.activeWebsites.length > 0 ? this.state.activeWebsites.map((website, index) =>
                          <tbody key={index}>
                            <tr style={{ cursor: 'pointer', overflow: "hidden" }}
                              onClick={() => this.websitesDetails(website.id, website.website_details && website.website_details.categories)}>
                              <td colSpan={1} >
                                <div className="custom-control custom-control-alternative custom-checkbox p-0">
                                  <input
                                    className="custom-control-input"
                                    id={website.id}
                                    type="checkbox"
                                    value={website.id}
                                    checked={this.state.websitesIds.includes(website.id)}
                                    onClick={(e) => this.updateWebsitesList(e.target.value, website.website_details.domain, website.promo_price >0 ? website.promo_price : website.sell_price, website.ad_type)}
                                  />
                                  <label className="custom-control-label" htmlFor={website.id}>
                                    <span className="text-muted">&nbsp;</span>
                                  </label>
                                </div>
                              </td>
                              {/* <td colSpan={1}>{website.website_details.website_id} </td> */}
                              <td colSpan={1}
                                className="overlap"
                                data-toggle='modal' data-target='#websiteModal'
                              // onClick={() => 
                              // this.props.history.push({
                              //   pathname: `/websites/${website.id}/details`, state: {
                              //     detail: this.state.activecurrentPage, organic_traffic_low: this.state.organic_traffic_low, language: this.state.language, organic_traffic_high: this.state.organic_traffic_high, numberofrows: this.state.numberofrows, linksType: this.state.linksType, selectedCategories: this.state.selectedCategories, searchText: this.state.searchText, pricelow: this.state.pricelow, pricehigh: this.state.pricehigh, dalow: this.state.dalow, dahigh: this.state.dahigh, drlow: this.state.drlow, drhigh: this.state.drhigh, adType: this.state.adType,
                              //      alexalow:this.state.alexalow,alexahigh:this.state.alexahigh,
                              //     filter: this.state.filter, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList, ordersList1: this.state.ordersList1, sourcepage: 'websites'
                              //   }
                              // })
                              // } 
                              ><span style={{ fontSize: "large" }}>{website.website_details.domain}</span>{website.is_vendor_verified === 'No'?'':<span style={{paddingLeft:'5px'}}><i className="fas fa-check"></i></span>}
                                <div><span style={website.links_type === "Dofollow" ? { backgroundColor: "#1c8935", borderRadius: "7px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", color: 'white', fontSize: '9px', marginRight: "1px" } : { backgroundColor: "#fb6340", borderRadius: "7px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", color: "white", fontSize: '9px', marginRight: '1px' }}>{website.links_type}</span><span style={{ backgroundColor: "#6170e4", borderRadius: "7px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", color: "white", fontSize: '9px' }}>{website.ad_type}</span></div>
                              </td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.moz_da} </td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.ahrefs_domain_rating_dr} </td>
                              {/* <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.moz_pa} </td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.ahrefs_url_rating_ur} </td> */}
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.ahrefs_backlinks} </td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.ahrefs_referring_domains} </td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.organic_keywords}</td>
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal'>{website.website_details.organic_traffic}</td>
                              {/*<td><button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ websitesIds: [website.id]}, () => this.createOrder())}>Order</button></td>*/}
                              <td colSpan={1} data-toggle='modal' data-target='#websiteModal' ><div style={{display:'flex',flexDirection:'row',alignItems:'baseline',justifyContent:'flex-start'}}><span style={{textDecoration:website.promo_price >0 &&'line-through',paddingRight:'5px'}}>${website.sell_price}</span>{website.promo_price >0 ? <span>${website.promo_price}</span>:''}</div></td>
                              <td colSpan={1} >
                                <span data-toggle="modal" data-target="#projectModal" id="project_modal" ></span>
                                {userSubscriptionDetails.subscription && userSubscriptionDetails.subscription.name == 'Pro' ?
                                  <button type="button" style={{ background: '#ff9100', color: 'blue', marginRight: "1rem", cursor: 'pointer' }} onClick={() => this.addWebsite(website.assigned_project_details.id, website.id)}><i className="fas fa-plus"></i></button> : null}
                                <button type="button" className="btn btn-success btn-sm" onClick={() => this.updateSingleWebsite(website.id, website.website_details.domain,website.promo_price >0 ?website.promo_price : website.sell_price, website.ad_type)}>Order</button>
                              </td>
                            </tr>
                            {/* <tr >
                              <td colSpan={11} style={{border: 'none' ,overflow:"hidden"}}> */}
                                {/* categories:{website.website_details.categories.slice(0, 6).map((d) => <span>
                                  <span style={{ backgroundColor: "rgba(97, 112, 228, 0.68)", borderRadius: "5px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", display: "inline", marginRight: "1px", fontSize: "10px" }}>{d}</span>
                                </span>)}
                                <span>{website.website_details.categories.length > 6 ?
                                  <div style={{ backgroundColor: "rgba(97, 112, 228, 0.68)", display: "inline", position: "relative", borderRadius: "5px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", fontSize: "11px" }} onClick={() => this.setState({ displayid: website.id, display: !this.state.display })}>...
                                    {this.state.displayid === website.id && this.state.display &&
                                      <div className="categories_popup" id="categories_popup" onClick={() => this.setState({ displayid: '' })}>
                                        {website.website_details.categories.map(d => <span id="all_categories">{d}</span>)} </div>}
                                  </div> : ''}</span> */}
                                {/* <div>categories:{website.website_details.categories.slice(0, 8).map((d) =>
                                  <span style={{ backgroundColor: "rgba(97, 112, 228, 0.68)", borderRadius: "5px", padding: "0.3em 0.5rem", lineHeight: "1.7rem", display: "inline", marginRight: "1.5px", fontSize: "10px" }}>{d}</span>
                                )}</div> */}
                              {/* </td>
                            </tr> */}
                          </tbody>
                        )
                          :
                          <tbody>
                            <tr>
                              <td>
                                No websites found
                              </td>
                            </tr>
                          </tbody>
                        }
                      </table>
                    </div>
                  }
                  <OrderConfirmationModal websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist={this.state.ordersList} orderlist1={this.state.ordersList1} uncheckwebsites={this.uncheckwebsites} userrole={"client"} />
                  <OrderConfirmationSingleModal websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist1={this.state.ordersList1} uncheckwebsites={this.uncheckwebsites} userrole={"client"} />
                  {this.state.display_table &&
                    <div className="card-footer">
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end mb-0">
                          {Number(this.state.activenumOfPages) > 1 &&
                            <Pagination
                              currentPage={this.state.activecurrentPage}
                              totalPages={this.state.activenumOfPages}
                              getCurrentPage={this.getCurrentPage.bind(this)}
                            />
                          }
                        </ul>
                      </nav>
                    </div>
                  }
                </div>
                <br />
                {this.state.activeWebsites.length > 0 &&
                  <div className="text-center">
                    <span data-toggle="modal" data-target="#ConfirmationModal" id="cart" data-backdrop="static" data-keyboard="false" />
                    <span data-toggle="modal" data-target="#Alert_model" id="success" />
                    <span data-toggle="modal" data-target="#alert" id="success1" />
                    <span data-toggle="modal" data-target="#ConfirmationSingleModal" id="confirmationsinglemodal" data-backdrop="static" data-keyboard="false" />
                    {/*<button type="button" className="btn btn-info" onClick={() => this.createOrder()}>Create Order</button>*/}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }
}
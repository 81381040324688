 import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import {ADMIN, Alert_model, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER, DOMAIN_NAME } from '../common';

let i=0;
export default class FilterLinks extends Component{
  constructor(props) {
    super(props);
    this.state = {
      links:[],
      promoWebsite:false,
      showFilter:false
    }
  }

  componentDidMount() {
      this.links()
  }

  links(page=this.state.currentPage) {
    fetch_get(`/api/links/list/`)
    .then((response) => {
      if(!response.error) {
        this.setState({links:response.shared_links})
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  } 
  

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.links)
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />       
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.links)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-3 d-flex">Filter Links</h3>          
                </div>  
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Client </th>
                        <th scope="col">Filter attributes </th>
                        <th scope="col">Filter Link</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.links.length > 0 ? this.state.links.map((link, index) =>
                        <tr key={link.id}>
                          <td>{link.shared_to}</td>
                          <td>{link.custom_filtered_data}</td>
                          <td style={{color:'blue'}}>
                            {DOMAIN_NAME}/filter/{link.name}                        
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}


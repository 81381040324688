import React, { Component } from 'react';
// import ReactGA from 'react-ga'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Signin from './components/auth/Signin';
import SignUp from './components/auth/SignUp';
import Main from './components/Main';
import ForgotPassword from './components/auth/ForgotPassword';
import ChangePassword from './components/auth/ChangePassword';
import ResetPassword from './components/auth/ResetPassword';
import Unauthorized from './components/common/Unauthorized';

class App extends Component {
    constructor(props) {
    super(props);
    this.state = {
      role: localStorage.getItem('role')
    }
  }

  // componentDidMount(){
  //   ReactGA.initialize('UA-162412038-1')
  //   ReactGA.pageview(window.location.pathname + window.location.search)    
  // }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path='/signin' component={(this.state.role === 'vendors' || this.state.role === 'admin' || this.state.role === 'creator') ? Unauthorized : Signin} />
            <Route exact path='/vendors/signin' component={(this.state.role === 'client' || this.state.role === 'admin' || this.state.role === 'creator') ? Unauthorized : Signin} />
            <Route exact path='/admin/signin' component={(this.state.role === 'client' || this.state.role === 'vendors' || this.state.role === 'creator') ? Unauthorized : Signin}  />
            <Route exact path='/creator/signin' component={(this.state.role === 'client' || this.state.role === 'vendors' || this.state.role === 'admin') ? Unauthorized : Signin}  />            
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset-password/:uid/:token' component={ResetPassword} />
            <Route path='/' component={Main} />                                                        
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import LiveurlModel from '../admin/LiveurlModel'
import { ORDER, fetch_get, fetch_post, DOMAIN_NAME } from '../common';

let orderStatus = ""
export default class OrderDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      orderId: '',
      id:'',
      clientinfo:'',
      ordervalue:'',
      accept:'',
      addurl:'',
      status:false,
      reject:false,
      rejectid:'',
      order_id:'',
      show:true,
    }
  }

  componentWillMount(){
    var url_string = window.location.href
    var url = new URL(url_string);
    var c = url.searchParams.get("autologin");
    var accept = url.searchParams.get("accept");
    var user = url.searchParams.get("user");
    var order_id = url.searchParams.get('user_order');
    if(accept == '1/' || accept == '0/'){  
      localStorage.removeItem('UserId')  
      localStorage.setItem('role','vendors');  
      localStorage.setItem('UserToken',c);
      this.getUser()      
      this.setState({accept:accept,order_id:order_id})
    }
  }

  getUser(){
    let formData = new FormData()
    formData.append('user_token', localStorage.getItem('UserToken'))    
    fetch_post('/api/get_user/',formData)
    .then((response)=>{
      if(!response.error) {
        localStorage.setItem('UserId',response.user);
      }
    })    

  }

  componentDidMount() {
    this.orderDetails()
  }

  componentDidUpdate(prevState,state){
    if(this.state.status == false && this.state.id === '' && this.state.accept == '1/'){
      this.updateStatus(this.state.order_id,'Accepted')
      document.getElementById('live').click()      
    }
    else if(this.state.order_id !== '' && this.state.status == false && this.state.accept == '0/'){
      document.getElementById('reject').click()
      // this.updateStatus(this.state.rejectid,'Rejected')      
    }
    
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.state != nextProps.location.state){
      this.orderDetails()
    }
  }

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      if(response.detail == 'Invalid token.'){
        localStorage.clear()
        this.props.history.push('/vendors/signin/')
      }
       if(!response.error) {
        this.setState({ 
          orderDetails: response.order_details, 
          orderId: response.order_details.order_id,
          clientinfo: response.order_details.created_by.username,
          ordervalue: response.order_details.amount
        })
      }
      else{
        if(response.message == "You Don't have access to view is order"){
          this.setState({show:false})
        }        
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  updateStatus(userOrderId, status) {
    let formData = new FormData()
    formData.append('user_order_id', userOrderId)
    formData.append('status', status)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        orderStatus = response.order_details.user_order_details[0].status
        this.setState({ 
          orderDetails: response.order_details, 
          orderId: response.order_details.order_id,
          status:true,
          order_id:''
        })
      }
    })
    .catch((error) => {
      this.setState({ error });
      });
  }

  render(){
    const { orderDetails } = this.state;
    if(this.state.show){
      return(
        <div className="main-content">
        {localStorage.getItem('UserId') && <TopHeader showSearch={false} />}
          <LiveurlModel orderid={this.state.orderId} id={this.state.id} history={this.props.history} liveurl={this.state.liveurl} role="vendor"/>        
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className="card-header row marl">
                      <h3 className="mb-0 col-md-8 order_id">Order ID : <span className="order_id">{orderDetails.order_id}</span></h3>
                      <div className="col-md-4 text-right">
                        <button className="edit-website btn-primary mt-1 mr-2" onClick={() => this.props.history.goBack() }>
                          Back
                        </button> 
                      </div>
                    </div>
                    <div className="card-body">              
                      <form className="pt-3">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Website</th>
                                <th scope="col">Ad_type</th> 
                                <th scope="col">Price</th> 
                                <th scope="col">Content Status</th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            {(orderDetails.user_order_details && orderDetails.user_order_details.length > 0) && orderDetails.user_order_details.map((order, index) => 
                              <tbody key={order.user_website_details.website_id}>
                                <tr>
                                  <td>{index+1}</td>
                                  <td>{order.user_website_details.website}</td>
                                  <td>{order.user_website_details.ad_type}</td>
                                  <td>{order.user_website_details.assigned_website_vendor.price}</td>
                                  <td>
                                  {order.user_website_details.text_content_status ? 
                                    order.user_website_details.text_content.file_content ? 
                                    <span className="order_content-status d-flex pl-2">
                                      <a href={DOMAIN_NAME + order.user_website_details.text_content.file_content} download><i className="ni ni-cloud-download-95"></i></a>
                                    </span>:
                                    <span className="order_content-status d-flex pl-2">
                                      <a href={ order.user_website_details.text_content.link_url} target="_blank"><i className="fas fa-eye"></i></a>
                                    </span>                                
                                  :
                                    <span><i className="fas fa-times" style={{ color: 'red' }}></i></span>
                                  }
                                  </td>
                                  {order.user_website_details.text_content_status ?
                                  <td>
                                    <span id="live" data-toggle="modal" data-target="#liveurlModel" onClick={()=>this.setState({id:this.state.order_id,liveurl:order.post_url})}></span>
                                    <span id="reject" onClick={()=>this.updateStatus(this.state.order_id,'Rejected')}></span>
                                    {order.status === 'Pending Publish' ? 
                                    <span>
                                      <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={()=>this.updateStatus(order.id,'Accepted')}>Accept</button> 
                                      <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={()=>this.updateStatus(order.id,'Rejected')}>Reject</button>
                                    </span>:
                                    order.status == 'Rejected' ? "Rejected" : order.status === 'Accepted' || order.status === 'Completed' ? 
                                      order.post_url ? 
                                      <Fragment>
                                      <div className="profile_pc">
                                      <span style={{position:'relative'}} className="pic_actions">
                                        <span className="picture"> </span>
                                         <span id="actions" className="actions">
                                            <button type="button" htmlFor="imgUpload" className="btn btn-default btn-sm"><i data-toggle="modal" data-target="#liveurlModel" onClick={()=>this.setState({id:order.id, liveurl:order.post_url})} className="fas fa-pencil-alt"></i>
                                            </button> 
                                            <button type="button" style={{backgroundColor:"#ffc107"}} className="btn btn-warning btn-sm"><i onClick={()=> window.open(order.post_url)} className="fas fa-eye"></i></button>
                                          </span>
                                      </span>
                                      </div>
                                      </Fragment>:
                                      <button type="button" className="btn btn-info" data-toggle="modal" data-target="#liveurlModel" onClick={()=>this.setState({id:order.id,liveurl:order.post_url})} >Add Url</button>: <span style={{ paddingLeft:"30px"}}>----</span> }
                                  </td> : <td>Waiting for content</td>}
                                </tr>
                                <span data-toggle="modal" data-target="#liveurlModel" id="live"></span>
                              {/*// <span  data-toggle="modal" data-target="#liveurlModel" >Add Url</span>*/} 
                              </tbody>
                            )}
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      )
    }
    else{
      return(
        <div className="main-content">
          <TopHeader showSearch={false} />
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-4">
                      </span>
                       <span className="col-md-4">
                        <label className="order_value">You do not have permission to access this page</label>
                      </span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );      
    }
  }
}
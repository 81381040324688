import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DOMAIN_NAME, USERWEBSITE, fetch_post, fetch_get, Alert_model } from '../common';
import TopHeader from '../common/TopHeader';


export default class WebsiteEdit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: [],
      adTypeChoices: [],
      timeStamp: ''
    }
  }

  componentDidMount() {
    this.websiteDetails()
  }

  removeErrors(){
    this.setState({errors : {}})
    // this.props.history.push('/admin/websites')
  }

  websiteDetails() {
    fetch_get(`${USERWEBSITE}/${this.props.match.params.id}/update/ `)
    .then((response) => {
      this.setState({ vendorName: response.vendor_website_serializer.user_details, adType: response.vendor_website_serializer.ad_type,linktype: response.vendor_website_serializer.links_type })
      this.refs['domain1'].value = response.vendor_website_serializer.website_details.domain;
      this.refs['id'].value = response.vendor_website_serializer.website_details.website_id;
      this.refs['pricee'].value = response.vendor_website_serializer.price;
      // this.refs['sell_price'].value = response.vendor_website_serializer.sell_price;
      this.refs['example_post1'].value = response.vendor_website_serializer.example_post;
      this.refs['tat1'].value = response.vendor_website_serializer.tat;
      this.refs['special_notes1'].value = response.vendor_website_serializer.special_notes;
      this.refs['minimum_word_count1'].value = response.vendor_website_serializer.minimum_word_count;
      this.refs['maximum_number_of_links_in_post1'].value = response.vendor_website_serializer.maximum_number_of_links_in_post;
      this.refs['writing_included1'].checked = response.vendor_website_serializer.writing_included ? true : false;
      this.refs['prepaid1'].checked = response.vendor_website_serializer.prepaid ? true : false;
      this.refs['permanent_article1'].checked = response.vendor_website_serializer.permanent_article ? true : false;
      this.refs['disclosure_tag1'].checked = response.vendor_website_serializer.disclosure_tag ? true : false;
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websiteUpdate() {
    const { websiteDetails, selectedUsers, nicheText } = this.state;
    let formData = new FormData()
    formData.append('disclosure_tag', this.refs['disclosure_tag1'].checked ? 'True' : 'False')
    formData.append('prepaid', this.refs['prepaid1'].checked ? 'True' : 'False')
    formData.append('permanent_article', this.refs['permanent_article1'].checked ? 'True' : 'False')
    formData.append('writing_included', this.refs['writing_included1'].checked ? 'True' : 'False')
    formData.append('special_notes', this.refs['special_notes1'].value)
    // formData.append('domain', this.refs['domain1'].value)
    formData.append('example_post', this.refs['example_post1'].value )
    formData.append('tat', this.refs['tat1'].value)
    formData.append('minimum_word_count', this.refs['minimum_word_count1'].value)
    formData.append('maximum_number_of_links_in_post', this.refs['maximum_number_of_links_in_post1'].value)
    formData.append('ad_type', this.state.adType)
    formData.append('price', this.refs['pricee'].value)
    // formData.append('sell_price', this.refs['sell_price'].value)
    fetch_post(`${USERWEBSITE}/${this.props.match.params.id}/update/ `, formData)
    .then((response) => {
      if(!response.error) {
        let d = document.getElementById('success')
        d.click()
        // alert('website updated successfully')
        // this.props.history.push('/vendors/websites')
        this.removeErrors()
      }
      else{
        this.setState({ errors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    const { websiteDetails } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        { <Alert_model msg={"Website Updated successfully"} redirecturl={true} role={'vendor'}  history={this.props.history} /> }        
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          {websiteDetails.website_id !== null &&
            <div className="row">
              <div className="col-lg-12 instructions_col website_edit">
                <div className="card shadow mb-6">
                <div className="row card-header marl">
                  <div className="col-md-6">
                    <h3 className="mb-0 mt-2  ml-2">Website edit</h3>
                  </div>
                  <div className="col-md-6 text-right">
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.goBack()}>
                    Back
                  </button>
                  </div>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="pt-4">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" htmlFor="input-username">Website Id</label>
                              <input type="text" className="form-control" ref="id" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label">Domain</label>
                              <input type="text" ref="domain1" className="form-control" disabled />
                            </div>
                          </div>
                          {/*<div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Price</label>
                              <input 
                                type="number" 
                                ref="pricee" 
                                className="form-control" 
                              />
                            </div>
                          </div>*/}
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Example post</label>
                              <input type="text" ref="example_post1" className="form-control" />
                              <div className="text-danger">
                                {this.state.errors.example_post && this.state.errors.example_post}
                              </div>                               
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >TAT</label>
                              <input type="text" ref="tat1" className="form-control" />
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Special Notes</label>
                              <input type="text" ref="special_notes1" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Minimum word count</label>
                              <input type="number" ref="minimum_word_count1" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Maximum number of links</label>
                              <input type="number" ref="maximum_number_of_links_in_post1" className="form-control" />
                            </div>
                          </div>  
                        </div>
                      </div>
                      <div className="pt-4">
                        <div className="row pb-3 pt-4">
                          <div className="col-md-12">
                            <div className="form-group">
                              <div className="row no-gutters">
                               <div className="col-md-3">
                                  <input 
                                    type="checkbox" 
                                    className="col-sm-1 col-form-label" 
                                    ref="prepaid1"
                                  />
                                  <label htmlFor="staticEmail" className="col-sm-11 p-0 col-form-label">Prepaid</label>
                                </div>
                                <div className="col-md-3 ">
                                  <input 
                                    type="checkbox" 
                                    className="col-sm-1 col-form-label"
                                    ref="permanent_article1"
                                  />
                                  <label htmlFor="staticEmail" className="col-sm-11 p-0 col-form-label">Permanent article</label>
                                </div>
                                <div className="col-md-3">
                                  <input 
                                    type="checkbox" 
                                    className="col-sm-1 col-form-label" 
                                    ref="writing_included1"
                                  />
                                  <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Writing included</label>
                                </div>
                                <div className="col-md-3">
                                  <input 
                                    type="checkbox" 
                                    className="col-sm-1 col-form-label" 
                                    ref="disclosure_tag1"
                                  />
                                  <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Disclosure tag</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group mar-bot-5">
                              <label className="form-control-label">Ad type
                              </label>
                              <div className="input-group mb-3 dropdown no-caret">
                                <input 
                                  type="text"
                                  value={this.state.adType}
                                  onChange={() => {}}
                                  className="form-control" 
                                  aria-label="Select User" 
                                  aria-describedby="button-addon2"
                                  disabled 
                                /> 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group mar-bot-5">
                              <label className="form-control-label">Link Type
                              </label>
                              <div className="input-group mb-3 dropdown no-caret">
                                <input 
                                  type="text"
                                  value={this.state.linktype}
                                  onChange={() => {}}
                                  className="form-control" 
                                  aria-label="Select User" 
                                  aria-describedby="button-addon2"
                                  disabled 
                                /> 
                              </div>
                            </div>
                          </div>                          
                        </div>
                      </div>
                      <p className="m-3">
                        <button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                        Price Change
                        </button>
                      </p>
                      <div className="collapse" id="collapseExample">
                        <div className="card card-body">
                          <div className="table-responsive">
                            <table className="table align-items-center table-flush">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">S.No</th>
                                  <th scope="col">User Name</th>
                                  <th scope="col">Ad Type</th>
                                  <th scope="col">Price <div className="error-text">*</div></th>
                                </tr>
                              </thead>
                                <tbody>
                                  <tr>
                                    <td> 1 </td>
                                    <td>{this.state.vendorName}</td>
                                    <td>{this.state.adType}</td>
                                    <td> <input type="text" className="" ref="pricee"  /> 
                                      <div className="text-danger">
                                        {this.state.errors.price && this.state.errors.price}
                                      </div>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <br/>
                      <div className="col-md-12">
                        <div className="text-center">
                          <span data-toggle="modal" data-target="#Alert_model" id="success"> </span>
                          <button type="button" className="btn btn-info mb-3" onClick={() => this.websiteUpdate()}>Update Details</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>  
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
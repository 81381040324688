import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { INVOICES_LIST, Pagination, USER, DOMAIN_NAME } from '../common';

export default class Websites extends Component{
  constructor(props) {
    super(props);
    this.state = {
      websites:[],
      email:'',
      websiteId:'',
      loading:false,
      order:'',
      token:'',
      total_amount:'',
      is_completed:true,
     
    }
  this.redirectPage = React.createRef();
  }

  componentDidMount() {
    var url_string = window.location.href
    var url = new URL(url_string);
    var order = url.searchParams.get("order_id");
    var orderId = order.slice(0, -1)
    var token = url.searchParams.get("token");
    // fetch_get(`/api/public/order/${orderId}/details/`)
    // .then((response) => {
    fetch(`${DOMAIN_NAME}/api/public/order/${orderId}/details/`, {
      method: 'get',
    })
    .then(response => response.json())
    .then((response) => {
      if(!response.error) {
        this.setState({total_amount:response.order_details.total_amount,is_completed:response.order_details.is_completed})
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
    this.setState({order:orderId,token:token})
  }

  payment() {
    // fetch_get(`/api/public/user/${this.state.order}/pay/`)
    // .then((response) => {
    fetch(`${DOMAIN_NAME}/api/public/user/${this.state.order}/pay/`, {
      method: 'get',
    })
    .then(response => response.json())
    .then((response) => {
      if(!response.error) {
        this.setState({loading:false, redirectUrl: response.redirect_url, })
      }
      this.redirectPage.current.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    if(this.state.token == 'dsa987sadhjsad976faiubdbqe7dh7'){
    return(
     <div className="main-content client_web">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="col-md-6 m-auto modal fade" id="pay_later" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
              <center>Your order is placed, we will send you email with live link and payment link once completed</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" >Close</button>
              </div>
            </div>
          </div>
        </div>        
        <span style={{display:"none"}}> <a href={this.state.redirectUrl} ref={this.redirectPage}>abv</a></span>
        <section class="pricing_section mb-0">
          <div class="pricing_container container">
            <div class="row justify-content-center steps">
              <div class="col-lg-10">
                <ul role="tablist">

                  <li role="tab" class="closed" aria-disabled="false" aria-selected="true">
                    <a id="form-total-t-0" aria-controls="form-total-p-0">
                      <div class="title">
                        <span class="step-icon"><i class="fas fa-shopping-basket"></i></span>
                        <span class="step-text">Order Placed</span>
                        </div>
                      </a>
                  </li>


                <li role="tab" class="closed" aria-disabled="true">
                  <a id="form-total-t-1" aria-controls="form-total-p-1">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-upload"></i></span>
                      <span class="step-text">Uplaod Content</span>
                    </div>
                  </a>
                 </li>


                <li role="tab" class="current" aria-disabled="true">
                  <a id="form-total-t-2" aria-controls="form-total-p-2">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-money-bill"></i></span>
                      <span class="step-text">Payment</span>
                    </div>
                  </a>
                </li>

              </ul>
              </div>
            </div>
          </div>
        </section>   
        <div className="conatiner-fluid">
          <div className="row justify-content-center ml-0 mr-0">
            <div className="col-lg-3">
              <div className="card shadow-lg p-4">
                <div className="card-body">
                   <h5 className="text-center mb-4">
          total :  ${this.state.total_amount}
        </h5>
        {this.state.loading ? 
          <Fragment>
            <div className="spinner task m-auto">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </Fragment> :
        <div class="text-center mb-2"><button class="btn btn-primary" onClick={()=>this.setState({loading:true},this.payment)}> pay </button></div>}
        {this.state.is_completed ? null : <div style={{paddingTop:'1rem', color:'blue', cursor:'pointer'}} data-toggle="modal" data-target="#pay_later" class="text-center"><a> I Will Pay After Link Is Live</a></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>   
    )
    }
    else{
    return(
      <div className="main-content">
        <div className="main-content">
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <div className="container-fluid mt--7">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-11">
                <div className="card shadow  profile_card">
                  <div className="card-body">
                  <div className="card-header row no_border_bg">
                    <span className="col-md-4">
                    </span>
                     <span className="col-md-4">
                      <label className="order_value">You do not have permission to access this page</label>
                    </span>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  }
}

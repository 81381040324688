import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import Select from 'react-select';
import { ORDER, fetch_get, fetch_post, Pagination,USER_SUBSCRIPTIONS,SUBSCRIPTIONS, Alert, } from '../common';

export default class Orders extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
      currentPage: 1,
      numOfPages: 1,
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      createdontoggle:false,
      websitecounttoggle:false,
      orderstatustoggle:false,
      projects:[],
      projects_list:[],
      pid:'',
      oid:'',
      current_pid:'',
      subscriptions:[],
      redirectUrl: '',      
      makeOrder:false
    }
    this.ordersList = this.ordersList.bind(this);
    this.redirectToPage = React.createRef();
  }

  componentDidMount() {
    this.ordersList()
    this.projectsList()
    this.getSubscriptions()
  }

  ordersList(page=this.state.currentPage) {
    fetch_get(`${ORDER}s/list/?page=${page} `)
    .then((response) => {
      if(!response.error) {
        this.setState({ ordersList: response.orders, numOfPages: response.num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

    projectsList() {
      if(localStorage.getItem('subscription') == 'Pro'){
        fetch_get(`/api/projects/list/`)
        .then((response) => {
          if(!response.error) {
          let projects=[]
          response.projects_list.map((project)=>{
            projects.push({ label: project.name, value: project.id })
          })
            this.setState({ projects :projects,projects_list:response.projects_list  })
          } else {
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
      }
    }

  getSubscriptions() {
    fetch_get(USER_SUBSCRIPTIONS)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          subscriptions: response.subscriptions,
          presentPlan: response.current_subscription,
        })
        response.subscriptions.map((sub) => {
          if (sub.name == response.current_subscription.name) {
            this.setState({ precedence: sub.precedence, presentPlan: sub.name })
          }
        })
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }  

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`${ORDER}s/list/`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          ordersList: response.orders, 
          numOfPages: response.num_pages
        })                   
      }
    })
  } 

  orderFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'order_id'){
      if(this.state.ordertoggle){
        formData.append('column_name_order','order_id')
      }
      else{
        formData.append('column_name_order','-order_id')
      }
    }
    if(this.state.svalue == 'createdon'){
      if(this.state.createdontoggle){
        formData.append('column_name_order','created_on')
      }
      else{
        formData.append('column_name_order','-created_on')
      }
    } 
    if(this.state.svalue == 'websitecount'){
      if(this.state.websitecounttoggle){
        formData.append('column_name_order','websites_count')
      }
      else{
        formData.append('column_name_order','-websites_count')
      }
    }
    if(this.state.svalue == 'orderstatus'){
      if(this.state.orderstatustoggle){
        formData.append('column_name_order','order_status')
      }
      else{
        formData.append('column_name_order','-order_status')
      }
    }        
    fetch_post(`${ORDER}s/list/`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          ordersList: response.orders, 
          numOfPages: response.num_pages
        },window.scroll(0,0))
      }
    })       
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.0rders, 
    //       numOfPages: response.num_pages
    //     })                   
    //   }
    // })       
    this.setState({currentPage},this.orderFilter)
  }

  // rendorProject(project,orderid){
  //   let projectName = project
  //   if(!projectName){
  //     projectName = ''
  //   }
  //   return(
  //     <td style={{width:"12rem"}}>
  //       <select 
  //         value={projectName}
  //         onChange={(e) => this.updateProject(orderid, e.target.value,project)}
  //         className="form-control"
  //       >
  //         <option value="">Select</option>
  //         {this.state.projects_list.map((project)=>
  //           <option value={project.id}>{project.name}</option>
  //         )}
  //       </select>
  //     </td>
  //   )    
  // }

  updateProject(order_id, project,previous) {
    if(project == ''){
      if(previous){
        let formData = new FormData()
        formData.append('order_id', order_id)
        {previous && formData.append('prev_project_id',previous)}
        fetch_post(`${ORDER}s/list/`, formData)
        .then((response) => {
          if(!response.error) {
            let a = document.getElementById('remove_modal')
            a.click()             
            this.setState({ ordersList: response.orders, numOfPages: response.num_pages  })
          }
        })
        .catch((error) => {
          this.setState({ error });
        });        
      }
      else{
        let a = document.getElementById('remove_modal')
        a.click()
      }
    }
    else{    
      let formData = new FormData()
      if(project != previous){
        {previous && formData.append('prev_project_id',previous)}
      }
      formData.append('order_id', order_id)
      fetch_post(`/api/project/${project}/details/ `, formData)
      .then((response) => {
        if(!response.error) {
          let a = document.getElementById('remove_modal')
          a.click()           
          this.ordersList()
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
  }

  addOrderToProject(pid,oid){
    if(pid == undefined){
      pid=''
    }
    let a = document.getElementById('project_modal')
    this.setState({pid:pid,current_pid:pid,oid:oid},a.click())
    // let a = document.getElementById('project_modal')
  }

  upgradeSubscription(){
    let a = document.getElementById('upgrade')
    a.click()
  } 

  upgradePro(){
    let a = document.getElementById('remove_modal1')
    a.click();
    let b = document.getElementById('plans')
    b.click();
  }

  changeSubscription(id) {
    fetch_post(`${SUBSCRIPTIONS}${id}/`)
    .then((response) => {
      if(!response.error) {
        if(response.redirect_url){
          this.setState({ 
            redirectUrl: response.redirect_url
          })
        }
        if(this.state.redirectUrl){
          this.redirectToPage.current.click();
        }
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }


  render(){
    return(
      <div className="main-content client_web">
        <TopHeader />
        { this.state.makeOrder ? <Alert msg={'Select any website to create an order'} makeOrder={this.state.makeOrder}/> :''}
        <a href={this.state.redirectUrl} ref={this.redirectToPage} style={{ display: 'none' }}></a>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="modal fade" id="projectModal" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Add to Project</h3>
               <div className="row justify-content-center">
               </div>
              <div className="row">
                <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                  <div className="form-group">
                    <select 
                      onChange={(e) => this.setState({current_pid:e.target.value})}
                      value={this.state.current_pid}
                      className="form-control"
                    >
                      <option value="">Select</option>
                      {this.state.projects_list && this.state.projects_list.map((project)=>
                        <option value={project.id}>{project.name}</option>
                      )}
                    </select>                      
                  </div>
                </div>                                                                                          
              </div>
              <div className="row justify-content-center">
                <button type="button" className="btn btn-default" onClick={()=>this.updateProject(this.state.oid,this.state.current_pid,this.state.pid)}>add</button>
                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                <span id="remove_modal" data-dismiss="modal"></span>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal" id="upgradeSubscription" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-body row ">
                <p style={{paddingLeft:'6rem'}}>To use this feature upgrade to </p> <span onClick={()=>this.upgradePro()} style={{color:'blue',cursor:'pointer', paddingLeft:'0.4rem'}}>pro</span>
                <span id="remove_modal1" data-dismiss="modal"></span>
              </div>             
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#exampleModal" id="plans"></span>
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document" style={{maxWidth:"800px"}}>
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Subscription Plans</h5> */}
                <h5 className="modal-title" id="exampleModalLabel">PRO Account Benefits</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row mt-3 subscriptions">
                  {/* {this.state.subscriptions.length > 0 && this.state.subscriptions.map((sub) =>
                    <div className="col-lg-4" key={sub.id}>
                      <div className="card shadow">
                        <div className="card-header border-0">
                          <h3 className="mb-0 text-center"><span className="orange">{sub.name}</span></h3>
                          <div className="price">
                            ${sub.price}
                            <span>For 1 Month</span>
                          </div>
                          <div className="points">
                            <ul>
                              <li><b>Projects:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                              <li><b>Backlinks Monitor:</b> {sub.name == 'Free' ? 'No' : 'Yes'}</li>
                              <li><b>
                              Free Link/Post replacement or money-back guarantee:
                              </b>
                              {sub.name == 'Free' ? ' 3 Months' : ' 12 Months'}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="subscribe orange">
                          {(this.state.precedence === sub.precedence) ?
                            <a >
                              Currently Subscribed
                            </a>
                          :
                            <a style={{cursor:'pointer'}} onClick={() => this.changeSubscription(sub.id)}>
                            { sub.precedence > this.state.precedence ? 'Upgrade' : 'downgrade'}
                            </a>
                          }
                          
                        </div>             
                      </div>
                    </div>
                  )} */}
                  <div className="col-lg-12" >
                                  <div className="card shadow">
                                    <div className="card-header border-0">
                                      <h3 className="mb-0 text-center"><span className="orange">After you make your first plan</span></h3>
                                      <div className="price">
                                        Pro
                                        <span>WE WILL MAKE YOU PRO AUTOMATICALLY</span>
                                      </div>
                                      <div className="points">
                                        <ul>
                                          <li><b>Organize your orders in Projects</b></li>
                                          <li><b>Unlimited Website Searches</b></li>
                                          <li><b>Unlimited Saved Filter (Receive email every time we add new website that match your filter)</b></li>
                                          <li><b>Enjoy 12 months Free Link replacement or money-back guarantee</b></li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="subscribe orange">
                                        <a style={{cursor:'pointer'}} 
                                        data-toggle="modal" data-target="#alert"
                                        data-dismiss="modal"
                                        onClick={()=>this.setState({makeOrder:true})}
                                        >
                                        Make Order
                                        </a>
                                    </div>             
                                  </div>
                                 </div>
                </div>
                
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-info" data-dismiss="modal">Go Back</button>
              </div>
            </div>
          </div>
        </div>                         
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col">
              <div className="">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Orders</h3>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.orderFilter)}>Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order_id'},this.orderFilter)}>Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({createdontoggle:!this.state.createdontoggle,svalue:'createdon'},this.orderFilter)}>Created On <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({websitecounttoggle:!this.state.websitecounttoggle,svalue:'websitecount'},this.orderFilter)}>Websites Count <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({orderstatustoggle:!this.state.orderstatustoggle,svalue:'orderstatus'},this.orderFilter)}>Order Status <i className="fas fa-sort"></i></th>
                        {localStorage.getItem('subscription') == 'Free' ? null : <th scope="col">projects</th>}
                        <th scope="col">Add projects</th>
                        <th scope="col">Websites</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.state.ordersList && this.state.ordersList.length > 0) ? this.state.ordersList.map((order, index) => 
                        <tr style={{ cursor: 'pointer' }} key={order.id}>
                          <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                            {order.id}
                          </td>
                          <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                            {order.order_id}
                          </td>
                          <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                            {order.created_on}
                          </td>
                          <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                            { order.website_details.length }
                          </td>
                          <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                            { order.order_status }
                          </td>
                          {localStorage.getItem('subscription') == 'Free' ? null : 
                            <td onClick={() => this.props.history.push({pathname:`/orders/${order.order_id}/details`,state:{details:'details'}})}>
                              { order.assigned_project_details.name }
                            </td>
                          }
                          <td>
                            <span  data-toggle="modal" data-target="#projectModal" id="project_modal"></span>
                            <span  data-toggle="modal" data-target="#upgradeSubscription" id="upgrade"></span>
                            {localStorage.getItem('subscription') == 'Free' ? 
                              <button type="button" className="btn btn-success btn-sm" onClick={() => this.upgradeSubscription()}>Add to project</button> :
                              <button type="button" className="btn btn-success btn-sm" onClick={() => this.addOrderToProject(order.assigned_project_details.id,order.order_id)}>Add to project</button>
                            }
                          </td>
                          <td className="overlap" onClick={() => this.props.history.push(`/orders/${order.order_id}/details`)}>
                            {order.website_details.slice(0,5).map((d)=><span> <span style={{backgroundColor: "rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>)}
                          </td>                           
                        </tr>
                      )
                      :
                        <tr>
                          <td>No Records found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {Number(this.state.numOfPages) > 1 &&
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      </ul>
                    </nav>
                  </div>
                }
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TopHeader from '../common/TopHeader';
import { ADMIN, fetch_get, Pagination, fetch_post, USER, Alert, Alert_model } from '../common';
import AuthenticationModel from './AuthenticationModel';
import DeleteUser from './DeleteUser';

export default class Admins extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeAdminsList: [],
      inActiveAdminsList: [],
      deleteId: null,
      activecurrentPage: 1,
      activenumOfPages: 0,
      inactivecurrentPage: 1,
      inActivenumOfPages: 0,
      superuser: localStorage.getItem('SuperUser'),
      userId: localStorage.getItem('UserId'),
      active: true,
      filter: false,
      restore: false,
      permanentDelete:false,
      alertmessage:'',
      numberofrows:100,
      count:0,
      username_count:0,
      email_count:0,
      first_name_count:0,
      date_joined_count:0,
      last_login_count:0,
    }
    this.adminsList = this.adminsList.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.adminsList()
    this.clearStates()
  }

  clearStates(filter=false) {
    this.setState({
      id: '',
      email: '',
      firstname: '',
      lastname: '',
      searchText: ''
    }, () => { filter && this.adminFilter()})
  }

  adminsList(page1=this.state.activecurrentPage, page2=this.state.inactivecurrentPage, searchText=this.state.searchText) {
    fetch_get(`${ADMIN}list/?page1=${page1}&page2=${page2} `)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          activeAdminsList: response.active_admins_list, 
          inActiveAdminsList: response.inactive_admins_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inActivenumOfPages: response.inactive_num_pages,
        })
      }
      let model = document.getElementById("filterModel");
      model.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
  //   let formData = new FormData()
  //   this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   formData.append('page1',this.state.activecurrentPage)
  //   formData.append('page2',this.state.inactivecurrentPage)    
  //   fetch_post(`${ADMIN}list/`,formData)
  //   .then((response) => {
  //     if(!response.error){
  //       this.setState({
  //         activeAdminsList: response.active_admins_list, 
  //         inActiveAdminsList: response.inactive_admins_list,
  //         deleteId: null,
  //         activenumOfPages: response.active_num_pages, 
  //         inActivenumOfPages: response.inactive_num_pages,
  //       })                   
  //     }
  //   })
  // }

  adminFilter(page1=this.state.activecurrentPage, page2=this.state.inactivecurrentPage, searchText=this.state.searchText) {
    let formData = new FormData()
    formData.append('page1', page1)
    formData.append('page2', page2)
    this.state.searchText && formData.append('username', searchText)
    this.state.email && formData.append('email', this.state.email)
    this.state.firstname && formData.append('first_name', this.state.firstname)
    this.state.lastname && formData.append('last_name', this.state.lastname)
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('user_id', this.state.id)
    if(this.state.svalue == 'id'){
      if(this.state.count%2){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'username'){
      if(this.state.username_count%2){
        formData.append('column_name_order','username')
      }
      else{
        formData.append('column_name_order','-username')
      }
    }
    if(this.state.svalue == 'email'){
      if(this.state.email_count%2){
        formData.append('column_name_order','email')
      }
      else{
        formData.append('column_name_order','-email')
      }
    }   
    if(this.state.svalue == 'first_name'){
      if(this.state.first_name_count%2){
        formData.append('column_name_order','first_name')
      }
      else{
        formData.append('column_name_order','-first_name')
      }
    }
    if(this.state.svalue == 'date_joined'){
      if(this.state.date_joined_count%2){
        formData.append('column_name_order','date_joined')
      }
      else{
        formData.append('column_name_order','-date_joined')
      }
    }  
    if(this.state.svalue == 'last_login'){
      if(this.state.last_login_count%2){
        formData.append('column_name_order','last_login')
      }
      else{
        formData.append('column_name_order','-last_login')
      }
    }                                  
    fetch_post(`${ADMIN}list/`, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          activeAdminsList: response.active_admins_list, 
          inActiveAdminsList: response.inactive_admins_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inactivenumOfPages: response.inactive_num_pages,
        },window.scroll(0,0))
        let model = document.getElementById("filterModel");
        model.click();
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page1',currentPage)
    // formData.append('page2',currentPage)
    // fetch_post(`${ADMIN}list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       activeAdminsList: response.active_admins_list, 
    //       inActiveAdminsList: response.inactive_admins_list,
    //       activenumOfPages: response.active_num_pages, 
    //       inActivenumOfPages: response.inactive_num_pages,
    //     })                   
    //   }
    // })
    if(this.state.active) {
      this.setState({activecurrentPage: currentPage},this.adminFilter)
    } else {
      this.setState({ inactivecurrentPage:currentPage},this.adminFilter)
    }
  }

  search(searchText) {
    this.setState({ searchText }, this.adminFilter(this.state.activecurrentPage, this.state.inactivecurrentPage, searchText))
  }

  changeStatus(id) {
    fetch_get(`${USER}${id}/status/`) 
    .then((response) => {
      if(!response.error) {
        // alert("Admin restored successfully")
        this.setState({restore:true,permanentDelete:false})
        this.adminsList()
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  permanentDelete(id){
    let formData = new FormData()
    formData.append('permanent_delete',true)
    fetch_post(`${USER}${id}/delete/`, formData)
    .then((response) => {
      if(response.error){
        // alert(response.message)
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.adminsList()
      }
      else{
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.adminsList()        
      }
    })
  }


  rendorAdmins() {
    if (this.state.activeAdminsList && this.state.inActiveAdminsList) {
      let admins = this.state.active ? this.state.activeAdminsList : this.state.inActiveAdminsList
      let page = this.state.active ? this.state.activecurrentPage : this.state.inactivecurrentPage
      return(
        <div className="table-responsive" >
          <table className="table align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({count:this.state.count + 1,svalue:'id'},this.adminFilter)}>Id <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({username_count:this.state.username_count + 1,svalue:'username'},this.adminFilter)}>Username <i className="fas fa-sort"></i></th> 
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({email_count:this.state.email_count + 1,svalue:'email'},this.adminFilter)}>Email <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({first_name_count:this.state.first_name_count + 1,svalue:'first_name'},this.adminFilter)}>First Name <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({date_joined_count:this.state.date_joined_count + 1,svalue:'date_joined'},this.adminFilter)}>Created <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({last_login_count:this.state.last_login_count + 1,svalue:'last_login'},this.adminFilter)}>Last Login <i className="fas fa-sort"></i></th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {admins.length > 0 ? admins.map((admin, index) =>
                <tr key={admin.id} >
                  <th 
                    scope="row"
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {admin.id}
                  </th>
                  <td 
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}} 
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >{admin.username}</td>
                  <td 
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >{admin.email}</td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >{admin.first_name ? admin.first_name : '--'}</td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >{admin.date_joined}</td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/admins/${admin.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >{admin.last_login ? admin.last_login : '--'}</td>
                  <td>
                    {this.state.active ?
                      <Fragment>
                        <Link to={{pathname:`/admin/admins/${admin.id}/profile`}}>
                          <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                        </Link>
                        <DeleteUser userRole="Admin" id={this.state.deleteId} updateList={this.adminsList} />
                        {parseInt(this.state.userId) !== admin.id &&
                          <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: admin.id, restore: false })}>
                            <i className="fa fa-trash"></i>
                          </span>
                        }
                      </Fragment>
                    :
                      <Fragment>
                        <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(admin.id)}>
                          <span style={{paddingRight:20 }}><i className="fa fa-sync"></i></span>
                        </a>
                        {this.state.superuser === 'true' ? 
                        <span data-toggle="modal" data-target="#alert">
                        <a onClick={() => this.permanentDelete(admin.id)}>
                          <span style={{paddingRight:20 }}><i className="fa fa-trash"></i></span>
                        </a>
                        </span> : null}
                      </Fragment>                     
                    }
                  </td>
                </tr>
              ) 
              :
                <tr>
                  <th scope="row">No Records Found</th>
                </tr>
              }
            </tbody>
          </table>
        </div>
      )
    }
  }

  render(){
    return(
      <div className="main-content admin">
        <TopHeader search={this.search} isSearch={true} />
        {this.state.restore && this.state.permanentDelete ? <Alert msg={this.state.alertmessage}/> : this.state.restore ? <Alert msg={"Admin restored Successfully"}/> : <Alert msg={"Admin Deleted Successfully"}/>}        
        <Alert_model msg={'Admin Created Successfully' }/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
         
          <div className="row main_list_container">
            <div className="col-lg-12">
            <div className="col">
              <div className="card shadow website_detail_profile">
                <div className="card-header row bg-white border-0">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.adminFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>
                  <h3 className="mb-0 col-md-4 d-flex">Admins 
                  <div className="">
                  <label className="custom-toggle mb-0 ml-3">
                    <input type="checkbox"/> 
                    <span className="custom-toggle-slider" onClick={() => this.setState({ active: !this.state.active})}> {this.state.active ? 'Active' : 'Inactive'} </span>
                  </label>
                  </div>
                  </h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Create Admin</button>
                    <button type="button" className="btn" data-toggle="modal" data-target="#modal-form" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>
                   {this.state.filter &&
                    <div className="col-lg-12">
            <div className="row main_header">
              <div className="col-lg-12 p-0">
                <div className="filter_result">
                  <form>
                    <div className="row">
                      {/*<div className="col-md-2">
                        <div className="form-group">
                          <input 
                            type="number" 
                            className="form-control" 
                            placeholder="ID"
                            value={this.state.id}
                            onChange={(e) => this.setState({ id: e.target.value }, this.adminFilter)}
                          />
                        </div>
                      </div>*/}
                      <div className="col-md-3">
                        <div className="form-group">
                          <input 
                            type="text" 
                            className="form-control" 
                            value={this.state.searchText}
                            onChange={(e) => this.setState({ searchText: e.target.value }, this.adminFilter)}
                            placeholder="Username"
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input 
                            type="email" 
                            className="form-control" 
                            placeholder="Email" 
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value }, this.adminFilter)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group mar-bot-5">
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="First name" 
                            value={this.state.firstname}
                            onChange={(e) => this.setState({ firstname: e.target.value }, this.adminFilter)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Last name"
                            value={this.state.lastname}
                            onChange={(e) => this.setState({ lastname: e.target.value }, this.adminFilter)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                   <div className="col-lg-12 text-center">
                      <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                    </div>
                </div>
              </div>
            </div>
            </div>
          }
                </div>
                {this.rendorAdmins()}
                <div className="modal fade" id="exampleModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content col-md-6 m-auto">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        ...
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                      </div>
                    </div>
                  </div>
                </div>
                <AuthenticationModel userRole="Admin" updateList={this.adminsList} />
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.active ? this.state.activecurrentPage : this.state.inactivecurrentPage}
                          totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
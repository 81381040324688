import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import {fetch_get, fetch_post, Pagination } from '../common';

export default class BrokenLinks extends Component{
  constructor(props) {
    super(props);
    this.state = {
      brokenLinks:[],
      filter:false,
      numberofrows:100,
      currentPage: 1,
      numOfPages: 1,
      keywords:'',      

    }
    this.brokenLinks = this.brokenLinks.bind(this);
  }

  componentDidMount() { 
      this.brokenLinks()
  }

  brokenLinks(page=this.state.currentPage) {
    fetch_get(`/api/broken/links/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ brokenLinks: response.broken_links, numOfPages: response.num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  } 

  brokenFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    let key = this.state.keywords ? this.state.keywords.split(',') : null
    key && formData.append('keywords',JSON.stringify(key))       
    fetch_post('/api/broken/links/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          brokenLinks: response.broken_links, 
          numOfPages: response.num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })  
    this.setState({currentPage},this.brokenFilter)
  }

  clearStates(){
    this.setState({keywords:''},this.brokenFilter)
  }


  render(){
    return(
      <div className="main-content">
        <TopHeader />  
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.brokenFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Broken Backlinks</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>                  
                </div>
                  {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.keywords}
                                      onChange={(e) => this.setState({ keywords: e.target.value })}
                                      placeholder="keywords (enter , seperated values)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => {this.brokenFilter(); }}> Find </button>
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" >Unique Id </th>
                        <th scope="col">title </th>
                        <th scope="col">anchor </th>
                        <th scope="col">url_from </th>
                        <th scope="col">url_to </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.brokenLinks.length > 0 ? this.state.brokenLinks.map((link, index) =>
                        <tr key={link.id}>
                          {this.state.currentPage == 1 ? <td><a href>{(index + 1)}</a></td> : <td><a href>{(index + 1) + ((this.state.currentPage -1) * this.state.numberofrows)}</a></td> }
                          <td title={link.response.title}>{link.response.title.substring(0,50)}{link.response.title.length > 50 ? <span>...</span> : null  }</td>
                          <td title={link.response.anchor}>{link.response.anchor.substring(0,50)}{link.response.anchor.length > 50 ? <span>...</span> : null  }</td>
                          <td title={link.response.url_from}><a href={link.response.url_from} target="_blank">{link.response.url_from.substring(0,50)}{link.response.url_from.length > 50 ? <span>...</span> : null}</a></td>
                          <td title={link.response.url_to}><a href={link.response.url_to} target="_blank">{link.response.url_to.substring(0,50)}{link.response.url_to.length > 50 ? <span>...</span> : null}</a></td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

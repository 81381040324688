import React, { Component } from 'react';
import Select from 'react-select';
import { DOMAIN_NAME, WEBSITE, fetch_get, fetch_post, ADMINWEBSITE, Alert_model } from '../common';
import TopHeader from '../common/TopHeader';

let nicheList = [];
let UsersList = []
export default class ServiceEdit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
        service_name:this.props.location.state.service,
        description: this.props.location.state.description,
        price:this.props.location.state.price,
        id:this.props.location.state.id,
    }
  }

  componentDidMount() {
    // this.websiteDetails()
  }

  removeErrors(){
    this.setState({errors:{}})
  }

  serviceUpdate(){
    let formData = new FormData()
    formData.append('service_name',this.state.service_name)
    formData.append('description',this.state.description)
    formData.append('price',this.state.price) 
    fetch_post(`/api/service/${this.state.id}/update/ `, formData)
    .then((response) => {
      if(!response.error) {
        if(response.error == false){
          let d = document.getElementById('success')
          d.click()
          this.removeErrors()
        }
      }
      else{
        this.setState({ errors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });       
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>service Updated Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push({ pathname: '/admin/services', state:{detail:this.props.location.state.detail}})}>Close</button>
              </div>
            </div>
          </div>
        </div>     
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow row mb-6">
              <div className="row">
                <div className="card-header col-md-6 bg-white border-0">
                  <h3 className="mb-0">Service edit</h3>
                </div>
                <div className="col-md-6 text-right">
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/admin/services', state:{detail:this.props.location.state.detail,service:this.state.service_name,price:this.state.price,description:this.state.description}})}>
                    Back
                  </button>              
                </div>                    
              </div>
              <div className="card-body">
                <form>
                  <div className="pt-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">ServiceName <div className="error-text">*</div></label>
                          <input type="text" 
                            className="form-control"
                            value={this.state.service_name}
                            onChange={(e)=>this.setState({service_name:e.target.value})}
                           />
                          <div className="text-danger">
                            {this.state.errors && this.state.errors.service_name}
                          </div>                         
                        </div>                      
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">price <div className="error-text">*</div></label>
                          <input type="text" 
                            className="form-control"
                            value={this.state.price}
                            onChange={(e)=>this.setState({price:e.target.value})}
                           />
                        <div className="text-danger" style={{ fontSize: '1rem' }}>
                          {this.state.errors && this.state.errors.price}
                        </div>                          
                        </div>                      
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">description</label>
                          <input type="text" 
                            className="form-control"
                            value={this.state.description}
                            onChange={(e)=>this.setState({description:e.target.value})}
                           />                           
                        </div>                      
                      </div>                                                                  
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-center pt-3">
                      <span data-toggle="modal" data-target="#example_model" id="success"> </span>
                      <button type="button" className="btn btn-info mb-3" onClick={() => this.serviceUpdate()}>Update service</button>
                    </div> 
                  </div>                 
                </form>
              </div>
            </div>  
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { Link } from 'react-router-dom';
import {ADMIN, Alert_model, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

export default class BackLinks extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      backlinks:[],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      filter:false, 
      tag:'',
      svalue:'',
      idtoggle:false,
      tagtoggle:false,
      loading:false,
      backlinkId:'',
    }
    this.backlinks = this.backlinks.bind(this);
  }

  componentDidMount() {   
    this.backlinks()
  }

  backlinks(page=this.state.currentPage) {
    fetch_get(`/api/backlinks-moniter/?page=${page}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ backlinks: response.user_orders,numOfPages: response.num_pages  },window.scroll(0,0))
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  checkStatus(id){
    fetch_get(`/api/check-link-status/${id}/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          this.setState({loading:false})
          let a = document.getElementById('check_posturl')
          a.click()
          this.backlinks()
        }
      }
      else{
        this.setState({loading:false})
        this.backlinks()        
      }
    })
  }  

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.backlinks)
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />      
        <Alert_model msg={'Posturl status checked successfully'}/> 
        <span data-toggle = "modal" data-target = "#Alert_model" id="check_posturl"/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.backlinks)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">BackLinks</h3>                
                </div>                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">order id</th>
                        <th scope="col">order date </th>
                        <th scope="col">client </th>
                        <th scope="col">post url </th>
                        <th scope="col">date checked </th>
                        <th scope="col">status </th>
                        <th scope="col">check status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.backlinks.length > 0 ? this.state.backlinks.map((link, index) =>
                        <tr key={link.id}>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td style={{cursor:'pointer',color:'blue'}} onClick={() => this.props.history.push(`/admin/orders/${link.order_details.order_id}/details`)}>{link.order_details.order_id}</td>
                          <td>{link.order_details.created_on}</td>
                          <td>{link.order_details.created_by}</td>
                          <td>{link.post_url ? 
                                  <p style={{cursor:"pointer"}} className="posturl" onClick={()=> window.open(link.post_url)}>{link.post_url}</p>:
                              "----"}
                          </td>
                          <td>{link.post_url_checked_date == null ? '' : link.post_url_checked_date }</td>
                          <td>
                            {link.is_post_live ? 
                              <a href><i className="fas fa-check" style={{ color: 'green' }}></i></a> 
                            :
                              <a href><i className="fas fa-times" style={{ color: 'red' }}></i></a>
                            }
                          </td>
                          <td>
                            {
                              (this.state.loading && this.state.backlinkId === link.id)  ? 
                                <Fragment>
                                  <div className="spinner task">
                                    <div className="double-bounce1"></div>
                                    <div className="double-bounce2"></div>
                                  </div>
                                </Fragment> :
                              <button type="button" className="update_api btn btn-primary" onClick={() =>this.setState({loading:true,backlinkId:link.id},this.checkStatus(link.id))} >Check Status </button>
                            }
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

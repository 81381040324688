import React, { Component } from 'react';

export default class SubscriptionFailureMessage extends Component{
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render(){
    return(
     <div className="main-content client_web">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div style={{paddingTop:'225px',textAlign:'center'}}>
        <span style={{display: "inline-block", margin: "0 auto",textAlign:"center",fontSize: "4.5rem",color: "#9f3030",}}>Oops!</span>
          <h4 style={{textAlign:"center" ,margin: '1rem 0'}}>Payment Failed</h4>
        </div>
      </div>
    )
  }
}
import React, { Component } from 'react';
import Select from 'react-select';
import { DOMAIN_NAME, WEBSITE, fetch_get, fetch_post, ADMINWEBSITE, Alert_model } from '../common';
import TopHeader from '../common/TopHeader';

export default class ManageWebsiteEdit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      project_name:'',
      target_url:'',
      keywords:'',
    }
  }

  componentDidMount() {
    this.projectDetails()
  }

  projectDetails() {
    fetch_get(`/api/project/${this.props.match.params.id}/update/ `)
    .then((response) => {
      this.setState({
        project_name:response.project_serializer_data.name,
        target_url:response.project_serializer_data.target_url,
        keywords:response.project_serializer_data.keywords.toString(),
      })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  projectUpdate() {
    let key = this.state.keywords ? this.state.keywords.split(',') : null
    let formData = new FormData()
    formData.append('name', this.state.project_name)
    formData.append('target_url',this.state.target_url)
    formData.append('keywords', JSON.stringify(key))  
    fetch_post(`/api/project/${this.props.match.params.id}/update/ `, formData)
    .then((response) => {
      if(!response.error) {
        let model = document.getElementById('success')
        model.click()
      }
      else{
        this.setState({errors:response.errors})
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }


  render(){
    const { websiteDetails, nicheChoices, vendorsList } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        {this.state.errors ? <Alert_model msg={"Website Updated successfully"} redirecturl={true} history={this.props.history} managewebsites={true} /> : null}
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>project Updated Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push({ pathname: '/projects/', state:{details:"details"}})}>Close</button>
              </div>
            </div>
          </div>
        </div>     
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow row mb-6">
              <div className="row">
                <div className="card-header col-md-6 bg-white border-0">
                  <h3 className="mb-0">Project edit</h3>
                </div>
                <div className="col-md-6 text-right">
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/projects/', state:{details:this.props.location.state.details}})}>
                    Back
                  </button>              
                </div>                    
              </div>
              <div className="card-body">
                <form>
                  <div className="pt-4">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">ProjectName <div className="error-text">*</div></label>
                          <input type="text" 
                            className="form-control"
                            value={this.state.project_name}
                            onChange={(e)=>this.setState({project_name:e.target.value})}
                           />
                          <div className="text-danger">
                            {this.state.errors && this.state.errors.service_name}
                          </div>                         
                        </div>                      
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label className="form-control-label" htmlFor="input-username">target_url <div className="error-text">*</div></label>
                          <input type="text" 
                            className="form-control"
                            value={this.state.target_url}
                            onChange={(e)=>this.setState({target_url:e.target.value})}
                           />
                        <div className="text-danger" style={{ fontSize: '1rem' }}>
                          {this.state.errors && this.state.errors.price}
                        </div>                          
                        </div>                      
                      </div>
                        <div className="col-lg-4">
                          <div className="form-group mar-bot-5">
                            <label className="form-control-label" htmlFor="input-email">keywords (enter , seperated values) <div className="error-text">*</div></label>
                            <div className="input-group mb-3 dropdown no-caret">
                            <input type="text" 
                              className="form-control"
                              value={this.state.keywords}
                              onChange={(e)=>this.setState({keywords:e.target.value})}
                             />                             
                           </div>
                            <div className="text-danger">
                              {this.state.errors.website_errors && this.state.errors.website_errors.categories}
                            </div>                             
                          </div>
                        </div>                                                                 
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-center pt-3">
                      <span data-toggle="modal" data-target="#example_model" id="success"> </span>
                      <button type="button" className="btn btn-info mb-3" onClick={() => this.projectUpdate()}>Update project</button>
                    </div> 
                  </div>                 
                </form>
              </div>
            </div>  
          </div>
        </div>        
      </div>
    )
  }
}

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Clients from './Clients';
import Admins from './Admins';
import Vendors from './Vendors';
import Creators from './Creators';
import Services from './Services';
import Orders from './Orders';
import Tasks from './Tasks';
import BackLinks from './BackLinks';
import Websites from './Websites';
import ManageWebsites from './ManageWebsites';
import Csvmap from './Csvmap';
import Invoices from './Invoices';
import UnpaidInvoices from './UnpaidInvoices';
import InvoiceDetails from './InvoiceDetails'
import InvoicesDetails from './InvoicesDetails'
import OrderDetails from './OrderDetails';
import Ahrefdata from './Ahrefdata';
import Categories from './Categories';
import CategoryEdit from './CategoryEdit';
import Tags from './Tags';
import FilterLinks from './FilterLinks';
import TagEdit from './TagEdit';
import ServiceEdit from './ServiceEdit';
import WebsiteDetails from './WebsiteDetails';
import ManageWebsiteEdit from './ManageWebsiteEdit';
import ManageWebsiteDetails from './ManageWebsiteDetails';
import WebsiteEdit from './WebsiteEdit';
import PageNotFound from '../../components/common/PageNotFound';
import Profile from '../../components/auth/Profile';
import ChangePassword from '../../components/auth/ChangePassword';
import UploadContent from '../client/UploadContent';
import DiscountCodes from './DiscountCodes';
import BrokenLinks from './BrokenLinks';

const Component = () => (
  <Switch>
    <Route exact path='/admin/clients' component={Clients} />
    <Route exact path='/admin/admins' component={Admins} />
    <Route exact path='/admin/vendors' component={Vendors} />
    <Route exact path='/admin/creators' component={Creators} />
    <Route exact path='/admin/services' component={Services} />
    <Route exact path='/admin/websites' component={Websites} />
    <Route exact path='/admin/managewebsites' component={ManageWebsites} />
    <Route exact path='/admin/managewebsites/csvmap' component={Csvmap} />
    <Route exact path='/admin/invoices' component={Invoices} />
    <Route exact path='/admin/tasks' component={Tasks} />
    <Route exact path='/admin/backlinks' component={BackLinks} />
    <Route exact path='/admin/categories' component={Categories} />
    <Route exact path='/admin/category/:id/edit' component={CategoryEdit} />
    <Route exact path='/admin/tags' component={Tags} />
    <Route exact path='/admin/filterlinks' component={FilterLinks} />
    <Route exact path='/admin/tag/:id/edit' component={TagEdit} />
    <Route exact path='/admin/service/:id/edit' component={ServiceEdit} />    
    <Route exact path='/admin/unpaidinvoices' component={UnpaidInvoices} />
    <Route exact path='/admin/invoices/:id/details' component={InvoicesDetails} />
    <Route exact path='/admin/websites/:id/details' component={WebsiteDetails} />
    <Route exact path='/admin/websites/:id/edit' component={WebsiteEdit} />
    <Route exact path='/admin/managewebsites/:id/details' component={ManageWebsiteDetails} />
    <Route exact path='/admin/managewebsites/:id/edit' component={ManageWebsiteEdit} />
    <Route exact path='/admin/dashboard' component={Dashboard} />
    <Route exact path='/admin/orders' component={Orders} />
    <Route exact path='/admin/orders/:id/details' component={OrderDetails} />
    <Route exact path='/admin/' component={Dashboard} />
    <Route exact path='/admin/profile' component={Profile} />
    <Route exact path='/admin/ahrefdata' component={Ahrefdata} />
    <Route exact path='/admin/admins/profile' component={Profile} />
    <Route exact path='/admin/clients/profile' component={Profile} />
    <Route exact path='/admin/vendors/profile' component={Profile} />
    <Route exact path='/admin/admins/:id/profile' component={Profile} /> 
    <Route exact path='/admin/clients/:id/profile' component={Profile} />  
    <Route exact path='/admin/vendors/:id/profile' component={Profile} />
    <Route exact path='/admin/creators/:id/profile' component={Profile} /> 
    <Route exact path='/admin/:id/change-password' component={ChangePassword} /> 
    <Route exact path='/admin/websites/orders/upload-content' component={UploadContent} />
    <Route exact path='/admin/discountcodes' component={DiscountCodes} />
    <Route exact path='/admin/brokenlinks' component={BrokenLinks} />
    <Route exact path='/' component={Dashboard} />
    <Route component={PageNotFound}/>
  </Switch>
)

export default Component

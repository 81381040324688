import React, { Component, Fragment } from 'react';
import {  fetch_post, ORDER }  from '../common';
import  { Redirect } from 'react-router-dom'

export default class PricechangeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors:{},
      orderid:'',
      costPrice:'',
      sellingPrice:'',
    };
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      orderid:nextProps.orderid,
      costprice:nextProps.costprice,
      sellingprice:nextProps.sellingprice,
      // sellingprice:nextProps.sellingprice
    })

  }

  handleSubmit(event) {
    if(this.state.costprice == '' || this.state.sellingprice == '' || isNaN(this.state.costprice) || isNaN(this.state.sellingprice)){
      if(this.state.costprice  == ''){
        document.getElementById('cp').style.visibility = 'visible';
      }
      else{
        if(document.getElementById('cp')){
          document.getElementById('cp').style.visibility = 'hidden';
        }
      }
      if((isNaN(this.state.costprice))){
        if(document.getElementById('cp1')){
          document.getElementById('cp1').style.visibility = 'visible';
        }
      }
      if(this.state.sellingprice  == ''){
        document.getElementById('sp').style.visibility = 'visible';
      }
      else{
        if(document.getElementById('sp')){
          document.getElementById('sp').style.visibility = 'hidden';
        }
      }
      if((isNaN(this.state.sellingprice))){
        if(document.getElementById('sp1')){
          document.getElementById('sp1').style.visibility = 'visible';
        }
      }
    }
    else{   
      let formData = new FormData()
      formData.append('cost_price', this.state.costprice)
      formData.append('sell_price', this.state.sellingprice)
      formData.append('user_order_id', this.props.id)
      fetch_post(`${ORDER}/${this.state.orderid}/details/ `,formData)
      .then((response) => {
        if (!response.error) {
          let b = document.getElementById('dist')
          b.click();
          this.props.history.push(`/admin/orders/${this.state.orderid}/details`, {callOrderDetails:true})
        } else {
          this.setState({ errors: response.errors });
        }
      });
    }
  }

  onCancel(){
    this.setState({errors:{}})    
    this.props.history.push(`/admin/orders/${this.props.orderid}/details`)
  }

  render() {
    const { errors } = this.state;
    return (
        <div className="modal fade" id="pricechangeModel" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Price Change</h3>
               <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label>Cost Price <div className="error-text">*</div></label>
                      <div className="">
                        <input 
                          className="form-control" 
                          placeholder="Cost Price" 
                          type="text" 
                          onChange={(event)=>{ this.setState({ costprice: event.target.value }) }} value={this.state.costprice}
                        />
                        {this.state.costprice == '' ? 
                        <div className="text-danger" id="cp" style={{ fontSize: '1rem', visibility:'hidden'}}>
                          This field may not be blank.
                        </div>: (this.state.costprice !== '' && (isNaN(this.state.costprice))) ?
                        <div className="text-danger" id="cp1" style={{ fontSize: '1rem', visibility:'hidden'}}>
                          A valid number is required
                        </div> :
                        null
                        }                        
                      </div>  
                    </div>
                    <div className="col-md-12 form-group">
                      <label>Selling Price <div className="error-text">*</div></label>
                      <div className="">
                        <input 
                          className="form-control" 
                          placeholder="Selling Price" 
                          type="text" 
                          onChange={(event)=>{this.setState({ sellingprice:event.target.value })}} value={this.state.sellingprice}
                        />
                        {this.state.sellingprice == '' ? 
                        <div className="text-danger" id="sp" style={{ fontSize: '1rem', visibility:'hidden'}}>
                          This field may not be blank.
                        </div>: (this.state.sellingprice !== '' && (isNaN(this.state.sellingprice))) ?
                        <div className="text-danger" id="sp1" style={{ fontSize: '1rem', visibility:'hidden'}}>
                          A valid number is required
                        </div> :
                        null
                        }                                                
                      </div> 
                    </div>                    
                  </div>
                </form>
              <div className="col-md-12 text-center pb-4">
                <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                <button type="button" className="btn btn-info" onClick={() => this.handleSubmit()} >Upload</button>
                <button type="button" className="btn btn-default" id="dist" data-dismiss="modal" >Cancel</button> 
              </div>                
              </div>
            </div>
          </div>
        </div> 
    );
  }
}

import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { Link } from 'react-router-dom';
import {ADMIN, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

let orderStatus = ""
export default class Categories extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      categories:[],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      filter:false, 
      category:'',
      svalue:'',
      idtoggle:false,
      categorytoggle:false,
    }
    this.categories = this.categories.bind(this);
  }

  componentDidMount() {
    if(this.props.location.state){
      this.setState({ 
        filter:this.props.location.state.detail.filter,
        category:this.props.location.state.detail.category,
        numberofrows:this.props.location.state.detail.numberofrows,
        currentPage:this.props.location.state.detail.currentPage,
      }, this.categoryFilter)        
    }
    else{    
      this.categories()
    }
  }

  categories(page=this.state.currentPage) {
    fetch_get(`/api/category/list/?page=${page}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ categories: response.category_list, numOfPages: response.active_num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearStates(){
    this.setState({category:''},this.categoryFilter)
  }

  permanentDelete(id){
    fetch_get(`/api/category/${id}/delete/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          let d = document.getElementById('success')
          d.click()        
          this.categories()
        }
      }
      else{
        this.categories()        
      }
    })
  }  

  categoryFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    this.state.category && formData.append('name',this.state.category)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'category'){
      if(this.state.categorytoggle){
        formData.append('column_name_order','name')
      }
      else{
        formData.append('column_name_order','-name')
      }
    }       
    fetch_post('/api/category/list/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          categories: response.category_list, 
          numOfPages: response.active_num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.categoryFilter)
  }

  render(){
    let carryProps={
      filter:this.state.filter,
      category:this.state.category,
      numberofrows:this.state.numberofrows,
      currentPage:this.state.currentPage
    }
    return(
      <div className="main-content">
        <TopHeader />
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>Category Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/categories')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>    
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.categoryFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Categories</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>                  
                </div>
                  {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.category}
                                      onChange={(e) => this.setState({ category: e.target.value }, this.categoryFilter)}
                                      placeholder="category"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.categoryFilter)} >Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({categorytoggle:!this.state.categorytoggle,svalue:'category'},this.categoryFilter)}>Category <i className="fas fa-sort"></i></th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.categories.length > 0 ? this.state.categories.map((category, index) =>
                        <tr key={category.id}>
                          <td><a href>{category.id}</a></td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td>{category.name}</td>
                          <td>
                            <Link to={{pathname:`/admin/category/${category.id}/edit/`,state:{category:category.name,id:category.id,detail:carryProps}}}>
                              <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                            </Link>
                            <a onClick={() => this.permanentDelete(category.id)}>
                              <span style={{paddingRight:20, cursor:'pointer' }}><i className="fa fa-trash"></i></span>
                            </a>                            
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

 import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import {ADMIN, Alert_model, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

export default class DiscountCodes extends Component{
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      numOfPages: 1,
      numberofrows:100,
      discount:'',
      svalue:'',
      discount_type:'Fixed',
      discount_value:'',
      usage:'',
      websites:[],
      categoriesFilterOpenMenu:false,
      selectedwebsites:[],
      id:'',
      adtypes:[],
      discount_list:[],
      delete_id:'',
      filter:false,
      filter_adtype:'',
      domain:'',
      filter_usage:'',
      filter_code:'',
      filter_status:'',
      svalue:'',
      websitetoggle:false,
      adtypetoggle:false,
      discounttoggle:false,
      codetoggle:false,
      usagetoggle:false,
      statustoggle:false,
      all_adtypes:[],
    }
  }

  componentDidMount() {   
    this.discounts()
  }

  discounts() {
    fetch_get(`/api/codes/list/`)
    .then((response) => {
      this.setState({discount_list:response.codes_list,numOfPages: response.active_num_pages,all_adtypes:response.ad_types})
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  permanentDelete(id){
    fetch_get(`/api/code/${id}/delete/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          let dm = document.getElementById('deleteModel')
          dm.click();
          let d = document.getElementById('success')
          d.click()        
          this.discounts()
        }
      }
      else{
        this.discounts()        
      }
    })
  }

  beforeDelete(){
    let dc = document.getElementById('delete_discount')
    dc.click();
  }    


  discountFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    this.state.domain && formData.append('domain',this.state.domain)
    this.state.filter_adtype && formData.append('ad_type',this.state.filter_adtype)
    this.state.filter_usage && formData.append('usage',this.state.filter_usage)
    this.state.filter_code && formData.append('code',this.state.filter_code)
    this.state.filter_status && formData.append('status',this.state.filter_status)
    formData.append('page',this.state.currentPage) 
    if(this.state.svalue == 'website'){
      if(this.state.websitetoggle){
        formData.append('column_name_order','website__domain')
      }
      else{
        formData.append('column_name_order','-website__domain')
      }
    } 
    if(this.state.svalue == 'ad_type'){
      if(this.state.adtypetoggle){
        formData.append('column_name_order','ad_type')
      }
      else{
        formData.append('column_name_order','-ad_type')
      }
    }
    if(this.state.svalue == 'discount_value'){
      if(this.state.discounttoggle){
        formData.append('column_name_order','discount_value')
      }
      else{
        formData.append('column_name_order','-discount_value')
      }
    } 
    if(this.state.svalue == 'code'){
      if(this.state.codetoggle){
        formData.append('column_name_order','code')
      }
      else{
        formData.append('column_name_order','-code')
      }
    }
    if(this.state.svalue == 'usage'){
      if(this.state.usagetoggle){
        formData.append('column_name_order','usage')
      }
      else{
        formData.append('column_name_order','-usage')
      }
    } 
    if(this.state.svalue == 'status'){
      if(this.state.statustoggle){
        formData.append('column_name_order','status')
      }
      else{
        formData.append('column_name_order','-status')
      }
    }     
    fetch_post('/api/codes/list/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          discount_list: response.codes_list, 
          numOfPages: response.active_num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.discountFilter)
  }

  removeErrors(){
    this.setState({errors:{}, discount_type:'Fixed',id:'',adtypes:[], discount_list:[],usage:'',selectedwebsites:[],discount_value:''},this.discounts)
  }

  clearStates(){
    this.setState({domain:'', filter_adtype:'',filter_usage:'', filter_status:'',filter_code:''},this.discountFilter)
  }

  getWebsitesData(websites){
    let formData = new FormData()
    formData.append('name', websites)
    fetch_post('/api/get_websites_data/',formData)
    .then((response) => {
      if(!response.error) {
        let websites = []
        response.websites_data.map((w)=>{
          websites.push({label:w.domain, value:w.id})
        })
        this.setState({ websites: websites })
      }
    })
  }

  collectWebsiteIds(){
    // this.state.selectedwebsites.map( w => {
    //   this.setState({id:w.value})
    // })
    let formData = new FormData()
    this.state.selectedwebsites[0] && formData.append('website_id',this.state.selectedwebsites[0].value)
    fetch_post('/api/get_websites_data/',formData)
    .then((response) => {
      if(!response.error) {
        let websites = []
        this.setState({adtypes:response.ad_types})
      }
    })
  }

  createDiscount(){
    let formData = new FormData()
    this.state.discount_type == 'Percentage' ?  formData.append('discount_is_percentage', true) : formData.append('discount_is_percentage', false)
    this.state.discount_value && formData.append('discount_value',this.state.discount_value)
    // this.state.id && formData.append('website',this.state.id)
    this.state.selectedwebsites[0] && formData.append('website',this.state.selectedwebsites[0].value)
    this.state.ad_type && formData.append('ad_type',this.state.ad_type)
    this.state.usage && formData.append('usage',this.state.usage)
    fetch_post('/api/create/discount_code/',formData)
    .then((response) => {
      if(!response.error) {
        let websites = []
        let model = document.getElementById("createModel");
        model.click();
        let display = document.getElementById('display_model');
        display.click();
        this.discounts();
      }
      else{
        this.setState({errors:response.errors})
      }
    })
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <span data-toggle="modal" data-target="#exampleModal4" id="delete_discount"></span>
        <span data-toggle="modal" data-target="#example_model" id="display_model"></span>
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>Discount Code Created Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push({ pathname: '/admin/discountcodes'})}>Close</button>
              </div>
            </div>
          </div>
        </div>        
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>Discount Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/discountcodes')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade col-md-6 m-auto" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content m-auto">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteModalLabel">website Delete</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Are you Sure ? You want to delete Website ?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">Close</button>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={()=>this.permanentDelete(this.state.delete_id)}>delete</button>
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>          
        <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Create DiscountCode</h3>
               <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    <div className="col-md-5 form-group">
                      <label>Discount Value <div className="error-text">*</div></label>
                          <div className="form-group">
                            <select 
                              className="form-control"
                              value={this.state.discount_type}
                              name="discountType"
                              onChange={(e) => this.setState({ discount_type: e.target.value})}
                            >
                              <option value="Fixed">Fixed ($)</option>
                              <option value="Percentage">Percentage(%)</option>
                            </select>
                          </div>                      
                        </div>
                    <div className="col-md-7 form-group">
                      <div className="form-group" style={{marginTop:'1.9rem'}}>
                          <input 
                            className="form-control" 
                            placeholder={this.state.discount_type == 'Fixed' ? 'Discount Value($)' : 'Discount Value(%)'}
                            type="email" 
                            onChange={(event)=>{ this.setState({ discount_value:event.target.value }) }} value={this.state.discount_value}
                          />
                        </div>
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.discount_value}
                      </div>    
                    </div>
                    <div className="col-md-12 form-group">
                      <label>websites  <div className="error-text">*</div></label>
                          <div className="form-group">
                            <Select 
                              className="form-control"
                              value={this.state.selectedwebsites}
                              onChange={(e) => this.setState({ selectedwebsites: e, categoriesFilterOpenMenu:false},this.collectWebsiteIds)}
                              onInputChange={(e)=>{if(e){this.setState({categoriesFilterOpenMenu:true},this.getWebsitesData(e))}
                              else{
                                this.setState({categoriesFilterOpenMenu:false})
                              }
                              }}
                              options={this.state.websites}
                              menuIsOpen={this.state.categoriesFilterOpenMenu}
                              isMulti
                              placeholder="Websites"
                            />
                        </div>                      
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.website}
                      </div>  
                    </div>
                    <div className="col-md-12 form-group">
                      <label>ad Type <div className="error-text">*</div></label>
                          <div className="form-group">
                            <select 
                              className="form-control"
                              value={this.state.ad_type}
                              name="adType"
                              onChange={(e) => this.setState({ ad_type: e.target.value})}
                            >
                              <option value="">AdType</option>
                              {this.state.adtypes.map((adtype)=>
                                <option value={adtype}>{adtype}</option>
                              )}
                            </select>
                          </div>                      
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.ad_type}
                      </div> 
                    </div>                      
                    <div className="col-md-12 form-group">
                      <label> Usage </label>
                        <div className="form-group">
                          <input 
                            className="form-control" 
                            placeholder="Usage" 
                            type="email" 
                            onChange={(event)=>{ this.setState({ usage:event.target.value }) }} 
                            value={this.state.usage}
                          />
                        </div>                                             
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.usage}
                      </div>
                    </div>                                          
                  </div>
                </form> 
                <div className="col-md-12 text-center pb-4">
                <button type="button" className="btn btn-info" onClick={() => this.createDiscount()} >Create</button>
                <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={() => this.removeErrors()} >Cancel</button> 
              </div>
              </div>
            </div>
          </div>
        </div>  
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.discountFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Discount Codes</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal2">Create Discount Code</button>                  
                  </div>                  
                </div>
                {this.state.filter &&
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <div className="filter_result">
                          <form>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.domain}
                                    onChange={(e) => this.setState({ domain: e.target.value }, this.discountFilter)}
                                    placeholder="domain"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select 
                                    className="form-control"
                                    value={this.state.filter_adtype}
                                    name="adType"
                                    onChange={(e) => this.setState({ filter_adtype: e.target.value}, this.discountFilter)}
                                  >
                                    <option value="">AdType</option>
                                    {this.state.all_adtypes.map((adtype)=>
                                      <option value={adtype}>{adtype}</option>
                                    )}
                                  </select>                                
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.filter_usage}
                                    onChange={(e) => this.setState({ filter_usage: e.target.value }, this.discountFilter)}
                                    placeholder="usage"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.filter_code}
                                    onChange={(e) => this.setState({ filter_code: e.target.value }, this.discountFilter)}
                                    placeholder="code"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select 
                                    className="form-control"
                                    onChange={(e) => this.setState({ filter_status: e.target.value}, this.discountFilter)}
                                    value={this.state.filter_status}
                                  >
                                  <option value="">status</option>
                                    <option value="active">active</option>
                                    <option value="invalid">invalid</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="col-lg-12 text-center">
                              <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates()}>Clear</button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }             
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({websitetoggle:!this.state.websitetoggle,svalue:'website'},this.discountFilter)}>website <i className="fas fa-sort"/></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({adtypetoggle:!this.state.adtypetoggle,svalue:'ad_type'},this.discountFilter)}>ad type <i className="fas fa-sort"/></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({discounttoggle:!this.state.discounttoggle,svalue:'discount_value'},this.discountFilter)}>discount <i className="fas fa-sort"/></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({codetoggle:!this.state.codetoggle,svalue:'code'},this.discountFilter)}>code <i className="fas fa-sort"/></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({usagetoggle:!this.state.usagetoggle,svalue:'usage'},this.discountFilter)}>usage <i className="fas fa-sort"/></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({statustoggle:!this.state.statustoggle,svalue:'status'},this.discountFilter)}>status<i className="fas fa-sort"/></th>
                        <th scope="col">action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.discount_list.length > 0 ? this.state.discount_list.map((discount, index) =>
                        <tr key={discount.id}>
                          <td><a href>{discount.website}</a></td>
                          <td>{discount.ad_type}</td>
                          <td>
                            {discount.discount_value}                            
                          </td>
                          <td>{discount.code}</td>
                          <td>{discount.no_of_times_used_code} / {discount.usage}</td>
                          <td>{discount.status ? 'active' : 'invalid'}</td>
                          <td>
                            <a onClick={() => this.setState({delete_id:discount.id},this.beforeDelete)}>
                              <span style={{paddingRight:20, cursor:'pointer' }}><i className="fa fa-trash"></i></span>
                            </a>                            
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

import React, { Component } from 'react';
export class Alert_model extends Component{
  constructor(props) {
    super(props);
    this.state = {
      message:'',
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      message:nextProps.msg,
    })

  }
 
  render() {
    return (
      <div className="col-md-6 m-auto modal fade" id="Alert_model" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
          </div>
          <div className="modal-body">
          <center>{this.state.message}</center>
          </div>
          <div className="modal-footer">
            {this.props.managewebsites === true && this.props.redirecturl === true ? 
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/admin/managewebsites')} >Close</button>:
            this.props.redirecturl === true && this.props.role === 'vendor' ? 
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/vendors/websites')} >Close</button>:
            this.props.redirecturl === true ?
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/admin/websites')} >Close</button>:
            this.props.uploadcontent === true && this.props.public === true ?
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push(`/public/payment?token=${this.props.token}&order_id=${this.props.order_id}/`)} >Close</button>:
            this.props.uploadcontent === true ? 
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.goBack()} >Close</button>:
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" >Close</button>}
          </div>
        </div>
      </div>
    </div>
    );
  }
}

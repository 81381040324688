import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { SIGNUP, DOMAIN_NAME } from '../common';

export default class SignUp extends Component{
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
      password: '',
      name:'',
      errors: {},
      lastName: '',
      username: '',
      selectedNav: window.location.pathname.split('/')[1],
      passwordStrengthChecker:'Weak',
    };
  this.passwordStrength = this.passwordStrength.bind(this)
  this.handleKeyPress = this.handleKeyPress.bind(this)
  }


  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit(event) {
    // event.preventDefault();
    const { email, password,name, lastName, username } = this.state;
    let formData = new FormData()
    // let url = ''
    // if(this.props.location.state) {
    //   url = ADMIN_SIGNUP
    // } else {
    //   url = SIGNUP
    //   formData.append('user_role', this.state.selectedNav === 'vendors' ? 'VENDOR' : 'CLIENT')
    // }
    formData.append('email', email)
    formData.append('username', username)
    formData.append('last_name', lastName)
    formData.append('password', password)
    formData.append('first_name', name)
    if(email.length!==0&&username.length!==0&&password.length!==0&&name.length!==0){
      if(document.getElementById("cb").checked){
        fetch(DOMAIN_NAME+SIGNUP, {
          method: 'post',
          body: formData
        })  
        .then(response => response.json())
        .then((response) => {
          if (!response.error) {
            // if(this.state.selectedNav === 'vendors'){
            //   this.props.history.push('/vendors/signin');  
            // } else if(this.state.selectedNav === 'admin'){
            //   this.props.history.push('/admin/dashboard');  
            // } else {
            //   this.props.history.push('/signin'); 
            // }
            this.props.history.goBack()
          } else {
            this.setState({ errors: response.errors });
          }
        });
      }
      else{
        this.setState({ errors: { checkbox: 'Please agree with the privacy policy '}})
      }
    }
    else{
      this.setState({ errors: {...this.state.errors, all: 'Please fill all the details '}}) 
    }
  }

  passwordStrength(event){
    this.setState({password:event.target.value})    
      let strengthValue = {
      'caps': false,
      'special': false,
      'numbers': false,
      'small': false
    };
    for(let index=0; index < event.target.value.length; index++) {
      let char = event.target.value.charCodeAt(index);
      if(!strengthValue.caps && char >= 65 && char <= 90) {
          strengthValue.caps = true;
      } else if(!strengthValue.small && char >=97 && char <= 122){
        strengthValue.small = true;
      } else if(!strengthValue.numbers && char >=48 && char <= 57){
        strengthValue.numbers = true;
      } else if((!strengthValue.special && (char >=33 && char <= 47)) || (char >=58 && char <= 64)) {
        strengthValue.special = true;
      }
    }
  
    if((strengthValue.caps&&strengthValue.numbers&&strengthValue.small&&!strengthValue.special) || (strengthValue.caps&&strengthValue.numbers&&!strengthValue.small&&strengthValue.special) || (strengthValue.caps&&!strengthValue.numbers&&strengthValue.small&&strengthValue.special)|| (!strengthValue.caps&&strengthValue.numbers&&strengthValue.small&&strengthValue.special)){
      this.setState({passwordStrengthChecker:'Medium'})
    }
   
    else if(strengthValue.caps&&strengthValue.numbers&&strengthValue.small&&strengthValue.special){
        this.setState({passwordStrengthChecker:'Strong'})
      }       
    
    else{
      this.setState({passwordStrengthChecker:'Weak'})
    }
 
  
  }

  render(){
    const { errors } = this.state; 
    return(
      <Fragment>
        <div className="login_rc">
          <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
              <div className="container px-4 justify-content-center">
                <a className="navbar-brand" href="../index.html">
                  <img src="/img/brand/logo.png" alt="" style={{height:"100px"}}/>
                </a>
                <div className="collapse navbar-collapse" id="navbar-collapse-main">
                  <div className="navbar-collapse-header d-md-none">
                    <div className="row">
                      <div className="col-6 collapse-brand">
                        <a href="../index.html">
                          <img src="../assets/img/brand/blue.png" alt="" />
                        </a>
                      </div>
                      <div className="col-6 collapse-close">
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="header bg-gradient-primary py-9 py-lg-9">
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>
            <div className="container mt--9 pb-1">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 mb-5">
                  <div className="card border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small> Sign up with Credentials</small>
                      </div>
                      <form name="form">
                        <div className="form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress} 
                              className="form-control" 
                              placeholder="Username" 
                              type="text" 
                              onChange={(event)=>{this.setState({username:event.target.value})}} value={this.state.username}
                            />                        
                          </div>
                          <div className="text-danger">
                            {errors.username ? errors.username[0] : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}
                              className="form-control" 
                              placeholder="Email" 
                              type="email" 
                              onChange={(event)=>{this.setState({email:event.target.value})}} value={this.state.email}
                            />                        
                          </div>
                          <div className="text-danger">
                            {errors.email ? errors.email[0] : null}
                            {errors.non_field_errors ? errors.non_field_errors[0] : null}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input
                              onKeyPress={this.handleKeyPress} 
                              className="form-control" 
                              placeholder="First Name" 
                              type="text" 
                              onChange={(event)=>{this.setState({name:event.target.value})}} 
                              value={this.state.name}
                            />                        
                          </div>
                          <div className="text-danger">
                            {errors.first_name ? errors.first_name[0] : null}
                          </div>  
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-alternative mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}
                              className="form-control" 
                              placeholder="last Name" 
                              type="text" 
                              onChange={(event)=>{this.setState({lastName:event.target.value})}} 
                              value={this.state.lastName}
                            />                        
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}
                              className="form-control" 
                              placeholder="Password" 
                              type="password" 
                              onChange={(e)=>this.passwordStrength(e)} 
                              value={this.state.password}
                            />                          
                          </div>
                          <div className="text-danger">
                            {errors.password ? errors.password[0] :  null }
                          </div>                        
                        </div>
                        <div className="text-muted font-italic"><small>password strength: {this.state.passwordStrengthChecker === 'Strong' ? <span className="text-success font-weight-700">{this.state.passwordStrengthChecker}</span>:this.state.passwordStrengthChecker === 'Medium'?<span className="text-warning font-weight-700">{this.state.passwordStrengthChecker}</span>:<span className="text-danger font-weight-700">{this.state.passwordStrengthChecker}</span>}</small></div>
                        <div className="row my-4">
                          <div className="col-12">
                            <div className="pl-0 custom-control custom-control-alternative custom-checkbox">
                              <input className="" id="cb" type="checkbox"
                                onKeyPress={this.handleKeyPress}
                               />
                              <label className="pl-1" for="customCheckRegister">
                                <span className="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                              </label>
                              <div className="text-danger">
                                {errors.checkbox ? errors.checkbox :  null }
                              </div>                              
                            </div>
                          </div>
                        </div>
                        <div className="text-danger">
                          <div className="text-center">
                          {errors.all ? errors.all :  null }
                          </div>
                        </div>                         
                        <div className="text-center">
                          <button type="button" className="btn btn-primary mt-4" onClick={this.handleSubmit.bind(this)}>Create account</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <div className="text-light">Already have an Account ?  <Link to={this.state.selectedNav === 'vendors' ? "/vendors/signin" : '/signin'}>Login</Link></div>
                  </div>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
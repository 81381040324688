import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get } from '../common';

export default class CreateOrder extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      orderId: ''
    }
  }

  componentDidMount() {
    this.orderDetails()
  }

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.location.state.id}/details `)
    .then((response) => { 
      if(!response.error) {
        this.setState({ orderDetails: response.order_details, orderId: response.order_details.id })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <div className="main-content">
          <div className="header bg-gradient-primary  pt-md-7">
          </div>
          <div className="container-fluid">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-9">
                <div className="card shadow  profile_card">
                  <div className="card-header">
                    <h3 className="mb-0">Order Title</h3>
                  </div>
                  <div className="card-body">
                    <form className="pt-3">
                      <div className="">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group text-left">
                              <input type="text" className="form-control form-control-alternative" placeholder="Order Name" disabled />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group text-left">
                              <input type="email" className="form-control form-control-alternative" placeholder="Order ID" value={this.state.orderId} disabled />
                            </div>
                          </div>
                        </div>
                        <table className="table align-items-center table-flush mt-3">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Url</th>
                              <th scope="col">Action</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.orderDetails.id && this.state.orderDetails.order_website_details.map((website) => 
                              <tr key={website.id}>
                                <td>
                                  {website.user_website_details.website}
                                </td>
                                <td>
                                  {website.text_content_status ?
                                    <span><i className="fas fa-check" style={{ color: 'green' }}></i></span>
                                  :
                                    <span><i className="fas fa-times" style={{ color: 'red' }}></i></span>
                                  }
                                </td>
                                 <td>
                                  <button 
                                    type="button" 
                                    className="btn btn-info" 
                                    onClick={() => 
                                      this.props.history.push('/websites/orders/upload-content', 
                                      { id: this.state.orderId, website: website.user_website_details.website, websiteId: website.user_website_details.id, edit: website.upload_content ? website.upload_content : null })}
                                  >
                                    Upload
                                  </button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


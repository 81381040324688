import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Orders from './Orders';
import Dashboard from './Dashboard';
import Services from './Services';
import Payments from './Payments';
import ServiceEdit from './ServiceEdit';
import OrderDetails from './OrderDetails';
import PageNotFound from '../../components/common/PageNotFound';
import Profile from '../../components/auth/Profile';
import ChangePassword from '../../components/auth/ChangePassword';
import UploadContent from '../client/UploadContent';

const Component = () => (
  <Switch>
    <Route exact path='/creator/dashboard' component={Dashboard} />
    <Route exact path='/creator/services' component={Services} />
    <Route exact path='/creator/payments' component={Payments} />
    <Route exact path='/creator/service/:id/edit' component={ServiceEdit} />
    <Route exact path='/creator/orders' component={Orders} />
    <Route exact path='/creator/order/:id/details' component={OrderDetails} />
    <Route exact path='/creator/profile' component={Profile} />
    <Route exact path='/creator/:id/change-password' component={ChangePassword} />
    <Route exact path='/creator/websites/orders/upload-content' component={UploadContent} /> 
    <Route component={PageNotFound}/>
  </Switch>
)

export default Component

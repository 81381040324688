import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get, Pagination } from '../common';

export default class Orders extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
      currentPage: 1,
      numOfPages: 1
    }
    this.ordersList = this.ordersList.bind(this);
  }

  componentDidMount() {
    this.ordersList()
  }

  ordersList(page=this.state.currentPage) {
    fetch_get(`${ORDER}s/list/?page=${page}&num_pages=${10}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ ordersList: response.orders, numOfPages: response.num_pages  },window.scroll(0,0))
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getCurrentPage(currentPage) {
    this.setState({currentPage}, this.ordersList)
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <div className="header bg-gradient-primary pb-9">
        </div>
        <div className="container-fluid mt--7">
      <div className="row main_list_container">
        <div className="col">
          <div className="card shadow">
            <div className="card-header border-0">
              <h3 className="mb-0">Orders</h3>
            </div>
            <div className="table-responsive">
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th width="15%">Order Id</th>
                    <th width="15%">Created On</th>
                    <th width="10%">Websites Count</th>
                    <th width="15%">Order Status</th>
                    <th width="15%">Payment Done</th>
                    <th width="40%">Websites</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ordersList.length > 0 ? this.state.ordersList.map((order, index) => 
                    <tr style={{ cursor: 'pointer' }} key={order.id} onClick={() => this.props.history.push(`/vendors/orders/${order.order_id}/details`, { role: 'vendor' })}>
                      <td>
                        <a href>{order.order_id}</a>
                      </td>
                      <td>
                        <a href>{order.created_on}</a>
                      </td>
                      <td>
                        <a href>{order.website_details.length}</a>
                      </td>   
                      <td>
                        <a href>{order.order_status}</a>
                      </td>                                                                                                         
                      <td>
                        {order.payment_status === 'Paid' ?
                          <a href><i className="fas fa-check" style={{ color: 'green' }}></i></a>
                        :
                          <a href><i className="fas fa-times" style={{ color: 'red' }}></i></a>
                        }
                      </td>
                      <td className="overlap">
                        {order.website_details.splice(0,5).map((d)=><span> <span style={{backgroundColor: "rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem",}}>{d}</span> </span>)}
                      </td>                                            
                    </tr>
                  )
                  :
                    <tr>
                      <td>No Records found</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="card-footer py-4">
              <nav aria-label="...">
                <ul className="pagination justify-content-end mb-0">
                  {Number(this.state.numOfPages) > 1 &&
                    <Pagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.numOfPages}
                      getCurrentPage={this.getCurrentPage.bind(this)}
                    />
                  }
                </ul>
              </nav>
            </div>
          </div>
          <br />
        </div>
      </div>
        </div>
      </div>   
    )
  }
}
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Websites from './Websites';
import WebsiteDetails from './WebsiteDetails';
import WebsiteEdit from './WebsiteEdit';
import Orders from './Orders';
import Payments from './Payments';
import OrderDetails from './OrderDetails';
import PageNotFound from '../../components/common/PageNotFound';
import Profile from '../../components/auth/Profile';
import ChangePassword from '../../components/auth/ChangePassword';

const Component = () => (
  <Switch>
    <Route exact path='/vendors' component={Dashboard} />
    <Route exact path='/vendors/dashboard' component={Dashboard} />
    <Route exact path='/vendors/payments' component={Payments} />
    <Route exact path='/vendors/websites' component={Websites} />
    <Route exact path='/vendors/orders' component={Orders} />
    <Route exact path='/vendors/orders/:id/details' component={OrderDetails} />
    <Route exact path='/vendors/profile' component={Profile} />
    <Route exact path='/vendors/website/:id/edit' component={WebsiteEdit} />
    <Route exact path='/vendors/:id/change-password' component={ChangePassword} />  
    <Route component={PageNotFound}/>
  </Switch>
)

export default Component

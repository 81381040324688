import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/')[1],
      tabActive: window.location.pathname.split('/')[2],
      superuser: localStorage.getItem('SuperUser'),
      sharedFilters:false
    };
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  isActiveTab(value) {
    return (value === this.state.tabActive) ? "nav-link active" : "nav-link";
  }

  render() {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to={"/admin/dashboard"} className={this.isActiveTab('dashboard')} onClick={() => {this.setState({ tabActive: 'dashboard'});this.props.show(false)}}>
            <i className="ni ni-tv-2 text-primary"></i>Dashboard
          </Link>
        </li>
        {this.state.superuser === 'true' &&
          <li className="nav-item">
            <Link to={"/admin/admins"} className={this.isActiveTab('admins')} onClick={() => {this.setState({ tabActive: 'admins'});this.props.show(false)}}>
              <i className="ni ni-single-02 text-green"></i>Admins
            </Link>
          </li>
        }
        <li className="nav-item">
          <Link to={"/admin/clients"} className={this.isActiveTab('clients')} onClick={() => {this.setState({ tabActive: 'clients'});this.props.show(false)}}>
            <i className="ni ni-app text-yellow"></i>Clients
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/vendors"} className={this.isActiveTab('vendors')} onClick={() => {this.setState({ tabActive: 'vendors'});this.props.show(false)}}>
            <i className="ni ni-hat-3 text-orange"></i>Vendors
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/creators"} className={this.isActiveTab('creators')} onClick={() => {this.setState({ tabActive: 'creators'});this.props.show(false)}}>
            <i className="ni ni-single-02 text-green"></i>Creators
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/services"} className={this.isActiveTab('services')} onClick={() => {this.setState({ tabActive: 'services'});this.props.show(false)}}>
            <i className="ni ni-support-16 text-info"></i>Services
          </Link>
        </li>                 
        <li className="nav-item">
          <Link to={"/admin/managewebsites"} className={this.isActiveTab('managewebsites')} onClick={() => {this.setState({ tabActive: 'managewebsites'});this.props.show(false)}}>
            <i className="ni ni-world text-teal"></i> Manage Websites
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/websites"} className={this.isActiveTab('websites')} onClick={() => {this.setState({ tabActive: 'websites'});this.props.show(false)}}>
            <i className="ni ni-world text-teal"></i> Websites <span style={{paddingLeft:'3rem', color:'blue'}}><i onClick={()=>this.setState({sharedFilters:!this.state.sharedFilters})} class="fas fa-angle-down"></i></span>
          </Link>
          {this.state.sharedFilters && 
            <li className="nav-item" style={{paddingLeft:'2rem'}}>
              <Link to={"/admin/filterlinks"} className={this.isActiveTab('filterlinks')} onClick={() => {this.setState({ tabActive: 'filterlinks'});this.props.show(false)}}>
                Filter Links
              </Link>
            </li>
          }
        </li>
        <li className="nav-item">
          <Link to={"/admin/orders"} className={this.isActiveTab('orders')} onClick={() => {this.setState({ tabActive: 'orders'});this.props.show(false)}}>
            <i className="ni ni-cart text-red"></i>Orders
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/invoices"} className={this.isActiveTab('invoices')} onClick={() => {this.setState({ tabActive: 'invoices'});this.props.show(false)}}>
            <i className="ni ni-paper-diploma text-info"></i>Invoices
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/categories"} className={this.isActiveTab('categories')} onClick={() => {this.setState({ tabActive: 'categories'});this.props.show(false)}}>
            <i className="ni ni-tv-2 text-red"></i>Categories
          </Link>
        </li> 
        <li className="nav-item">
          <Link to={"/admin/tags"} className={this.isActiveTab('tags')} onClick={() => {this.setState({ tabActive: 'tags'});this.props.show(false)}}>
            <i className="fa fa-tag text-teal"></i>Tags
          </Link>
        </li>         
        <li className="nav-item">
          <Link to={"/admin/tasks"} className={this.isActiveTab('tasks')} onClick={() => {this.setState({ tabActive: 'tasks'});this.props.show(false)}}>
            <i className="ni ni-compass-04 text-info"></i>Tasks
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/backlinks"} className={this.isActiveTab('backlinks')} onClick={() => {this.setState({ tabActive: 'backlinks'});this.props.show(false)}}>
            <i className="fa fa-link text-green"></i>BackLinks
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/ahrefdata"} className={this.isActiveTab('ahrefdata')} onClick={() => {this.setState({ tabActive: 'ahrefdata'});this.props.show(false)}}>
            <i className="ni ni-compass-04 text-info"></i>Ahref Data
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/discountcodes"} className={this.isActiveTab('discountcodes')} onClick={() => {this.setState({ tabActive: 'discountcodes'});this.props.show(false)}}>
            <i className="ni ni-notification-70 text-green"></i>Discount Codes
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/admin/brokenlinks"} className={this.isActiveTab('Broken Links Building Tool')} onClick={() => {this.setState({ tabActive: 'Broken Links Building Tool'});this.props.show(false)}}>
            <i className="fa fa-unlink text-red"></i>Broken Links Building Tool
          </Link>
        </li>
      </ul>
    );
  }
}

export default withRouter(LeftNav);

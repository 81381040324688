import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

export default class UnpaidInvoices extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: ''
    }
    this.invoices = this.invoices.bind(this);
    this.redirectPage = React.createRef();
  }

  componentDidMount() {
    this.invoices()
  }


  invoices(page=this.state.currentPage) {
    let formData = new FormData()
    formData.append('payment_type', true)
    formData.append('page', this.state.currentPage)        
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ invoices: response.orders_list_serializer, numOfPages: response.num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getCurrentPage(currentPage) {
    this.setState({currentPage}, this.invoices)
  }

  payment(id) {
    fetch_get(`${USER}${id}/pay/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ redirectUrl: response.redirect_url })
      } else {
      }
      this.redirectPage.current.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    return(
      <div className="main-content client_web">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col">
                <div className="card-header bg-white border-0">
                  <h3 className="mb-0 p-3">Unpaid Invoices</h3>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Unique Id</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Created on</th>
                        <th scope="col">Invoice Amount</th>
                        <th scope="col">Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.invoices.length > 0 ? this.state.invoices.map((invoice, index) =>
                        <tr key={invoice.id}>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })} ><a href>{invoice.id}</a></td>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })} ><a href>{invoice.order_id}</a></td>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.created_on}</td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td style={{cursor:'pointer', color:"blue"}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.amount}</td>
                          <td>
                            { invoice.payment_status === 'Paid' ? 'Payment Done' : 'Payment yet to receive'}
                          </td>
                        </tr>
                      ): null}
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import Alert_selfmodel from '../common/Alert_selfmodel'
import { VENDOR_SIGNUP, ADMIN_SIGNUP, fetch_post, fetch_get, ORDER, SIGNUP, Alert_model } from '../common';

export default class BulkConfirmationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      serviceList:[],   
      selectedPrice:'', 
      websiteData:{},
      services:[],
      selecetedService:'' 
    };
  }

  updateList(){
    this.setState({loading:false,websiteData:{}})
    this.props.uncheckwebsites()
  }

  componentDidMount(){  
    fetch_get('/api/article_services/list/')
    .then((response) => { 
      if(!response.error){
        let services=[]
        response.services_list.map((service)=>
          services.push({label:service.service_name,price:service.price,id:service.id}))
        this.setState({serviceList:response.services_list,services:services})
      }

    })  
  }

  // selectArticle(e,wid,index){
  //   if(!(wid in this.state.websiteData)){
  //     this.state.websiteData[wid]=e.id
  //   }
  //   this.props.orderlist[index]['total'] = parseInt(this.props.orderlist[index].price) + parseInt(e.price)
  //   this.setState({})
  // }

  selectArticle(sid,wid,index){
    if(!(wid in this.state.websiteData)){
      this.state.websiteData[wid]=sid
    }    
    var article = this.state.serviceList.find(article => article.id == sid);
    if(article){
      this.props.orderlist[index]['total_price'] = parseInt(this.props.orderlist[index].sell_price) + parseInt(article.price)
    }
    else{
      this.props.orderlist[index]['total_price'] = parseInt(this.props.orderlist[index].sell_price)
    }    
    this.setState({})
  }


  createOrders() {
    this.props.orderlist .map((order,i)=>{
      if(!(order.id in this.state.websiteData)){
        this.state.websiteData[order.id]=""
      }
    }) 
    // let a = document.getElementById("createModel");
    // a.click()
    if(this.props.orderlist.length>0) {
      // let a = document.getElementById("createModel");
      // a.click()      
      let data = new FormData();
      data.append('websites', JSON.stringify(this.state.websiteData))
      // data.append('prices', JSON.stringify(this.props.prices))
      data.append('project_id', this.props.projectid)
      data.append('is_not_ordered_website',true)
      this.props.role === 'admin' && this.props.clientid.value && data.append('client_id', this.props.clientid.value)
      fetch_post(`${ORDER}/create/`, data)
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          // alert(response.message)
          localStorage.setItem('orderid',response.order_id)
          this.setState({loading:false})
          let a = document.getElementById("bulk_dis");
          a.click();
          // this.props.updatewebsites()
          let b = document.getElementById("bulk");
          b.click();
          // this.props.uncheckwebsites()
          // this.props.history.push('/websites/orders/create', { id: response.order_id })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
    else {
      alert('please select atleast one website to create order')
    }
  }


  render() {
    const { errors } = this.state;
    return (
      <div class="modal fade bd-example-modal-lg" id="BulkConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <Alert_model msg={'order created successfully'}/> 
        <Alert_selfmodel msg={'order created successfully'} role='client' />
        <div className="modal-dialog col-md-12" role="document" style={{maxWidth:'1641px'}}>
          <div className="col-lg-12">
            <div className="filter_result mt-5">
             <h3 className="mb-4 col-md-12 text-center">Your Websites Cart</h3>
             <div className="row justify-content-center">
              </div>
              <div className="table-responsive">
                <table className="table align-items-center table-flush">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">website name</th>
                      <th scope="col">website adtype</th>
                      {this.props.userrole == 'client' ?
                        <Fragment> 
                        <th scope="col">price</th>
                        <th scope="col">articlewriting</th>
                        <th scope="col">total</th>
                        </Fragment>:
                        <Fragment>
                        <th scope="col">caluclatedprice</th>
                        <th scope="col">originalprice</th>
                        <th scope="col">articlewriting</th>
                        <th scope="col">total</th>                        
                        </Fragment>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.orderlist.length > 0 ? this.props.orderlist.map((order, index) => 
                      <tr style={{ cursor: 'pointer' }} key={order.id}>
                        <td>
                          {order.website_details.domain}
                        </td>
                        <td>
                          {order.ad_type}
                        </td>
                        {this.props.userrole == 'client' ?
                          <Fragment>
                          <td>
                            {order.sell_price}
                          </td>
                          <td>
                            { order.ad_type == 'Article' ?
                            <select 
                            className="form-control"
                            placeholder="services"
                            onChange={(e)=>this.selectArticle(e.target.value,order.id,index)}
                            >
                            <option value="">select service</option>
                            {this.state.serviceList.map((service)=>
                              <option value={service.id}>{service.service_name}(+${service.price})</option>
                              )}
                            </select>
                            :null}                           
                          </td>
                          <td>
                            {order.total_price}
                          </td>
                          </Fragment>:                                                    
                          <Fragment>
                          <td>
                            {order.price}
                          </td>                          
                          <td>
                            {order.originalprice}
                          </td>
                          <td>
                            { order.ad_type == 'Article' ?
                            <select 
                            className="form-control"
                            placeholder="services"
                            onChange={(e)=>this.selectArticle(e.target.value,order.websiteId,index)}
                            >
                            <option value="">select service</option>
                            {this.state.serviceList.map((service)=>
                              <option value={service.id}>{service.service_name}(+${service.price})</option>
                              )}
                            </select>
                            :null}                           
                          </td>
                          <td>
                            {order.total}
                          </td>                           
                          </Fragment>
                        }                                              
                      </tr>
                    )
                    :
                      <tr>
                        <td>No Records found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>               
            <div className="row justify-content-center">
              <span data-toggle="modal" data-target="#exampleModalLong" id="bulk"></span>
              {this.state.loading == false ?
              <button type="button" className="btn btn-info" onClick={()=>{this.setState({loading:true},this.createOrders)}}>confirm</button>:
              <Fragment>
                <div>
                  <div className="spinner task">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                  </div>
                </div>
              </Fragment>
              } 
              <button type="button" className="btn btn-default" id="bulk_dis" data-dismiss="modal" onClick={() => this.updateList()} >Cancel</button>
            </div>              
            </div>
          </div>
        </div>
      </div>

    );
  }
}

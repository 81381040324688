import React, { Component } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import TopHeader from '../common/TopHeader';
import moment from 'moment';
import {fetch_get, ADMINDASHBOARD } from '../common';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
let last7days = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
let last30days = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
let d = new Date()
let dd = new Date()
let current_year = new Date().getFullYear()
let previous_Year = current_year-1
dd.setMonth(dd.getMonth() - 1);
let url=''
export default class Dashboard extends Component{
  constructor(props) {
    // localStorage.setItem('from_date',moment(d).format('YYYY-MM-DD'))
    // localStorage.setItem('to_date',moment(d).format('YYYY-MM-DD'))
    super(props);
    this.today = new Date(new Date().toDateString());
    this.weekStart = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
    this.weekEnd = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
        - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString());
    this.monthStart = new Date(new Date(new Date().setDate(1)).toDateString());
    // this.monthEnd = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).toDateString());
    this.monthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
    this.lastStart = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
    this.lastEnd = new Date(new Date(new Date().setDate(0)).toDateString());
    this.yearStart = new Date(new Date(new Date().getFullYear() - 1, 0, 1).toDateString());
    this.yearEnd = new Date(new Date(new Date().getFullYear() - 1, 11, 31).toDateString()); 
    this.this_yearStart = new Date(new Date(new Date().getFullYear() - 0, 0, 1).toDateString()); 
    this.this_yearEnd = new Date(new Date(new Date().getFullYear() - 0, 11, 31).toDateString());
    this.state = {
      dashboardtabdata:'',
      orderslist:[],
      invoiceslist:[],
      clientslist:[],
      vendorslist:[],
      lineGraphData: {},
      barGraphData: {},
      from_date:moment(this.monthStart).format('YYYY-MM-DD'),
      to_date:moment(this.monthEnd).format('YYYY-MM-DD'),
      span:'',
      period:'This month',
      display_period:'This month',
      revenue:0,
      expense:0,
      profit:0,
      outstanding:0,
    };

  }

  componentDidMount(){
    fetch_get(ADMINDASHBOARD)
    .then((response) => {
      if(localStorage.getItem('UserToken')) {
        this.getChartData()
        this.setState({
          dashboardtabdata:response,
          orderslist:response.orders,
          invoiceslist:response.invoices_list,
          clientslist:response.clients_list,
          vendorslist:response.vendors_list,
        })
        let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
        // let data = {
        //   labels: labels
        // }
        // let datasets = [ 
        //   { label: 'monthly invoices value list', data: [], backgroundColor: '#5d72e4', fill: false, borderColor: '#5d72e4', lineTension: 0.1, }, 
        //   { label: 'monthly invoices cost list', data: [], backgroundColor: '#22ff3e', fill: false, borderColor: '#22ff3e', lineTension: 0.1, }, 
        //   { label: 'monthly invoices profit list', data: [], backgroundColor: '#ff5722', fill: false, borderColor: '#ff5722', lineTension: 0.1, }   
        // ]
        // Object.values(response.monthly_invoices_value_list).map((value) =>
        //   datasets[0]['data'].push(value)
        // )
        // Object.values(response.monthly_invoices_cost_list).map((value) =>
        //   datasets[1]['data'].push(value)
        // )
        // Object.values(response.monthly_invoices_profit_list).map((value) =>
        //   datasets[2]['data'].push(value)
        // )
        // data['datasets'] = datasets
        let bardata = {
          labels: labels
        }
        let barDatasets = [ 
          { label: 'monthly order items count', data: [], backgroundColor: '#fb623f', fill: false, borderColor: '#fb623f' }, 
        ]
        if (response && response.monthly_order_items_count.length){
        Object.values(response.monthly_order_items_count).map((value) =>
          barDatasets[0]['data'].push(value)
        )}
        bardata['datasets'] = barDatasets
        this.setState({ barGraphData: bardata })
      }
      
    })

    
  }

  getChartData(){
    let url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&day=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&day=True`
    if(this.state.period == 'Last 7 days' || this.state.period == 'Last 30 days' || this.state.period == 'This Month' || this.state.period == 'Last Month' || this.state.period == 'APPLY' || this.state.span == 'day'){
       url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&day=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&day=True`
    }
    if(this.state.period == 'This Year' || this.state.period == 'Last Year' || this.state.period == 'month'){
       url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&month=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&month=True`
    }
    if(this.state.period == 'month'){
       url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&month=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&month=True`
    }
    if(this.state.period == 'week'){
       url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&week=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&week=True`
    }    
    // if(this.state.span == 365 || this.state.span == 366){
    //   if(moment(d).format('YYYY') == moment(this.state.from_date).format('YYYY')){
    //      url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&this_year=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&this_year=True`
    //   }
    //   else{
    //     if(previous_Year == moment(this.state.from_date).format('YYYY')){
    //       url = (localStorage.getItem('from_date') && localStorage.getItem('to_date')  ) ? `/api/admin/dashboard/?from_date=${localStorage.getItem('from_date')}&to_date=${localStorage.getItem('to_date')}&last_year=True`: `/api/admin/dashboard/?from_date=${this.state.from_date}&to_date=${this.state.to_date}&last_year=True`
    //     }
    //   }
    // }
    if(url){
      fetch_get(url)
      .then((response) => {
        if (response) {
          if(this.state.period == 'This Year' || this.state.period == 'Last Year'){
            var labels = Object.keys(response && response.monthly_invoices_list)
          }
          else if(this.state.span == 'month'){
            var labels = Object.keys(response && response.monthly_invoices_list)
          }
          else if(this.state.period == 'week'){
            var labels = Object.keys(response && response.weekly_invoices_list)
          }          
          else{
            var labels = Object.keys(response && response.daily_invoices_list && response.daily_invoices_list)
          }
          // let labels = Object.keys(response.daily_invoices_list)
          let data = {
            labels: labels
          }
          this.setState({revenue:response.total_revenue,expense:response.total_expenses,profit:response.total_profit,outstanding:response.total_outstanding_amount, limit_revenue:response.total_revenue_within_limit,limit_expenses:response.total_expenses_within_limit,limit_profit:response.total_profit_within_limit,limit_outstanding:response.total_outstanding_amount_within_limit})
          let datasets = [ 
            { label: 'invoices', data: [], backgroundColor: '#5d72e4', fill: false, borderColor: '#5d72e4', lineTension: 0.1, }, 
            { label: 'paid invoices', data: [], backgroundColor: '#22ff3e', fill: false, borderColor: '#22ff3e', lineTension: 0.1, }, 
            { label: 'paid expenses', data: [], backgroundColor: '#ff5722', fill: false, borderColor: '#ff5722', lineTension: 0.1, },
            { label: 'profit', data: [], backgroundColor: 'green', fill: false, borderColor: 'green', lineTension: 0.1, }   
          ]
          if(this.state.period == 'This Year' || this.state.period == 'Last Year'){
            Object.values(response.monthly_invoices_list).map((value) =>
              datasets[0]['data'].push(value)
            )
            Object.values(response.monthly_paid_invoices_list).map((value) =>
              datasets[1]['data'].push(value)
            )
            Object.values(response.monthly_paid_expenses_list).map((value) =>
              datasets[2]['data'].push(value)
            )
            Object.values(response.monthly_profilt_list).map((value) =>
              datasets[3]['data'].push(value)
            ) 
          }
          else if(this.state.span == 'month'){
            Object.values(response.monthly_invoices_list).map((value) =>
              datasets[0]['data'].push(value)
            )
            Object.values(response.monthly_paid_invoices_list).map((value) =>
              datasets[1]['data'].push(value)
            )
            Object.values(response.monthly_paid_expenses_list).map((value) =>
              datasets[2]['data'].push(value)
            )
            Object.values(response.monthly_profilt_list).map((value) =>
              datasets[3]['data'].push(value)
            ) 
          }
          else if(this.state.period == 'week'){
            Object.values(response.weekly_invoices_list).map((value) =>
              datasets[0]['data'].push(value)
            )
            Object.values(response.weekly_paid_invoices_list).map((value) =>
              datasets[1]['data'].push(value)
            )
            Object.values(response.weekly_paid_expenses_list).map((value) =>
              datasets[2]['data'].push(value)
            )
            Object.values(response.weekly_profilt_list).map((value) =>
              datasets[3]['data'].push(value)
            ) 
          }                    
          else{
            Object.values(response.daily_invoices_list).map((value) =>
              datasets[0]['data'].push(value)
            )
            Object.values(response.daily_paid_invoices_list).map((value) =>
              datasets[1]['data'].push(value)
            )
            Object.values(response.daily_paid_expenses_list).map((value) =>
              datasets[2]['data'].push(value)
            )
            Object.values(response.daily_profilt_list).map((value) =>
              datasets[3]['data'].push(value)
            )
          }        
          data['datasets'] = datasets
          // let bardata = {
          //   labels: labels
          // }
          // let barDatasets = [ 
          //   { label: 'monthly order items count', data: [], backgroundColor: '#fb623f', fill: false, borderColor: '#fb623f' }, 
          // ]
          // Object.values(response.monthly_order_items_count).map((value) =>
          //   barDatasets[0]['data'].push(value)
          // )
          // bardata['datasets'] = barDatasets
          this.setState({ lineGraphData: data })
      }
      })
    }  
  }

  findFromDate(value){
    if(this.state.tabActive=='Day'){
      // localStorage.setItem('from_date',moment(d).format('YYYY-MM-DD'))
      // localStorage.setItem('to_date',moment(d).format('YYYY-MM-DD'))
      if(localStorage.getItem('week')){
        localStorage.removeItem('week');
      }
      if(localStorage.getItem('month')){
        localStorage.removeItem('month');
      } 
      this.setState({span:''},this.getChartData)
    }
    if(this.state.tabActive=='Week'){
      // let from_date = this.weekStart
      // let to_date = this.weekEnd
      // let week = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      // let from__date = moment(from_date).format('YYYY-MM-DD')
      // let to__date = moment(to_date).format('YYYY-MM-DD')
      // localStorage.setItem('from_date',from__date)
      // localStorage.setItem('to_date',to__date)
      if(localStorage.getItem('day')){
        localStorage.removeItem('day');
      }
      if(localStorage.getItem('month')){
        localStorage.removeItem('month');
      }  
      this.setState({span:'week'},this.getChartData)
    }
    if(this.state.tabActive=='Month'){
      // let d = new Date()
      // d.setMonth(d.getMonth() - 1);
      // let month = moment(d).format('YYYY-MM-DD') 
      // let from_date = this.monthStart
      // let to_date = this.monthEnd
      // let from__date = moment(from_date).format('YYYY-MM-DD')
      // let to__date = moment(to_date).format('YYYY-MM-DD')      
      // localStorage.setItem('from_date',from__date)
      // localStorage.setItem('to_date',to__date)
      if(localStorage.getItem('day')){
        localStorage.removeItem('day');
      }
      if(localStorage.getItem('week')){
        localStorage.removeItem('week');
      }
      this.setState({span:'month'},this.getChartData)
    }
  }

  beforeGetData(){
    if(this.state.from_date && this.state.to_date){
      let from_ = moment(this.state.from_date).format('YYYY-MM-DD')
      let to   = moment(this.state.to_date).format('YYYY-MM-DD')
      localStorage.setItem('from_date',from_)
      localStorage.setItem('to_date',to)
      if(localStorage.getItem('day')){
        localStorage.removeItem('day')
      }
      if(localStorage.getItem('week')){
        localStorage.removeItem('week')
      } 
      if(localStorage.getItem('month')){
        localStorage.removeItem('month')
      }  
      this.setState({from_date:from_,to_date:to},this.getChartData)
    }
  }

  render() {
    const { dashboardtabdata, } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        <div className="header bg-gradient-primary pb-5 pt-6 pt-md-8">
          <div className="container-fluid">
            <div className="header-body">
              <div className="row">
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Websites</h5>
                          <span className="h2 font-weight-bold mb-0">{dashboardtabdata.websites_count}</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar"></i>
                          </div>
                        </div>
                      </div>
                      {dashboardtabdata.websites_percentage_change === '' || dashboardtabdata.websites_percentage_change === 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"><i className="fas fa-arrow-down"></i>0%</span> 
                        <span className="text-nowrap"> Since Last Month </span> 
                      </p> :
                      dashboardtabdata.websites_percentage_change > 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up"></i>{dashboardtabdata.websites_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> : 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2"><i className="fa fa-arrow-down"></i>{dashboardtabdata.websites_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> 
                      }
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Clients</h5>
                          <span className="h2 font-weight-bold mb-0">{dashboardtabdata.clients_count}</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie"></i>
                          </div>
                        </div>
                      </div>
                      {dashboardtabdata.clients_percentage_change === '' || dashboardtabdata.clients_percentage_change === 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"><i className="fas fa-arrow-down"></i>0%</span> 
                        <span className="text-nowrap"> Since Last Month </span> 
                      </p> :
                      dashboardtabdata.clients_percentage_change > 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up"></i>{dashboardtabdata.clients_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> : 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2"><i className="fa fa-arrow-down"></i>{dashboardtabdata.clients_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> 
                      }
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Vendors</h5>
                          <span className="h2 font-weight-bold mb-0">{dashboardtabdata.vendors_count}</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                            <i className="fas fa-users"></i>
                          </div>
                        </div>
                      </div>
                      {dashboardtabdata.vendors_percentage_change === '' || dashboardtabdata.vendors_percentage_change === 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"><i className="fas fa-arrow-down"></i>0%</span> 
                        <span className="text-nowrap"> Since Last Month </span> 
                      </p> :
                      dashboardtabdata.vendors_percentage_change > 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up"></i>{dashboardtabdata.vendors_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> : 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2"><i className="fa fa-arrow-down"></i>{dashboardtabdata.vendors_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> 
                      }
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Subscribers</h5>
                          <span className="h2 font-weight-bold mb-0">{dashboardtabdata.subscriptions_count}</span>
                        </div>
                        <div className="col-auto">
                          <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                            <i className="fas fa-percent"></i>
                          </div>
                        </div>
                      </div>
                      {dashboardtabdata.subscription_percentage_change === '' || dashboardtabdata.subscription_percentage_change === 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-warning mr-2"><i className="fas fa-arrow-down"></i>0%</span> 
                        <span className="text-nowrap"> Since Last Month </span> 
                      </p> :
                      dashboardtabdata.subscription_percentage_change > 0 ? 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-success mr-2"><i className="fa fa-arrow-up"></i>{dashboardtabdata.subscription_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> : 
                      <p className="mt-3 mb-0 text-muted text-sm">
                        <span className="text-danger mr-2"><i className="fa fa-arrow-down"></i>{dashboardtabdata.subscription_percentage_change}</span>
                        <span className="text-nowrap">Since Last Month</span> 
                      </p> 
                      }
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row calendar_tabs justify-content-end">
                <div className="col-xl-3 text-right">
                  <div class="btn-group" role="group" aria-label="Default button group">
                    <button type="button" className={localStorage.getItem('day') ? "btn btn-primary selected" : "btn btn-primary"} onClick={() => {localStorage.setItem('day',true);this.setState({ tabActive: 'Day',period:'Today'},this.findFromDate)}}>Day </button> <button type="button" className={localStorage.getItem('week') ? "btn btn-primary selected" : "btn btn-primary"} onClick={() => {localStorage.setItem('week',true);this.setState({ tabActive: 'Week',period:'week'},this.findFromDate)}}>Week</button><button type="button" className={localStorage.getItem('month') ? "btn btn-primary selected" : "btn btn-primary"} onClick={() => {localStorage.setItem('month',true);this.setState({ tabActive: 'Month',period:'month'},this.findFromDate)}}>Month</button>
                </div>
                </div>
                <div className="col-xl-2 text-right">
                <div className='control-pane'>
                    <div className='control-section'>
                        <div className='datepicker-control-section'>
                            <DateRangePickerComponent placeholder='Select a range' value={(localStorage.getItem('from_date') ? localStorage.getItem('from_date') : this.state.from_date) + '   -   ' + (localStorage.getItem('to_date') ? localStorage.getItem('to_date') : this.state.to_date)} change={(e) => {localStorage.setItem('from_date',e.startDate);localStorage.setItem('to_date',e.endDate);this.setState({ from_date: e.startDate, to_date: e.endDate,span:e.daySpan, period:e.event.target.innerText,display_period:e.event.target.innerText },this.beforeGetData)}}>
                                <PresetsDirective>
                                    <PresetDirective label="Last 7 days" start={last7days} end={d}></PresetDirective>
                                    <PresetDirective label="Last 30 days" start={last30days} end={d}></PresetDirective>
                                    <PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd}></PresetDirective>
                                    <PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd}></PresetDirective>
                                    <PresetDirective label="This Year" start={this.this_yearStart} end={this.this_yearEnd}></PresetDirective>
                                    <PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd}></PresetDirective>
                                </PresetsDirective>
                            </DateRangePickerComponent>
                        </div>
                    </div>
                </div> 
              </div>
              </div>
              
              <div className="row" style={{paddingTop:'1rem'}}>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Total Revenue</h5>
                          <span className="h2 font-weight-bold mb-0">{this.state.revenue}</span>
                          <div style={{display:'flex'}}>
                            <div className="h2 font-weight-bold mb-0" style={{color:'#0472b6'}}>${this.state.limit_revenue}</div> <span style={{paddingLeft:'0.5rem'}}>{this.state.display_period == 'APPLY' ? this.state.span +' Days': this.state.display_period}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Total Expenses</h5>
                          <span className="h2 font-weight-bold mb-0">${this.state.expense}</span>
                          <div style={{display:'flex'}}>
                            <div className="h2 font-weight-bold mb-0" style={{color:'#0472b6'}}>${this.state.limit_expenses}</div> <span style={{paddingLeft:'0.5rem'}}>{this.state.display_period == 'APPLY' ? this.state.span +' Days': this.state.display_period}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Total Profit</h5>
                          <span className="h2 font-weight-bold mb-0">${this.state.profit}</span>
                          <div style={{display:'flex'}}>
                            <div className="h2 font-weight-bold mb-0" style={{color:'#0472b6'}}>${this.state.limit_profit}</div> <span style={{paddingLeft:'0.5rem'}}>{this.state.display_period == 'APPLY' ? this.state.span +' Days': this.state.display_period}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <h5 className="card-title text-uppercase text-muted mb-0">Outstanding</h5>
                          <span className="h2 font-weight-bold mb-0">${this.state.outstanding}</span>
                          <div style={{display:'flex'}}>
                            <div className="h2 font-weight-bold mb-0" style={{color:'#0472b6'}}>${this.state.limit_outstanding}</div> <span style={{paddingLeft:'0.5rem'}}>{this.state.display_period == 'APPLY' ? this.state.span +' Days': this.state.display_period}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
        <div className="header bg-gradient-primary  pt-5">
        </div>
        <div className="container-fluid mt--7">
          <div  className="col mt-5 p-0">
            <div className="row col-12">
              <div className="col-12 card shadow" style={{ backgroundColor: '#18224d' }}>
                <p style={{ color: '#fff', fontSize: '10px', fontWeight: '500', marginBottom: '0rem' }}>Overview</p>
                <p style={{ color: '#fff', fontSize: '20px', fontWeight: '500', marginBottom: '0rem' }}></p>
                <Line 
                  data={this.state.lineGraphData} 
                  backgroundColor={'#000'} 
                  width={100} 
                  height={40} 
                  options={{ 
                    legend: {
                      labels: {
                        fontColor: "#fff",
                        fontSize: 12
                      }
                    },
                    scales:{
                      xAxes: [{
                        display: true,
                        gridLines: { display: false },
                        ticks: {
                          fontColor: "#fff",
                          fontSize: 12
                        },
                      }],
                      yAxes: [{
                        display: true,
                        gridLines: { display: false },
                        ticks: {
                          fontColor: "#fff",
                          fontSize: 12
                        },
                      }]
                    }
                  }}
                />
              </div>
              <div className="col-12 card shadow" >
                <p style={{ color: '#000', fontSize: '10px', fontWeight: '500', marginBottom: '0rem' }}>Performance</p>
                <p style={{ color: '#000', fontSize: '20px', fontWeight: '500', marginBottom: '0rem' }}>Total Orders</p>
                <Bar 
                  data={this.state.barGraphData} 
                  backgroundColor={'#000'} 
                  width={100} 
                  height={40} 
                  options={{ 
                    legend: {
                      labels: {
                        fontColor: "#000",
                        fontSize: 12
                      }
                    },
                    scales:{
                      xAxes: [{
                        display: true,
                        gridLines: { display: false },
                        ticks: {
                          fontColor: "#000",
                          fontSize: 12
                        },
                      }],
                      yAxes: [{
                        display: true,
                        gridLines: { display: true },
                        ticks: {
                          fontColor: "#000",
                          fontSize: 12
                        },
                      }]
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-6 mb-5 mb-xl-0">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">New Orders</h3>
                    </div>
                    <div className="col text-right">
                      <button className="btn btn-primary" onClick={() => this.props.history.push('/admin/orders') }>See all </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ORDER ID</th> 
                        <th scope="col">CREATED BY</th>
                        <th scope="col">WEBSITES COUNT</th>
                        <th scope="col">PAYMENT DONE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orderslist.length > 0 ? this.state.orderslist.map((order, index) => 
                      <tr style={{ cursor: 'pointer' }} key={order.id} onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`)}>
                        <td scope="row">
                          {order.order_id}
                        </td>
                        <td>
                          {order.created_by.username}
                        </td>
                        <td>
                          {order.website_details.length}
                        </td>
                        <td>
                          {order.is_payment_done ?
                            <a href><i className="fas fa-check" style={{ color: 'green' }}></i></a>
                          :
                            <a href><i className="fas fa-times" style={{ color: 'red' }}></i></a>
                          }
                        </td>
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Top Clients</h3>
                    </div>
                    <div className="col text-right">
                    <button className="btn btn-primary" onClick={() => this.props.history.push('/admin/clients') }>See all </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">USERNAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">TOTAL SPENT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.clientslist.length > 0 ? this.state.clientslist.map((clients, index) => 
                      <tr style={{ cursor: 'pointer' }} key={index} onClick={() => this.props.history.push(`/admin/clients/${clients.id}/profile`)}>
                        <td scope="row">
                          {clients.created_by__username}
                        </td>
                        <td>
                          {clients.created_by__email}
                        </td>
                        <td>
                          {clients.sum}
                        </td>                    
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-6 mb-5 mb-xl-0">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Top Paid Vendors</h3>
                    </div>
                    <div className="col text-right">
                      <button className="btn btn-primary" onClick={() => this.props.history.push('/admin/vendors') }>See all </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">USERNAME</th>
                        <th scope="col">EMAIL</th>
                        <th scope="col">FIRST NAME</th>
                        <th scope="col">TOTAL PAID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.vendorslist.length > 0 ? this.state.vendorslist.map((vendors, index) => 
                      <tr style={{ cursor: 'pointer' }} key={vendors.id} onClick={() => this.props.history.push(`/admin/vendors/${vendors.id}/profile`)}>
                        <td scope="row">
                          {vendors.user_website__user__username}
                        </td>
                        <td>
                          {vendors.user_website__user__email}
                        </td>
                        <td>
                          {vendors.user_website__user__first_name}
                        </td>
                        <td>
                          {vendors.sum}
                        </td>                    
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Unpaid Invoices</h3>
                    </div>
                    <div className="col text-right">
                    <button className="btn btn-primary" onClick={() => this.props.history.push('/admin/unpaidinvoices') }>See all </button>
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ORDER ID</th>
                        <th scope="col">CREATED ON</th>
                        <th scope="col">INVOICE AMOUNT</th>
                        <th scope="col">PAYMENT STATUS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.invoiceslist.length > 0 ? this.state.invoiceslist.map((invoice, index) =>
                      <tr key={invoice.id}>
                        <td scope="row">
                          {invoice.order_id}
                        </td> 
                        <td>
                          {invoice.created_on}
                        </td>
                        <td>
                          {invoice.total_amount}
                        </td>
                        <td>
                          { invoice.is_payment_done ? 'Payment Done' : 'Payment yet to receive'}
                        </td>
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
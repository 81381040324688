import React, { Component } from 'react';
import moment from 'moment';
import { DOMAIN_NAME, WEBSITE, fetch_get, fetch_post } from '../common';
import TopHeader from '../common/TopHeader';

export default class ManageWebsiteDetails extends Component{
    constructor(props) {
    super(props);
    this.state = {
      errors: '',
      price:'',
      websiteDetails: {},
      show:'',
    }
  }

  componentDidMount() {
    this.websitesDetails()
  }

  websitesDetails() {
    fetch_get(`${WEBSITE}${this.props.match.params.id}/details/ `)
    .then((response) => {
      this.setState({ websiteDetails: response.website_serializer, blocked:response.website_serializer.is_blocked  })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websitesDetailsPost(price,id){
    let formData = new FormData()
    id && formData.append('user_website_id', id)
    price && formData.append('price', price)
    formData.append('is_blocked',this.state.blocked)
    fetch_post(`${WEBSITE}${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(response.error){
        this.setState({errors:response.message})        
      }
      else{
        this.setState({errors:''},this.websitesDetails)
      }

    })    
  }

  render(){
    const { websiteDetails } = this.state;
    const websiteCategories = websiteDetails.hasOwnProperty('categories') ?  websiteDetails.categories.map((category)=> category.name): []
    const nicheCategories = websiteDetails.hasOwnProperty('niche') ?  websiteDetails.niche.map((category)=> category.name): []
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow mb-6">
            <div className="row">
              <div className="card-header col-md-6 bg-white border-0 mb-3">
                  <h3 className="mb-0">Website Details</h3>
                </div>
                <div className="col-md-6 text-right">
              <label htmlFor="staticEmail">Blocked</label>
              <input 
                type="checkbox" 
                className="col-sm-1 col-form-label"
                id="blocked"
                checked={this.state.blocked ? true : false}
                onChange={(e)=>this.setState({blocked:document.getElementById('blocked').checked ? 'Y' : 'N'},this.websitesDetailsPost)}
              />                
              <button className="edit-website btn-primary mt-3 mr-2" onClick={() => this.props.history.push({ pathname: '/admin/managewebsites', state:{detail:this.props.location.state.detail}}) }>
                Back
              </button>  
              <button className="edit-website btn-info" onClick={() => this.props.history.push(`/admin/managewebsites/${this.props.match.params.id}/edit`) }>
                Edit
              </button>
              </div>
              </div>
              <div className="card-body detail-website">
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                  <tr>
                  <td>Website Id</td>
                  <td>{websiteDetails.id}</td>
                  </tr>
                  <tr>
                  <td>Domain </td>
                  <td>{websiteDetails.domain}</td>
                  </tr>                
                  <tr>
                  <td>categories</td>
                  <td>{websiteCategories.join(', ')}</td>
                  </tr>
                  <tr>
                  <td>Niche Categories</td>
                  <td>{nicheCategories.join(', ')}</td>
                  </tr>                  
                  <tr>
                  {/* <td>Alexa SiteData </td>
                  <td>{websiteDetails.alexa_sitedata}</td> */}
                  </tr>  
                  <tr>
                  <td>Domain Age</td>
                  <td>{websiteDetails.domain_age}</td>
                  </tr>                                                       
                  <tr>
                  <td>Moz DA</td>
                  <td>{websiteDetails.moz_da}</td>
                  </tr>
                  <tr>
                  <td>Moz PA</td>
                  <td>{websiteDetails.moz_pa}</td>
                  </tr> 
                  <tr>
                  <td>Moz Linking Domains</td>
                  <td>{websiteDetails.moz_linking_domains}</td>
                  </tr> 
                  <tr>
                  <td>Moz Inbound Links</td>
                  <td>{websiteDetails.moz_inbound_links}</td>
                  </tr>
                  <tr>
                  <td>Moz Spam Score </td>
                  <td>{websiteDetails.moz_spam_score}</td>
                  </tr> 
                  <tr>
                  {/* <td>Alexa Rank</td>
                  <td>{websiteDetails.alexa_rank}</td> */}
                  </tr> 
                  <tr>
                  {/* <td>Alexa Country</td>
                  <td>{websiteDetails.alexa_country}</td> */}
                  </tr> 
                  <tr>
                  {/* <td>Alexa Country Rank</td>
                  <td>{websiteDetails.alexa_country_rank}</td> */}
                  </tr>    
                  <tr> 
                  {/* <td>Alexa Speed</td>
                  <td>{websiteDetails.alexa_speed/1000}</td> */}
                  </tr> 
                  <tr>
                  <td>ahrefs_domain_rating_dr</td>
                  <td>{websiteDetails.ahrefs_domain_rating_dr}</td>
                  </tr>                     
                  <tr>
                  <td>Ahrefs URL rating URl</td>
                  <td>{websiteDetails.ahrefs_url_rating_ur}</td>
                  </tr>
                  <tr>
                  <td>Ahrefs Rank of Domain</td>
                  <td>{websiteDetails.ahrefs_rank_of_domain}</td>
                  </tr>
                  <tr>
                  <td>Ahrefs Referring Domains</td>
                  <td>{websiteDetails.ahrefs_referring_domains}</td>
                  </tr> 
                  <tr>
                  <td>Ahrefs Backlinks</td>
                  <td>{websiteDetails.ahrefs_backlinks}</td>
                  </tr> 
                  <tr>
                  <td>Accept Adult</td>
                  <td>{websiteDetails.accept_adult ? 'True' : 'False'}</td>
                  </tr>                                 
                  <tr>
                  <td>Accept Link insertion </td>
                  <td>{websiteDetails.accept_link_insertion_in_existing_post ? 'True' : 'False'}</td>
                  </tr> 
                  <tr>
                  <td>Accept Weight Loss </td>
                  <td>{websiteDetails.accept_weight_loss ? 'True' : 'False'}</td>
                  </tr> 
                  <tr>
                  <td>Accept Gambling</td>
                  <td>{websiteDetails.accept_gambling ? 'True' : 'False'}</td>
                  </tr> 
                  <tr>
                  <td>Accept THC</td>
                  <td>{websiteDetails.accept_thc ? 'True' : 'False'}</td>
                  </tr> 
                  <tr>
                  {/* <td>Alexa Contains Adult Content</td>
                  <td>{websiteDetails.alexa_contains_adult_content ? 'True' : 'False'}</td> */}
                  </tr>
                  <tr>
                  {/* <td>traffic </td>
                  <td>{websiteDetails.traffic}</td> */}
                  </tr> 
                  <tr>
                  <td>Ahref Organic monthly traffic </td>
                  <td><div>Total:{websiteDetails.organic_traffic}</div> {websiteDetails.historical_data ? websiteDetails.historical_data.map((country)=> <div>{country.country == 'United States'? 'US' : country.country == 'Great Britain'? 'GB' : country.country == 'Australia' ? 'AU' : country.country == 'Canada' ? 'CA' : country.country == 'Germany' ? 'DE' : country.country == 'India'? 'IN' : country.country} : {country.traffic_data__traffic}</div>): null}</td>
                  </tr>                    
                  <tr>
                  <td>language</td>
                  <td>{websiteDetails.language}</td>
                  </tr>
                  <tr>
                  <td>protocols</td>
                  <td>{websiteDetails.protocols}</td>
                  </tr> 
                  <tr>
                  <td>google_indexed</td>
                  <td>{websiteDetails.google_indexed }</td>
                  </tr>  
                  <tr>
                  <td>ip_address_of_website_server</td>
                  <td>{websiteDetails.ip_address_of_website_server}</td>
                  </tr> 
                  <tr>
                  <td>date_added</td>
                  <td>{moment(websiteDetails.date_added).format('DD-MMM-YY')}</td>
                  </tr> 
                  <tr>
                  <td>date_updated</td>
                  <td>{websiteDetails.date_updated === null ? '' : moment(websiteDetails.date_updated).format('DD-MMM-YY')}</td>
                  </tr> 
                  <tr>
                  <td>status</td>
                  <td>{websiteDetails.status}</td>
                  </tr>                                                                                                                                                                                                                                                                                                                                           
                  <tr>
                  <td>Active</td>
                  <td>{websiteDetails.active ? 'True' : 'False'}  </td>
                  </tr>              
                  <tr>
                  <td>organic Keywords</td>
                  <td>{websiteDetails.organic_keywords}</td>
                   </tr>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
                  </table>
                </div>
              </div>
              <p className="m-3">
                <button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                Assigned Vendors
                </button>
              </p>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">S.No</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">User Name</th>
                          <th scope="col">Adtype</th>
                          <th scope="col">Price</th>
                        </tr>
                      </thead>
                        <tbody>
                        {(websiteDetails.assigned_vendor_details && websiteDetails.assigned_vendor_details.length > 0) && websiteDetails.assigned_vendor_details.map((vendor, index) => 
                          <tr>
                            <td> {index+1} </td>
                            <td> {vendor.user__first_name} </td>
                            <td> {vendor.user__email} </td>
                            <td> {vendor.user__username} </td>
                            <td> {vendor.ad_type} </td>
                          <td onClick={() => this.setState({ show: vendor.id,price:vendor.price })}>
                            <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Domain"
                            value={this.state.show == vendor.id ? this.state.price : vendor.price}
                            onChange={(e) => this.state.show == vendor.id ? this.setState({price:e.target.value},this.websitesDetailsPost(e.target.value,vendor.id)): null}
                            />
                            {this.state.show == vendor.id ?
                            <div className="text-danger">
                              {this.state.errors}
                            </div>  : null}        
                          </td>                            
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div> 
    )
  }
}

import React, { Component, Fragment } from 'react';
import { RESET_PASSWORD, DOMAIN_NAME, Alert } from '../common';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password1: '',
      password2: '',
      errors: {}
    };
    this.handleKeyPress = this.handleKeyPress.bind(this)
  } 

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  } 

  handleSubmit() {
    const { password1, password2 } = this.state;
      let data = new FormData()
      data.append('new_password1', password1)
      data.append('new_password2', password2)
      data.append('uidb64', this.props.match.params.uid)
      data.append('token', this.props.match.params.token)
      fetch(DOMAIN_NAME+RESET_PASSWORD, {
        method: 'post',
        body: data
      })
      .then(response => response.json())
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          // alert("Password Updated Successfully. Please login")
          // this.props.history.push('/signin')
          let a = document.getElementById('success')
          a.click()
        }
      })
      .catch(() => {
        console.log('Something went wrong, Please try again');
      });
  }

  render(){
    const { errors } = this.state;
    return( 
      <Fragment>
        <div className="login_rc">
          <Alert msg={"Password Updated Successfully. Please login"} resetpassword="true" history={this.props.history}/>
          <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
              <div className="container px-4">
                <a className="navbar-brand" href="../index.html">
                  <img src="/img/brand/logo.png" alt=""  style={{height:"100px"}} />
                </a>
                <div className="collapse navbar-collapse" id="navbar-collapse-main">
                  <div className="navbar-collapse-header d-md-none">
                    <div className="row">
                      <div className="col-6 collapse-brand">
                        <a href="../index.html">
                          <img src="/img/brand/blue.png" alt="" />
                        </a>
                      </div>
                      <div className="col-6 collapse-close">
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="header bg-gradient-primary py-7 py-lg-8">
              <div className="container">
                <div className="header-body text-center mb-7">
                  <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6">
                      <small>Reset Password</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>
            <div className="container mt--8 pb-5">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div className="card border-0">
                    <div className="card-header bg-transparent pb-5">
                    </div>
                    <div className="card-body px-lg-5 py-lg-5">
                      <form>
                        <div className="form-group mb-3">
                          <div className="form-group">
                            <div className="input-group input-group-alternative">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                              </div>
                              <input 
                                onKeyPress={this.handleKeyPress}
                                className="form-control" 
                                type="text" 
                                placeholder="Enter new password" 
                                value={this.state.password1} 
                                onChange={(event)=>{this.setState({password1:event.target.value})}}
                              />
                            </div>
                            <div className="text-danger">
                              {errors.new_password1 && errors.new_password1}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group input-group-alternative">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                              </div>
                              <input
                                onKeyPress={this.handleKeyPress}
                                className="form-control" 
                                placeholder="Enter Confirm Password" 
                                type="text" 
                                value={this.state.password2} 
                                onChange={(event)=>{this.setState({password2:event.target.value})}}
                              />
                            </div>
                            <div className="text-danger">
                              <div className="text-danger">
                                {errors.new_password2 && errors.new_password2}
                                {errors.non_field_errors && errors.non_field_errors}
                              </div>
                            </div> 
                          </div>                       
                        </div>
                        <span data-toggle="modal" data-target="#alert" id="success"/>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-primary my-4" 
                            onClick={() => this.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}      
import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import Select from 'react-select';
import { ORDER, fetch_get, fetch_post, Pagination } from '../common';

export default class SuggestedWebsites extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
      currentPage: 1,
      numOfPages: 1,
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      createdontoggle:false,
      websitecounttoggle:false,
      orderstatustoggle:false,
      projects:[],
      projects_list:[],
    }
    this.websitesList = this.websitesList.bind(this);
  }

  componentDidMount() {
    this.websitesList()
  }

  websitesList(page=this.state.currentPage) {
    fetch_get(`/api/project/${this.props.match.params.id}/suggested_websites/ `)
    .then((response) => {
      this.setState({ opportunities:response.suggested_post_opportunities, numOfPages: response.active_num_pages   })
    })
    .catch((error) => {
      this.setState({ error });
    });        
  }

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`/api/project/${this.props.match.params.id}/suggested_websites/ `,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          opportunities:response.suggested_post_opportunities, 
          numOfPages: response.active_num_pages
        })                   
      }
    })
  } 

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.0rders, 
    //       numOfPages: response.num_pages
    //     })                   
    //   }
    // })       
    this.setState({currentPage},this.websitesList)
  }


  render(){
    return(
      <div className="main-content client_web">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col">
              <div className="">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Suggested Websites</h3>
                  <div className="col-md-6 text-right">
                    <button className="edit-website btn-primary mt-1 mr-2" onClick={() => this.props.history.goBack() }>
                    Back
                    </button>  
                  </div>                  
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">domain</th>
                        <th scope="col">adtype</th>
                        <th scope="col">linktype</th>
                        <th scope="col">price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.opportunities ? this.state.opportunities.map((websites, index) => 
                      <tr style={{ cursor: 'pointer' }} key={index}>
                        <td scope="row">
                          {websites.id}
                        </td>
                        <td>
                          {websites.website.domain}
                        </td>
                        <td>
                          {websites.ad_type}
                        </td>
                        <td scope="row">
                          {websites.links_type}
                        </td>
                        <td>
                          {websites.sell_price}
                        </td>                                            
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {Number(this.state.numOfPages) > 1 &&
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      </ul>
                    </nav>
                  </div>
                }
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
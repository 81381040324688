import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

let orderStatus = ""
export default class Invoices extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      idtoggle:false,
      svalue:'',
      order_idtoggle:false,
      created_ontoggle:false,
      amounttoggle:false,
      paymenttoggle:false,
    }
    this.invoices = this.invoices.bind(this);
    this.redirectPage = React.createRef();
  }

  componentDidMount() {
    this.invoices()
  }


  updateStatus(userOrderId, status) {
    let formData = new FormData()
    formData.append('order_id', userOrderId)
    formData.append('payment_status', status)
    formData.append('page', this.state.currentPage)
    fetch_post(`${INVOICES_LIST}`, formData)
    .then((response) => {
      if(!response.error) {
        orderStatus = response.order_status
        this.setState({ 
          invoices: response.orders_list_serializer,
          numOfPages: response.num_pages,
           
        })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }


  invoices(page=this.state.currentPage) {
    fetch_get(`${INVOICES_LIST}?page=${page}&num_pages=${this.state.numberofrows}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ invoices: response.orders_list_serializer, numOfPages: response.num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  invoicesFilter(page=this.state.currentPage) {
    let formData = new FormData() 
    formData.append('page',page)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'order_id'){
      if(this.state.order_idtoggle){
        formData.append('column_name_order','order_id')
      }
      else{
        formData.append('column_name_order','-order_id')
      }
    }
    if(this.state.svalue == 'created_on'){
      if(this.state.created_ontoggle){
        formData.append('column_name_order','created_on')
      }
      else{
        formData.append('column_name_order','-created_on')
      }
    }
    if(this.state.svalue == 'amount'){
      if(this.state.amounttoggle){
        formData.append('column_name_order','amount')
      }
      else{
        formData.append('column_name_order','-amount')
      }
    }  
    if(this.state.svalue == 'payment'){
      if(this.state.paymenttoggle){
        formData.append('column_name_order','payment_status')
      }
      else{
        formData.append('column_name_order','-payment_status')
      }
    }          
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          invoices: response.orders_list_serializer,
          numOfPages: response.num_pages,
        })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }  

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          invoices: response.orders_list_serializer, 
          numOfPages: response.num_pages
        })                   
      }
    })
  }

  getCurrentPage(currentPage) {
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',currentPage)
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          invoices: response.orders_list_serializer, 
          numOfPages: response.num_pages,
          currentPage:currentPage,
          // createdUsersList:created_users,
        },window.scroll(0,0))
      }
    })     
    // this.setState({currentPage})
  }

  payment(id) {
    fetch_get(`${USER}${id}/pay/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ redirectUrl: response.redirect_url })
      } else {
      }
      this.redirectPage.current.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  rendorStatus(status, userOrderId) {
    orderStatus = status;
    return(
      <td className="p-1">
        <div className="col-md-12 p-0">
          <div className="form-group mb-0">
            <select 
              value={orderStatus}
              onChange={(e) => this.updateStatus(userOrderId, e.target.value)}
              className="form-control"
            >
              <option value="">Select</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Paid">Paid</option>
              <option value="Canceled">Canceled</option>
            </select>
          </div>
        </div>
      </td>
    )
  }

  render(){
    return(
      <div className="main-content client_web">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row">
            <div className="col">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Invoices</h3>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.invoicesFilter)}>Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({order_idtoggle:!this.state.order_idtoggle,svalue:'order_id'},this.invoicesFilter)} >Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({created_ontoggle:!this.state.created_ontoggle,svalue:'created_on'},this.invoicesFilter)}>Created on <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({amounttoggle:!this.state.amounttoggle,svalue:'amount'},this.invoicesFilter)}>Invoice Amount <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({paymenttoggle:!this.state.paymenttoggle,svalue:'payment'},this.invoicesFilter)}>Payment Status <i className="fas fa-sort"></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.invoices.length > 0 ? this.state.invoices.map((invoice, index) =>
                        <tr key={invoice.id}>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })} ><a href>{invoice.id}</a></td>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })} ><a href>{invoice.order_id}</a></td>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.created_on}</td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td style={{cursor:'pointer', color:"blue"}} onClick={() => this.props.history.push(`/admin/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.total_amount}</td>
                          {this.rendorStatus(invoice.payment_status, invoice.order_id)}
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

import React, { Component, Fragment } from 'react';
import download from "downloadjs";
import { ORDER, fetch_get, DOMAIN_NAME } from '../common';

  export default class InvoiceDetails extends Component{
    constructor(props) {
      super(props);
      this.state = {
        orderDetails: [],
        orderId: '',
        total:'',
      }
    }

  componentDidMount() {
    this.orderDetails()
  }

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      if(!response.error) {
        this.setState({ orderDetails: response.order_details.user_order_details, total:response.order_details.amount, orderId: response.order_details.order_id })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  invoiceDownload(){
    fetch_get(`/api/invoice/${this.state.orderId}/download`, true)
    .then((response) => response.blob())
    .then((response) => {
      download(response,'invoice.pdf')
    })
  }


    render(){
      return(
        <div className="main-content">          
          <div id="page_1" className="invoice_main" >
          <div className="row card-header">
            <div className="invoice_main_1"><img src="/img/brand/logo.png " width="75" height="50" /></div>
            <div className="col-md-8 text-right">
              <button className="edit-website btn-primary mt-1 mr-2" onClick={() => this.props.history.goBack() }>
                Back
              </button>
            </div>
            <span style={{marginTop:"1px", cursor:"pointer"}}>
              <a className="icon download" >
                <i className="ni ni-cloud-download-95" onClick={()=>this.invoiceDownload()}></i>
              </a>  
            </span>            
          </div>
          <table className="invoice_table">
          <tr>
            <td className="invoice_table_td1"  style={{height: "54px"}}><p className="invoice_table_p1" style={{font: "bold", font: "23px", font:'Helvetica', lineHeight: "27px"}}>Rankcastle</p></td>
            <td className="invoice_table_td2" style={{height: "54px"}}><p className="invoice_table-p2" style={{font:"bold", font :"33px", font: 'Helvetica',lineHeight: "38px"}}>INVOICE</p></td>
          </tr>
          <tr>
            <td className="invoice_table_td1" style={{height: "23px"}}><p className="invoice_table_p1" style={{font: "14px", font:'Helvetica', lineHeight: "16px"}}>123 Your Street</p></td>
            <td className="invoice_table_td2"  style={{height: "23px"}}><p className="invoice_table-p2" style={{font: "14px", font:'Helvetica', lineHeight: "16px"}}>{this.props.location.state.created_on}</p></td>
          </tr>
          <tr>
            <td className="invoice_table_td1"  style={{height: "21px"}}><p className="invoice_table_p1" style={{font: "14px", font:'Helvetica', lineHeight: "16px"}}>Your Town</p></td>
            <td className="invoice_table_td2"  style={{height: "21px"}}><p className="invoice_table-p2" style={{font: "14px", font: 'Helvetica',lineHeight: "16px"}}>Invoice #{this.state.orderId}</p></td>
          </tr>
          <tr>
            <td className="invoice_table_td1"  style={{height: "36px"}}><p className="invoice_table_p1" style={{font: "14px", font:'Helvetica', lineHeight: "16px"}}>(123) 456 789</p></td>
            <td className="invoice_table_td2"  style={{height: "36px"}}><p className="invoice_table-p2" style={{font: "bold", font: "17px", font: 'Helvetica',lineHeight: "19px"}}>{this.props.location.state.username}</p></td>
          </tr>
          <tr>
            <td className="invoice_table_td1"  style={{height: "23px"}}><p className="invoice_table_p1" style={{font: "14px", font:'Helvetica',lineHeight: "16px"}}>email@yourcompany.com</p></td>
          </tr>
          <tr>
            <td className="invoice_table_border" ><p className="invoice_table_border_p1 ">&nbsp;</p></td>
            <td className="invoice_table_border" style={{width: "182px"}}><p className="invoice_table_border_p1"></p></td>
          </tr>
          </table>
          <table   className="invoice_table2">
                  <tr>
                    <td className="invoice_table2_td1" >
                      <p className="invoice_table2_p1">Dear {this.props.location.state.username} </p>
                    </td>
                  </tr>
          </table>
          <table  className="invoice_table3" >
          <thead className="thead-light">
          <tr>
            <td className="invoice_table3_td1"><p>S.no</p></td>
            <td className="invoice_table3_td3"><p className="invoice_table3_p3">Website</p></td>
            <td className="invoice_table3_td3"><p className="invoice_table3_p3">Adtype</p></td>
            <td className="invoice_table3_td4"><p className="invoice_table3_p4">Total (€)</p></td>
          </tr>
          </thead>
            <tbody>
              {this.state.orderDetails.length > 0 ? this.state.orderDetails.map((invoice, index) =>
                <tr key={index} >
                  <td className="invoice_table3_td5">{index+1}</td>
                  <td className="invoice_table3_td7">{invoice.user_website_details.website}</td>
                  <td className="invoice_table3_td8">{invoice.user_website_details.ad_type}</td>
                  <td className="invoice_table3_td8">{invoice.user_website_details.price}</td>
                </tr>
              ): null}
            </tbody>
          <tr>
            <td className="invoice_table3_td9" colspan="3" ><p className="invoice_table3_p9 ">Total</p></td>
            <td className="invoice_table3_td11"><p className="invoice_table3_p11">{this.state.total}</p></td>
          </tr>
          </table>
          </div>
        </div>
      )
    }
  }
import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import Select from 'react-select';
import { ORDER, fetch_get, fetch_post, Pagination } from '../common';

export default class ProjectOrders extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
      currentPage: 1,
      numOfPages: 1,
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      createdontoggle:false,
      websitecounttoggle:false,
      orderstatustoggle:false,
      projects:[],
      projects_list:[],
    }
    this.ordersList = this.ordersList.bind(this);
  }

  componentDidMount() {
    this.ordersList()
  }

  ordersList(page=this.state.currentPage) {
    fetch_get(`/api/project/${this.props.match.params.id}/orders/ `)
    .then((response) => {
      this.setState({  orders:response.orders,numOfPages:response.active_num_pages  })
    })
    .catch((error) => {
      this.setState({ error });
    });        
  }

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`/api/project/${this.props.match.params.id}/orders/`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          orders:response.orders,
          numOfPages:response.active_num_pages
        })                   
      }
    })
  } 

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.0rders, 
    //       numOfPages: response.num_pages
    //     })                   
    //   }
    // })       
    this.setState({currentPage},this.ordersList)
  }


  render(){
    return(
      <div className="main-content client_web">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col">
              <div className="">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Orders</h3>
                  <div className="col-md-6 text-right">
                    <button className="edit-website btn-primary mt-1 mr-2" onClick={() => this.props.history.goBack() }>
                    Back
                    </button>  
                  </div>                  
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order_id'},this.orderFilter)}>Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({createdontoggle:!this.state.createdontoggle,svalue:'createdon'},this.orderFilter)}>Created On <i className="fas fa-sort"></i></th>
                        <th scope="col">Websites</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.state.orders && this.state.orders.length > 0) ? this.state.orders.map((order, index) => 
                        <tr style={{ cursor: 'pointer' }} key={order.id}>
                          <td onClick={() => this.props.history.push(`/orders/${order.order_id}/details`)}>
                            {order.order_id}
                          </td>
                          <td onClick={() => this.props.history.push(`/orders/${order.order_id}/details`)}>
                            {order.created_on}
                          </td>
                          <td className="overlap" onClick={() => this.props.history.push(`/orders/${order.order_id}/details`)}>
                            {order.website_details.splice(0,5).map((d)=><span> <span style={{backgroundColor: "rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>)}
                          </td>                           
                        </tr>
                      )
                      :
                        <tr>
                          <td>No Records found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {Number(this.state.numOfPages) > 1 &&
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      </ul>
                    </nav>
                  </div>
                }
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
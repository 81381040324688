import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/')[2],
    };
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  isActiveTab(value) {
    return (value === this.state.selectedMenu) ? "nav-link active" : "nav-link";
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const menu = nextProps.location.pathname.split('/')[2];
    if(menu !== prevState.selectedMenu) {
      return { selectedMenu: menu };
    }
    return null;
  }

  render() {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to={"/creator/dashboard"} className={this.isActiveTab('dashboard')} onClick={() => {this.setState({ tabActive: 'dashboard'});this.props.show(false)}}>
            <i className="fas fa-tachometer-alt text-primary"></i>Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/creator/services"} className={this.isActiveTab('websites')} onClick={() => {this.setState({ tabActive: 'websites'});this.props.show(false)}}>
            <i className="ni ni-support-16 text-green"></i>services
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/creator/orders"} className={this.isActiveTab('orders')} onClick={() => {this.setState({ tabActive: 'Dashboard'});this.props.show(false)}}>
            <i className="fas fa-shopping-basket text-red"></i>Orders
          </Link>          
        </li>
        <li className="nav-item">
          <Link to={"/creator/payments"} className={this.isActiveTab('payments')} onClick={() => {this.setState({ tabActive: 'payments'});this.props.show(false)}}>
            <i className="ni ni-money-coins text-teal"></i>Payments
          </Link>          
        </li>        
      </ul>
    );
  }
}

export default withRouter(LeftNav);

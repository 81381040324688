import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { fetch_get } from '../common';

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/')[1],
    };
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  // componentDidMount() {
  //   if(!localStorage.getItem('UserToken')) {
  //     this.props.history.push('/signin');
  //   }
  //   fetch_get(`/api/user/subscription/list/`) 
  //   .then((response) => {
  //     if(!response.error) {
  //     }
  //   })
  //   .catch((error) => {
  //     this.setState({ error });
  //   });
  // }

  static getDerivedStateFromProps(nextProps, prevState){
    const menu = nextProps.location.pathname.split('/')[1];
    if(menu !== prevState.selectedMenu) {
      return { selectedMenu: menu };
    }
    return null;
  }

  isActiveTab(value) {
    return (value === this.state.selectedMenu) ? "nav-link active" : "nav-link";
  }

  render() {
    return (
      <ul className="navbar-nav">       
      </ul>
    );
  }
}

export default withRouter(LeftNav);

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get, DOMAIN_NAME } from '../common';

export default class OrderDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      orderId: '',
      clientinfo:'',
      ordervalue:'',
      discount:'',
      totalAmount:'', 
      is_percent:'',
      contentUpload: false,
      show:true,    
    }
  }

  componentDidMount() {
    localStorage.removeItem('orderid')
    this.orderDetails()
  }

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          orderDetails: response.order_details,
          orderId: response.order_details.order_id,
          clientinfo:response.order_details.created_by.username,
          ordervalue:response.order_details.amount,
          discount:response.order_details.discount_value,
          totalAmount:response.order_details.total_amount,
          is_percent:response.order_details.discount_is_percentage,
          contentUpload: response.is_update_article
        })
      }
      else{
        if(response.message == "You Don't have access to view is order"){
          this.setState({show:false})
        }
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    if(this.state.show){
      return(
        <div className="main-content">
          <TopHeader showSearch={false} />
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className=" row card-header marl">
                      <h3 className="mb-0 col-md-8">Order ID : <span className="order_id">{this.state.orderDetails.order_id}</span></h3>
                      {this.props.location.state ? 
                      <div className="col-md-4 text-right">
                        <button className="edit-website btn-primary mt-1 mr-2" onClick={() => this.props.history.goBack() }>
                        Back
                        </button>  
                      </div> : null}
                    </div>
                    <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-4">
                        <label className="client_info">Name:</label>
                        <span>{this.state.clientinfo}</span>
                      </span>
                       <span className="col-md-4">
                        <label className="order_value">Ordervalue:</label>
                        <span>{this.state.ordervalue}</span>
                      </span>
                      <span className="col-md-4">
                        <label className="invoice_link">InvoiceLink:</label>
                        {this.state.orderDetails.order_status === 'Completed'?
                          <button  type="button" className="update_api btn btn-primary" onClick={()=>this.props.history.push(`/invoices/${this.state.orderId}/details`, { created_on: moment(this.state.orderDetails.created_on).format('DD-MMM-YY'), username:this.state.orderDetails.created_by.username })}>invoice link</button>:
                          "----"
                        }
                      </span>                                   
                    </div>
                    <div className="card-header row no_border_bg">
                      {this.state.discount !== 0 ?
                        <span className="col-md-4">
                          <label className="client_info">Discount:</label>
                          <span>{this.state.is_percent ? this.state.discount + "%" : this.state.discount + "$"}</span>
                        </span>
                      :null}
                      {this.state.discount !== 0 ? 
                       <span className="col-md-4">
                        <label className="order_value">TotalAmount:</label>
                        <span>{this.state.totalAmount}</span>
                      </span> : null}                                                                                                                                                                                                                                            
                    </div>                                        
                      <form className="pt-3">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Website</th>
                                <th scope="col">Ad_type</th> 
                                <th scope="col">Price</th> 
                                <th scope="col">View Content</th>
                                {this.state.contentUpload &&
                                  <th scope="col">Content Upload</th>
                                }
                                <th scope="col">Content Status</th>
                                <th scope="col">Live Url</th>                              
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.orderDetails.user_order_details && this.state.orderDetails.user_order_details.map((website, index) => 
                                <tr key={website.user_website_details.website_id}>
                                  <td>{index + 1}</td>
                                  <td>{website.user_website_details.website}</td>
                                  <td>{website.user_website_details.ad_type}</td>
                                  <td> {website.user_website_details.price ? 
                                  website.user_website_details.website_vendors_list.length && website.user_website_details.website_vendors_list[0].promo_price >0 ? 
                                  website.user_website_details.website_vendors_list[0].promo_price : website.user_website_details.price : '--'} </td>
                                  <td>
                                    {website.user_website_details.text_content_status ?
                                    <span className="order_content-status d-flex pl-2">
                                     {website.user_website_details.text_content.file_content ? <a href={DOMAIN_NAME + website.user_website_details.text_content.file_content} download ><i className="ni ni-cloud-download-95"></i></a> : null }
                                     {website.user_website_details.text_content.link_url ? <a href={ website.user_website_details.text_content.link_url} target="_blank"> <i className="fas fa-eye"></i></a> : null }
                                    </span>
                                    :
                                    website.user_website_details.post_url == '' ?
                                      <span><i className="fas fa-times" style={{ color: 'red' }}></i></span> :
                                    <span className="order_content-status d-flex pl-2">
                                     {website.user_website_details.post_url ? <a href={ website.user_website_details.post_url} target="_blank"> <i className="fas fa-eye"></i></a> : null }
                                    </span>
                                    }
                                  </td>
                                  {/*{(!this.props.location.state && this.state.contentUpload) &&*/}
                                  { this.state.contentUpload &&
                                    <td>
                                      {website.status == 'Rejected'|| website.status =='Pending' || website.status == 'Waiting Content' || website.status == 'Pending Publish'  ? 
                                        <button 
                                          type="button" 
                                          className="btn btn-info" 
                                          onClick={() => 
                                            this.props.history.push('/websites/orders/upload-content', 
                                            { id: this.state.orderDetails.order_id, userOrderId:website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}
                                          >
                                            Upload
                                        </button>
                                      :
                                        <button 
                                          type="button" 
                                          disabled
                                          className="btn btn-info" 
                                          onClick={() => 
                                            this.props.history.push('/websites/orders/upload-content', 
                                            { id: this.state.orderDetails.order_id, userOrderId:website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}
                                          >
                                            Upload
                                        </button> 

                                      }                                   
                                    </td>
                                  }
                                  {/*}*/}
                                    <td>
                                      {website.status}
                                    </td>
                                  <td>
                                  { website.post_url ? 
                                    <Fragment>
                                    <div className="profile_pc">
                                    <span style={{position:'relative'}} className="pic_actions">
                                      <span className="picture" style={{ whiteSpace: "nowrap", width:"150px", overflow: "hidden", textoverFlow:"ellipsis", display:"block"}}> </span>
                                        <button type="button" style={{backgroundColor:"#ffc107"}} className="btn btn-warning btn-sm"><i onClick={()=> window.open(website.post_url)} className="fas fa-eye"></i></button>
                                    </span>
                                    </div>
                                    </Fragment>:
                                    <span style={{ paddingLeft:"30px"}}>----</span> }
                                  </td>                                 
                                </tr>
                               )
                              }
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      )
    }
    else{
      return(
        <div className="main-content">
          <TopHeader showSearch={false} />
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-4">
                      </span>
                       <span className="col-md-4">
                        <label className="order_value">You do not have permission to access this page</label>
                      </span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

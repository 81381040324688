import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import Select from 'react-select';
import { ORDER, fetch_get, fetch_post, Pagination, Alert_model, Alert } from '../common';

let orderStatus = ""
export default class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskList: [],
      currentPage: 1,
      numOfPages: 0,
      order_id: '',
      order_status: '',
      filter: false,
      createduser: '',
      numberofrows: 100,
      svalue: '',
      website: '',
      adtype: '',
      price: '',
      cost: '',
      post: '',
      created_by: '',
      posttoggle: false,
      costtoggle: false,
      pricetoggle: false,
      adtypetoggle: false,
      websitetoggle: false,
      ordertoggle: false,
      createdbytoggle: false,
      createdontoggle: false,
      completedontoggle: false,
      orderstatustoggle: false,
      paymentoggle: false,
      order_status_toggle: false,
      archiveToggle: false,
      reason_for_archive: '',
      archive_order_status: '',
      show_archived: false,
      send_email: true,
      website: '',
      created_on: '',
      completed_on: '',
      vendors: [],
      clients: [],
      vendor: '',
      client: '',
      domain: '',
      contentstatustoggle: false,
      contentstatus: '',
      payment__status: '',
      order__id: '',
      pricemin: '',
      pricemax: '',
      payment_status: '',
      openMenu: false,
      vendor_transaction: '',
      invoice_link:'',
      payment_info:'',
      send_vendor_paid_mail:'true',
      payment_transaction: '',
      userOrderId: '',
      vendor_payment_id: '',
      paypal_payment_id: '',
      vendorPaymenttoggle: false,
      content_status: '',
      paid_email: '',
      paid_from_paypal: '',
      scrollPosition:'',
      followupMsg: false,
      vendor_msgs:''


    }
    this.tasksList = this.tasksList.bind(this);
  }

  componentDidMount() {
    // this.tasksList()
    // console.log("this.props.location.state", this.props);
    if (this.props.location.state) {
      this.setState({
        order_id: this.props.location.state.details.orderid,
        completed_on: this.props.location.state.details.completed_on,
        domain: this.props.location.state.details.domain,
        pricemin: this.props.location.state.details.pricemin,
        pricemax: this.props.location.state.details.pricemax,
        cost: this.props.location.state.details.cost,
        created_on: this.props.location.state.details.created_on,
        vendor: this.props.location.state.details.vendor,
        client: this.props.location.state.details.client,
        vendors: this.props.location.state.details.vendors,
        clients: this.props.location.state.details.clients,
        post_url: this.props.location.state.details.post_url,
        adType: this.props.location.state.details.adtype,
        order_status: this.props.location.state.details.order_status,
        payment_status: this.props.location.state.details.payment_status,
        adtype: this.props.location.state.details.adtype,
        filter: this.props.location.state.details.filter,
        numberofrows: this.props.location.state.details.numberofrows,
        currentPage: this.props.location.state.details.currentpage,
        content_status: this.props.location.state.details.content_status,
        to_created_on: this.props.location.state.details.to_created_on,
        paypal_payment_id: this.props.location.state.details.paypal_payment_id,
        vendor_payment_id: this.props.location.state.details.vendor_payment_id,
        payment_info: this.props.location.state.details.payment_info,
        invoice_link: this.props.location.state.details.invoice_link,
        reason_for_archive: this.props.location.state.details.reason_for_archive,

      }, this.orderFilter)
    }
    else if (this.props.location.client) {
      this.setState({
        order_status: this.props.location.client.details.order_status,
        created_by: this.props.location.client.details.created_by,
      }, this.orderFilter)
    }
    else {
      this.tasksList()
    }
  }

  // updateStatus(userOrderId, status) {
  //   let formData = new FormData()
  //   formData.append('order_id', userOrderId)
  //   formData.append('order_status', status)
  //   formData.append('page', this.state.currentPage)
  //   fetch_post(`${ORDER}s/list/`, formData)
  //   .then((response) => {
  //     if(!response.error) {
  //       orderStatus = response.order_status
  //       this.setState({ 
  //         ordersList: response.orders,
  //         numOfPages: response.num_pages,

  //       })
  //     }
  //   })
  //   .catch((error) => {
  //     this.setState({ error });
  //   });
  // }

  // rendorStatus(status, userOrderId) {
  //   orderStatus = status;
  //   return(
  //     <td className="p-1">
  //       <div className="col-md-12 p-0">
  //         <div className="form-group mb-0">
  //         {orderStatus === 'Completed' ?
  //           <select disabled
  //             value={orderStatus}
  //             onChange={(e) => this.updateStatus(userOrderId, e.target.value)}
  //             className="form-control"
  //           >
  //             <option value="">Select</option>
  //             <option value="Pending">Pending</option>
  //             <option value="Completed">Completed</option>
  //           </select>
  //         :
  //           <select
  //             value={orderStatus}
  //             onChange={(e) => this.updateStatus(userOrderId, e.target.value)}
  //             className="form-control"
  //           >
  //             <option value="">Select</option>
  //             <option value="Pending">Pending</option>
  //             <option value="Completed">Completed</option>
  //           </select>
  //         }
  //         </div>
  //       </div>
  //     </td>
  //   )
  // }

  tasksList(page = this.state.currentPage) {
    fetch_get(`/api/tasks/list/?page=${page}`)
      .then((response) => {
        if (!response.error) {
          // let clients = []
          // response.created_by_data.map((data) =>
          //   clients.push({ label: data.created_by__email, name: data.created_by__id }))
          // let vendors=[]
          // response.vendors_list.map((data)=>
          //   vendors.push({label:data.user_website__user__email,name:data.user_website__user__id}))
          this.setState({
            taskList: response.tasks,
            numOfPages: response.num_pages,
            // clients: clients,
            // vendors:vendors,
          })
        } else {
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  payment() {
    let formData = new FormData()
    formData.append('page', this.state.currentPage)
    formData.append('order_payment_status', this.state.payment__status)
    formData.append('get_order_id', this.state.order__id)
    fetch_post(`/api/tasks/list/`, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({
            taskList: response.tasks,
          })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }
  // }

  // rows_count(){
  // let formData = new FormData()
  // formData.append('page', this.state.currentPage)
  // this.state.order_id && formData.append('filter_order_id', this.state.order_id)
  // this.state.createdUser && formData.append('created_by', this.state.createdUser.value)
  // this.state.order_status && formData.append('order_status_filter', this.state.order_status)
  // this.state.payment_status && formData.append('payment_status', this.state.payment_status)
  // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  // fetch_post(`${ORDER}s/list/`,formData)
  // .then((response) => {
  //   contentstatustoggle.log("resssspppppp",response)
  //   if(!response.error){
  //     this.setState({
  //       ordersList: response.orders,
  //       numOfPages: response.num_pages,
  //       currentPage: response.page
  //       // createdUsersList:created_users,
  //     })                
  //   }
  // })
  //   this.orderFilter();
  // }

  
  orderFilter() {
    let formData = new FormData()
    formData.append('page', this.state.currentPage)
    this.state.order_id && formData.append('order_id', this.state.order_id)
    this.state.domain && formData.append('domain', this.state.domain)
    this.state.pricemin && formData.append('min_sell_price', this.state.pricemin)
    this.state.pricemax && formData.append('max_sell_price', this.state.pricemax)
    this.state.cost && formData.append('cost_price', this.state.cost)
    this.state.completed_on && formData.append('invoice_date', this.state.completed_on)
    this.state.created_on && formData.append('from_created_on', this.state.created_on)
    this.state.to_created_on && formData.append('to_created_on', this.state.to_created_on)
    this.state.vendor && formData.append('vendor_email', this.state.vendor)
    this.state.client && formData.append('created_by', this.state.client)
    this.state.post_url && formData.append('post_url', this.state.post_url)
    this.state.content_status && formData.append('content_status', this.state.content_status)
    this.state.order_status && formData.append('order_status', this.state.order_status)
    this.state.payment_status && formData.append('payment_status', this.state.payment_status)
    this.state.adtype && formData.append('ad_type', this.state.adtype)
    this.state.numberofrows && formData.append('custom_num_pages', this.state.numberofrows)
    this.state.vendor_payment_id && formData.append('vendor_payment_id', this.state.vendor_payment_id)
    this.state.paypal_payment_id && formData.append('paypal_payment_id', this.state.paypal_payment_id)
    this.state.created_by && formData.append('created_by', this.state.created_by)
    this.state.paid_from_paypal && formData.append('paid_from_paypal', this.state.paid_from_paypal)
    this.state.show_archived && formData.append('show_archived', this.state.show_archived)

    // if(this.state.svalue == 'id'){
    //   if(this.state.idtoggle){
    //     formData.append('column_name_order','id')
    //   }
    //   else{
    //     formData.append('column_name_order','-id')
    //   }
    // }
    if (this.state.svalue == 'order_id') {
      if (this.state.ordertoggle) {
        formData.append('column_name_order', 'order__order_id')
      }
      else {
        formData.append('column_name_order', '-order__order_id')
      }
    }
    if (this.state.svalue == 'website') {
      if (this.state.websitetoggle) {
        formData.append('column_name_order', 'website__domain')
      }
      else {
        formData.append('column_name_order', '-website__domain')
      }
    }
    if (this.state.svalue == 'adtype') {
      if (this.state.adtypetoggle) {
        formData.append('column_name_order', 'meta_details__ad_type')
      }
      else {
        formData.append('column_name_order', '-meta_details__ad_type')
      }
    }
    if (this.state.svalue == 'price') {
      if (this.state.pricetoggle) {
        formData.append('column_name_order', 'meta_details__sell_price')
      }
      else {
        formData.append('column_name_order', '-meta_details__sell_price')
      }
    }
    if (this.state.svalue == 'cost') {
      if (this.state.costtoggle) {
        formData.append('column_name_order', 'meta_details__price')
      }
      else {
        formData.append('column_name_order', '-meta_details__price')
      }
    }
    if (this.state.svalue == 'post') {
      if (this.state.posttoggle) {
        formData.append('column_name_order', 'post_url')
      }
      else {
        formData.append('column_name_order', '-post_url')
      }
    }
    if (this.state.svalue == 'createdby') {
      if (this.state.createdbytoggle) {
        formData.append('column_name_order', 'order__created_by__email')
      }
      else {
        formData.append('column_name_order', '-order__created_by__email')
      }
    }
    if (this.state.svalue == 'createdon') {
      if (this.state.createdontoggle) {
        formData.append('column_name_order', 'order__created_on')
      }
      else {
        formData.append('column_name_order', '-order__created_on')
      }
    }
    if (this.state.svalue == 'completedon') {
      if (this.state.completedontoggle) {
        formData.append('column_name_order', 'order__invoice_date')
      }
      else {
        formData.append('column_name_order', '-order__invoice_date')
      }
    }
    if (this.state.svalue == 'useremail') {
      if (this.state.useremailtoggle) {
        formData.append('column_name_order', 'user_website__user__email')
      }
      else {
        formData.append('column_name_order', '-user_website__user__email')
      }
    }
    if (this.state.svalue == 'contentstatus') {
      if (this.state.contentstatustoggle) {
        formData.append('column_name_order', 'text_content')
      }
      else {
        formData.append('column_name_order', '-text_content')
      }
    }
    if (this.state.svalue == 'orderstatus') {
      if (this.state.orderstatustoggle) {
        formData.append('column_name_order', 'status')
      }
      else {
        formData.append('column_name_order', '-status')
      }
    }
    if (this.state.svalue == 'order_status') {
      if (this.state.order_status_toggle) {
        formData.append('column_name_order', 'order__order_status')
      }
      else {
        formData.append('column_name_order', '-order__order_status')
      }
    }
    // if(this.state.svalue == 'orderstatus'){
    //   if(this.state.orderstatustoggle){
    //     formData.append('column_name_order','order_status')
    //   }
    //   else{
    //     formData.append('column_name_order','-order_status')
    //   }
    // } 
    if (this.state.svalue == 'payment') {
      if (this.state.paymenttoggle) {
        formData.append('column_name_order', 'order__paypal_payment_id')
      }
      else {
        formData.append('column_name_order', '-order__paypal_payment_id')
      }
    }
    if (this.state.svalue == 'vendorPayment') {
      if (this.state.vendorPaymenttoggle) {
        formData.append('column_name_order', 'vendor_payment_id')
      }
      else {
        formData.append('column_name_order', '-vendor_payment_id')
      }
    }
    if (this.state.svalue == 'archive') {
      if (this.state.archiveToggle) {
        formData.append('column_name_order', 'order__reason_for_archive')
      }
      else {
        formData.append('column_name_order', '-order__reason_for_archive')
      }
    }

    fetch_post(`/api/tasks/list/`, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({
            taskList: response.tasks,
            numOfPages: response.num_pages,
            currentPage: response.page
          }, window.scroll(0, 0))
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  clearStates() {
    this.setState({
      order_id: '',
      domain: '',
      post_url: '',
      adtype: '',
      order_status: '',
      payment_status: '',
      pricemin: '',
      pricemax: '',
      cost: '',
      created_on: '',
      created_by: '',
      vendor: '',
      client: '',
      vendor_payment_id: '',
      paypal_payment_id: '',
      to_created_on: '',
      content_status: '',
      created_by: '',
      completed_on: '',
      paid_from_paypal: '',
      reason_for_archive: '',
      archive_order_status: '',
      show_archived: false,
      vendor_msgs:''
    }, this.orderFilter)
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.order_id && formData.append('filter_order_id', this.state.order_id)
    // this.state.createdUser && formData.append('created_by', this.state.createdUser.value)
    // this.state.order_status && formData.append('order_status_filter', this.state.order_status)
    // this.state.payment_status && formData.append('payment_status', this.state.payment_status)
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   console.log("rm",response)
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.orders,this.state.cost && formData.append('cost_price', this.state.cost)
    //       numOfPages: response.num_pages,
    //       currentPage:currentPage
    //       // createdUsersList:created_users,
    //     })                
    //   }
    //   else{
    this.setState({ currentPage }, this.orderFilter)
    //   }
    // })     
  }

  // getVendorsData(vendor) {
  //   let formData = new FormData()
  //   formData.append('username', vendor)
  //   fetch_post('/api/get_vendors_data/', formData)
  //     .then((response) => {
  //       if (!response.error) {
  //         let vendors = [];
  //         response.vendors_data.map((vendor) =>
  //           vendors.push({ label: vendor.username, value: vendor.id })
  //         )
  //         this.setState({ vendors: vendors })

  //       }
  //     })
  // }

  addTranasctionId() {
    let formData = new FormData()
    formData.append('vendor_transaction_id', this.state.vendor_transaction)
    formData.append('invoice_link', this.state.invoice_link)
    formData.append('payment_info', this.state.payment_info)
    formData.append('send_vendor_paid_mail', this.state.send_vendor_paid_mail)
    console.log("sjhbdfjhsbkjdvba",this.state.vendor_transaction,this.state.invoice_link,this.state.payment_info,this.state.send_vendor_paid_mail)
    // if (this.state.vendor_transaction,this.state.invoice_link,this.state.payment_info) {
      fetch_post(`/api/payment_details/${this.state.userOrderId}/update/`, formData)
        .then((response) => {
          if (!response.error) {
            // document.getElementById('vendor_transaction').style.visibility = 'hidden'
            // document.getElementById('invoice_link').style.visibility = 'hidden'
            // document.getElementById('payment_info').style.visibility = 'hidden'
            this.setState({ vendor_transaction: '',invoice_link:'', payment_info:'',send_vendor_paid_mail:true})
            let b = document.getElementById('vendorModalClose')
            b.click();
            this.orderFilter();
            setTimeout(() => window.scrollTo(0, this.state.scrollPosition), 2000);
          }
        })
    // }
    // else {
      // if (this.state.vendor_transaction === ''){
      //   document.getElementById('vendor_transaction').style.visibility = 'visible'
      // }
      // else{
      //   document.getElementById('vendor_transaction').style.visibility = 'hidden'
      // }
      // if (this.state.invoice_link === ''){
      //   document.getElementById('invoice_link').style.visibility = 'visible'
      // }
      // else {
      //    document.getElementById('invoice_link').style.visibility = 'hidden'
      //   }
      // if (this.state.payment_info === ''){
      //   document.getElementById('payment_info').style.visibility = 'visible'
      //   }
      // else {
      //   document.getElementById('payment_info').style.visibility = 'hidden'
      //   }  
    // }
  }
  async addArchive() {
    this.setState({ archive_order_status: true })
    let formData = new FormData()
    await formData.append('page', this.state.currentPage)
    await formData.append('get_order_id', this.state.order__id)
    await formData.append('archive_order_status', this.state.archive_order_status)
    await formData.append('reason_for_archive', this.state.reason_for_archive)
    if (this.state.reason_for_archive) {
      await fetch_post(`/api/tasks/list/`, formData)
        .then((response) => {
          console.log("archiveResponse", response)
          if (!response.error) {
            document.getElementById('archive_txt').style.visibility = 'hidden'
            this.setState({
              archive_order_status: '', reason_for_archive: ''
            })
            let a = document.getElementById('archiveModal')
            a.click();
            this.orderFilter();
          }
        })
    }
    else {
      if (this.state.reason_for_archive === '') {
        document.getElementById('archive_txt').style.visibility = 'visible'
      }
      else {
        document.getElementById('archive_txt').style.visibility = 'hidden'
      }
    }
  }
  removeArchiveError() {
    document.getElementById('archive_txt').style.visibility = 'hidden'
    let a = document.getElementById('archiveModal')
    a.click();
    this.setState({
      archive_order_status: '', reason_for_archive: ''
    })
  }
  async removeArchived() {
    let formData = new FormData()
    await formData.append('page', this.state.currentPage)
    await formData.append('get_order_id', this.state.order__id)
    await formData.append('unarchive_order_status', true)

    await fetch_post(`/api/tasks/list/`, formData)
      .then((response) => {
        if (!response.error) {
          this.orderFilter();
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }
  paymentTranasctionId() {
    let formData = new FormData()
    formData.append('page', this.state.currentPage)
    formData.append('order_payment_status', this.state.payment_transaction)
    formData.append('get_order_id', this.state.order__id)
    formData.append('paypal_email', this.state.paid_email)
    if (this.state.send_email === true) {
      formData.append('send_email', this.state.send_email)
    }
    if (((/^([a-zA-Z0-9\.-]+)@([a-zA-Z0-9-]+)\.([a-z]{2,8})(.[a-z]{2,8})?$/.test(this.state.paid_email)) && this.state.payment_transaction) || (this.state.payment_transaction && this.state.paid_email === '')) {
      fetch_post(`/api/tasks/list/`, formData)
        .then((response) => {
          if (!response.error) {
            document.getElementById('client_transaction').style.visibility = 'hidden'
            document.getElementById('client_email').style.visibility = 'hidden'
            this.setState({
              payment_transaction: '', paid_email: '', send_email: true
            })
            let a = document.getElementById('clientModal')
            a.click()
            this.orderFilter();
            // let b = document.getElementById('mail')
            // b.click();
          }
        })
        .catch((error) => {
          this.setState({ error });
        });
    }
    else {
      if (this.state.payment_transaction === '') {
        document.getElementById('client_transaction').style.visibility = 'visible'
      }
      else {
        document.getElementById('client_transaction').style.visibility = 'hidden'
      }
      if (this.state.paid_email) {
        if (!(/^([a-zA-Z0-9\.-]+)@([a-zA-Z0-9-]+)\.([a-z]{2,8})(.[a-z]{2,8})?$/.test(this.state.paid_email))) {
          document.getElementById('client_email').style.visibility = 'visible'
        }
        else {
          document.getElementById('client_email').style.visibility = 'hidden'
        }
      }
    }
  }

  removeErrors() {
    // document.getElementById('vendor_transaction').style.visibility = 'hidden'
    // document.getElementById('invoice_link').style.visibility = 'hidden'
    // document.getElementById('payment_info').style.visibility = 'hidden'
    let a = document.getElementById('vendorModalClose')
    a.click();
    this.setState({ vendor_transaction: '',invoice_link:'', payment_info:''})
  }

  removeClientErrors() {
    document.getElementById('client_transaction').style.visibility = 'hidden'
    document.getElementById('client_email').style.visibility = 'hidden'
    let a = document.getElementById('clientModal')
    a.click();
    this.setState({ payment_transaction: '', paid_email: '' })
  }
  followup(id, order_id) {
    let formData = new FormData()
    formData.append('user_order_id', id)
    formData.append('send_email', true)
    formData.append('follow_up', true)
    fetch_post(`/api/order/${order_id}/details/`, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({ followupMsg: true })
          let b = document.getElementById('followup')
          b.click();
          this.orderFilter();
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }
  mailCommunication(order_id){
    fetch_get(`/api/get_vendor_emails/${order_id}/`)
    .then((response) => {
      if (response && !response.error) {
        // console.log(response)
        this.setState({vendor_msgs: response.vendor_msgs && response.vendor_msgs})
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }
  timeAgo(prevDate) {
    // moment(previousDate).fromNow();
    const diff = Number(new Date()) - prevDate;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000);
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
      case diff < hour:
        return Math.round(diff / minute) + ' minutes ago';
      case diff < day:
        return Math.round(diff / hour) + ' hours ago';
      case diff < month:
        return Math.round(diff / day) + ' days ago';
      case diff < year:
        return Math.round(diff / month) + ' months ago';
      case diff > year:
        return Math.round(diff / year) + ' years ago';
      default:
        return "";
    }
  };
  render() {
    // console.log(this.state.vendor_msgs,this.state.isVendorMail,'msg')
    return (
      <div className="main-content">
        <TopHeader />
        <span data-toggle="modal" data-target="#followupmodal" id="followup"></span>
        <div className="col-md-6 m-auto modal fade" id="followupmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
                <center>{"Followup mail is successfully send"}</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.setState({ followupMsg: false })} >Close</button>
              </div>
            </div>
          </div>
        </div>
        <Alert_model msg={'Transaction ID Sent Successfully'} />
        <span data-toggle="modal" data-target="#Alert_model" id="mail"></span>
        <div className="modal fade" id="vendorPayment" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">Enter Transaction Id</h3>
                <div className="row justify-content-center">
                </div>
                <div className="row">
                  <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                    <div className="form-group">
                      <label>Transaction Id 
                        {/* <div className="error-text">*</div>  */}
                        </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.vendor_transaction}
                        onChange={(e) => this.setState({ vendor_transaction: e.target.value })}
                        placeholder="Transaction Id"
                      />
                      {/* <div className="text-danger" id="vendor_transaction" style={{ visibility: 'hidden' }}>
                        please enter Transaction Id
                      </div> */}
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          data-toggle="toggle"
                          onChange={async () => { await this.setState({ send_vendor_paid_mail: !this.state.send_vendor_paid_mail }) }}
                          checked={this.state.send_vendor_paid_mail}
                          aria-label="Send Paid Email" />
                        <label class="form-check-label mb-4" >Send Paid Email</label>
                      </div>
                      <label>Invoice Link </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.invoice_link}
                        onChange={(e) => this.setState({ invoice_link: e.target.value })}
                        placeholder="Invoice Link"
                      />
                      {/* <div className="text-danger" id="invoice_link" style={{ visibility: 'hidden' }}>
                        please enter Invoice Link
                      </div> */}
                      <label>Payment Info </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.payment_info}
                        onChange={(e) => this.setState({ payment_info: e.target.value })}
                        placeholder="Payment Info"
                      />
                      {/* <div className="text-danger" id="payment_info" style={{ visibility: 'hidden' }}>
                        please enter Payment Info
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <span id="vendorModalClose" data-dismiss="modal"></span>
                  <button type="button" className="btn btn-info" onClick={() => this.addTranasctionId()} >add</button>
                  <button type="button" className="btn btn-default" onClick={() => this.removeErrors()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="paymentStatus" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">Enter Transaction Id</h3>
                <div className="row justify-content-center">
                </div>
                <div className="row">
                  <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                    <div className="form-group">
                      <label>Transaction Id <div className="error-text">*</div> </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.payment_transaction}
                        onChange={(e) => this.setState({ payment_transaction: e.target.value })}
                        placeholder="Transaction Id"
                      />
                      <div className="text-danger" id="client_transaction" style={{ visibility: 'hidden' }}>
                        {/*{this.state.errors && this.state.errors.client_email}*/}
                        please enter Transaction Id
                      </div>
                      <label>Paid From </label>
                      <input
                        type="email"
                        className="form-control"
                        value={this.state.paid_email}
                        onChange={(e) => this.setState({ paid_email: e.target.value })}
                        placeholder="paid from email"
                      />
                      <div className="text-danger" id="client_email" style={{ visibility: 'hidden' }}>
                        please enter a valid email
                      </div>
                      <div class="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          data-toggle="toggle"
                          onChange={async () => { await this.setState({ send_email: !this.state.send_email }) }}
                          checked={this.state.send_email}
                          aria-label="send thanks email" />
                        <label class="form-check-label" >Send Thanks Email</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <span id="clientModal" data-dismiss="modal"></span>
                  <button type="button" className="btn btn-info" onClick={() => this.paymentTranasctionId()} >add</button>
                  <button type="button" className="btn btn-default" onClick={() => this.removeClientErrors()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="archive_reason" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">Reason for Archive</h3>
                <div className="row justify-content-center">
                </div>
                <div className="row">
                  <div className="col-md-10" style={{ paddingLeft: '7rem' }}>
                    <div className="form-group">
                      <label>Reason for Archive<div className="error-text">*</div> </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.reason_for_archive}
                        onChange={(e) => this.setState({ reason_for_archive: e.target.value })}
                        placeholder="reason for archive"
                      />
                      <div className="text-danger" id="archive_txt" style={{ visibility: 'hidden' }}>
                        {/*{this.state.errors && this.state.errors.client_email}*/}
                        This field is required
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <span id="archiveModal" data-dismiss="modal"></span>
                  <button type="button" className="btn btn-info" onClick={() => this.addArchive()} >Archive</button>
                  <button type="button" className="btn btn-default" onClick={() => this.removeArchiveError()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="vendorCommunication" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content m-auto">
              <div className="modal-header">
              <h3 className="mb-1 col-md-9 text-right">Vendor Gmail Communication</h3>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>this.setState({vendor_msgs:''})}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              {<div className="card-body" style={{ height: '500px', maxHeight: '500px', overflowY: 'auto', backgroundColor: '#e9ecef', overflowX: 'hidden' }}>
                      {this.state.vendor_msgs && this.state.vendor_msgs.length ? this.state.vendor_msgs.map((msg, ind) =>
                        <div key={msg.id}>
                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(item => {
                            if (item.name === "From") {
                              const str = (item.value.split("@").length - 1);
                              const type = (str === 1 && item.value.match(/<(.*?)>/) !== null) ? item.value.match(/<(.*?)>/)[1] : item.value
                              return <div>
                                {type === 'publishers@rankcastle.com' ?
                                  <div className='col-md-10 card shadow mb-2' style={{ overflowX: 'hidden' }}>
                                    <div className='mt-3'>
                                      <h6>Rankcastle</h6>
                                      {msg.payload &&
                                        msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                          value.body && value.body.data && value.mimeType === "text/html" ?  
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </>
                                            : value.parts && value.parts.length ?
                                              value.parts.map(val => val.mimeType === "text/html" ?
                                                <>
                                                  {val.body.data && val.body.data ? <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                                    : val.body.attachmentId && val.body.attachmentId ? <img src={<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.attachmentId.replace(/-/g, '+').replace(/_/g, '/').replace(/\r\n/g, "</br>").replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />} /> : ''}
                                                </> : ''
                                              ) : ''
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  : <div className='col-md-10 card shadow mb-2' style={{ marginLeft: '130px', overflowX: 'hidden' }}>
                                    <div className='mt-3'>
                                      <h6>{type}</h6>
                                      {msg.payload &&
                                        msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                          value.body && value.body.data && value.mimeType === "text/html" ? 
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </>
                                            : value.parts && value.parts.length ?
                                              value.parts.map(val => val.mimeType === "text/html" ?
                                                <>
                                                  {val.body.data && val.body.data ? <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                                    : val.body.attachmentId && val.body.attachmentId ? <img src={<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.attachmentId.replace(/-/g, '+').replace(/_/g, '/').replace(/\r\n/g, "</br>").replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />} /> : ''}
                                                </> : ''
                                              ) : ''
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>}
                              </div>
                            }
                          })}
                        </div>
                      ) : <p>{this.state.vendor_msgs && this.state.vendor_msgs.message}</p>}
                    </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
              <div className="card shadow">
                <div className="row card-header bg-white border-0">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                    <div>
                      <label className="custom-toggle mb-0">
                        <select
                          placeholder="show"
                          value={this.state.numberofrows}
                          onChange={(e) => this.setState({ numberofrows: e.target.value }, this.orderFilter)}
                        >
                          <option value="">select</option>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>
                    </div>
                  </h3>
                  <h3 className="pt-3 col-md-4 d-flex">Orders</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>
                </div>
                {this.state.filter &&
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <div className="filter_result">
                          <form>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.order_id}
                                    onChange={(e) => this.setState({ order_id: e.target.value }, this.orderFilter)}
                                    placeholder="orderid"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.domain}
                                    onChange={(e) => this.setState({ domain: e.target.value }, this.orderFilter)}
                                    placeholder="domain"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.post_url}
                                    onChange={(e) => this.setState({ post_url: e.target.value }, this.orderFilter)}
                                    placeholder="post_url"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select
                                    className="form-control"
                                    onChange={(e) => this.setState({ adtype: e.target.value }, this.orderFilter)}
                                    value={this.state.adtype}
                                  >
                                    <option value="">adtype</option>
                                    <option value="Article">Article</option>
                                    <option value="Link insertion">Link insertion</option>
                                    <option value="Gambling Link">Gambling Link</option>
                                    <option value="CBD Marijuana Link">CBD Marijuana Link</option>
                                    <option value="Weight Loss Link">Weight Loss Link</option>
                                    <option value="Adult Link">Adult Link</option>
                                    <option value="Partners Page Link">Partners Page Link</option>
                                    <option value="Crypto">Crypto</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="Number"
                                    className="form-control"
                                    value={this.state.pricemin}
                                    onChange={(e) => this.setState({ pricemin: e.target.value }, this.orderFilter)}
                                    placeholder="price_min"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="Number"
                                    className="form-control"
                                    value={this.state.pricemax}
                                    onChange={(e) => this.setState({ pricemax: e.target.value }, this.orderFilter)}
                                    placeholder="price_max"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="Number"
                                    className="form-control"
                                    value={this.state.cost}
                                    onChange={(e) => this.setState({ cost: e.target.value }, this.orderFilter)}
                                    placeholder="cost"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.vendor}
                                    onChange={(e) => this.setState({ vendor: e.target.value }, this.orderFilter)}
                                    placeholder="Vendor"
                                  />
                                  {/* <Select
                                    value={this.state.vendor}
                                    onChange={(e) => this.setState({ vendor: e, openMenu: false }, this.orderFilter)}
                                    onInputChange={(e) => {
                                      if (e) { this.setState({ openMenu: true }, this.getVendorsData(e)) }
                                      else {
                                        this.setState({ openMenu: false })
                                      }
                                    }}
                                    options={this.state.vendors}
                                    menuIsOpen={this.state.openMenu}
                                    placeholder="Select Vendor"
                                  /> */}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.client}
                                    onChange={(e) => this.setState({ client: e.target.value }, this.orderFilter)}
                                    placeholder="Client"
                                  />
                                  {/* <Select
                                    value={this.state.client}
                                    placeholder="Select Client"
                                    onChange={(e) => this.setState({ client: e }, this.orderFilter)}
                                    options={this.state.clients}
                                  /> */}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select
                                    className="form-control"
                                    onChange={(e) => this.setState({ content_status: e.target.value }, this.orderFilter)}
                                    value={this.state.content_status}
                                  >
                                    <option value="">ContentStatus</option>
                                    <option value="Accepted">Accepted</option>
                                    <option value="Rejected">Rejected</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Pending Publish">Pending Publish</option>
                                    <option value="Waiting Content">Waiting Content</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select
                                    className="form-control"
                                    onChange={(e) => this.setState({ payment_status: e.target.value }, this.orderFilter)}
                                    value={this.state.payment_status}
                                  >
                                    <option value="">PaymentStatus</option>
                                    <option value="Paid">YES</option>
                                    <option value="Unpaid">NO</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.vendor_payment_id}
                                    onChange={(e) => this.setState({ vendor_payment_id: e.target.value }, this.orderFilter)}
                                    placeholder="vendor payment id"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.paypal_payment_id}
                                    onChange={(e) => this.setState({ paypal_payment_id: e.target.value }, this.orderFilter)}
                                    placeholder="paypal payment id"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <select
                                    className="form-control"
                                    onChange={(e) => this.setState({ order_status: e.target.value }, this.orderFilter)}
                                    value={this.state.order_status}
                                  >
                                    <option value="">OrderStatus</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Paid">Paid</option>
                                    <option value="Invoiced">Invoiced</option>
                                    <option value="Archived">Archived</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={this.state.paid_from_paypal}
                                    onChange={(e) => this.setState({ paid_from_paypal: e.target.value }, this.orderFilter)}
                                    placeholder="paid_from_paypal"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 mt-2">
                                <div class="form-check">
                                  <input class="form-check-input"
                                    className="form-check-input"
                                    type="checkbox"
                                    data-toggle="toggle"
                                    onChange={(e) => this.setState({ show_archived: !this.state.show_archived }, this.orderFilter)}
                                    id=""
                                    value={this.state.reason_for_archive}
                                    aria-label="Archived" />
                                  <label class="form-check-label" >Show Archived</label>
                                </div>
                              </div>
                              <div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className="form-control-label" htmlFor="input-email"> completed on </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={this.state.completed_on}
                                    onChange={(e) => this.setState({ completed_on: e.target.value }, this.orderFilter)}
                                    placeholder="completed_on"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className="form-control-label" htmlFor="input-email"> from Date </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={this.state.created_on}
                                    onChange={(e) => this.setState({ created_on: e.target.value }, this.orderFilter)}
                                    placeholder="From_created_on"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-group">
                                  <label className="form-control-label" htmlFor="input-email"> To Date </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={this.state.to_created_on}
                                    onChange={(e) => this.setState({ to_created_on: e.target.value }, this.orderFilter)}
                                    placeholder="To_created_on"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="col-lg-12 text-center">
                            <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ ordertoggle: !this.state.ordertoggle, svalue: 'order_id' }, this.orderFilter)}>Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ websitetoggle: !this.state.websitetoggle, svalue: 'website' }, this.orderFilter)}>website <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ adtypetoggle: !this.state.adtypetoggle, svalue: 'adtype' }, this.orderFilter)}>adtype <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ pricetoggle: !this.state.pricetoggle, svalue: 'price' }, this.orderFilter)}>price <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ costtoggle: !this.state.costtoggle, svalue: 'cost' }, this.orderFilter)}>cost <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ contentstatustoggle: !this.state.contentstatustoggle, svalue: 'contentstatus' }, this.orderFilter)}>view content Status <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ orderstatustoggle: !this.state.orderstatustoggle, svalue: 'orderstatus' }, this.orderFilter)}>content status <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ posttoggle: !this.state.posttoggle, svalue: 'post' }, this.orderFilter)}>live url <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ createdbytoggle: !this.state.createdbytoggle, svalue: 'createdby' }, this.orderFilter)}>created by <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ createdontoggle: !this.state.createdontoggle, svalue: 'createdon' }, this.orderFilter)}>created on <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ completedontoggle: !this.state.completedontoggle, svalue: 'completedon' }, this.orderFilter)}>completed on <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ order_status_toggle: !this.state.order_status_toggle, svalue: 'order_status' }, this.orderFilter)}>order status <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ useremailtoggle: !this.state.useremailtoggle, svalue: 'useremail' }, this.orderFilter)}>vendor <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ vendorPaymenttoggle: !this.state.vendorPaymenttoggle, svalue: 'vendorPayment' }, this.orderFilter)}>vendor payment <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ paymenttoggle: !this.state.paymenttoggle, svalue: 'payment' }, this.orderFilter)}>PaymentStatus <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{ cursor: 'pointer' }} onClick={() => this.setState({ archiveToggle: !this.state.archiveToggle, svalue: 'archive' }, this.orderFilter)}>Archive <i className="fas fa-sort"></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.taskList.length > 0 ? this.state.taskList.map((order, index) =>
                        order.order_details.tag == 'new' ?
                          <tr style={{ cursor: 'pointer', backgroundColor: '#fdebd6' }} key={order.id} >
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.order_id}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.website}</a> {order.order_details.tag ? <span className="new _badge" style={{ display: 'inlineBlock', color: '#fff', background: 'green', padding: '0.2rem 0.5rem', borderRadius: '3px' }}>{order.order_details.tag}</span> : null}
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.ad_type}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clints, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.price}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.cost_price}</a>
                            </td>
                            <td>
                              {order.user_website_details.text_content_status ?
                                <span className="order_content-status d-flex pl-2">
                                  {<a href={order.user_website_details.text_content.link_url ? order.user_website_details.text_content.link_url : order.user_website_details.text_content.file_content} target="_blank"><i className="fas fa-eye"></i></a>}
                                </span>
                                :
                                <span><i className="fas fa-times"></i></span>
                              }
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.status}</a>
                            </td>
                            <td>
                              {order.post_url ?
                                <button type="button" style={{ backgroundColor: "#ffc107" }} className="btn btn-warning btn-sm"><i title={order.post_url} onClick={() => window.open(order.post_url)} className="fas fa-eye"></i></button> :
                                <span style={{ paddingLeft: "15px" }}>----</span>
                              }
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.created_by}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.created_on}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.invoice_date}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.order_status}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a 
                              href=''
                              role="button"
                              aria-pressed="true"
                              data-toggle="modal" data-target="#vendorCommunication" data-backdrop="static" data-keyboard="false"
                              onClick={() => this.mailCommunication(order.order_details.order_id)}
                              style={{color:'#007bff'}}
                              >{order.user_website_details.assigned_website_vendor ? order.user_website_details.assigned_website_vendor.user.email : null}</a>
                            </td>
                            <td>
                            {order.vendor_payment_id ?<div>{order.vendor_payment_id }{order.invoice_link?<div><a target="_blank" href={order.invoice_link}>Invoice Link</a></div>:null}{order.payment_info?<div>{order.payment_info}</div>:null}</div> :
                              <div>
                              <a
                                style={{ width: "50px", }}
                                className="btn btn-primary btn-sm active"
                                role="button"
                                aria-pressed="true"
                                data-toggle="modal" data-target="#vendorPayment" data-backdrop="static" data-keyboard="false"
                                onClick={() => {this.setState({ order__id: order.order_details.order_id,scrollPosition:window.pageYOffset, userOrderId: order.id,invoice_link:order.invoice_link !==null ? order.invoice_link !== ''?order.invoice_link:'':'',payment_info:order.payment_info !== null? order.payment_info !== '' ? order.payment_info:'':''}) }}
                              >
                                Add
                                </a>{order.invoice_link !== null?order.invoice_link !== ''?<span><a target="_blank" href={order.invoice_link}>Invoice Link</a></span>:'':''}{order.payment_info !==null ?order.payment_info !== '' ?<div >{order.payment_info}</div>:'':''}
                           </div>
                              }
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {order.order_details.paypal_payment_id || order.order_details.paid_from_paypal ? <div> <div>{order.order_details.paypal_payment_id}</div> <div>{order.order_details.paid_from_paypal}</div></div> :
                                <a
                                  style={{ width: "50px", }}
                                  className="btn btn-primary btn-sm active"
                                  role="button"
                                  aria-pressed="true"
                                  data-toggle="modal" data-target="#paymentStatus" data-backdrop="static" data-keyboard="false"
                                  onClick={async () => { await this.setState({ order__id: order.order_details.order_id, send_email: true }) }}
                                >
                                  $
                                </a>
                              }
                            </td>
                            <td>
                              {order.order_details.reason_for_archive ? <div>{order.order_details.reason_for_archive}<button class="btn-xs btn-outline-info" data-toggle="tooltip" data-placement="top" title="unarchive" style={{ marginLeft: "2px", width: "20px", border: "none", borderRadius: "3px", cursor: "pointer" }}
                                onClick={async () => { await this.setState({ order__id: order.order_details.order_id }); this.removeArchived() }}><i class="fa fa-arrow-circle-up" style={{ fontSize: "16px", position: "relative", top: "1px", left: "-4px" }} /></button></div> :
                                <a
                                  style={{ width: "50px", }}
                                  className="btn btn-primary btn-sm active"
                                  role="button"
                                  aria-pressed="true"
                                  data-toggle="modal" data-target="#archive_reason" data-backdrop="static" data-keyboard="false"
                                  onClick={() => {
                                    this.setState({
                                      order__id: order.order_details.order_id
                                    })
                                  }}
                                >
                                  Add
                                </a>
                              }
                            </td>
                          </tr> :
                          <tr style={{ cursor: 'pointer' }} key={order.id} >
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.order_id}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.website}</a> {order.order_details.tag ? <span className="new _badge" style={{ display: 'inlineBlock', color: '#fff', background: 'lightblue', padding: '0.2rem 0.5rem', borderRadius: '3px' }}>{order.order_details.tag}</span> : null}
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.ad_type}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clints, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.price}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, content_status: this.state.content_status, vendor_payment_id: this.state.vendor_payment_id, paypal_payment_id: this.state.paypal_payment_id, to_created_on: this.state.to_created_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.user_website_details.cost_price}</a>
                            </td>
                            <td>
                              {order.user_website_details.text_content_status ?
                                <span className="order_content-status d-flex pl-2">
                                  {<a href={order.user_website_details.text_content.link_url ? order.user_website_details.text_content.link_url : order.user_website_details.text_content.file_content} target="_blank"><i className="fas fa-eye"></i></a>}
                                </span>
                                :
                                <span><i className="fas fa-times"></i></span>
                              }
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.status}</a>
                            </td>
                            <td>
                              {order.post_url ?
                                <button type="button" style={{ backgroundColor: "#ffc107" }} className="btn btn-warning btn-sm"><i title={order.post_url} onClick={() => window.open(order.post_url)} className="fas fa-eye"></i></button> :
                                <span style={{ paddingLeft: "15px" }}>----</span>
                              }
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.created_by}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.created_on}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.invoice_date}</a>
                            </td>
                            <td onClick={() => this.props.history.push(`/admin/orders/${order.order_details.order_id}/details`, { orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              <a href>{order.order_details.order_status}</a>
                            </td>
                            <td onClick={() => this.props.history.push({ orderid: this.state.order_id, completed_on: this.state.completed_on, domain: this.state.domain, pricemin: this.state.pricemin, pricemax: this.state.pricemax, cost: this.state.cost, vendor: this.state.vendor, client: this.state.client, vendors: this.state.vendors, clients: this.state.clients, created_on: this.state.created_on, post_url: this.state.post_url, payment_status: this.state.payment_status, order_status: this.state.order_status, adtype: this.state.adtype, numberofrows: this.state.numberofrows, currentpage: this.state.currentPage, filter: this.state.filter, source: 'tasklist' })}>
                              {order.user_website_details.assigned_website_vendor ?
                                <div>
                                  <a 
                                  href=''
                                  role="button"
                                  aria-pressed="true"
                                  data-toggle="modal" data-target="#vendorCommunication" data-backdrop="static" data-keyboard="false"
                                  onClick={() => this.mailCommunication(order.order_details.order_id)}
                                  style={{color:'#007bff'}}
                                  > {order.user_website_details.assigned_website_vendor.user.email} </a>
                                  <div>{order.Vendor_Message_ID !== null && order.status === "Pending Publish" && (order.order_details.order_status !== "Paid"|| order.order_details.order_status !==  "Completed" || order.order_details.order_status !==  "Invoiced" )?
                                    <button
                                      style={{
                                        width: "80px",
                                      }}
                                      className="btn btn-primary btn-sm active"
                                      role="button"
                                      aria-disabled={order.is_follow_up_mail_sent}
                                      aria-pressed={order.is_follow_up_mail_sent ? false : true}
                                      disabled={order.is_follow_up_mail_sent}
                                      onClick={() => this.followup(order.id, order.order_details.order_id)}
                                    >
                                      Follow up
                                    </button> : null}</div>
                                </div>
                                : null}
                            </td>
                            <td>
                            {order.vendor_payment_id ?<div>{order.vendor_payment_id }{order.invoice_link?<div><a target="_blank" href={order.invoice_link}>Invoice Link</a></div>:null}{order.payment_info?<div>{order.payment_info}</div>:null}</div> :
                              <div>
                              <a
                                style={{ width: "50px", }}
                                className="btn btn-primary btn-sm active"
                                role="button"
                                aria-pressed="true"
                                data-toggle="modal" data-target="#vendorPayment" data-backdrop="static" data-keyboard="false"
                                onClick={() => {this.setState({ order__id: order.order_details.order_id, userOrderId: order.id,scrollPosition:window.pageYOffset,invoice_link:order.invoice_link !==null ? order.invoice_link !== ''?order.invoice_link:'':'',payment_info:order.payment_info !== null? order.payment_info !== '' ? order.payment_info:'':''}) }}
                              >
                                Add
                                </a>{order.invoice_link !== null?order.invoice_link !== ''?<span><a target="_blank" href={order.invoice_link}>Invoice Link</a></span>:'':''}{order.payment_info !==null ?order.payment_info !== '' ?<div >{order.payment_info}</div>:'':''}
                           </div>
                              }
                            </td>
                            <td style={{ textAlign: 'center' }}>
                              {order.order_details.paypal_payment_id || order.order_details.paid_from_paypal ? <div> <div>{order.order_details.paypal_payment_id}</div> <div>{order.order_details.paid_from_paypal}</div></div> :
                                <a
                                  style={{ width: "50px", }}
                                  className="btn btn-primary btn-sm active"
                                  role="button"
                                  aria-pressed="true"
                                  data-toggle="modal" data-target="#paymentStatus" data-backdrop="static" data-keyboard="false"
                                  onClick={async () => { this.setState({ order__id: order.order_details.order_id, send_email: true }) }}
                                >
                                  $
                                </a>
                              }
                            </td>
                            <td>
                              {order.order_details.reason_for_archive ? <div>{order.order_details.reason_for_archive}<button class="btn-xs btn-outline-info" data-toggle="tooltip" data-placement="top" title="unarchive" style={{ marginLeft: "2px", width: "20px", border: "none", borderRadius: "3px", cursor: "pointer" }}
                                onClick={async () => { await this.setState({ order__id: order.order_details.order_id }); this.removeArchived() }}><i class="fa fa-arrow-circle-up" style={{ fontSize: "16px", position: "relative", top: "1px", left: "-4px" }} /></button></div> :
                                <a
                                  style={{ width: "50px", }}
                                  className="btn btn-primary btn-sm active"
                                  role="button"
                                  aria-pressed="true"
                                  data-toggle="modal" data-target="#archive_reason" data-backdrop="static" data-keyboard="false"
                                  onClick={() => {
                                    this.setState({
                                      order__id: order.order_details.order_id
                                    })
                                  }}
                                >
                                  Add
                                </a>
                              }
                            </td>
                          </tr>
                      )
                        :
                        <tr>
                          <td>No Records found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div >
      </div >
    )
  }
}



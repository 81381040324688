import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { Link } from 'react-router-dom';
import { ORDER, fetch_get, fetch_post, Pagination } from '../common';

let key = []
export default class Projects extends Component{
  constructor(props) {
    super(props);
    this.state = {
      projectsList: [],
      currentPage: 1,
      numOfPages: 1,
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      createdontoggle:false,
      websitecounttoggle:false,
      orderstatustoggle:false,
      name:'',
      target_url:'',
      keywords:'',
      pid:'',
    }
    this.projectsList = this.projectsList.bind(this);
  }

  componentDidMount() {
    this.projectsList()
  }

  projectsList(page=this.state.currentPage) {
    fetch_get(`/api/projects/list/?page=${page} `)
    .then((response) => {
      if(!response.error) {
        this.setState({ projectsList: response.projects_list, numOfPages: response.active_num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`/api/projects/list/`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          projectsList: response.projects_list,
          numOfPages: response.active_num_pages
        })                   
      }
    })
  } 

  orderFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'order_id'){
      if(this.state.ordertoggle){
        formData.append('column_name_order','order_id')
      }
      else{
        formData.append('column_name_order','-order_id')
      }
    }
    if(this.state.svalue == 'createdon'){
      if(this.state.createdontoggle){
        formData.append('column_name_order','created_on')
      }
      else{
        formData.append('column_name_order','-created_on')
      }
    } 
    if(this.state.svalue == 'websitecount'){
      if(this.state.websitecounttoggle){
        formData.append('column_name_order','websites_count')
      }
      else{
        formData.append('column_name_order','-websites_count')
      }
    }
    if(this.state.svalue == 'orderstatus'){
      if(this.state.orderstatustoggle){
        formData.append('column_name_order','order_status')
      }
      else{
        formData.append('column_name_order','-order_status')
      }
    }        
    fetch_post(`${ORDER}s/list/`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          ordersList: response.orders, 
          numOfPages: response.num_pages
        })              
      }
    })       
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.0rders, 
    //       numOfPages: response.num_pages
    //     })                   
    //   }
    // })       
    this.setState({currentPage},this.orderFilter)
  }

  createProject(){
    let key = this.state.keywords ? this.state.keywords.split(',') : null
    // this.setState({key:key})
    let formData = new FormData()
    formData.append('name',this.state.name)
    formData.append('target_url',this.state.target_url)
    key && formData.append('keywords',JSON.stringify(key))
    fetch_post('/api/project/create/', formData)
    .then((response) => {
      if(!response.error){
        let a = document.getElementById('createModel');
        a.click();
        this.setState({name:'',target_url:'',keywords:''})
        this.projectsList();                  
      }
      else{
        this.setState({errors:response.errors})
      }
    })      
  }

  removeErrors(){
    this.setState({errors:{},name:'',target_url:'',keywords:''})
  }

  permanentDelete(id){
    fetch_get(`/api/project/${id}/delete/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          let a = document.getElementById("deleteModel");
          a.click();          
          let d = document.getElementById('success')
          d.click()        
          this.projectsList()
        }
      }
      else{
        this.projectsList()        
      }
    })
  }

  beforeDelete(id){
    this.setState({pid:id})
    document.getElementById('delete_project').click()

  }

  render(){
    let carryProps={
      numberofrows:this.state.numberofrows,
      currentPage:this.state.currentPage
    }  
    return(
      <div className="main-content client_web">
      <span data-toggle="modal" data-target="#exampleModal4" id="delete_project"></span>
      <div className="modal fade col-md-6 m-auto" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content m-auto">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel"> Project Delete</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p><b>If You Delete the project, websites added to that project will be deleted but orders will remain same and will not be assigned to any project.</b></p>
              <p><b>Are you Sure ? You want to delete project ?</b></p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">Close</button>
              <button type="button" className="btn btn-primary" data-toggle="modal" onClick={()=>this.permanentDelete(this.state.pid)}>delete</button>
            </div>
          </div>
        </div>
        </div>    
        <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Create Project</h3>
               <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>Project Name <div className="error-text">*</div></label>
                        <input 
                          type="text" 
                          className="form-control" 
                          value={this.state.name}
                          onChange={(e) => this.setState({ name: e.target.value })}
                          placeholder="Name"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.name}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>Target Url <div className="error-text">*</div></label>
                        <input 
                          type="text" 
                          className="form-control" 
                          value={this.state.target_url}
                          onChange={(e) => this.setState({ target_url: e.target.value })}
                          placeholder="Target Url"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.target_url}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>keywords <div className="error-text">*</div></label>
                        <input 
                          type="text" 
                          className="form-control" 
                          value={this.state.keywords}
                          onChange={(e) => this.setState({ keywords: e.target.value })}
                          placeholder="enter , seperated values"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.keywords}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>                                    
                  <div className="row justify-content-center">
                    <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                    <button type="button" className="btn btn-info" onClick={() => this.createProject()} >Create</button>
                    <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={()=>this.removeErrors()}>Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>project Deleted Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/projects/')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>                
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col">
              <div className="">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">projects</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal2">Create project</button>                  
                  </div>                   
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.orderFilter)}>S.No <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order_id'},this.orderFilter)}>Name <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order_id'},this.orderFilter)}>Target Url <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({createdontoggle:!this.state.createdontoggle,svalue:'createdon'},this.orderFilter)}>Keywords <i className="fas fa-sort"></i></th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.state.projectsList && this.state.projectsList.length > 0) ? this.state.projectsList.map((project, index) => 
                        <tr style={{ cursor: 'pointer' }}>
                          <td onClick={() => this.props.history.push(`/projects/${project.id}/details`)}>
                            {index+1}
                          </td>
                          <td onClick={() => this.props.history.push(`/projects/${project.id}/details`)}>
                            {project.name}
                          </td> 
                          <td onClick={() => this.props.history.push(`/projects/${project.id}/details`)}>
                            {project.target_url}
                          </td>
                          <td onClick={() => this.props.history.push(`/projects/${project.id}/details`)}>
                            {project.keywords.map((d)=><span> <span style={{backgroundColor:"rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>)}
                          </td>
                          <td>
                            <Link to={{pathname:`/project/${project.id}/edit/`,state:{projectName:project.name,projectId:project.id},details:carryProps}}>
                              <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                            </Link>
                            <a onClick={() => this.beforeDelete(project.id)}>
                              <span style={{paddingRight:20, cursor:'pointer' }}><i className="fa fa-trash"></i></span>
                            </a>
                          </td>                                                           
                        </tr>
                      )
                      :
                        <tr>
                          <td>No Records found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {Number(this.state.numOfPages) > 1 &&
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      </ul>
                    </nav>
                  </div>
                }
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
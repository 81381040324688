import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import OrderConfirmationModal from './OrderConfirmationModal';
import OrderConfirmationSingleModal from './OrderConfirmationSingleModal';
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from '../common/TopHeader';
import Alert_selfmodel from '../common/Alert_selfmodel';
import { WEBSITES, ORDER, fetch_get, fetch_post, Pagination, CLIENT_WEBSITES, Alert_model, Alert } from '../common';

export default class BestOffers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      categories:[],
      displayid:'',
      selectedCategories:[],
      files: [],
      errors: {},
      websites: [],
      selectedWebsites: [],
      activeWebsites: [],
      activecurrentPage: this.props.location.state ? this.props.location.state.detail.detail : 1 ,
      activenumOfPages: 0,
      timeStamp: null,
      metricDate: '',
      searchText: '',
      active: true,
      clientId: '',
      clientsList: [],
      websitesIds: this.props.location.state ? this.props.location.state.detail.websiteids : [],
      filter: false, 
      domain:[],
      price: this.props.location.state ? this.props.location.state.detail.prices : [],
      ordersList: this.props.location.state ? this.props.location.state.detail.ordersList : [],
      ordersList1: this.props.location.state ? this.props.location.state.detail.ordersList1 : [], 
      websiteselected:false,
      // alexalow:'',
      // alexahigh:'',
      display:false,
      displayid:'',
      linksType: '',
      searchText: '',
      pricelow:'',
      pricehigh:'',
      dalow:'',
      dahigh:'',
      drlow:'',
      drhigh:'',
      adType:'',
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      domaintoggle:false,
      accesstoggle:false,
      // alexatoggle:false,
      datoggle:false,
      patoggle:false,
      drtoggle:false,
      urtoggle:false,
      adtypetoggle:false,
      pricetoggle:false,
      categorytoggle:false,
      traffictoggle:false,
      organic_traffic_low:'',
      organic_traffic_high:'',
      organicPositionToggle:false, 
      Organic_keywords:'',
      min_organic_position:'',
      max_organic_position:'',
      categoriesFilterOpenMenu:false,
      organic_country:'',
    }
    this.websitesList = this.websitesList.bind(this);
    this.search = this.search.bind(this);
    this.uncheckwebsites = this.uncheckwebsites.bind(this)
  }

  componentDidMount() {
    this.websitesList()
    if(this.props.location.state){
    this.setState({ 
      linksType: this.props.location.state.detail.linksType,
      selectedCategories: this.props.location.state.detail.selectedCategories,
      searchText: this.props.location.state.detail.searchText,
      pricelow: this.props.location.state.detail.pricelow,
      pricehigh:this.props.location.state.detail.pricehigh,
      dalow:this.props.location.state.detail.dalow,
      dahigh:this.props.location.state.detail.dahigh,
      drlow:this.props.location.state.detail.drlow,
      drhigh:this.props.location.state.detail.drhigh,
      adType:this.props.location.state.detail.adtype,
      // alexalow:this.props.location.state.detail.alexalow,
      // alexahigh:this.props.location.state.detail.alexahigh,
      filter:this.props.location.state.detail.filter,
      organic_traffic_low:this.props.location.state.detail.organic_traffic_low,
      organic_traffic_high:this.props.location.state.detail.organic_traffic_high,
      min_organic_position:this.props.location.state.detail.min_organic_position,
      max_organic_position:this.props.location.state.detail.max_organic_position,
      numberofrows:this.props.location.state.detail.numberofrows
    }, this.websitesFilter)      
    }    
  }

  uncheckwebsites(){
    this.setState({ ordersList:[], ordersList1:[], websitesIds:[],price:[]})
  }

  checkOrder(websiteId=null, price=null) {
    if(this.state.websitesIds.length > 0) {
      this.setState({websiteselected:false})
      let model = document.getElementById("cart");
      model.click();
    } else {
      this.setState({websiteselected:true})
      let a = document.getElementById("success1");
      a.click();
      // alert('please select atleast one website to create order')
    }
  }

  websitesFilter(page1=this.state.activecurrentPage, searchText=this.state.searchText) {
    let formData = new FormData()
    formData.append('domain', searchText)
    formData.append('best_offers',true)
    this.state.linksType && formData.append('links_type',  this.state.linksType)
    this.state.pricelow && formData.append('min_price', this.state.pricelow)
    this.state.pricehigh && formData.append('max_price', this.state.pricehigh)
    this.state.organic_traffic_low && formData.append('min_organic_traffic', this.state.organic_traffic_low)
    this.state.organic_traffic_high && formData.append('max_organic_traffic', this.state.organic_traffic_high)
    this.state.min_organic_position && formData.append('min_organic_position', this.state.min_organic_position)
    this.state.max_organic_position && formData.append('max_organic_position', this.state.max_organic_position)
    this.state.organic_country && formData.append('organic_country', this.state.organic_country)    
    this.state.dalow && formData.append('min_moz_da', this.state.dalow)
    this.state.dahigh && formData.append('max_moz_da', this.state.dahigh) 
    this.state.drlow && formData.append('min_dr', this.state.drlow)
    this.state.drhigh && formData.append('max_dr', this.state.drhigh)   
    this.state.adType && formData.append('ad_type', this.state.adType)
    // this.state.alexalow && formData.append('min_alexa_rank', this.state.alexalow)
    // this.state.alexahigh && formData.append('max_alexa_rank', this.state.alexahigh)   
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)   
    let categories = [];
    this.state.selectedCategories.length > 0 && this.state.selectedCategories.map((category) => 
      categories.push(category.value)
    )
    if(categories.length > 0) {
      formData.append('categories', JSON.stringify(categories));
    }    
    // this.state.selectedCategories && formData.append('categories', this.state.selectedCategories)
    formData.append('page', page1)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'domain'){
      if(this.state.domaintoggle){
        formData.append('column_name_order','website__domain')
      }
      else{
        formData.append('column_name_order','-website__domain')
      } 
    }
    if(this.state.svalue == 'da'){
      if(this.state.datoggle){
        formData.append('column_name_order','website__moz_da')
      }
      else{
        formData.append('column_name_order','-website__moz_da')
      }
    }
    if(this.state.svalue == 'pa'){
      if(this.state.patoggle){
        formData.append('column_name_order','website__moz_pa')
      }
      else{
        formData.append('column_name_order','-website__moz_pa')
      } 
    }
    if(this.state.svalue == 'dr'){
      if(this.state.drtoggle){
        formData.append('column_name_order','website__ahrefs_domain_rating_dr')
      }
      else{
        formData.append('column_name_order','-website__ahrefs_domain_rating_dr')
      } 
    }
    if(this.state.svalue == 'ur'){
      if(this.state.urtoggle){
        formData.append('column_name_order','website__ahrefs_url_rating_ur')
      }
      else{
        formData.append('column_name_order','-website__ahrefs_url_rating_ur')
      }
    }
    if(this.state.svalue == 'adtype'){
      if(this.state.adtypetoggle){
        formData.append('column_name_order','ad_type')
      }
      else{
        formData.append('column_name_order','-ad_type')
      }  
    } 
    if(this.state.svalue == 'price'){ 
      if(this.state.pricetoggle){
        formData.append('column_name_order','price')
      }
      else{
        formData.append('column_name_order','-price')
      }
    }
    // if(this.state.svalue == 'alexa'){
    //   if(this.state.alexatoggle){
    //     formData.append('column_name_order','website__alexa_rank')
    //   }
    //   else{
    //     formData.append('column_name_order','-website__alexa_rank')
    //   }
    // }
    if(this.state.svalue == 'organic'){
      if(this.state.traffictoggle){
        formData.append('column_name_order','website__organic_traffic')
      }
      else{
        formData.append('column_name_order','-website__organic_traffic')
      }
    }
    if(this.state.svalue == 'organicPosition'){
      if(this.state.organicPositionToggle){
        formData.append('column_name_order','website__organic_keywords')
      }
      else{
        formData.append('column_name_order','-website__organic_keywords')
      }
    } 
    fetch_post(`${CLIENT_WEBSITES} `, formData)
    .then((response) => {
      this.setState({ 
        activeWebsites: response.active_websites, 
        activenumOfPages: response.num_pages,
        // activecurrentPage: response.page1,
        // inActivecurrentPage: response.page2,
      },window.scroll(0,0))
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearFilter() {
    this.setState({ 
      linksType: '',
      selectedCategories: '',
      searchText: '',
      pricelow:'',
      pricehigh:'',
      dalow:'',
      dahigh:'',
      drlow:'',
      drhigh:'',
      adType:'',
      // alexalow:'',
      // alexahigh:'',
      organic_traffic_low:'',
      organic_traffic_high:'',   
      min_organic_position:'',
      max_organic_position:'',   
      organic_country:'',
    }, this.websitesFilter)
  }

  websitesList(page1=this.state.activecurrentPage) {
    let formData = new FormData()
    formData.append('page',this.state.activecurrentPage) 
    formData.append('best_offers',true)    
    fetch_post(`${CLIENT_WEBSITES}`,formData)
    .then((response) => {
      // let categories=[]
      // response.categories.map((category)=>{
      //   categories.push({ label: category.categories__name, value: category.categories__id })
      // })
      this.setState({ 
        activeWebsites: response.active_websites, 
        activenumOfPages: response.num_pages,
        // categories:categories,
        // activecurrentPage: response.page1,
        // inActivecurrentPage: response.page2,
        deleteId: null,
      },this.websitesFilter)
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
  //   // let formData = new FormData()
  //   // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   // formData.append('page',this.state.activecurrentPage)   
  //   // fetch_post(`${CLIENT_WEBSITES}`,formData)
  //   // .then((response) => {
  //   //   if(!response.error){
  //   //     this.setState({
  //   //     activeWebsites: response.active_websites, 
  //   //     activenumOfPages: response.num_pages,
  //   //     deleteId: null,
  //   //     })                   
  //   //   }
  //   // })
  //   this.websitesFilter()
  // }  
  
  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${CLIENT_WEBSITES}`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //     activeWebsites: response.active_websites, 
    //     activenumOfPages: response.num_pages,
    //     deleteId: null,
    //     })                   
    //   }
    // })    
      this.setState({activecurrentPage: currentPage},this.websitesFilter)
  }

  search(searchText) {
    this.setState({ searchText }, this.websitesFilter(this.state.activecurrentPage, searchText))
  }

  updateSingleWebsite(websiteId,domain,price,ad_type){
    let orderData1 = {}
    orderData1['websiteId'] = websiteId
    orderData1['domain'] = domain
    orderData1['price'] = price
    orderData1['ad_type'] = ad_type
    if(this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      // this.state.websitesIds.splice(indexx, 1);
      // this.state.ordersList1.splice(indexx, 1);
      this.state.ordersList.splice(indexx, 1);
      this.setState({ordersList1:this.state.ordersList1,websitesIds:[parseInt(websiteId)]})
      // if(this.state.ordersList.length === 0){
      //   this.setState({websiteIds:[],ordersList:[],})
      //   let b = document.getElementById('success1')
      //   b.click()
      // }
      //   this.setState({websitesIds:[...this.state.websitesIds.filter((data)=>{
      //   if(data.websiteId == websiteId){
      //     console.log("dataew",data)
      //     return data
      //   }
      // })]}) 
      this.setState({ordersList1:[...this.state.ordersList1.filter((data)=>{
        if(data.websiteId == websiteId){
          return data
        }
      })]})      
    } 
    else{
      this.setState({ websitesIds: [ parseInt(websiteId)], price:[...this.state.price, price], ordersList1:[ orderData1] })
      // let b = document.getElementById('confirmationsinglemodal')
      // b.click()
    }
    if(websiteId) {
      this.setState({websiteselected:false})
      let model = document.getElementById("confirmationsinglemodal");
      model.click();
    } else {
      alert('please select atleast one website to create order')
    }      
  }

  updateWebsitesList(websiteId,domain,price,ad_type) {
    let orderData = {}
    orderData['websiteId'] = websiteId
    orderData['domain'] = domain
    orderData['price'] = price
    orderData['ad_type'] = ad_type
    if(this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      this.state.websitesIds.splice(indexx, 1);
      this.state.domain.splice(indexx, 1);
      this.setState({ websitesIds: this.state.websitesIds})
      this.state.price.splice(indexx, 1);
      // this.state.ordersList.splice(indexx, 1);
      this.setState({ordersList:[...this.state.ordersList.filter((data)=>{
        if(data.websiteId != websiteId){
          return data
        }
      })]})
      this.setState({ websitesIds: this.state.websitesIds })
    } else {
      this.setState({ websitesIds: [...this.state.websitesIds, parseInt(websiteId)], price:[...this.state.price, price], ordersList:[...this.state.ordersList, orderData], ordersList1:[...this.state.ordersList1 , orderData] })
    }
  }

  mainContent(){
    if(this.state.display){
      this.setState({display:!this.state.display})
    }
  }

  getCategoriesData(category){
    let formData = new FormData()
    formData.append('name', category)
    fetch_post('/api/get_websites_category_data/',formData)
    .then((response) => {
      if(!response.error) {
        let categories=[]
        response.categories.map((category)=>{
          categories.push({ label: category.categories__name, value: category.categories__id })
        })
        this.setState({ categories: categories })
      }
    })

  }


  render(){
    return(
      <div className="main-content client_web" onClick={()=>this.mainContent()}>
        <TopHeader search={this.search} isSearch={true} />
        <Alert_selfmodel msg={'order created successfully'} role='client' />
        {this.state.websiteselected ? <Alert_model msg={'please select atleast one website to create order'}/> : <Alert_model msg={'order created successfully'}/> }    
        <Alert msg={'please select atleast one website to create order'}/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7 ">
          <div className="col">
            <div className="row main_list_container">
              <div className="col-md-12 m-auto client_websites">
                <div className="card shadow">
                  <div className="row card-header bg-white border-0">
                  <h3 className="mb-0 pt-2 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.websitesFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                   
                    <h3 className="mb-0 pt-2 col-md-4 d-flex">Websites</h3>
                    <div className="col-md-6 text-right website_filter">
                      <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                    </div>
                    {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result p-3">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      placeholder="Domain"
                                      value={this.state.searchText}
                                      onChange={(e) => this.setState({ searchText: e.target.value}, this.websitesFilter)}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group mar-bot-5">
                                    <select
                                      className="form-control"
                                      name="organic_country"
                                      onChange={(e) => this.setState({ organic_country: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value}}, this.websitesFilter)}
                                      value={this.state.organic_country}
                                    >
                                    <option value="">Organic Traffic Country</option>
                                      <option value="United States">United States - us</option>
                                      <option value="Great Britain">Great Britain - gb</option>
                                      <option value="Australia">Australia - au</option>
                                      <option value="Canada">Canada - ca</option>
                                      <option value="Germany">Germany - de</option>
                                      <option value="India">India - in</option>
                                    </select>
                                  </div>
                                </div>                                
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Organictraffic (Min)"
                                      onChange={(e) => this.setState({ organic_traffic_low: e.target.value }, this.websitesFilter)}
                                      value={this.state.organic_traffic_low}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Organictraffic (Max)"
                                      onChange={(e) => this.setState({ organic_traffic_high: e.target.value }, this.websitesFilter)}
                                      value={this.state.organic_traffic_high}
                                    />
                                  </div>
                                </div>         
                                <div className="col-md-2">
                                <div className="form-group">
                                  <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="keywords(Min)"
                                    name="organicPositionMin"
                                    onChange={(e) => this.setState({ min_organic_position: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value}}, this.websitesFilter)}
                                    value={this.state.min_organic_position}
                                  />
                                </div>
                              </div>       
                              <div className="col-md-2">
                                <div className="form-group">
                                  <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="keywords(Max)"
                                    name="organicPositionMax"
                                    onChange={(e) => this.setState({ max_organic_position: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value}}, this.websitesFilter)}
                                    value={this.state.max_organic_position}
                                  />
                                </div>
                              </div>                         
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Price (Min)"
                                      onChange={(e) => this.setState({ pricelow: e.target.value }, this.websitesFilter)}
                                      value={this.state.pricelow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Price (Max)"
                                      onChange={(e) => this.setState({ pricehigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.pricehigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DA (Min)"
                                      onChange={(e) => this.setState({ dalow: e.target.value }, this.websitesFilter)}
                                      value={this.state.dalow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DA (Max)"
                                      onChange={(e) => this.setState({ dahigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.dahigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DR (Min)"
                                      onChange={(e) => this.setState({ drlow: e.target.value }, this.websitesFilter)}
                                      value={this.state.drlow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DR (Max)"
                                      onChange={(e) => this.setState({ drhigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.drhigh}
                                    />
                                  </div>
                                </div>
                                {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Min)"
                                      onChange={(e) => this.setState({ alexalow: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexalow}
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Max)"
                                      onChange={(e) => this.setState({ alexahigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexahigh}
                                    />
                                  </div>
                                </div>                                                                                                                                 */}
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <Select 
                                      className="form-control"
                                      isMulti
                                      value={this.state.selectedCategories}
                                      onChange={(e) => this.setState({ selectedCategories: e, categoriesFilterOpenMenu:false },this.websitesFilter)}
                                      onInputChange={(e)=>{if(e){this.setState({categoriesFilterOpenMenu:true},this.getCategoriesData(e))}
                                      else{
                                        this.setState({categoriesFilterOpenMenu:false})
                                      }
                                      }}
                                      options={this.state.categories}
                                      // menuIsOpen={this.state.categoriesFilterOpenMenu}
                                      closeMenuOnSelect={false}
                                      placeholder="Categories"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <select 
                                      className="form-control"
                                      value={this.state.linksType}
                                      onChange={(e) => this.setState({ linksType: e.target.value }, this.websitesFilter)}
                                    >
                                      <option value="">LinksType</option>
                                      <option value="None">None</option>
                                      <option value="Dofollow">DoFollow</option>
                                      <option value="Nofollow">No Follow</option>
                                    </select>
                                  </div>
                                </div>                                                                
                                <div className="col-md-2">
                                  <div className="form-group mar-bot-5">
                                    <select 
                                      className="form-control"
                                      onChange={(e) => this.setState({ adType: e.target.value }, this.websitesFilter)}
                                      value={this.state.adType}
                                    >
                                    <option value="">Ad Type</option>
                                      <option value="Article">Article</option>
                                      <option value="Link insertion">Link insertion</option>
                                      <option value="Gambling Link">Gambling Link</option>
                                      <option value="CBD Marijuana Link">CBD Marijuana Link</option>
                                      <option value="Adult Link">Adult Link</option>
                                      <option value="Weight Loss Link">Weight Loss Link</option>
                                      <option value="Partners Page Link">Partners Page Link</option>
                                      <option value="Crypto">Crypto</option>
                                    </select>
                                  </div>
                                </div>                                                                
                              </div>
                              <div className="col-lg-12 text-center">
                              <button type="button" className="btn btn-primary botton_b1 " onClick={() => this.clearFilter()}>Clear</button>
                            </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  </div>
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush">
                      <thead className="thead-light">
                        <tr>
                          <th width="2%"></th>
                          <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.websitesFilter)}>ID <i className="fas fa-sort"></i></th>
                          <th width="14%" style={{cursor:'pointer'}} onClick={()=>this.setState({domaintoggle:!this.state.domaintoggle,svalue:'domain'},this.websitesFilter)}>Domain <i className="fas fa-sort"></i></th>
                          <th width="8%">categories</th>
                          <th width="8%" style={{cursor:'pointer'}} onClick={()=>this.setState({adtypetoggle:!this.state.adtypetoggle,svalue:'adtype'},this.websitesFilter)}>Ad type <i className="fas fa-sort"></i></th>
                          {/* <th width="4%" style={{cursor:'pointer'}} onClick={()=>this.setState({alexatoggle:!this.state.alexatoggle,svalue:'alexa'},this.websitesFilter)}>Alexa <i className="fas fa-sort"></i></th> */}
                          <th width="2%" style={{cursor:'pointer'}} onClick={()=>this.setState({datoggle:!this.state.datoggle,svalue:'da'},this.websitesFilter)}>DA <i className="fas fa-sort"></i></th>
                          <th width="2%" style={{cursor:'pointer'}} onClick={()=>this.setState({patoggle:!this.state.patoggle,svalue:'pa'},this.websitesFilter)}>PA <i className="fas fa-sort"></i></th>
                          <th width="2%" style={{cursor:'pointer'}} onClick={()=>this.setState({drtoggle:!this.state.drtoggle,svalue:'dr'},this.websitesFilter)}>DR <i className="fas fa-sort"></i></th>
                          <th width="2%" style={{cursor:'pointer'}} onClick={()=>this.setState({urtoggle:!this.state.urtoggle,svalue:'ur'},this.websitesFilter)}>UR <i className="fas fa-sort"></i></th>
                          <th width="3%" style={{cursor:'pointer'}} onClick={()=>this.setState({pricetoggle:!this.state.pricetoggle,svalue:'price'},this.websitesFilter)}>Price <i className="fas fa-sort"></i></th>
                          <th width="2%" style={{cursor:'pointer'}} onClick={()=>this.setState({traffictoggle:!this.state.traffictoggle,svalue:'organic'},this.websitesFilter)}>traffic <i className="fas fa-sort"></i></th>
                          <th width="7%" style={{cursor:'pointer'}} onClick={()=>this.setState({organicPositionToggle:!this.state.organicPositionToggle,svalue:'organicPosition'},this.websitesFilter)}>Keywords <i className="fas fa-sort"></i></th>
                          <th width="5%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.activeWebsites.length > 0 ? this.state.activeWebsites.map((website, index) =>
                          <tr style={{ cursor: 'pointer' }} key={index}>
                            <td>
                              <div className="custom-control custom-control-alternative custom-checkbox p-0">
                                <input 
                                  className="custom-control-input" 
                                  id={website.id} 
                                  type="checkbox" 
                                  value={website.id}
                                  checked={this.state.websitesIds.includes(website.id)}
                                  onClick={(e) => this.updateWebsitesList(e.target.value, website.website_details.domain, website.sell_price, website.ad_type )}
                                />
                                <label className="custom-control-label" htmlFor={website.id}>
                                  <span className="text-muted">&nbsp;</span>
                                </label>
                              </div>
                            </td>
                            <td>{website.website_details.website_id} </td>
                            <td className="overlap" onClick={() => this.props.history.push({ pathname: `/websites/${website.id}/details`, state:{detail:this.state.activecurrentPage, organic_traffic_low:this.state.organic_traffic_low,organic_traffic_high:this.state.organic_traffic_high,numberofrows:this.state.numberofrows,linksType:this.state.linksType,selectedCategories:this.state.selectedCategories,searchText:this.state.searchText,pricelow:this.state.pricelow,pricehigh:this.state.pricehigh,dalow:this.state.dalow,dahigh:this.state.dahigh,drlow:this.state.drlow,drhigh:this.state.drhigh,adType:this.state.adType,
                              // alexalow:this.state.alexalow,alexahigh:this.state.alexahigh,
                              filter:this.state.filter,websiteids:this.state.websitesIds,prices:this.state.price,ordersList:this.state.ordersList,ordersList1:this.state.ordersList1}})}><a href>{website.website_details.domain}</a></td>
                            <td>{website.website_details.categories.slice(0,1).map((d)=><span> <span style={{backgroundColor: "rgba(97, 112, 228, 0.68)",borderRadius:"5px" ,padding:"0.3em 0.5rem",lineHeight:"1.7rem"}}>{d}</span> </span>)}<span>{website.website_details.categories.length > 1 ? <div style={{backgroundColor: "rgba(97, 112, 228, 0.68)",display:"inline", position: "relative",borderRadius:"5px",padding:"0.3em 0.5rem",lineHeight:"1.7rem"}} onClick={()=>this.setState({displayid:website.id,display:!this.state.display})}>...
                              {this.state.displayid == website.id && this.state.display &&
                              <div className="categories_popup" id="categories_popup" onClick={()=>this.setState({displayid:''})}>
                                {website.website_details.categories.map((d)=><span id="all_categories">{d}</span>)}
                              </div>}</div>: ''}</span>
                            </td>
                            <td>{website.ad_type}</td>
                            {/* <td>{website.website_details.alexa_rank} </td> */}
                            <td>{website.website_details.moz_da} </td>
                            <td>{website.website_details.moz_pa} </td>
                            <td>{website.website_details.ahrefs_domain_rating_dr} </td>
                            <td>{website.website_details.ahrefs_url_rating_ur} </td>
                            <td>{website.sell_price}</td>
                            <td>{website.website_details.organic_traffic}</td>
                            <td>{website.website_details.organic_keywords}</td>
                            {/*<td><button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ websitesIds: [website.id]}, () => this.createOrder())}>Order</button></td>*/}
                            <td><button type="button" className="btn btn-success btn-sm" onClick={() => this.updateSingleWebsite(website.id, website.website_details.domain, website.sell_price, website.ad_type)}>Order</button></td>
                          </tr>
                        )
                        :
                          <tr>
                            <td>
                              No websites found
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <OrderConfirmationModal websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist={this.state.ordersList} orderlist1={this.state.ordersList1} uncheckwebsites={this.uncheckwebsites} userrole={"client"} />
                  <OrderConfirmationSingleModal websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist1={this.state.ordersList1} uncheckwebsites={this.uncheckwebsites} userrole={"client"}/>
                  <div className="card-footer">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        {Number(this.state.activenumOfPages) > 1 &&
                          <Pagination
                            currentPage={this.state.activecurrentPage}
                            totalPages={this.state.activenumOfPages}
                            getCurrentPage={this.getCurrentPage.bind(this)}
                          />
                        }
                      </ul>
                    </nav>
                  </div>
                </div>
                <br />
                {this.state.activeWebsites.length > 0 &&
                  <div className="text-center">
                    <span data-toggle = "modal" data-target = "#ConfirmationModal" id="cart"/>
                    <span data-toggle = "modal" data-target = "#Alert_model" id="success"/>
                    <span data-toggle = "modal" data-target = "#alert" id="success1"/>
                    <span data-toggle="modal" data-target="#ConfirmationSingleModal" id="confirmationsinglemodal"/>
                    {/*<button type="button" className="btn btn-info" onClick={() => this.createOrder()}>Create Order</button>*/}
                    <button type="button" className="btn btn-info" onClick={() => this.checkOrder()} >Create Order</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
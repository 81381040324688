export const DOMAIN_NAME =  process.env.REACT_APP_DOMAIN_NAME  || 'https://dev.rankcastle.com'
// export const DOMAIN_NAME =  process.env.REACT_APP_DOMAIN_NAME  || 'https://8000-twilight-recipe-97412096.eu-ws2.runcode.io'
// export const DOMAIN_NAME =  process.env.REACT_APP_DOMAIN_NAME  || 'http://rank.io:8000';

export const SIGNUP = '/api/register/';	
export const VENDOR_SIGNUP = '/api/vendor/create/';
export const ADMIN_SIGNUP = '/api/admin/create/';
export const CREATOR_SIGNUP = '/api/creator/create/';
export const LOGIN = '/api/login/';
export const USER = '/api/user/';
export const FORGOT_PASSWORD = '/api/forgot-password/';
export const RESET_PASSWORD = '/api/reset-password/';
export const CHANGE_PASSWORD = '/api/change-password/';
export const UPLOADCSV = '/api/upload_csv/';
export const LOGOUT = '/api/logout/';
export const WEBSITES = '/api/websites/';
export const WEBSITE = '/api/website/';
export const ADMINWEBSITE = '/api/admin/website/';  
export const CLIENTS = '/api/clients/';
export const ADMIN = '/api/admins/';
export const VENDORS = '/api/vendors/';
export const CREATORS = '/api/creators/';
export const ORDER = '/api/order';
export const REMOVE_PROFILE_PIC = '/api/remove-profile-pic/';
export const VENDOR_WEBSITE = '/api/vendor/website';
export const CLIENT_WEBSITES = '/api/client/websites/list/';
export const ADMIN_WEBSITE = '/api/admin/website';
export const CREATE_WEBSITE = '/api/website/create/';
export const USER_SUBSCRIPTIONS = '/api/user/subscription/list/';
export const SUBSCRIPTIONS = '/api/user/subscription-change/';
export const SUBSCRIPTION_THANKS = '/api/user/subscription/thanks/';
export const INVOICES_LIST = '/api/client/invoices/list/';
export const ADMINDASHBOARD = '/api/index/';
export const PAYPAL = '/api/paypal/';
export const USERWEBSITE = '/api/user/website'; 
export const DELETEFILTER = '/api/delete_filter/';

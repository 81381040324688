import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

export default class Invoices extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      loading: false,
      paymentId: null,
      numberofrows:'',
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      amounttoggle:false,
      paymenttoggle:false,     
    }
    this.invoices = this.invoices.bind(this);
    this.redirectPage = React.createRef();
  }

  componentDidMount() {
    this.invoices()
  }

  invoices(page=this.state.currentPage) {
    fetch_get(`${INVOICES_LIST}?page=${page}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          invoices: response.orders_list_serializer, 
          numOfPages: response.num_pages,
        })
      } 
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  rows_count(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          invoices: response.orders_list_serializer, 
          numOfPages: response.num_pages
        })                   
      }
    })
  } 

  invoiceFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'order'){
      if(this.state.ordertoggle){
        formData.append('column_name_order','order_id')
      }
      else{
        formData.append('column_name_order','-order_id')
      }
    }
    if(this.state.svalue == 'amount'){
      if(this.state.amounttoggle){
        formData.append('column_name_order','amount')
      }
      else{
        formData.append('column_name_order','-amount')
      }
    }
    if(this.state.svalue == 'payment'){
      if(this.state.paymenttoggle){
        formData.append('column_name_order','payment_status')
      }
      else{
        formData.append('column_name_order','-payment_status')
      }
    }                          
    fetch_post(`${INVOICES_LIST}`,formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          invoices: response.orders_list_serializer, 
          numOfPages: response.num_pages,
        },window.scroll(0,0))
      }
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${INVOICES_LIST}`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       invoices: response.orders_list_serializer, 
    //       numOfPages: response.num_pages,
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.invoiceFilter)
  }

  payment(id) {
    fetch_get(`${USER}${id}/pay/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ redirectUrl: response.redirect_url, loading: false })
      }
      this.redirectPage.current.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    return(
     <div className="main-content client_web">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <span style={{display:"none"}}> <a href={this.state.redirectUrl} ref={this.redirectPage}>abv</a></span>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col">
              <div className="">
                <div className="card-header bg-white border-0 d-flex">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.rows_count)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Invoices</h3>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.invoiceFilter)}>Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order'},this.invoiceFilter)}>Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({amounttoggle:!this.state.amounttoggle,svalue:'amount'},this.invoiceFilter)}>Invoice Amount <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({paymenttoggle:!this.state.paymenttoggle,svalue:'payment'},this.invoiceFilter)}>Payment Status <i className="fas fa-sort"></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.invoices.length > 0 ? this.state.invoices.map((invoice, index) =>
                        <tr key={invoice.id}>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.id}</td>
                          <td style={{cursor:'pointer'}} onClick={() => this.props.history.push(`/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.order_id}</td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td style={{cursor:'pointer', color:"blue"}} onClick={() => this.props.history.push(`/invoices/${invoice.order_id}/details`, { created_on: invoice.created_on, username:invoice.created_by.username })}>{invoice.total_amount}</td>
                          <td>
                            {
                              invoice.payment_status === 'Paid' ?
                              <a style={{width:"80px",color:'white'}} className="btn btn-success btn-sm paid_invoice" role="button" aria-pressed="true">Paid</a>
                              : invoice.payment_status === 'Canceled' ?
                              <a style={{width:"80px", backgroundColor:'red', color:'white'}} className="btn btn-secondary btn-sm paid_invoice" role="button" aria-pressed="true">Canceled</a>
                              : (this.state.loading && this.state.paymentId === invoice.id) ? 
                                <Fragment>
                                  <div className="spinner task">
                                    <div className="double-bounce1"></div>
                                    <div className="double-bounce2"></div>
                                  </div>
                                </Fragment>
                              :
                              <a
                                style={{width:"80px"}} 
                                className="btn btn-primary btn-sm active" 
                                role="button" 
                                aria-pressed="true" 
                                onClick={() => {this.setState({ loading: true, paymentId: invoice.id }, this.payment(invoice.order_id) )} } 
                              >
                                Pay Now
                              </a> 
                            }
                          </td>
                        </tr>
                      ):                         
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                {Number(this.state.numOfPages) > 1 &&
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      </ul>
                    </nav>
                  </div>
                }
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

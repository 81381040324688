import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';

export default class PaymentSuccessMessage extends Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount(){
    // window.location.reload()
  }

  render(){
    return(
     <div className="main-content client_web">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <section class="pricing_section mb-0">
          <div class="pricing_container container">
            <div class="row justify-content-center steps">
              <div class="col-lg-10">
                <ul role="tablist">

                  <li role="tab" class="closed" aria-disabled="false" aria-selected="true">
                    <a id="form-total-t-0" aria-controls="form-total-p-0">
                      <div class="title">
                        <span class="step-icon"><i class="fas fa-shopping-basket"></i></span>
                        <span class="step-text">Order Placed</span>
                        </div>
                      </a>
                  </li>


                <li role="tab" class="closed" aria-disabled="true">
                  <a id="form-total-t-1" aria-controls="form-total-p-1">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-upload"></i></span>
                      <span class="step-text">Uplaod Content</span>
                    </div>
                  </a>
                 </li>


                <li role="tab" class="closed" aria-disabled="true">
                  <a id="form-total-t-2" aria-controls="form-total-p-2">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-money-bill"></i></span>
                      <span class="step-text">Payment</span>
                    </div>
                  </a>
                </li>

              </ul>
              </div>
            </div>
          </div>
        </section>  
        <div style={{paddingTop:'225px',textAlign:'center'}}>
          <span style={{display: "inline-block",margin: "0 auto",background: "#b9dc58", padding: "0.25rem",borderRadius: "50%",width: "75px",height: "75px",textAlign: "center"}}>
            <i style={{color:"green",fontSize:"40px",display:"flex",justifyContent: "center",lineHeight: "70px"}} className="fas fa-check"></i>
          </span>
          <h4 style={{textAlign:"center" ,margin: '1rem 0'}}>Thank you for  payment!</h4>
        </div>
      </div>
    )
  }
}
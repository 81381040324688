import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DOMAIN_NAME, USERWEBSITE, fetch_get, fetch_post, Alert_model } from '../common';
import TopHeader from '../common/TopHeader';

let nicheList = [];
let UsersList = []
export default class WebsiteEdit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: [],
      selectedOption: null,
      usersList: [],
      selectedUsers: [],
      price: "",
      nicheText: '',
      nicheChoices: [],
      nicheDropDown: false,
      userDropDown: false,
      userIds: [],
      vendorsList: [],
      SelectedVendorsIds: [],
      vendorIds: [],
      vendorName: '',
      adType: '',
      linksType:'',
    }
  }

  componentDidMount() {
    this.websiteDetails()
  }

  handleChange(selectedUsers){
    this.setState({ selectedUsers });
  }

  removeErrors(){
    this.setState({errors : {}})
    // this.props.history.push('/admin/websites')
  }

  websiteDetails() {
    fetch_get(`${USERWEBSITE}/${this.props.match.params.id}/update/ `)
    .then((response) => {
      // console.log('webisteedit 48',response)
      this.setState({ vendorName: response.vendor_website_serializer.user_details, adType: response.vendor_website_serializer.ad_type, linksType: response.vendor_website_serializer.links_type  })
      this.refs['domain1'].value = response.vendor_website_serializer.website_details.domain;
      this.refs['id'].value = response.vendor_website_serializer.website_details.website_id;
      this.refs['pricee'].value = response.vendor_website_serializer.price;
      this.refs['old_price'].value = response.vendor_website_serializer.old_price;
      this.refs['promo_price'].value = response.vendor_website_serializer.promo_price;
      this.refs['sell_price'].value = response.vendor_website_serializer.sell_price;
      this.refs['example_post1'].value = response.vendor_website_serializer.example_post;
      this.refs['tat1'].value = response.vendor_website_serializer.tat;
      this.refs['special_notes1'].value = response.vendor_website_serializer.special_notes;
      this.refs['client_note'].value = response.vendor_website_serializer.client_note;
      this.refs['minimum_word_count1'].value = response.vendor_website_serializer.minimum_word_count;
      this.refs['maximum_number_of_links_in_post1'].value = response.vendor_website_serializer.maximum_number_of_links_in_post;
      this.refs['writing_included1'].checked = response.vendor_website_serializer.writing_included ? true : false;
      this.refs['prepaid1'].checked = response.vendor_website_serializer.prepaid ? true : false;
      this.refs['permanent_article1'].checked = response.vendor_website_serializer.permanent_article ? true : false;
      this.refs['is_vendor_verified1'].checked = response.vendor_website_serializer.is_vendor_verified == 'Yes' || response.vendor_website_serializer.is_vendor_verified == 'yes'? true : false;
      this.refs['disclosure_tag1'].checked = response.vendor_website_serializer.disclosure_tag ? true : false;
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websiteUpdate() {
    const { websiteDetails, selectedUsers, nicheText } = this.state;
    let formData = new FormData()
    formData.append('disclosure_tag', this.refs['disclosure_tag1'].checked ? 'True' : 'False')
    formData.append('prepaid', this.refs['prepaid1'].checked ? 'True' : 'False')
    formData.append('permanent_article', this.refs['permanent_article1'].checked ? 'True' : 'False')
    formData.append('is_vendor_verified', this.refs['is_vendor_verified1'].checked ? 'Yes' : 'No')
    formData.append('writing_included', this.refs['writing_included1'].checked ? 'True' : 'False')
    formData.append('special_notes', this.refs['special_notes1'].value)
    formData.append('client_note', this.refs['client_note'].value)
    // formData.append('domain', this.refs['domain1'].value)
    formData.append('example_post', this.refs['example_post1'].value )
    formData.append('tat', this.refs['tat1'].value)
    formData.append('minimum_word_count', this.refs['minimum_word_count1'].value)
    formData.append('maximum_number_of_links_in_post', this.refs['maximum_number_of_links_in_post1'].value)
    formData.append('ad_type', this.state.adType)
    this.refs['pricee'].value && formData.append('price', this.refs['pricee'].value)
    this.refs['sell_price'].value && formData.append('sell_price', this.refs['sell_price'].value)
    this.refs['old_price'].value && formData.append('old_price', this.refs['old_price'].value)
    this.refs['promo_price'].value && formData.append('promo_price', this.refs['promo_price'].value)
    fetch_post(`${USERWEBSITE}/${this.props.match.params.id}/update/ `, formData)
    .then((response) => {
      if(!response.error) {
        // alert('website updated successfully')
        if(response.error === false){
            let d = document.getElementById('success')
            d.click()
            // this.props.history.push('/admin/websites')
            this.removeErrors()
        }
      }
      else{
        this.setState({ errors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  onSearch(action, e) {
    if(action === 'user') {
      let value = e.target.value
      this.setState({ userText: value });
      let filteredValues = UsersList.filter(function (user) {
        return (user.username.toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ usersList: filteredValues, userDropDown: true })
    } else {
      let value = e.target.value
      this.setState({ nicheText: value });
      let filteredValues = nicheList.filter(function (niche) {
        return (niche[0].toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ nicheChoices: filteredValues, nicheDropDown: (filteredValues.length) > 0 ? true : false })
    }
  }

  usersUpdate(user, index) {
    if(this.state.userIds.includes(user.id)) {
      this.state.userIds.splice(this.state.userIds.indexOf(user.id), 1 );
      this.state.selectedUsers.forEach((selectedUser, index) => {
        if(selectedUser.id === user.id) {
          this.state.selectedUsers.splice(index, 1)
        }
      })
      this.setState({ userIds: this.state.userIds, selectedUsers: this.state.selectedUsers})
    } else {
      this.setState({ selectedUsers: [...this.state.selectedUsers, user], userIds: [...this.state.userIds, user.id] })
    }
    // this.setState({ labelSearchText: '', allLabels: labelsList });
  }

  render(){
    const { websiteDetails, nicheChoices, vendorsList } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        { <Alert_model msg={"Website Updated successfully"} redirecturl={true} history={this.props.history} /> }
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          {websiteDetails.website_id !== null &&
            <div className="col-md-12 m-auto instructions_col website_details">
              <div className="card shadow row mb-6">
                <div className="row">
                  <div className="card-header col-md-6 bg-white border-0">
                    <h3 className="mb-0">Website edit</h3>
                  </div>
                  <div className="col-md-6 text-right">
                    <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.goBack() }>
                      Back
                    </button>              
                  </div>                   
                </div>
                <div className="card-body">
                  <form>
                    <div className="pt-4">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" htmlFor="input-username">Website Id</label>
                            <input type="text" className="form-control" ref="id" disabled />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label">Domain</label>
                            <input type="text" ref="domain1" className="form-control" disabled />
                          </div>
                        </div>
                        {/*<div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Price</label>
                            <input 
                              type="number" 
                              ref="pricee" 
                              className="form-control" 
                            />
                          </div>
                        </div>*/}
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Example post</label>
                            <input type="text" ref="example_post1" className="form-control" />
                            <div className="text-danger">
                              {this.state.errors.example_post && this.state.errors.example_post}
                            </div>                            
                          </div>                          
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >TAT</label>
                            <input type="text" ref="tat1" className="form-control" />
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Special Notes</label>
                            <input type="text" ref="special_notes1" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Client_note</label>
                            <input type="text" ref="client_note" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Minimum word count</label>
                            <input type="number" ref="minimum_word_count1" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label className="form-control-label" >Maximum number of links</label>
                            <input type="number" ref="maximum_number_of_links_in_post1" className="form-control" />
                          </div>
                        </div>  
                      </div>
                    </div>
                    <div>
                      <div className="row pb-3 pt-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="row no-gutters">
                             <div className="col-md-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  ref="prepaid1"
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 p-0 col-form-label">Prepaid</label>
                              </div>
                              <div className="col-md-3 ">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label"
                                  ref="permanent_article1"
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 p-0 col-form-label">Permanent article</label>
                              </div>
                              <div className="col-md-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  ref="writing_included1"
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Writing included</label>
                              </div>
                              <div className="col-md-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  ref="disclosure_tag1"
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Disclosure tag</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-3 ">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label"
                                  ref="is_vendor_verified1"
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 p-0 col-form-label">Is Vendor Verified</label>
                              </div>
                        <div className="col-lg-3">
                          <div className="form-group mar-bot-5">
                            <label className="form-control-label">Ad type
                            </label>
                            <div className="input-group mb-3 dropdown no-caret">
                              <input 
                                type="text"
                                value={this.state.adType}
                                onChange={() => {}}
                                className="form-control" 
                                aria-label="Select User" 
                                aria-describedby="button-addon2"
                                disabled 
                              /> 
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group mar-bot-5">
                            <label className="form-control-label">Link Type
                            </label>
                            <div className="input-group mb-3 dropdown no-caret">
                              <input 
                                type="text"
                                value={this.state.linksType}
                                onChange={() => {}}
                                className="form-control" 
                                aria-label="Select User" 
                                aria-describedby="button-addon2"
                                disabled 
                              /> 
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                    <p className="m-3">
                      <button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      Price Change
                      </button>
                    </p>
                    <div className="collapse" id="collapseExample">
                      <div className="card card-body">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Ad Type</th>
                                <th scope="col" style={{width:'10rem'}}>Old Price </th>
                                <th scope="col" style={{width:'10rem'}}>Price <div className="error-text">*</div></th>
                                <th scope="col">Sell Price</th>
                                <th scope="col" style={{width:'10rem'}}>Promo price </th>
                              </tr>
                            </thead>
                              <tbody>
                                <tr>
                                  <td> 1 </td>
                                  <td>{this.state.vendorName}</td>
                                  <td>{this.state.adType}</td>
                                  <td> <input type="text" className="" ref="old_price"  /> 
                                  <div className="text-danger">
                                    {this.state.errors.old_price && this.state.errors.old_price}
                                  </div>
                                  </td>   
                                  <td> <input type="text" className="" ref="pricee"  /> 
                                  <div className="text-danger">
                                    {this.state.errors.price && this.state.errors.price}
                                  </div>
                                  </td>
                                  <td> <input type="text" className="" ref="sell_price"  /> 
                                  <div className="text-danger">
                                    {this.state.errors.sell_price && this.state.errors.sell_price}
                                  </div>
                                  </td>     
                                  <td> <input type="text" className="" ref="promo_price"  /> 
                                  <div className="text-danger">
                                    {this.state.errors.promo_price && this.state.errors.promo_price}
                                  </div>
                                  </td>                                
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="col-md-12">
                      <div className="text-center">
                        <span data-toggle="modal" data-target="#Alert_model" id="success"> </span>
                        <button type="button" className="btn btn-info mb-3" onClick={() => this.websiteUpdate()}>Update Details</button>
                        
                      </div>
                    </div>
                  </form>
                </div>
              </div>  
            </div>
          }
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { fetch_post, PAYPAL, DOMAIN_NAME } from '../common';

export default class PaymentSuccess extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errormsg:'',
      token:'',
      order_id:''

    }
  }

  componentDidMount() {
    this.postDetails()
  }

  postDetails() {
    let formData = new FormData()
    let paymentId = this.props.location.search.split('=')[1]
    let token = this.props.location.search.split('=')[2]
    formData.append('PayerID', this.props.location.search.split('=')[3])
    formData.append('paymentId', paymentId.split('&')[0])
    formData.append('token', token.split('&')[0])
    // fetch_post(`${PAYPAL}success/`, formData) 
    // .then((response) => {
    fetch(`${DOMAIN_NAME}${PAYPAL}success/`, {
      method: 'post',
      body: formData
    })
    .then(response => response.json())
    .then((response) => { 
      if(!response.error){
        // this.props.history.push('/invoices')
        if(response.is_public_order){
          this.props.history.push('/public/paymentsuccess/')
        }
        else{
          this.props.history.push('/invoices')
        }
      }
      else{
        this.setState({errormsg:response.message,order_id:response.order_id,token:response.static_token})
        let a = document.getElementById('paymentfailure');
        a.click();
      }
    })
  }

  render(){

    return(
      <div className="main-content">
        <div className="col-md-6 m-auto modal fade" id="pmt_failure" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
              </div>
              <div className="modal-body">
              <center>{this.state.errormsg}</center>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="pmt_success" onClick={()=> this.props.history.push(`/public/payment?token=${this.state.token}&order_id=${this.state.order_id}/`) }>Close</button>
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#pmt_failure" id="paymentfailure"></span>
      </div>
    )
  }
}


import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { fetch_get, fetch_post } from '../common';

let intervalId;
export default class Ahrefdata extends Component{
  constructor(props) {
    super(props);
    this.state = {
      count:0,
      failure:'',
      status:"abc",
      task_id:"",
      // alexa_id:'',
      moz_id:'',
      historic_id:'',
      task_status:'',
      moz_status:'',
      historic_status:'',
      // alexa_status:'',
      done_percent:0,
      tobedone_percent:'',
      moz_done_percent:0,
      moz_tobedone_percent:'',
      historic_done_percent:0,
      historic_tobedone_percent:'',

      // alexa_done_percent:0,
      // alexa_tobedone_percent:'',
      done_ahref:'',
      total_ahref:'',
      done_moz:'',
      done_historic:'',
      total_moz:'',
      total_historic:'',
      // done_alexa:'',
      // total_Alexa:'',
      google_status:'',
      google_id:'',

    }
  }

  componentDidMount(){
    // intervalId = setInterval(()=>this._websiteCount(), 5000)
    if(localStorage.getItem('task_id')){
      this._websiteCount()
      intervalId = setInterval(()=>this._websiteCount(), 5000)
    }
    if(localStorage.getItem('moz_id')){
      this.mozWebsiteCount()
      intervalId = setInterval(()=>this.mozWebsiteCount(), 5000)
    }
    // if(localStorage.getItem('alexa_id')){
    //   this.alexaWebsiteCount()
    //   intervalId = setInterval(()=>this.alexaWebsiteCount(), 5000)
    // } 
    if(localStorage.getItem('google_id')){
      this.googleIndex()
      intervalId = setInterval(()=>this.googleIndex(), 5000)
    }
    if(localStorage.getItem('historic_id')){
      this.historicTrafficCount()
      intervalId = setInterval(()=>this.historicTrafficCount(), 5000)
    }       
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  } 

  remove_taskid(id,status){
    let formData = new FormData()
    if(status != 'SUCCESS'){
      formData.append('revoke', true)
      let id1 = localStorage.getItem(id)
      fetch_post(`/api/task/${id1}/progress/`, formData)
      .then((response) => {
        if(!response.error) {
          if(id == 'task_id'){
            this.setState({task_id:'',task_status:'revoke'},localStorage.removeItem('task_id'))
          }
          if(id == 'moz_id'){
            this.setState({moz_id:'',moz_status:'revoke'},localStorage.removeItem('moz_id'))
          }
          // if(id == 'alexa_id')   {
          //   this.setState({alexa_id:'',alexa_status:'revoke'},localStorage.removeItem('alexa_id'))
          // }
          if(id == 'google_id'){
            this.setState({google_id:'',google_status:'revoke'},localStorage.removeItem('google_id'))
          }
          if(id == 'historic_id'){
            this.setState({historic_id:'',historic_status:'revoke'},localStorage.removeItem('historic_id'))
          }          
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
    else{
      if(id=='task_id'){
        this.setState({task_id:'',task_status:''},localStorage.removeItem('task_id'))
      }
      else if(id == 'moz_id'){
        this.setState({moz_id:'',moz_status:''},localStorage.removeItem('moz_id'))
      }
      else if(id == 'google_id'){
        this.setState({google_id:'',google_status:''},localStorage.removeItem('google_id'))
      }      
      else if(id == 'historic_id'){
        this.setState({historic_id:'', historic_status:''},localStorage.removeItem('historic_id'))
      }      

      // else{
      //   this.setState({alexa_id:'',alexa_status:''},localStorage.removeItem('alexa_id'))
      // }
    }    
  }

  getId(){
    fetch_get("/api/update_ahrefs_data/")
    .then((response) => {
      if(!response.error) {
        this.setState({ task_id: response.task_id, task_status:response.task_status })
        localStorage.setItem('task_id',response.task_id)
        this._websiteCount();
        intervalId = setInterval(()=>this._websiteCount(), 5000)
      }
    })
    .catch((error) => {
    });

  } 

  googleId(){
    fetch_get("/api/update_websites_google_indexd_data/")
    .then((response) => {
      if(!response.error) {
        this.setState({ google_id: response.task_id, google_status:response.task_status })
        localStorage.setItem('google_id',response.task_id)
        this.googleIndex();
        intervalId = setInterval(()=>this.googleIndex(), 5000)
      }
    })
    .catch((error) => {
    });

  }   

  getMozId(){
    fetch_get("/api/update_websites_moz_data/")
    .then((response) => {
      if(!response.error) {
        this.setState({ moz_id: response.task_id, moz_status:response.task_status })
        localStorage.setItem('moz_id',response.task_id)
        this.mozWebsiteCount()
        intervalId = setInterval(()=>this.mozWebsiteCount(), 5000)
      }
    })
    .catch((error) => {
    });

  } 

  getHistoricId(){
    fetch_get("/api/update_websites_historical_organic_traffic/")
    .then((response) => {
      if(!response.error) {
        this.setState({ historic_id: response.task_id, historic_status:response.task_status })
        localStorage.setItem('historic_id',response.task_id)
        this.historicTrafficCount()
        intervalId = setInterval(()=>this.historicTrafficCount(), 5000)
      }
    })
    .catch((error) => {
    });

  }
  // getAlexaId(){
  //   fetch_get("/api/update_websites_alexa_data/")
  //   .then((response) => {
  //     if(!response.error) {
  //       this.setState({ alexa_id: response.task_id, alexa_status:response.task_status })
  //       localStorage.setItem('alexa_id',response.task_id)
  //       this.alexaWebsiteCount();
  //       intervalId = setInterval(()=>this.alexaWebsiteCount(), 5000)
  //     }
  //   })
  //   .catch((error) => {
  //   });

  // }   

  _websiteCount() {
    if(!(this.state.task_status == 'SUCCESS' || this.state.task_status == 'FAILURE')){
      if(localStorage.getItem('task_id')){
        fetch_get(`/api/task/${localStorage.getItem('task_id')}/progress/`)
        .then((response) => {
          if(!response.error) {
            this.setState({ task_status:response.state, })
            if(response.details){
              if(response.state != 'FAILURE'){
                this.setState({done_percent:response.details.done/response.details.total*100,tobedone_percent:100-((response.details.done/response.details.total)*100), done_ahref:response.details.done, total_ahref:response.details.total})
              }
            }
          }
        })
        .catch((error) => {
        });
      }
    }
  }

  googleIndex() {
    if(!(this.state.google_status == 'SUCCESS' || this.state.google_status == 'FAILURE')){
      if(localStorage.getItem('google_id')){
        fetch_get(`/api/task/${localStorage.getItem('google_id')}/progress/`)
        .then((response) => {
          if(!response.error) {
            this.setState({ google_status:response.state, })
            if(response.details){
              if(response.state != 'FAILURE'){
                this.setState({google_done_percent:response.details.done/response.details.total*100,google_tobedone_percent:100-((response.details.done/response.details.total)*100), done_google:response.details.done, total_google:response.details.total})
              }
            }
          }
        })
        .catch((error) => {
        });
      }
    }
  }  

  mozWebsiteCount() {
    if(!(this.state.moz_status == 'SUCCESS' || this.state.moz_status == 'FAILURE')){
      if(localStorage.getItem('moz_id')){
        fetch_get(`/api/task/${localStorage.getItem('moz_id')}/progress/`)
        .then((response) => {
          if(!response.error) {
            this.setState({ moz_status:response.state, })
            if(response.details){
              if(response.state != 'FAILURE'){
                this.setState({
                  moz_done_percent:response.details.done/response.details.total*100,moz_tobedone_percent:100-((response.details.done/response.details.total)*100), done_moz:response.details.done, total_moz:response.details.total
                })
              }
            }
          }
        })
        .catch((error) => {
        });
      }
    }
  }

  historicTrafficCount() {
    if(!(this.state.historic_status == 'SUCCESS' || this.state.historic_status == 'FAILURE')){
      if(localStorage.getItem('historic_id')){
        fetch_get(`/api/task/${localStorage.getItem('historic_id')}/progress/`)
        .then((response) => {
          if(!response.error) {
            this.setState({ historic_status:response.state, })
            if(response.details){
              if(response.state != 'FAILURE'){
                this.setState({
                  historic_done_percent:response.details.done/response.details.total*100,historic_tobedone_percent:100-((response.details.done/response.details.total)*100), done_historic:response.details.done, total_historic:response.details.total
                })
              }
            }
          }
        })
        .catch((error) => {
        });
      }
    }
  }

  // alexaWebsiteCount() {
  //   if(!(this.state.alexa_status == 'SUCCESS' || this.state.alexa_status == 'FAILURE')){
  //     if(localStorage.getItem('alexa_id')){
  //       fetch_get(`/api/task/${localStorage.getItem('alexa_id')}/progress/`)
  //       .then((response) => {
  //         if(!response.error) {
  //           this.setState({ alexa_status:response.state, })
  //           if(response.details){
  //             if(response.state != 'FAILURE'){
  //               this.setState({alexa_done_percent:response.details.done/response.details.total*100,alexa_tobedone_percent:100-((response.details.done/response.details.total)*100), done_alexa:response.details.done, total_alexa:response.details.total})
  //             }
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //       });
  //     }
  //   }
  // }        

  render(){ 
    return(
      <div className="main-content">
        <Fragment>
          <TopHeader />
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <div className="container-fluid mt--5">
            <div className="row main_header card shadow">
              <div className="col-lg-12">
                <div className="">
                  <div className="card-header bg-white border-0">
                      <h3 className="mb-0">Update Domain Data</h3>
                  </div>
                  <div className="card-body">
                    <ul className="instructions Ahrefdata pl-0">
                      <li>
                       {
                        this.state.task_status == 'PROGRESS' || this.state.task_status == 'PENDING'?
                        <button type="button" disabled className="btn btn-primary" onClick={() => this.getId()}>Ahrefdata</button>:
                        <button type="button" className="btn btn-primary" onClick={() => this.getId()}>Ahrefdata</button>
                       }
                      </li>
                      <li>
                        {this.state.task_status == 'SUCCESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Successfully Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment> :
                      this.state.task_status == 'FAILURE' ? 
                        this.state.done_ahref == '' ?
                        <Fragment>
                          <div className="progress_block">
                            <label className="">Failed</label>
                            <div className="progress">
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div> 
                          </div>                      
                        </Fragment>:
                        <Fragment>
                          <div className="progress_block">
                            <label className="">{this.state.done_ahref} of {this.state.total_ahref} Completed</label>
                            <div className="progress">
                              <div className="progress-bar bg-success" role="progressbar" style={{width: this.state.done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: this.state.tobedone_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>                            
                            </div> 
                          </div>
                        </Fragment>
                      :
                      this.state.task_status == 'PROGRESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_ahref} of {this.state.total_ahref} Completed</label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>:
                      this.state.task_status == 'PENDING' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className=""></label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: 0 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>                      
                      :null
                    }
                      </li>
                      { this.state.task_status == 'SUCCESS' || this.state.task_status == 'FAILURE' || this.state.task_status == 'PROGRESS' || this.state.task_status == 'PENDING' ? 
                        <li>
                          <i class="fas fa-times" style={{color:'red',paddingLeft:'1rem',paddingTop:'1.5rem',cursor:'pointer'}} onClick={()=>this.remove_taskid('task_id',this.state.task_status)}></i>
                        </li> : null
                      }                       
                    </ul> 
                    <ul className="instructions Ahrefdata pl-0">
                      <li>
                       {
                        this.state.moz_status == 'PROGRESS' || this.state.moz_status == 'PENDING' ?
                        <button type="button" disabled className="btn btn-primary" onClick={() => this.getMozId()}>Mozdata</button>:
                        <button type="button" className="btn btn-primary" onClick={() => this.getMozId()}>Mozdata</button>
                       }
                      </li> 
                      <li>
                        {this.state.moz_status == 'SUCCESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Successfully Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment> :
                      this.state.moz_status == 'FAILURE' ? 
                      this.state.done_moz == '' ?
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Failed</label>
                          <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>                      
                      </Fragment>:                      
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_moz} of {this.state.total_moz} Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: this.state.moz_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: this.state.moz_tobedone_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>                            
                          </div> 
                        </div>
                      </Fragment>
                      :
                      this.state.moz_status == 'PROGRESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_moz} of {this.state.total_moz} Completed</label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.moz_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>:
                      this.state.moz_status == 'PENDING' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className=""></label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: 0 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>                      
                      :null
                    }
                      </li>
                      {this.state.moz_status == 'SUCCESS' || this.state.moz_status == 'FAILURE' || this.state.moz_status == 'PROGRESS' || this.state.moz_status == 'PENDING' ?
                      <li>
                        <i class="fas fa-times" style={{color:'red',paddingLeft:'1rem',paddingTop:'1.5rem',cursor:'pointer'}} onClick={()=>this.remove_taskid('moz_id',this.state.moz_status)}></i>
                      </li> : null
                      }                     
                    </ul>
                    {/* <ul className="instructions Ahrefdata pl-0">
                      <li>
                       {
                        this.state.alexa_status == 'PROGRESS' || this.state.alexa_status == 'PENDING'?
                        <button type="button" disabled className="btn btn-primary" onClick={() => this.getAlexaId()}>Alexadata</button>:
                        <button type="button" className="btn btn-primary" onClick={() => this.getAlexaId()}>Alexadata</button>
                       }
                      </li>
                      <li>
                        {this.state.alexa_status == 'SUCCESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Successfully Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment> :
                      this.state.alexa_status == 'FAILURE' ? 
                      this.state.done_alexa == '' ?
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Failed</label>
                          <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>                      
                      </Fragment>:                      
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_alexa} of {this.state.total_alexa} Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: this.state.alexa_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                            <div className="progress-bar bg-danger" role="progressbar" style={{width: this.state.alexa_tobedone_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>                            
                          </div> 
                        </div>
                      </Fragment>
                      :
                      this.state.alexa_status == 'PROGRESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_alexa} of {this.state.total_alexa} Completed</label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.alexa_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>:
                      this.state.alexa_status == 'PENDING' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className=""></label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: 0 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>                      
                      :null
                    }
                      </li>
                      { this.state.alexa_status == 'SUCCESS' || this.state.alexa_status == 'FAILURE' || this.state.alexa_status == 'PROGRESS' || this.state.alexa_status == 'PENDING' ?
                      <li>
                        <i class="fas fa-times" style={{color:'red',paddingLeft:'1rem',paddingTop:'1.5rem',cursor:'pointer'}} onClick={()=>this.remove_taskid('alexa_id',this.state.alexa_status)}></i>
                      </li>:null
                      }
                    </ul>  */}
                    <ul className="instructions Ahrefdata pl-0">
                      <li>
                       {
                        this.state.google_status == 'PROGRESS' || this.state.google_status == 'PENDING'?
                        <button type="button" disabled className="btn btn-primary" onClick={() => this.googleId()}>GoogleIndex</button>:
                        <button type="button" className="btn btn-primary" onClick={() => this.googleId()}>GoogleIndex</button>
                       }
                      </li>
                      <li>
                        {this.state.google_status == 'SUCCESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Successfully Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment> :
                      this.state.google_status == 'FAILURE' ? 
                        this.state.done_google== '' ?
                        <Fragment>
                          <div className="progress_block">
                            <label className="">Failed</label>
                            <div className="progress">
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div> 
                          </div>                      
                        </Fragment>:
                        <Fragment>
                          <div className="progress_block">
                            <label className="">{this.state.done_google} of {this.state.total_google} Completed</label>
                            <div className="progress">
                              <div className="progress-bar bg-success" role="progressbar" style={{width: this.state.google_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: this.state.google_tobedone_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>                            
                            </div> 
                          </div>
                        </Fragment>
                      :
                      this.state.google_status == 'PROGRESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_google} of {this.state.total_google} Completed</label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.google_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>:
                      this.state.google_status == 'PENDING' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className=""></label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: 0 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>                      
                      :null
                    }
                      </li>
                      { this.state.google_status == 'SUCCESS' || this.state.google_status == 'FAILURE' || this.state.google_status == 'PROGRESS' || this.state.google_status == 'PENDING' ? 
                        <li>
                          <i class="fas fa-times" style={{color:'red',paddingLeft:'1rem',paddingTop:'1.5rem',cursor:'pointer'}} onClick={()=>this.remove_taskid('google_id',this.state.google_status)}></i>
                        </li> : null
                      }                       
                    </ul>
                    <ul className="instructions Ahrefdata pl-0">
                      <li>
                       {
                        this.state.historic_status == 'PROGRESS' || this.state.historic_status == 'PENDING'?
                        <button type="button" disabled className="btn btn-primary" onClick={() => this.getHistoricId()}>Historical Organic Traffic</button>:
                        <button type="button" className="btn btn-primary" onClick={() => this.getHistoricId()}>Historical Organic Traffic</button>
                       }
                      </li>
                      <li>
                        {this.state.historic_status == 'SUCCESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">Successfully Completed</label>
                          <div className="progress">
                            <div className="progress-bar bg-success" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment> :
                      this.state.historic_status == 'FAILURE' ? 
                        this.state.done_historic == '' ?
                        <Fragment>
                          <div className="progress_block">
                            <label className="">Failed</label>
                            <div className="progress">
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: 100 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                            </div> 
                          </div>                      
                        </Fragment>:
                        <Fragment>
                          <div className="progress_block">
                            <label className="">{this.state.done_historic} of {this.state.total_historic} Completed</label>
                            <div className="progress">
                              <div className="progress-bar bg-success" role="progressbar" style={{width: this.state.historic_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>
                              <div className="progress-bar bg-danger" role="progressbar" style={{width: this.state.historic_tobedone_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                              </div>                            
                            </div> 
                          </div>
                        </Fragment>
                      :
                      this.state.historic_status == 'PROGRESS' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className="">{this.state.done_historic} of {this.state.total_historic} Completed</label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: this.state.historic_done_percent + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>:
                      this.state.historic_status == 'PENDING' ? 
                      <Fragment>
                        <div className="progress_block">
                          <label className=""></label>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: 0 + '%'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                            </div>
                          </div> 
                        </div>
                      </Fragment>                      
                      :null
                    }
                      </li>
                      { this.state.historic_status == 'SUCCESS' || this.state.historic_status == 'FAILURE' || this.state.historic_status == 'PROGRESS' || this.state.historic_status == 'PENDING' ? 
                        <li>
                          <i class="fas fa-times" style={{color:'red',paddingLeft:'1rem',paddingTop:'1.5rem',cursor:'pointer'}} onClick={()=>this.remove_taskid('historic_id',this.state.historic_status)}></i>
                        </li> : null
                      }                       
                    </ul>                                                                                          
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
    </div>
  )
  }
}
import { DOMAIN_NAME } from './apiUrls';
import ReactGA from 'react-ga';
export const fetch_get = (url, status=false) => {
  ReactGA.pageview(window.location.pathname + window.location.search)
  const ROOT_API = DOMAIN_NAME
  return fetch(ROOT_API + url, {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('UserToken')
    }
  })
  .then(response => {
    if(response.status === 403) {                                                                 
      return {}
    }
    else if(status) {
      return response
    } else {
      return response.json()
    }
  })
  .catch((error) => {
  });
}

export const fetch_post = (url, data, status=false) => {
  ReactGA.pageview(window.location.pathname + window.location.search) 
  const ROOT_API = DOMAIN_NAME
  return fetch(ROOT_API + url, {
    method: 'post',
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('UserToken')
      // 'Authorization': 'Token 6681b6460d216fd7d36ab801831ee675b0b94db8' 
    },
    body: data
  })
  .then(response => {
    if(response.status === 403) {
      return {}
    }
    if(status) {
      return response
    } else {
      return response.json()
    }
  })
  .catch((error) => {
  });
}
import React, { Component, Fragment } from 'react';
import moment from 'moment'
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DeleteUser from '../admin/DeleteUser';
import TopHeader from '../common/TopHeader';
import { UPLOADCSV, USERWEBSITE, fetch_get, fetch_post, Pagination, WEBSITE, Alert } from '../common';

export default class Websites extends Component{
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      errors: {},
      activeWebsites: [],
      inActiveWebsites: [],
      activecurrentPage: 1,
      activenumOfPages: 0,
      inActivecurrentPage: 1,
      inActivenumOfPages: 0,
      searchText: '',
      active: true,
      timeStamp: '',
      filter: false,
      pricelow:'',
      pricehigh:'',
      restore:false

    }
    this.websitesList = this.websitesList.bind(this);
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.websitesList()
  }

  changeStatus(id) {
    fetch_get(`${WEBSITE}${id}/status/`) 
    .then((response) => {
      if(!response.error) {
        // alert("Website restored successfully")
        this.setState({restore:true})
        this.websitesList()
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  handleChange(date) {
    this.setState({ timeStamp: date });
  }

  uploadCSV() {
    const data = new FormData();
    data.append('upload_csv', this.state.files[0]);
    fetch_post( UPLOADCSV, data)
    .then((response) => {
      if(!response.error) {
        alert(response.message)
        this.setState({ files: [] })
        this.websitesList()
      } else {
        this.setState({ errors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websitesList(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage) {
    fetch_get(`${USERWEBSITE}s/list/?page1=${page1}&page2=${page2}`
    )
    .then((response) => {
      this.setState({ 
        activeWebsites: response.active_user_websites, 
        inActiveWebsites: response.inactive_user_websites,
        activenumOfPages: response.active_num_pages,
        inActivenumOfPages: response.inactive_num_pages,
        deleteId: null,
      })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websitesFilter(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage, searchText=this.state.searchText) {
    let formData = new FormData()
    formData.append('domain', searchText)
    this.state.vendorId && formData.append('vendor_id', this.state.vendorId)
    this.state.pricelow && formData.append('min_price', this.state.pricelow)
    this.state.pricehigh && formData.append('max_price', this.state.pricehigh)
    this.state.disclosureTag && formData.append('disclosure_tag', this.state.disclosureTag)
    this.state.writingIncluded && formData.append('writing_included', this.state.writingIncluded)
    this.state.permanentArticle && formData.append('permanent_article', this.state.permanentArticle)
    this.state.prepaid && formData.append('prepaid', this.state.prepaid)
    this.state.examplePost && formData.append('example_post', this.state.examplePost)
    this.state.tat && formData.append('tat', this.state.tat)
    this.state.specialNotes && formData.append('special_notes', this.state.specialNotes)
    this.state.adType && formData.append('ad_type', this.state.adType)
    this.state.minWord && formData.append('minimum_word_count', this.state.minWord)
    this.state.maxlinks && formData.append('maximum_number_of_links_in_post', this.state.maxlinks)
    this.state.linksType && formData.append('links_type', this.state.linksType)
    this.state.acceptGambling && formData.append('accept_gambling', this.state.acceptGambling)
    this.state.acceptThc && formData.append('accept_thc', this.state.acceptThc)
    this.state.acceptAdultContent && formData.append('accept_adult', this.state.acceptAdultContent)
    this.state.acceptLinkInsertion && formData.append('accept_link_insertion_in_existing_post', this.state.acceptLinkInsertion)
    this.state.timestamp && formData.append('timestamp', moment(this.state.timestamp).format('YYYY-MM-DD'))
    formData.append('page1', page1)
    formData.append('page2', page2)
    fetch_post(`${USERWEBSITE}s/list/ `, formData)
    .then((response) => {
      this.setState({ 
        activeWebsites: response.active_user_websites, 
        inActiveWebsites: response.inactive_user_websites,
        activenumOfPages: response.active_num_pages,
        inActivenumOfPages: response.inactive_num_pages,
        // activecurrentPage: response.page1,
        // inActivecurrentPage: response.page2,
        deleteId: null,
      },window.scroll(0,0))
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearFilter() {
    this.setState({ 
      pricelow: '',
      pricehigh:'',
      disclosureTag: '',
      writingIncluded: '',
      permanentArticle: '',
      prepaid: '',
      examplePost: '',
      tat: '',
      specialNotes: '',
      adType: '',
      minWord: '',
      maxlinks: '',
      linksType: '',
      acceptGambling: '',
      acceptThc: '',
      acceptAdultContent: '',
      acceptLinkInsertion: '',
      timestamp: '',
      searchText: ''
    }, this.websitesFilter)
  }

  getCurrentPage(currentPage) {
    if(this.state.active) {
      this.setState({activecurrentPage: currentPage}, this.websitesFilter)
    } else {
      this.setState({ inActivecurrentPage:currentPage}, this.websitesFilter)
    }
  }

  search(searchText) {
    this.setState({ searchText }, this.websitesFilter(this.state.currentPage, searchText))
  }

  rendorWebsites() {
    if (this.state.activeWebsites && this.state.inActiveWebsites) {
      let websites = this.state.active ? this.state.activeWebsites : this.state.inActiveWebsites
      let page = this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage
      return(
        <div className="table-responsive">
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th scope="col">UniqueId</th>
              <th scope="col">Domain</th>
              <th scope="col">Ad Type</th>
              <th scope="col">Link Type</th>
              <th scope="col">Price</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {websites.length > 0 ? websites.map((website, index) => 
              (website.website &&
                <tr key={index}>
                  <th scope="row">{website.id}</th>
                  <td>{website.website.domain}</td>
                  <td>{website.ad_type}</td>
                  <td>{website.links_type}</td>
                  <td>{website.price}</td>
                  {this.state.active ? 
                    <td>
                      <Link to={{ pathname:`/vendors/website/${website.id}/edit` }}>
                        <span style={{ paddingRight:20 }}><i style={{fontSize:17}}className="fa fa-edit"></i></span>
                      </Link>
                      <DeleteUser userRole="website" component='website' id={this.state.deleteId} updateList={this.websitesList} />
                      <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: website.id, restore:false})}>
                        <i style={{ fontSize:17, color: 'red', cursor:'pointer' }} className="fa fa-trash"></i>
                      </span>
                    </td> : 
                  <td>
                    <a data-toggle="modal" data-target="#alert"  onClick={() => this.changeStatus(website.website.website_id)} >
                      <span style={{paddingLeft:20 }}><i className="fa fa-sync"></i></span>
                    </a>
                  </td>
                }
                </tr>
              )
            )
            :
              <tr>
                <th scope="row">No Websites found</th>
              </tr>
            }
          </tbody>
        </table>
        </div>
      )
    }
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader search={this.search} isSearch={true} />
        {this.state.restore ? <Alert msg={"Website Restored Successfully"}/> : <Alert msg={"Website Deleted Successfully"}/>}
        <div className="header bg-gradient-primary  pb-9 "></div>
        <div className="container-fluid  mt--7">
          {/*<div className="row mt-5">
            <div className="col-lg-6">
              <div className="card shadow">
                <div className="card-header border-0">
                  <h3 className="mb-0">Upload File</h3>
                </div>
                <div className="card-body">
                  <Dropzone onDrop={acceptedFiles => this.setState({ files: acceptedFiles })}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()} className="drag_drop shadow text-center">
                          <input {...getInputProps()} />
                          <p>{this.state.files.length > 0 ? this.state.files[0].name : 'Drop or Click to Upload CSV file'}</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div className="text-danger">
                    {this.state.errors.file && this.state.errors.file}
                  </div>    
                  <form className="form-inline">
                    <button type="button" className="btn btn-primary" onClick={() => this.uploadCSV()}>Upload</button>
                  </form>
                </div>  
              </div>  
            </div>
            <div className="col-lg-6 instructions_col">
              <div className="card shadow">
                <div className="card-header border-0">
                  <h3 className="mb-0">Instructions</h3>
                </div>
                <div className="card-body">
                  <ul className="instructions">
                    <li>Upload file should be in .csv format</li>
                    <li>You should upload file upto maximum of 100KB</li>
                    <li>Make sure you upload file with atleast 1 website details</li>
                  </ul>
                </div>  
              </div>  
            </div>
          </div>*/}
        
          <div className="row main_list_container">
          <div className="col-md-12 p-0">
            <div className="col">
              <div className="card vendor_website">
                <div className="row card-header bg-white border-0">
                  <h3 className="mb-0 pt-2 col-md-6 d-flex">Websites
                  <div className="">
                   <label className="custom-toggle mb-0 ml-3">
                    <input type="checkbox"/> 
                    <span className="custom-toggle-slider"onClick={() => this.setState({ active: !this.state.active })}> {this.state.active ? 'Active' : 'Inactive'} </span>
                  </label>
                  </div>
                  </h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div> 
                  </div>
                     {this.state.filter &&
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="filter_result p-3">
                  <form>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <input 
                            type="email" 
                            className="form-control" 
                            placeholder="Domain"
                            value={this.state.searchText}
                            onChange={(e) => this.setState({ searchText: e.target.value}, this.websitesFilter)}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group mar-bot-5">
                          <select 
                            className="form-control"
                            onChange={(e) => this.setState({ adType: e.target.value }, this.websitesFilter)}
                            value={this.state.adType}
                          >
                          <option value="">Ad Type</option>
                            <option value="Article">Article</option>
                            <option value="Link insertion">Link insertion</option>
                            <option value="Gambling Link">Gambling Link</option>
                            <option value="CBD Marijuana Link">CBD Marijuana Link</option>
                            <option value="Adult Link">Adult Link</option>
                            <option value="Weight Loss Link">Weight Loss Link</option>
                            <option value="Partners Page Link">Partners Page Link</option>
                            <option value="Crypto">Crypto</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input 
                            type="email" 
                            className="form-control" 
                            placeholder="Price (Min)"
                            onChange={(e) => this.setState({ pricelow: e.target.value }, this.websitesFilter)}
                            value={this.state.pricelow}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <input 
                            type="email" 
                            className="form-control" 
                            placeholder="Price (Max)"
                            onChange={(e) => this.setState({ pricehigh: e.target.value }, this.websitesFilter)}
                            value={this.state.pricehigh}
                          />
                        </div>
                      </div>                      
                      <div className="col-md-2">
                        <div className="form-group">
                          <select 
                            className="form-control"
                            value={this.state.linksType}
                            onChange={(e) => this.setState({ linksType: e.target.value }, this.websitesFilter)}
                          >
                            <option value="">LinksType</option>
                            <option value="None">None</option>
                            <option value="Dofollow">DoFollow</option>
                            <option value="Nofollow">No Follow</option>
                            <option value="Sponsored">Sponsored</option>
                            <option value="UGC">UGC</option>
                          </select>
                        </div>
                      </div>
                    </div>
                     <div className="col-lg-12 text-center">
                      <button type="button" className="btn btn-primary botton_b1" onClick={() => this.clearFilter()}>Clear</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          } 
                </div>
                {this.rendorWebsites()}
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage}
                          totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Vendors from './vendors/Main';
import Client from './client/Main';
import Public from './Public/Main';
import Creator from './creator/Main';
import AdminMain from './admin/Main';
import Header from './common/Header';
import Profile from './auth/Profile';



class Main extends Component {

  main() {
    return (
      <Switch>
        <Route path='/profile/' component={Profile}/> 
        <Route path='/public/' component={Public}/>
        <Route path='/admin' component={AdminMain}/>
        <Route path='/vendors' component={Vendors}/>
        <Route path='/creator' component={Creator}/>  
        <Route path='/' component={Client}/>         
      </Switch>
    )
  }

  render() {
    return (
      <div>
        <Header />
          {this.main()}
      </div>
    );
  }
}

export default Main

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import BestOffers from './BestOffers';
import Signin from '../auth/Signin';
import Orders from './Orders';
import CreateOrder from './CreateOrder';
import UploadContent from './UploadContent';
import Websites from './Websites';
import WebsiteDetails from './WebsiteDetails';
import Invoices from './Invoices';
import Projects from './Projects';
import ProjectDetails from './ProjectDetails';
import ProjectEdit from './ProjectEdit';
import ProjectOrders from './ProjectOrders';
import SuggestedWebsites from './SuggestedWebsites';
import ProjectWebsites from './ProjectWebsites';
import InvoiceDetails from './InvoiceDetails';
import InvoicesDetails from './InvoicesDetails';
import OrderDetails from './OrderDetails';
import PaymentSuccess from './PaymentSuccess';
import FilterLink from './FilterLink';
import PaymentCancel from './PaymentCancel';
import PageNotFound from '../../components/common/PageNotFound';
import SubscriptionRedirect from './SubscriptionRedirect';
import SubscriptionSuccessMessage from './SubscriptionSuccessMessage';
import SubscriptionFailureMessage from './SubscriptionFailureMessage';
import ChangePassword from '../../components/auth/ChangePassword';



const Component = () => (
  <Switch>
    <Route exact path='/websites' component={Websites} />
    <Route exact path='/bestoffers' component={BestOffers} />
    <Route exact path='/filter/:id' component={FilterLink} />
    <Route exact path='/websites/:id/details' component={WebsiteDetails} />
    <Route exact path='/dashboard' component={Dashboard} />
    <Route exact path='/' component={Dashboard} />
    <Route exact path='/orders' component={Orders} />
    <Route exact path='/invoices' component={Invoices} />
    <Route exact path='/projects' component={Projects} />
    <Route exact path='/projects/:id/details/' component={ProjectDetails} />
    <Route exact path='/project/:id/edit/' component={ProjectEdit} />
    <Route exact path='/projects/:id/orders/' component={ProjectOrders} />
    <Route exact path='/projects/:id/websites/' component={ProjectWebsites} />
    <Route exact path='/projects/:id/suggestedwebsites/' component={SuggestedWebsites} />
    <Route exact path='/invoice/thankyou' component={PaymentSuccess} />
    <Route exact path='/invoice/cancel' component={PaymentCancel} />
    <Route exact path='/orders/:id/details' component={OrderDetails} />
    <Route exact path='/invoices/:id/details' component={InvoicesDetails} />
    <Route exact path='/websites/orders/create' component={CreateOrder} />
    <Route exact path='/websites/orders/upload-content' component={UploadContent} />
    <Route exact path='/subscription/thanks/' component={SubscriptionRedirect} />
    <Route exact path='/subscription/success/' component={SubscriptionSuccessMessage} />
    <Route exact path='/subscription/failure/' component={SubscriptionFailureMessage} />
    <Route exact path='/signin' component={Signin} />
    <Route exact path='/user/:id/change-password' component={ChangePassword} />  
    <Route component={PageNotFound}/> 
  </Switch>
)

export default Component

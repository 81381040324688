import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import OrderConfirmationModal from '../client/OrderConfirmationModal';
import Alert_selfmodel from '../common/Alert_selfmodel';
import OrderConfirmationSingleModal from '../client/OrderConfirmationSingleModal';
import { UPLOADCSV, USERWEBSITE, fetch_get, fetch_post, Pagination, CREATE_WEBSITE, ORDER, WEBSITE, Alert, Alert_model } from '../common';

let nicheList = [];
let options = [];
let item = {};
// let carryProps={}
export default class Websites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      categories: [],
      nicheCategories: [],
      selectedNicheCategories: [],
      // countries:[],
      // selectedCountries:[],
      protocol: '',
      dalow: '',
      dahigh: '',
      palow: '',
      pahigh: '',
      urlow: '',
      urhigh: '',
      drlow: '',
      drhigh: '',
      ahref_low: '',
      ahref_high: '',
      domain_age: '',
      language: '',
      languages: [],
      // alexa_speed:'',
      maoz_spam_score: '',
      selectedCategories: [],
      errors: {},
      activeWebsites: [],
      inActiveWebsites: [],
      deleteId: null,
      activecurrentPage: this.props.location.state ? this.props.location.state.detail.currentPage : 1,
      activenumOfPages: 0,
      inActivecurrentPage: this.props.location.state ? this.props.location.state.detail.inActivecurrentPage : 1,
      inActivenumOfPages: 0,
      vendors: [],
      selectedVendors: [],
      websiteSelectedVendors: '',
      timeStamp: '',
      vendorsList: [],
      searchText: '',
      superuser: localStorage.getItem('SuperUser'),
      createErrors: {},
      categoryChoices: [],
      nicheText: '',
      nicheDropDown: false,
      active: true,
      websitesIds: this.props.location.state ? this.props.location.state.websiteids : [],
      filter: false,
      advancedFilters: false,
      clientsList: [],
      pricelow: '',
      pricehigh: '',
      // alexalow:'',
      // alexahigh:'',
      clients_list: [],
      client_id: '',
      price: this.props.location.state ? this.props.location.state.price : [],
      domain: [],
      ordersList: this.props.location.state ? this.props.location.state.ordersList : [],
      ordersList1: [],
      singlewebsite: false,
      restore: false,
      websiteselected: false,
      numberofrows: 100,
      idtoggle: false,
      svalue: '',
      domaintoggle: false,
      accesstoggle: false,
      // alexatoggle:false,
      datoggle: false,
      patoggle: false,
      drtoggle: false,
      urtoggle: false,
      adtypetoggle: false,
      linktypetoggle: false,
      pricetoggle: false,
      sellpricetoggle: false,
      ordertoggle: false,
      count: 0,
      traffictoggle: false,
      organic_traffic_low: '',
      organic_traffic_high: '',
      openMenu: false,
      categoriesFilterOpenMenu: false,
      nicheCategoriesFilterOpenMenu: false,
      create_link: false,
      organic_traffic: '',
      organic_country: '',
      filtered_data: {},
      organicPositionToggle: false,
      Organic_keywords: '',
      min_organic_position: '',
      max_organic_position: '',
      promoWebsite: false,
      exportCsv: false
    }
    this.websitesList = this.websitesList.bind(this);
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uncheckwebsites = this.uncheckwebsites.bind(this);
  }

  componentDidMount() {
    this.websitesList(1)
    // this.getVendors()
    this.websiteGet()
    this.getIabCategoriesData()
    if (this.props.location.state) {
      this.setState({
        filter: this.props.location.state.detail.filter,
        selectedNicheCategories: this.props.location.state.detail.selectedNicheCategories,
        activecurrentPage: this.props.location.state.detail.currentPage,
        inActivecurrentPage: this.props.location.state.detail.inActivecurrentPage,
        vendorId: this.props.location.state.detail.vendorId,
        pricelow: this.props.location.state.detail.pricelow,
        pricehigh: this.props.location.state.detail.pricehigh,
        selectedCategories: this.props.location.state.detail.selectedCategories,
        // selectedCountries:this.props.location.state.detail.selectedCountries,
        // alexahigh:this.props.location.state.detail.alexahigh,
        // alexalow:this.props.location.state.detail.alexalow,
        dalow: this.props.location.state.detail.dalow,
        dahigh: this.props.location.state.detail.dahigh,
        palow: this.props.location.state.detail.palow,
        pahigh: this.props.location.state.detail.pahigh,
        urlow: this.props.location.state.detail.urlow,
        urhigh: this.props.location.state.detail.urhigh,
        drlow: this.props.location.state.detail.drlow,
        drhigh: this.props.location.state.detail.drhigh,
        ahref_low: this.props.location.state.detail.ahref_low,
        ahref_high: this.props.location.state.detail.ahref_high,
        domain_age: this.props.location.state.detail.domain_age,
        language: this.props.location.state.detail.language,
        // alexa_speed:this.props.location.state.detail.alexa_speed,
        moz_spam_score: this.props.location.state.detail.moz_spam_score,
        disclosureTag: this.props.location.state.detail.disclosureTag,
        writingIncluded: this.props.location.state.detail.writingIncluded,
        permanentArticle: this.props.location.state.detail.permanentArticle,
        prepaid: this.props.location.state.detail.prepaid,
        examplePost: this.props.location.state.detail.examplePost,
        tat: this.props.location.state.detail.tat,
        specialNotes: this.props.location.state.detail.specialNotes,
        adType: this.props.location.state.detail.adType,
        minWord: this.props.location.state.detail.minWord,
        maxlinks: this.props.location.state.detail.maxlinks,
        linksType: this.props.location.state.detail.linksType,
        acceptGambling: this.props.location.state.detail.accept_gambling,
        acceptThc: this.props.location.state.detail.acceptThc,
        acceptAdultContent: this.props.location.state.detail.acceptAdultContent,
        acceptLinkInsertion: this.props.location.state.detail.acceptLinkInsertion,
        timestamp: this.props.location.state.detail.timestamp,
        searchText: this.props.location.state.detail.searchText,
        websiteSelectedVendors: this.props.location.state.detail.websiteSelectedVendors,
        protocol: this.props.location.state.detail.protocol,
        organic_traffic_low: this.props.location.state.detail.organic_traffic_low,
        organic_traffic_high: this.props.location.state.detail.organic_traffic_high,
        max_organic_position: this.props.location.state.detail.max_organic_position,
        min_organic_position: this.props.location.state.detail.min_organic_position,
        numberofrows: this.props.location.state.detail.numberofrows,
      }, this.websitesFilter)
    }
  }

  uncheckwebsites() {
    let model = document.getElementById("closeModel");
    model.click();
    this.setState({ ordersList: [], ordersList1: [], websitesIds: [], price: [], singlewebsite: false })
  }

  removeErrors() {
    this.setState({ errors: {}, client_id: '', websitesIds: [], ordersList: [], create_link: false, })
  }

  changeStatus(id) {
    fetch_get(`${WEBSITE}${id}/status/`)
      .then((response) => {
        if (response && !response.error) {
          // alert("Website restored successfully")
          this.setState({ restore: true })
          this.websitesList()
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  // getVendors() {
  //   fetch_get( UPLOADCSV)
  //   .then((response) => {
  //     if(!response.error) {
  //       let vendors = []
  //       response.vendors_list.map((vendor) => 
  //         vendors.push({ label: vendor.username, value: vendor.id })
  //       )
  //       this.setState({ vendorsList: vendors },this.websitesFilter)
  //     } 
  //   })
  //   .catch((error) => {
  //   });
  // }

  uploadCSV() {
    const data = new FormData();
    data.append('upload_csv', this.state.files[0]);
    let vendors = [];
    this.state.selectedVendors.length > 0 && this.state.selectedVendors.map((vendor) =>
      vendors.push(vendor.value)
    )
    if (vendors.length > 0) {
      data.append('vendors_list', JSON.stringify(vendors));
    }
    fetch_post(UPLOADCSV, data)
      .then((response) => {
        if (response && !response.error) {
          alert(response.message)
          this.setState({ files: [], selectedVendors: [], errors: {} })
          this.websitesList()
        } else {
          if (response.errors) {
            this.setState({ errors: response.errors })
          } else if (response.error_message) {
            this.setState({ errors: { vendor: response.error_message } })
          }
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  handleChange(date) {
    this.setState({ timeStamp: date }, this.websitesFilter);
  }

  websitesList(page1 = this.state.activecurrentPage, page2 = this.state.inActivecurrentPage) {
    fetch_get(`${USERWEBSITE}s/list/?page1=${page1}&page2=${page2}&num_pages=${this.state.numberofrows}`)
      .then((response) => {
        // console.log("weblistres",response)
        // let countries=[]
        // response.alexa_countries.map((country,i)=>{
        //   countries.push({ label: country, value:i })
        // })       

        // let categories=[]
        // response.categories.map((category)=>{
        //   categories.push({ label: category.categories__name, value: category.categories__id })
        // })

        // let nicheCategories=[]
        // response.niche_categories.map((category)=>{
        //   nicheCategories.push({ label: category.niche__name, value: category.niche__id })
        // })

        if (response && !response.error) {
          let clients = []
          response.clients_serializer.map((client) =>
            clients.push({ label: client.username, value: client.id })
          )
          this.setState({
            activeWebsites: response.active_user_websites,
            inActiveWebsites: response.inactive_user_websites,
            activenumOfPages: response.active_num_pages,
            inActivenumOfPages: response.inactive_num_pages,
            clientsList: response.clients_serializer,
            clients_list: clients,
            // categories:categories,
            // countries:countries,
            // nicheCategories:nicheCategories,
            languages: response.languages,
            // activecurrentPage: response.page1,
            // inActivecurrentPage: response.page2,
            deleteId: null,
          })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  // rows_count(){
  //   // let formData = new FormData()
  //   // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   // formData.append('page1',this.state.activecurrentPage)
  //   // formData.append('page2',this.state.inactivecurrentPage)    
  //   // fetch_post(`${USERWEBSITE}s/list/`,formData)
  //   // .then((response) => {
  //   //   if(!response.error){
  //   //     this.setState({
  //   //       activeWebsites: response.active_user_websites, 
  //   //       inActiveWebsites: response.inactive_user_websites,
  //   //       activenumOfPages: response.active_num_pages,
  //   //       inActivenumOfPages: response.inactive_num_pages,
  //   //       deleteId: null,
  //   //     })                   
  //   //   }
  //   // })
  //   this.websitesFilter()
  // }  

  websitesFilter(page1 = this.state.activecurrentPage, page2 = this.state.inActivecurrentPage, searchText = this.state.searchText) {
    // let check4 = this.refs['check4'].checked ? 'True' : 'False';
    let formData = new FormData()
    let categories = [];
    // let countries = [];
    this.state.selectedCategories.length > 0 && this.state.selectedCategories.map((category) =>
      categories.push(category.value)
    )
    if (categories.length > 0) {
      formData.append('categories', JSON.stringify(categories));
    }
    // this.state.selectedCountries.length > 0 && this.state.selectedCountries.map((country) => 
    //   countries.push(country.label)
    // )
    // if(countries.length > 0) {
    //   formData.append('alexa_country',JSON.stringify(countries));
    // }       
    this.state.searchText && formData.append('domain', searchText)
    this.state.websiteSelectedVendors && formData.append('vendor_id', this.state.websiteSelectedVendors.value)
    this.state.pricelow && formData.append('min_price', this.state.pricelow)
    this.state.pricehigh && formData.append('max_price', this.state.pricehigh)
    this.state.organic_traffic_low && formData.append('min_organic_traffic', this.state.organic_traffic_low)
    this.state.organic_traffic_high && formData.append('max_organic_traffic', this.state.organic_traffic_high)
    this.state.min_organic_position && formData.append('min_organic_position', this.state.min_organic_position)
    this.state.max_organic_position && formData.append('max_organic_position', this.state.max_organic_position)
    this.state.organic_country && formData.append('organic_country', this.state.organic_country)
    this.state.dalow && formData.append('min_moz_da', this.state.dalow)
    this.state.dahigh && formData.append('max_moz_da', this.state.dahigh)
    this.state.palow && formData.append('min_moz_pa', this.state.palow)
    this.state.pahigh && formData.append('max_moz_pa', this.state.pahigh)
    this.state.urlow && formData.append('min_ur', this.state.urlow)
    this.state.urhigh && formData.append('max_ur', this.state.urhigh)
    this.state.drlow && formData.append('min_dr', this.state.drlow)
    this.state.drhigh && formData.append('max_dr', this.state.drhigh)
    // this.state.alexalow && formData.append('min_alexa_rank', this.state.alexalow)
    // this.state.alexahigh && formData.append('max_alexa_rank', this.state.alexahigh)
    this.state.ahref_low && formData.append('min_ahrefs_rank', this.state.ahref_low)
    this.state.ahref_high && formData.append('max_ahrefs_rank', this.state.ahref_high)
    this.state.domain_age && formData.append('domain_age', this.state.domain_age)
    this.state.language && formData.append('language', this.state.language)
    // this.state.alexa_speed && formData.append('alexa_speed', this.state.alexa_speed) 
    this.state.moz_spam_score && formData.append('moz_spam_score', this.state.moz_spam_score)
    this.state.protocol && formData.append('protocols', 'https')
    this.state.disclosureTag && formData.append('disclosure_tag', this.state.disclosureTag)
    this.state.writingIncluded && formData.append('writing_included', this.state.writingIncluded)
    this.state.permanentArticle && formData.append('permanent_article', this.state.permanentArticle)
    this.state.prepaid && formData.append('prepaid', this.state.prepaid)
    this.state.examplePost && formData.append('example_post', this.state.examplePost)
    this.state.tat && formData.append('tat', this.state.tat)
    this.state.specialNotes && formData.append('special_notes', this.state.specialNotes)
    this.state.adType && formData.append('ad_type', this.state.adType)
    this.state.minWord && formData.append('minimum_word_count', this.state.minWord)
    this.state.maxlinks && formData.append('maximum_number_of_links_in_post', this.state.maxlinks)
    this.state.linksType && formData.append('links_type', this.state.linksType)
    this.state.acceptGambling && formData.append('accept_gambling', this.state.acceptGambling)
    this.state.acceptThc && formData.append('accept_thc', this.state.acceptThc)
    this.state.acceptAdultContent && formData.append('accept_adult', this.state.acceptAdultContent)
    this.state.acceptLinkInsertion && formData.append('accept_link_insertion_in_existing_post', this.state.acceptLinkInsertion)
    this.state.timestamp && formData.append('timestamp', moment(this.state.timestamp).format('YYYY-MM-DD'))
    this.state.numberofrows && formData.append('custom_num_pages', this.state.numberofrows)
    this.state.promoWebsite && formData.append('promo_price', 'promo_price')
    this.state.exportCsv && formData.append('export_to_csv', this.state.exportCsv)
    let nicheCategories = [];
    this.state.selectedNicheCategories.length > 0 && this.state.selectedNicheCategories.map((category) =>
      nicheCategories.push(category.value)
    )
    if (nicheCategories.length > 0) {
      formData.append('niche_category', JSON.stringify(nicheCategories));
    }
    formData.append('page1', page1)
    formData.append('page2', page2)
    if (this.state.svalue == 'id') {
      if (this.state.idtoggle) {
        formData.append('column_name_order', 'id')
      }
      else {
        formData.append('column_name_order', '-id')
      }
    }
    if (this.state.svalue == 'num_order') {
      if (this.state.ordertoggle) {
        formData.append('column_name_order', 'meta_details__order_count')
      }
      else {
        formData.append('column_name_order', '-meta_details__order_count')
      }
    }
    if (this.state.svalue == 'domain') {
      if (this.state.domaintoggle) {
        formData.append('column_name_order', 'website__domain')
      }
      else {
        formData.append('column_name_order', '-website__domain')
      }
    }
    if (this.state.svalue == 'access') {
      if (this.state.accesstoggle) {
        formData.append('column_name_order', 'user__username')
      }
      else {
        formData.append('column_name_order', '-user__username')
      }
    }
    // if(this.state.svalue == 'alexa'){
    //   if(this.state.alexatoggle){
    //     formData.append('column_name_order','website__alexa_rank')
    //   }
    //   else{
    //     formData.append('column_name_order','-website__alexa_rank')
    //   }
    // }
    if (this.state.svalue == 'da') {
      if (this.state.datoggle) {
        formData.append('column_name_order', 'website__moz_da')
      }
      else {
        formData.append('column_name_order', '-website__moz_da')
      }
    }
    if (this.state.svalue == 'pa') {
      if (this.state.patoggle) {
        formData.append('column_name_order', 'website__moz_pa')
      }
      else {
        formData.append('column_name_order', '-website__moz_pa')
      }
    }
    if (this.state.svalue == 'dr') {
      if (this.state.drtoggle) {
        formData.append('column_name_order', 'website__ahrefs_domain_rating_dr')
      }
      else {
        formData.append('column_name_order', '-website__ahrefs_domain_rating_dr')
      }
    }
    if (this.state.svalue == 'ur') {
      if (this.state.urtoggle) {
        formData.append('column_name_order', 'website__ahrefs_url_rating_ur')
      }
      else {
        formData.append('column_name_order', '-website__ahrefs_url_rating_ur')
      }
    }
    if (this.state.svalue == 'adtype') {
      if (this.state.adtypetoggle) {
        formData.append('column_name_order', 'ad_type')
      }
      else {
        formData.append('column_name_order', '-ad_type')
      }
    }
    if (this.state.svalue == 'links_type') {
      if (this.state.linktypetoggle) {
        formData.append('column_name_order', 'links_type')
      }
      else {
        formData.append('column_name_order', '-links_type')
      }
    }
    if (this.state.svalue == 'price') {
      if (this.state.pricetoggle) {
        formData.append('column_name_order', 'price')
      }
      else {
        formData.append('column_name_order', '-price')
      }
    }
    if (this.state.svalue == 'sellprice') {
      if (this.state.sellpricetoggle) {
        formData.append('column_name_order', 'price')
      }
      else {
        formData.append('column_name_order', '-price')
      }
    }
    if (this.state.svalue == 'organic') {
      if (this.state.traffictoggle) {
        formData.append('column_name_order', 'website__organic_traffic')
      }
      else {
        formData.append('column_name_order', '-website__organic_traffic')
      }
    }
    if (this.state.svalue == 'organicPosition') {
      if (this.state.organicPositionToggle) {
        formData.append('column_name_order', 'website__organic_keywords')
      }
      else {
        formData.append('column_name_order', '-website__organic_keywords')
      }
    }
    fetch_post(`${USERWEBSITE}s/list/ `, formData)
      .then((response) => {
        if (response && response.csv_data) {
          this.exportToCSV(response && response.csv_data)
        }
        this.setState({
          activeWebsites: response.active_user_websites,
          inActiveWebsites: response.inactive_user_websites,
          activenumOfPages: response.active_num_pages,
          inActivenumOfPages: response.inactive_num_pages,
          // activecurrentPage: response.page1,
          // inActivecurrentPage: response.page2,
          deleteId: null,
        }, window.scroll(0, 0))
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  convertToCSV(data) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));

    for (const row of data) {
      const values = headers.map(function (header) {
        return row[header];
      });
      csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
  }
  formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

    return `${formattedHours}-${formattedMinutes} ${formattedDate}`;
  }
  exportToCSV(res) {
    const apiResponse = res
    const csvData = this.convertToCSV(apiResponse);

    const timestamp = new Date().toISOString();
    const formattedTimestamp = this.formatTimestamp(timestamp);
    const filename = `exportedData_${formattedTimestamp}.csv`;
    
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ exportCsv: false })

  }

  clearFilter() {
    this.getIabCategoriesData()
    this.setState({
      vendorId: '',
      selectedNicheCategories: [],
      pricelow: '',
      pricehigh: '',
      selectedCategories: '',
      // selectedCountries:'',
      // alexahigh:'',
      // alexalow:'',
      dalow: '',
      dahigh: '',
      palow: '',
      pahigh: '',
      urlow: '',
      urhigh: '',
      drlow: '',
      drhigh: '',
      ahref_low: '',
      ahref_high: '',
      domain_age: '',
      language: '',
      // alexa_speed:'',
      moz_spam_score: '',
      disclosureTag: '',
      writingIncluded: '',
      permanentArticle: '',
      prepaid: '',
      examplePost: '',
      tat: '',
      specialNotes: '',
      adType: '',
      minWord: '',
      maxlinks: '',
      linksType: '',
      acceptGambling: '',
      acceptThc: '',
      acceptAdultContent: '',
      acceptLinkInsertion: '',
      timestamp: '',
      searchText: '',
      websiteSelectedVendors: '',
      protocol: false,
      organic_traffic_high: '',
      organic_traffic_low: '',
      min_organic_position: '',
      max_organic_position: '',
      organic_country: '',
      promoWebsite: false,
      filtered_data: {},
      exportCsv: false
    }, this.websitesFilter)
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page1',currentPage)
    // formData.append('page2',currentPage)
    // fetch_post(`${USERWEBSITE}s/list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //     activeWebsites: response.active_user_websites, 
    //     inActiveWebsites: response.inactive_user_websites,
    //     activenumOfPages: response.active_num_pages,
    //     inActivenumOfPages: response.inactive_num_pages,
    //     deleteId: null,
    //     })                   
    //   }
    // })    
    if (this.state.active) {
      this.setState({ activecurrentPage: currentPage }, this.websitesFilter)
    } else {
      this.setState({ inActivecurrentPage: currentPage }, this.websitesFilter)
    }
  }

  search(searchText) {
    this.setState({ searchText }, this.websitesFilter(this.state.currentPage, searchText))
  }

  createWebsite() {
    const { websiteDetails, selectedUsers, nicheText } = this.state;
    let formData = new FormData()
    formData.append('domain', this.refs['cdomain'].value)
    formData.append('accept_link_insertion_in_existing_post', this.refs['ccheck5'].checked ? 'True' : 'False')
    formData.append('accept_gambling', this.refs['ccheck3'].checked ? 'True' : 'False')
    formData.append('active', this.refs['ccheck1'].checked ? 'True' : 'False')
    formData.append('accept_thc', this.refs['ccheck6'].checked ? 'True' : 'False')
    formData.append('accept_weight_loss', this.refs['ccheck4'].checked ? 'True' : 'False')
    formData.append('language', this.refs['clanguage'].value)
    formData.append('links_type', this.refs['clinkType'].value)
    formData.append('protocols', this.refs['protocols'].value)
    formData.append('categories', this.state.nicheText)
    let users = []
    this.state.websiteSelectedVendors.length > 0 && this.state.websiteSelectedVendors.map((user) =>
      users.push(user.value)
    )
    if (users.length > 0) {
      formData.append('assigned_vendors_list', JSON.stringify(users))
    }
    fetch_post(`${CREATE_WEBSITE}`, formData)
      .then((response) => {
        if (response && !response.error) {
          this.websitesList()
          alert('website created successfully')
          let model = document.getElementById("createModel");
          model.click();
        } else {
          this.setState({ createErrors: response.errors })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  websiteGet() {
    fetch_get(`${CREATE_WEBSITE}`)
      .then((response) => {
        nicheList = response.niche_choices;
        this.setState({ categoryChoices: response.niche_choices })
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  updateWebsitesList(websiteId, domain, price, originalprice, ad_type, index) {
    let orderData = {}
    orderData['websiteId'] = websiteId
    orderData['domain'] = domain
    orderData['price'] = price
    orderData['originalprice'] = originalprice
    orderData['ad_type'] = ad_type
    orderData['total'] = price
    if (this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      this.state.websitesIds.splice(indexx, 1);
      this.setState({ websitesIds: this.state.websitesIds })
      this.state.price.splice(indexx, 1);
      this.setState({
        ordersList: [...this.state.ordersList.filter((data) => {
          if (data.websiteId != websiteId) {
            return data
          }
        })]
      })
      this.setState({ websitesIds: this.state.websitesIds })
    } else {
      this.setState({ websitesIds: [...this.state.websitesIds, parseInt(websiteId)], price: [...this.state.price, price], ordersList: [...this.state.ordersList, orderData] })
    }
  }

  onSearch(e) {
    let value = e.target.value
    this.setState({ nicheText: value });
    let filteredValues = nicheList.filter(function (niche) {
      return (niche[0].toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ categoryChoices: filteredValues, nicheDropDown: (filteredValues.length) > 0 ? true : false })
  }

  updateSingleWebsite(websiteId, domain, price, originalprice, ad_type) {
    let orderData1 = {}
    orderData1['websiteId'] = websiteId
    orderData1['domain'] = domain
    orderData1['price'] = price
    orderData1['originalprice'] = originalprice
    orderData1['ad_type'] = ad_type
    orderData1['total'] = price
    if (this.state.websitesIds.includes(parseInt(websiteId))) {
      let indexx = this.state.websitesIds.indexOf(parseInt(websiteId))
      this.state.websitesIds.splice(indexx, 1);
      this.state.ordersList1.splice(indexx, 1);
      this.setState({ ordersList1: this.state.ordersList1 })
      this.setState({
        ordersList: [...this.state.ordersList.filter((data) => {
          if (data.websiteId == websiteId) {
            return data
          }
        })]
      })
    }
    else {
      this.setState({ websitesIds: [...this.state.websitesIds, parseInt(websiteId)], price: [...this.state.price, price], ordersList1: [...this.state.ordersList1, orderData1], singlewebsite: true })
    }
    if (websiteId) {
      this.setState({ websiteselected: false })
      let model = document.getElementById("orderModel");
      model.click();
    } else {
      alert('please select atleast one website to create order')
    }
  }


  checkOrder(websiteId = null, price = null) {
    if (this.state.websitesIds.length > 0) {
      this.setState({ websiteselected: false })
      let model = document.getElementById("orderModel");
      model.click();
    } else {
      this.setState({ websiteselected: true })
      let alert = document.getElementById("success");
      alert.click();
      // alert('please select atleast one website to create order')
    }
  }

  createOrder() {
    let prices = []
    let websites = []
    let allWebsites = this.state.active ? this.state.activeWebsites : this.state.inActiveWebsites
    for (let website in allWebsites) {
      if (this.state.websitesIds.includes(allWebsites[website].id)) {
        prices.push(parseInt(allWebsites[website].sell_price))
      }
    }
    let data = new FormData();
    data.append('websites', JSON.stringify(this.state.websitesIds))
    data.append('prices', JSON.stringify(prices))
    this.state.client_id.value && data.append('client_id', this.state.client_id.value)
    fetch_post(`${ORDER}/create/ `, data)
      .then((response) => {
        if (response.error) {
          this.setState({ errors: response.errors })
        } else {
          alert(response.message)
          let model = document.getElementById("closeModel");
          model.click();
          this.setState({ errors: {}, websitesIds: [], client_id: '' })
          // this.props.history.push('/websites/orders/create', { id: response.order_id })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  getVendorsData(vendor) {
    let formData = new FormData()
    formData.append('username', vendor)
    fetch_post('/api/get_vendors_data/', formData)
      .then((response) => {
        if (response && !response.error) {
          let vendors = [];
          response.vendors_data.map((vendor) =>
            vendors.push({ label: vendor.username, value: vendor.id })
          )
          this.setState({ vendorsList: vendors })

        }
      })
  }

  rendorWebsites() {
    let carryProps = {
      filter: this.state.filter,
      selectedNicheCategories: this.state.selectedNicheCategories,
      currentPage: this.state.activecurrentPage,
      inActivecurrentPage: this.state.inActivecurrentPage,
      vendorId: this.state.vendorId,
      pricelow: this.state.pricelow,
      pricehigh: this.state.pricehigh,
      selectedCategories: this.state.selectedCategories,
      // selectedCountries:this.state.selectedCountries,
      // alexahigh:this.state.alexahigh,
      // alexalow:this.state.alexalow,
      dalow: this.state.dalow,
      dahigh: this.state.dahigh,
      palow: this.state.palow,
      pahigh: this.state.pahigh,
      urlow: this.state.urlow,
      urhigh: this.state.urhigh,
      drlow: this.state.drlow,
      drhigh: this.state.drhigh,
      ahref_low: this.state.ahref_low,
      ahref_high: this.state.ahref_high,
      domain_age: this.state.domain_age,
      language: this.state.language,
      // alexa_speed:this.state.alexa_speed,
      moz_spam_score: this.state.moz_spam_score,
      disclosureTag: this.state.disclosureTag,
      writingIncluded: this.state.writingIncluded,
      permanentArticle: this.state.permanentArticle,
      prepaid: this.state.prepaid,
      examplePost: this.state.examplePost,
      tat: this.state.tat,
      specialNotes: this.state.specialNotes,
      adType: this.state.adType,
      minWord: this.state.minWord,
      maxlinks: this.state.maxlinks,
      linksType: this.state.linksType,
      acceptGambling: this.state.acceptGambling,
      acceptThc: this.state.acceptThc,
      acceptAdultContent: this.state.acceptAdultContent,
      acceptLinkInsertion: this.state.acceptLinkInsertion,
      timestamp: this.state.timestamp,
      searchText: this.state.searchText,
      websiteSelectedVendors: this.state.websiteSelectedVendors,
      organic_traffic_low: this.state.organic_traffic_low,
      organic_traffic_high: this.state.organic_traffic_high,
      min_organic_position: this.state.min_organic_position,
      max_organic_position: this.state.max_organic_position,
      numberofrows: this.state.numberofrows,
      protocol: false,
      promoWebsite: false,
      exportCsv: false
    }
    if (this.state.activeWebsites && this.state.inActiveWebsites) {
      let websites = this.state.active ? this.state.activeWebsites : this.state.inActiveWebsites
      let page = this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage
      return (
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th width="5%"></th>
              <th width="9%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ idtoggle: !this.state.idtoggle, svalue: 'id' }, this.websitesFilter)}>ID <i className="fas fa-sort"></i></th>
              <th width="27%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ domaintoggle: !this.state.domaintoggle, svalue: 'domain' }, this.websitesFilter)}>Domain <i className="fas fa-sort"></i></th>
              <th width="17%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ accesstoggle: !this.state.accesstoggle, svalue: 'access' }, this.websitesFilter)}>Access to <i className="fas fa-sort"></i></th>
              {/* <th width="17%" style={{cursor:'pointer'}} onClick={()=>this.setState({accesstoggle:!this.state.accesstoggle,svalue:'access'},this.websitesFilter)}>verify <i className="fas fa-sort"></i></th> */}
              {/* <th width="7%" style={{cursor:'pointer'}} onClick={()=>this.setState({alexatoggle:!this.state.alexatoggle,svalue:'alexa'},this.websitesFilter)}>ALEXA <i className="fas fa-sort"></i></th> */}
              <th width="3%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ datoggle: !this.state.datoggle, svalue: 'da' }, this.websitesFilter)}>DA <i className="fas fa-sort"></i></th>
              <th width="3%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ patoggle: !this.state.patoggle, svalue: 'pa' }, this.websitesFilter)}>PA <i className="fas fa-sort"></i></th>
              <th width="3%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ drtoggle: !this.state.drtoggle, svalue: 'dr' }, this.websitesFilter)}>DR <i className="fas fa-sort"></i></th>
              <th width="4%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ urtoggle: !this.state.urtoggle, svalue: 'ur' }, this.websitesFilter)}>UR <i className="fas fa-sort"></i></th>
              <th width="13%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ adtypetoggle: !this.state.adtypetoggle, svalue: 'adtype' }, this.websitesFilter)}>Ad Type <i className="fas fa-sort"></i></th>
              <th width="13%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ linktypetoggle: !this.state.linktypetoggle, svalue: 'links_type' }, this.websitesFilter)}>Link Type <i className="fas fa-sort"></i></th>
              <th width="5%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ pricetoggle: !this.state.pricetoggle, svalue: 'price' }, this.websitesFilter)}>Price <i className="fas fa-sort"></i></th>
              <th width="7%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ sellpricetoggle: !this.state.sellpricetoggle, svalue: 'sellprice' }, this.websitesFilter)}>sell Price <i className="fas fa-sort"></i></th>
              <th width="7%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ ordertoggle: !this.state.ordertoggle, svalue: 'num_order' }, this.websitesFilter)}>orders <i className="fas fa-sort"></i></th>
              <th width="7%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ traffictoggle: !this.state.traffictoggle, svalue: 'organic' }, this.websitesFilter)}>traffic <i className="fas fa-sort"></i></th>
              <th width="7%" style={{ cursor: 'pointer' }} onClick={() => this.setState({ organicPositionToggle: !this.state.organicPositionToggle, svalue: 'organicPosition' }, this.websitesFilter)}>Keywords<i className="fas fa-sort"></i></th>
              <th width="8%">Actions</th>
              <th width="10%"></th>
            </tr>
          </thead>
          <tbody>
            {websites.length > 0 ? websites.map((website, index) =>
            (website.website &&
              <tr style={{ cursor: 'pointer' }} key={index}>
                <td>
                  <div className="custom-control custom-control-alternative custom-checkbox p-0">
                    <input
                      className="custom-control-input"
                      id={website.id}
                      type="checkbox"
                      value={website.id}
                      checked={this.state.websitesIds.includes(website.id)}
                      onChange={(e) => this.updateWebsitesList(e.target.value, website.website.domain, website.promo_price > 0 ? website.promo_price : website.sell_price, website.price, website.ad_type, index)}
                    />
                    <label className="custom-control-label" htmlFor={website.id}>
                      <span className="text-muted">&nbsp;</span>
                    </label>
                  </div>
                </td>
                {
                  this.props.location.state && document.getElementById(website.id) ? document.getElementById(website.id).checked ? () => { this.updateWebsitesList(website.id, website.website.domain, website.price.calculated_price, website.price.original_price, website.ad_type, index) } : null : null}
                <th scope="row" onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.id}</th>
                <td className="overlap" onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.domain}</td>
                <td className="" onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}><div>{website.user.username} {website.is_vendor_verified === 'No' ? '' : <span><i className="fas fa-check"></i></span>}</div></td>
                {/* <td onClick={() => this.props.history.push({pathname:`/admin/websites/${website.id}/details`,state:{detail:carryProps,websiteids:this.state.websitesIds,prices:this.state.price,ordersList:this.state.ordersList}})}>{website.website.alexa_rank}</td> */}
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.moz_da}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.moz_pa}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.ahrefs_domain_rating_dr}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.ahrefs_url_rating_ur}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.ad_type}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.links_type}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.price}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-around' }}><span style={{ textDecoration: website.promo_price > 0 && 'line-through' }}>${website.sell_price}</span>{website.promo_price > 0 ? <span style={{ fontSize: '14px' }}>${website.promo_price}</span> : ''}</div></td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.meta_details.order_count}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.organic_traffic}</td>
                <td onClick={() => this.props.history.push({ pathname: `/admin/websites/${website.id}/details`, state: { detail: carryProps, websiteids: this.state.websitesIds, prices: this.state.price, ordersList: this.state.ordersList } })}>{website.website.organic_keywords}</td>
                {this.state.active ?
                  <td>
                    <Link to={{ pathname: `/admin/websites/${website.id}/edit` }}>
                      <span style={{ paddingRight: 20 }}><i style={{ fontSize: 17 }} className="fa fa-edit"></i></span>
                    </Link>
                    <DeleteUser userRole="website" component='website' id={this.state.deleteId} updateList={this.websitesList} />
                    <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: website.id, restore: false })}>
                      <i style={{ fontSize: 17, color: 'red' }} className="fa fa-trash"></i>
                    </span>
                  </td> :
                  <td>
                    <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(website.id)}>
                      <span style={{ paddingLeft: 20 }}><i className="fa fa-sync"></i></span>
                    </a>
                  </td>
                }
                {this.state.active ?
                  <td><button type="button" className="btn btn-success btn-sm" onClick={() => this.setState({ websitesIds: [website.id] }, this.updateSingleWebsite(website.id, website.website.domain, website.promo_price > 0 ? website.promo_price : website.sell_price, website.price, website.ad_type))}>Order</button></td>

                  : null}
              </tr>
            )
            )
              :
              <tr>
                <th scope="row">No Websites found</th>
              </tr>
            }
          </tbody>
        </table>
      )
    }
  }

  getCategoriesData(category) {
    let formData = new FormData()
    formData.append('name', category)
    fetch_post('/api/get_websites_category_data/', formData)
      .then((response) => {
        if (response && !response.error) {
          let categories = []
          response.categories.map((category) => {
            categories.push({ label: category.categories__name, value: category.categories__id })
          })
          this.setState({ categories: categories })
        }
      })

  }

  getIabCategoriesData() {
    let parent = ''
    fetch_get('/api/niche-category/list/')
      .then((response) => {
        if (response && !response.error) {
          let niche_categories = []
          response.categories_list.map((category) => {
            if (category.parent == null) {
              parent = category.name
              options = []
              item = {}
            }
            if (category.parent == parent) {
              options.push({ label: category.name, value: category.id, class: category.parent })
            }
            else {
              item = {
                label: <a onClick={() => { this.onOptionsClick(category, response.categories_list) }}><h6 style={{ color: 'black', cursor: 'pointer' }}>{category.name}</h6></a>,
                options: options
              }
              niche_categories.push(item)
            }
          })
          this.setState({ nicheCategories: niche_categories })
        }
      })

  }

  onOptionsClick(category, categoryList) {
    categoryList.map((obj) => {
      if (category.name == obj.parent) {
        let found = this.state.selectedNicheCategories.some(sc => sc.label == obj.name)
        if (!found) {
          this.state.selectedNicheCategories.push({ label: obj.name, value: obj.id })
        }
      }
    })
    let nicheCategories = this.state.nicheCategories.filter((e, i) => {
      if (e.label.props.children.props.children !== category.name) {
        return e
      }
    })
    this.setState({ selectedNicheCategories: this.state.selectedNicheCategories, nicheCategories: nicheCategories })
  }

  createLink() {
    let i = 0;
    for (i in this.state.filtered_data) {
      if (this.state.filtered_data[i] == '' || this.state.filtered_data[i] == []) {
        delete this.state.filtered_data[i]
      }
    }
    let formData = new FormData()
    { this.state.client_id && formData.append('client_id', this.state.client_id.value) }
    formData.append('filtered_data', JSON.stringify(this.state.filtered_data))
    fetch_post('/api/link/assign/', formData)
      .then((response) => {
        if (response && !response.error) {
          let model = document.getElementById("closeModel");
          model.click();
          this.clearFilter();
        }
        else {
          this.setState({ errors: response.errors })
        }
      })
  }

  render() {
    return (
      <div className="main-content">
        <TopHeader search={this.search} isSearch={true} />
        <Alert_selfmodel msg={'order created successfully'} />
        {this.state.restore ? <Alert msg={"Website Restored Successfully"} /> : <Alert msg={"Website Deleted Successfully"} />}
        {this.state.websiteselected ? <Alert_model msg={'please select atleast one website to create order'} /> : <Alert_model msg={'order created successfully'} />}
        <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
        <div className="container-fluid mt--7">
          <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="modal-form" data-backdrop="static" data-keyboard="false" aria-hidden="true">
            <div className="modal-dialog col-md-12" role="document">
              <div className="col-lg-12">
                <div className="filter_result mt-5">
                  <h3 className="mb-4 col-md-12 text-center">Select Client</h3>
                  <form>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="form-group mar-bot-5">
                          <label className="form-control-label" htmlFor="input-email"> Clients <div className="error-text">*</div></label>
                          <Select
                            value={this.state.client_id}
                            placeholder="Select Client"
                            onChange={(e) => this.setState({ client_id: e })}
                            options={this.state.clients_list}
                          />
                          <div className="text-danger">
                            {this.state.errors.client && this.state.errors.client}
                            {this.state.errors.client_id && this.state.errors.client_id}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.create_link ?
                      <div className="row justify-content-center">
                        <button type="button" className="btn btn-info" onClick={() => this.createLink()}>Create Link</button>
                        <button type="button" className="btn btn-default" id="closeModel" data-dismiss="modal" onClick={() => this.removeErrors()} >Cancel</button>
                      </div> :

                      <div className="row justify-content-center">
                        {/*<button type="button" className="btn btn-info" onClick={() => this.createOrder()}>Create Order</button>*/}
                        {this.state.client_id ? this.state.singlewebsite ? <button type="button" className="btn btn-info" data-toggle="modal" data-target="#ConfirmationSingleModal" data-backdrop="static" data-keyboard="false" >Create Order</button> :
                          <button type="button" className="btn btn-info" data-toggle="modal" data-target="#ConfirmationModal" data-backdrop="static" data-keyboard="false" >Create Order</button> :
                          <button type="button" className="btn btn-info" onClick={() => this.createOrder()}>Create Order</button>}
                        <button type="button" className="btn btn-default" id="closeModel" data-dismiss="modal" onClick={() => this.removeErrors()} >Cancel</button>
                      </div>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row main_list_container">
            <div className="col-lg-12">
              <div className="col">
                <div className="card shadow website_detail_profile">
                  <div className="card-header row bg-white border-0">
                    <h3 className="mb-0 col-md-2 d-flex">show
                      <div>
                        <label className="custom-toggle mb-0">
                          <select
                            placeholder="show"
                            value={this.state.numberofrows}
                            onChange={(e) => this.setState({ numberofrows: e.target.value }, this.websitesFilter)}
                          >
                            <option value="">select</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </h3>
                    <div className="col-md-4">
                      <h3 className="mb-0 col-md-6 d-flex">Websites
                        <div className="mt-1">
                          <label className="custom-toggle mb-0 ml-3">
                            <input type="checkbox" />
                            <span className="custom-toggle-slider" onClick={() => this.setState({ active: !this.state.active })}> {this.state.active ? 'Active' : 'Inactive'} </span>
                          </label>
                        </div>
                      </h3>
                    </div>
                    <div className="col-md-6 website_filter">
                      <button type="button" className="btn btn-info" style={{ backgroundColor: "#17a2b8" }} onClick={() => this.checkOrder()}>Create Order</button>
                      <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                    </div>
                    {this.state.filter &&
                      <div className="col-lg-12 p-0">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="filter_result p-3">
                              <form>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Domain"
                                        name="domain"
                                        value={this.state.searchText}
                                        onChange={(e) => this.setState({ searchText: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group mar-bot-5">
                                      <select
                                        className="form-control"
                                        name="adType"
                                        onChange={(e) => this.setState({ adType: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.adType}
                                      >
                                        <option value="">Ad Type</option>
                                        <option value="Article">Article</option>
                                        <option value="Link insertion">Link insertion</option>
                                        <option value="Gambling Link">Gambling Link</option>
                                        <option value="CBD Marijuana Link">CBD Marijuana Link</option>
                                        <option value="Adult Link">Adult Link</option>
                                        <option value="Weight Loss Link">Weight Loss Link</option>
                                        <option value="Partners Page Link">Partners Page Link</option>
                                        <option value="Crypto">Crypto</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Price (Min)"
                                        name="pricemin"
                                        onChange={(e) => this.setState({ pricelow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.pricelow}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Price (Max)"
                                        name="pricemax"
                                        onChange={(e) => this.setState({ pricehigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.pricehigh}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group mar-bot-5">
                                      <Select
                                        placeholder="Select Vendor"
                                        value={this.state.websiteSelectedVendors}
                                        onChange={(e) => this.setState({ websiteSelectedVendors: e, openMenu: false }, this.websitesFilter)}
                                        onInputChange={(e) => {
                                          if (e) { this.setState({ openMenu: true }, this.getVendorsData(e)) }
                                          else {
                                            this.setState({ openMenu: false })
                                          }
                                        }}
                                        options={this.state.vendorsList}
                                        menuIsOpen={this.state.openMenu}
                                      />
                                      <div className="text-danger">
                                        {this.state.createErrors.vendors_list && this.state.createErrors.vendors_list}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group mar-bot-5">
                                      <select
                                        className="form-control"
                                        name="organic_country"
                                        onChange={(e) => this.setState({ organic_country: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.organic_country}
                                      >
                                        <option value="">Organic Traffic Country</option>
                                        <option value="United States">United States - us</option>
                                        <option value="Great Britain">Great Britain - gb</option>
                                        <option value="Australia">Australia - au</option>
                                        <option value="Canada">Canada - ca</option>
                                        <option value="Germany">Germany - de</option>
                                        <option value="India">India - in</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Organictraffic (Min)"
                                        name="organictrafficmin"
                                        onChange={(e) => this.setState({ organic_traffic_low: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.organic_traffic_low}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Organictraffic (Max)"
                                        name="organictrafficmax"
                                        onChange={(e) => this.setState({ organic_traffic_high: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.organic_traffic_high}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Keywords(Min)"
                                        name="organicPositionMin"
                                        onChange={(e) => this.setState({ min_organic_position: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.min_organic_position}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Keywords(Max)"
                                        name="organicPositionMax"
                                        onChange={(e) => this.setState({ max_organic_position: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                        value={this.state.max_organic_position}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <Select
                                        className="form-control"
                                        value={this.state.selectedNicheCategories}
                                        onChange={(e) => { if (e.length == 0) { this.getIabCategoriesData() }; this.setState({ selectedNicheCategories: e, filtered_data: { ...this.state.filtered_data, ['niche_categories']: e } }, this.websitesFilter) }}
                                        options={this.state.nicheCategories}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        // menuIsOpen={true}
                                        placeholder="IAB Categories"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <select
                                        className="form-control"
                                        value={this.state.language}
                                        name="language"
                                        onChange={(e) => this.setState({ language: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                      >
                                        <option value=""> Select Language </option>
                                        {this.state.languages.map((lang) =>
                                          <option value={lang}>{lang}</option>
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div style={{ cursor: 'pointer' }}>
                                    <div className="form-group">
                                      <span id="advanced" onClick={() => this.setState({ advancedFilters: !this.state.advancedFilters })}></span>
                                    </div>
                                  </div>
                                  {this.state.advancedFilters &&
                                    <Fragment>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <Select
                                            className="form-control"
                                            value={this.state.selectedCategories}
                                            onChange={(e) => this.setState({ selectedCategories: e, categoriesFilterOpenMenu: false }, this.websitesFilter)}
                                            onInputChange={(e) => {
                                              if (e) { this.setState({ categoriesFilterOpenMenu: true }, this.getCategoriesData(e)) }
                                              else {
                                                this.setState({ categoriesFilterOpenMenu: false })
                                              }
                                            }}
                                            options={this.state.categories}
                                            menuIsOpen={this.state.categoriesFilterOpenMenu}
                                            isMulti
                                            placeholder="Categories"
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-3">
                                    <div className="form-group">
                                      <Select 
                                        className="form-control"
                                        isMulti
                                        value={this.state.selectedCountries}
                                        onChange={(e) => this.setState({ selectedCountries: e}, this.websitesFilter)}
                                        options={this.state.countries}
                                        placeholder="countries"
                                      />
                                    </div>
                                  </div>                                   */}
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="moz_spam_score"
                                            onChange={(e) => this.setState({ moz_spam_score: e.target.value }, this.websitesFilter)}
                                            value={this.state.moz_spam_score}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Min)"
                                      name="alexamin"
                                      onChange={(e) => this.setState({ alexalow: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value} }, this.websitesFilter)}
                                      value={this.state.alexalow}
                                    />
                                  </div>
                                </div> */}
                                      {/* <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Max)"
                                      name="alexamax"
                                      onChange={(e) => this.setState({ alexahigh: e.target.value, filtered_data:{...this.state.filtered_data,[e.target.name]:e.target.value} }, this.websitesFilter)}
                                      value={this.state.alexahigh}
                                    />
                                  </div>
                                </div> */}
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="DA (Min)"
                                            name="damin"
                                            onChange={(e) => this.setState({ dalow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                            value={this.state.dalow}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="DA (Max)"
                                            onChange={(e) => this.setState({ dahigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                            name="damax"
                                            value={this.state.dahigh}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="PA (Min)"
                                            onChange={(e) => this.setState({ palow: e.target.value }, this.websitesFilter)}
                                            value={this.state.palow}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="PA (Max)"
                                            onChange={(e) => this.setState({ pahigh: e.target.value }, this.websitesFilter)}
                                            value={this.state.pahigh}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="AHREF (Min)"
                                            onChange={(e) => this.setState({ ahref_low: e.target.value }, this.websitesFilter)}
                                            value={this.state.ahref_low}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="AHREF (Max)"
                                            onChange={(e) => this.setState({ ahref_high: e.target.value }, this.websitesFilter)}
                                            value={this.state.ahref_high}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="UR (Min)"
                                            onChange={(e) => this.setState({ urlow: e.target.value }, this.websitesFilter)}
                                            value={this.state.urlow}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="UR (Max)"
                                            onChange={(e) => this.setState({ urhigh: e.target.value }, this.websitesFilter)}
                                            value={this.state.urhigh}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="DR (Min)"
                                            name="drmin"
                                            onChange={(e) => this.setState({ drlow: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                            value={this.state.drlow}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="email"
                                            className="form-control"
                                            placeholder="DR (Max)"
                                            name="drmax"
                                            onChange={(e) => this.setState({ drhigh: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                            value={this.state.drhigh}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group">
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="domain_age"
                                            onChange={(e) => this.setState({ domain_age: e.target.value }, this.websitesFilter)}
                                            value={this.state.domain_age}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="number" 
                                      className="form-control" 
                                      placeholder="alexa_speed"
                                      onChange={(e) => this.setState({ alexa_speed: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexa_speed}
                                    />
                                  </div>
                                </div>                                                                                                                                                                                                                                                                                                                                                                                                                                       */}
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label className="form-control-label" htmlFor="input-email"> Link Type </label>
                                          <select
                                            className="form-control"
                                            value={this.state.linksType}
                                            name="linksType"
                                            onChange={(e) => this.setState({ linksType: e.target.value, filtered_data: { ...this.state.filtered_data, [e.target.name]: e.target.value } }, this.websitesFilter)}
                                          >
                                            <option value="">Select</option>
                                            <option value="Dofollow">DoFollow</option>
                                            <option value="Nofollow">NoFollow</option>
                                            <option value="Sponsored">Sponsored</option>
                                            <option value="UGC">UGC</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group mb-0">
                                          <label className="form-control-label" htmlFor="input-email"> Min. Word Count </label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Minimum word count"
                                            onChange={(e) => this.setState({ minWord: e.target.value }, this.websitesFilter)}
                                            value={this.state.minWord}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group">
                                          <label className="form-control-label" htmlFor="input-email"> Max No of Links in Post</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Max number of links in post"
                                            onChange={(e) => this.setState({ maxlinks: e.target.value }, this.websitesFilter)}
                                            value={this.state.maxlinks}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mar-bot-5">
                                          <label className="form-control-label" htmlFor="input-email"> Accept Gambling </label>
                                          <select
                                            value={this.state.acceptGambling}
                                            onChange={(e) => this.setState({ acceptGambling: e.target.value }, this.websitesFilter)}
                                            className="form-control"
                                          >
                                            <option value="">select</option>
                                            <option value="True">True</option>
                                            <option value="False">False</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Accept THC </label>
                                            <select
                                              value={this.state.acceptThc}
                                              onChange={(e) => this.setState({ acceptThc: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Disclosure tag </label>
                                            <select
                                              value={this.state.disclosureTag}
                                              onChange={(e) => this.setState({ disclosureTag: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Adult Content </label>
                                            <select
                                              value={this.state.acceptAdultContent}
                                              onChange={(e) => this.setState({ acceptAdultContent: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Writing included </label>
                                            <select
                                              value={this.state.writingIncluded}
                                              onChange={(e) => this.setState({ writingIncluded: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Permanent article </label>
                                            <select
                                              value={this.state.permanentArticle}
                                              onChange={(e) => this.setState({ permanentArticle: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Prepaid </label>
                                            <select
                                              value={this.state.prepaid}
                                              onChange={(e) => this.setState({ prepaid: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> Link Insertion </label>
                                            <select
                                              value={this.state.acceptLinkInsertion}
                                              onChange={(e) => this.setState({ acceptLinkInsertion: e.target.value }, this.websitesFilter)}
                                              className="form-control"
                                            >
                                              <option value="">select</option>
                                              <option value="True">True</option>
                                              <option value="False">False</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2">
                                        <div className="form-group mb-1">
                                          <div className="form-group mar-bot-5">
                                            <label className="form-control-label" htmlFor="input-email"> HTTPS <input
                                              type="checkbox"
                                              id="http"
                                              checked={this.state.protocol}
                                              onChange={(e) => this.setState({ protocol: document.getElementById('http').checked }, this.websitesFilter)}
                                            /> </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-2 ml-4 mt-2">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id='promo_price'
                                          data-toggle="toggle"
                                          onChange={(e) => this.setState({ promoWebsite: document.getElementById('promo_price').checked }, this.websitesFilter)}
                                          checked={this.state.promoWebsite}
                                        />
                                        <label className="form-control-label" >
                                          Promo websites
                                        </label>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group mar-bot-5">
                                          <textarea
                                            type="text"
                                            className="text-area"
                                            placeholder="Special Notes"
                                            onChange={(e) => this.setState({ specialNotes: e.target.value }, this.websitesFilter)}
                                            value={this.state.specialNotes}
                                          />
                                        </div>
                                      </div>
                                    </Fragment>
                                  }
                                  <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => this.clearFilter()}>Clear</button>
                                    <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => document.getElementById('advanced').click()}>Advanced Filters</button>
                                    <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => this.setState({ create_link: true }, document.getElementById('orderModel').click())} >Create Link</button>
                                    <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => this.setState({ exportCsv: true }, this.websitesFilter)}>Export in CSV</button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="table-responsive">
                    {this.rendorWebsites()}
                  </div>
                  {(this.state.client_id && !this.state.singlewebsite) && <OrderConfirmationModal websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist={this.state.ordersList} orderlist1={this.state.ordersList1} clientid={this.state.client_id} role={'admin'} uncheckwebsites={this.uncheckwebsites} userrole={"admin"} />}
                  {(this.state.client_id && this.state.singlewebsite) && <OrderConfirmationSingleModal websiteids={this.state.websitesIds} prices={this.state.price} orderlist1={this.state.ordersList1} clientid={this.state.client_id} role={'admin'} uncheckwebsites={this.uncheckwebsites} userrole={"admin"} />}
                  <div className="card-footer py-4">
                    <nav aria-label="...">
                      <ul className="pagination justify-content-end mb-0">
                        {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                          <Pagination
                            currentPage={this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage}
                            totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                            getCurrentPage={this.getCurrentPage.bind(this)}
                          />
                        }
                      </ul>
                    </nav>
                  </div>
                </div>
                <br />
                <div data-toggle="modal" data-target="#exampleModal3" id="orderModel"></div>
                <span data-toggle="modal" data-target="#Alert_model" id="success"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import { Link } from 'react-router-dom';
import {ADMIN, Alert_model, INVOICES_LIST, fetch_get, Alert, fetch_post, Pagination, USER } from '../common';

let orderStatus = ""
export default class Services extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      services:[],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      filter:false, 
      service:'',
      svalue:'',
      idtoggle:false,
      servicetoggle:false,
      serviceName:'',
      description:'',
      price:'',
      serviceid:'',
      permanentDelete:false
    }
    this.services = this.services.bind(this);
  }

  componentDidMount() {
    if(this.props.location.state){
      this.setState({ 
        filter:this.props.location.state.detail.filter,
        serviceName:this.props.location.state.detail.serviceName,
        description:this.props.location.state.detail.description,
        price:this.props.location.state.detail.price,
        numberofrows:this.props.location.state.detail.numberofrows,
        currentPage:this.props.location.state.detail.currentPage,
      }, this.serviceFilter)
    // if(this.props.location.state.service){
    //   this.setState({serviceName:this.props.location.state.service})
    // }
    // if(this.props.location.state.price){
    //   this.setState({price:this.props.location.state.price})
    // }
    // if(this.props.location.state.description){
    //   this.setState({price:this.props.location.state.description})
    // }                
    }
    else{   
      this.services()
    }
  }

  services(page=this.state.currentPage) {
    fetch_get(`/api/services/list/?page=${page}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ services: response.services_list, numOfPages: response.active_num_pages,service:''  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearStates(){
    this.setState({serviceName:'',price:''},this.serviceFilter)
  }

  permanentDelete(id){
    fetch_get(`/api/service/${id}/delete/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          let d = document.getElementById('success')
          d.click()        
          this.services()
        }
      }
      else{
        this.services()        
      }
    })
  }  

  serviceFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('service_name',this.state.serviceName)
    formData.append('price',this.state.price)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'service'){
      if(this.state.servicetoggle){
        formData.append('column_name_order','service_name')
      }
      else{
        formData.append('column_name_order','-service_name')
      }
    } 
    if(this.state.svalue == 'price'){
      if(this.state.servicetoggle){
        formData.append('column_name_order','price')
      }
      else{
        formData.append('column_name_order','-price')
      }
    }
    if(this.state.svalue == 'creator__username'){
      if(this.state.servicetoggle){
        formData.append('column_name_order','creator__username')
      }
      else{
        formData.append('column_name_order','-creator__username')
      }
    } 
    fetch_post('/api/services/list/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          services: response.services_list, 
          numOfPages: response.active_num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.serviceFilter)
  }

  removeErrors(){
    this.setState({errors:{}})
  }

  createService(){
    let formData = new FormData()
    formData.append('service_name',this.state.serviceName)
    formData.append('description',this.state.description)
    formData.append('price',this.state.price)
    fetch_post('/api/service/create/',formData)
    .then((response) => {
      if(!response.error){
        this.services();
        this.setState({serviceName:'', description:'', price:'',errors:{}})
        let a = document.getElementById("success1");
        a.click();         
        let model = document.getElementById("createModel");
        model.click();
        // this.setState({
        //   services: response.services_list, 
        //   numOfPages: response.active_num_pages,
        // })                   
      }
      else{
        this.setState({errors:response.errors})
      }     
    })    
  }

  render(){
    let carryProps={
      filter:this.state.filter,
      serviceName:this.state.serviceName,
      description:this.state.description,
      price:this.state.price,
      numberofrows:this.state.numberofrows,
      currentPage:this.state.currentPage
    }
    return(
      <div className="main-content">
        <TopHeader />
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>service added Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push({ pathname: '/admin/services'})}>Close</button>
              </div>
            </div>
          </div>
        </div>        
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>service Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/services')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>         
        <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Create Service</h3>
               <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label>Service Name <div className="error-text">*</div></label>
                      <div className="input-group input-group-alternative mb-3">
                        <input 
                          className="form-control" 
                          placeholder="Service Name" 
                          type="text" 
                          onChange={(event)=>{ this.setState({ serviceName: event.target.value }) }} value={this.state.serviceName}
                        />
                      </div> 
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.service_name}
                      </div> 
                    </div>
                    <div className="col-md-12 form-group">
                      <label> Price <div className="error-text">*</div></label>
                      <div className="input-group input-group-alternative mb-3">
                        <input 
                          className="form-control" 
                          placeholder="Price" 
                          type="text" 
                          onChange={(event)=>{this.setState({ price:event.target.value })}} value={this.state.price}
                        />                        
                      </div>
                      <div className="text-danger" style={{ fontSize: '1rem' }}>
                        {this.state.errors && this.state.errors.price}
                      </div>  
                    </div>                    
                    <div className="col-md-12 form-group">
                      <label> Description </label>
                      <div className="input-group input-group-alternative mb-3">
                        <input 
                          className="form-control" 
                          placeholder="Description" 
                          type="email" 
                          onChange={(event)=>{ this.setState({ description:event.target.value }) }} value={this.state.description}
                        />                                                
                      </div>                          
                    </div>                                          
                  </div>
                </form>
              <div className="col-md-12 text-center pb-4">
                <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                <button type="button" className="btn btn-info" onClick={() => this.createService()} >Create</button>
                <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={() => this.removeErrors()} >Cancel</button> 
              </div>                
              </div>
            </div>
          </div>
        </div>   
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.serviceFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Services</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal2">Create service</button>                  
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>                  
                </div>
                  {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.serviceName}
                                      onChange={(e) => this.setState({ serviceName: e.target.value }, this.serviceFilter)}
                                      placeholder="service"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.price}
                                      onChange={(e) => this.setState({ price: e.target.value }, this.serviceFilter)}
                                      placeholder="price"
                                    />
                                  </div>
                                </div>                                
                              </div>
                            </form>
                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.serviceFilter)} >Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({servicetoggle:!this.state.servicetoggle,svalue:'service'},this.serviceFilter)}>Service Name <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}}>Description</th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({servicetoggle:!this.state.servicetoggle,svalue:'price'},this.serviceFilter)}>price <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({servicetoggle:!this.state.servicetoggle,svalue:'creator__username'},this.serviceFilter)}>Created By <i className="fas fa-sort"></i></th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.services.length > 0 ? this.state.services.map((service, index) =>
                        <tr key={service.id}>
                          <td><a href>{service.id}</a></td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td>{service.service_name}</td>
                          <td>{service.description}</td>
                          <td>{service.price}</td>
                          <td>{service.creator}</td>
                          <td>
                            <Link to={{pathname:`/admin/service/${service.id}/edit/`,state:{service:service.service_name,description:service.description,price:service.price,id:service.id,detail:carryProps}}}>
                              <span><i className="fa fa-edit"></i></span>
                            </Link>
                            <Fragment>
                            <DeleteUser userRole="service" id={this.state.serviceid}  updateList={this.services} />
                            <span style={{ marginLeft:"25px", cursor:'pointer'}} data-toggle="modal" data-target="#deleteModal" onClick={()=>this.setState({serviceid:service.id,permanentDelete:true})}>
                              <i className="fa fa-trash"></i>
                            </span>
                            </Fragment>                
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

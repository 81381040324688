import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';

export default class SubscriptionSuccessMessage extends Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentWillMount(){
    // window.location.reload()
  }

  render(){
    return(
     <div className="main-content client_web">
        <TopHeader showSearch={false}/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div style={{paddingTop:'225px',textAlign:'center'}}>
          <span style={{display: "inline-block",margin: "0 auto",background: "#b9dc58", padding: "0.25rem",borderRadius: "50%",width: "75px",height: "75px",textAlign: "center"}}>
            <i style={{color:"green",fontSize:"40px",display:"flex",justifyContent: "center",lineHeight: "70px"}} className="fas fa-check"></i>
          </span>
          <h4 style={{textAlign:"center" ,margin: '1rem 0'}}>Thank you for subscribing!</h4>
        </div>
      </div>
    )
  }
}
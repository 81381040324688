import React, { Component } from 'react';
import { USERWEBSITE, fetch_get, fetch_post } from '../common';
import TopHeader from '../common/TopHeader';

export default class WebsiteDetails extends Component{
    constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: []
    }
  }

  componentDidMount() {
    this.websitesDetails()
  }

  websitesDetails() {
    fetch_get(`${USERWEBSITE}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      this.setState({ websiteDetails: response.user_website_serializer, bestoffer:response.user_website_serializer.best_offer })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websitedetailsupdate() {
    let formData = new FormData()
    formData.append('best_offer', this.state.bestoffer)
    fetch_post(`${USERWEBSITE}/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        // alert('website updated successfully')
       this.setState({ websiteDetails: response.user_website_serializer, bestoffer:response.user_website_serializer.best_offer})
      }
      else{
         this.setState({ errors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }  

  render(){
    const { websiteDetails } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/> 
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow row mb-6">
            <div className="row">
              <div className="card-header col-md-6 bg-white border-0">
                <h3 className="mb-0">Website Details</h3>
              </div>
              <div className="col-md-6 text-right">
              <label htmlFor="staticEmail">Best Offers</label>
              <input 
                type="checkbox" 
                className="col-sm-1 col-form-label"
                id="bestoffer"
                checked={this.state.bestoffer ? true : false}
                onChange={(e)=>this.setState({bestoffer:document.getElementById('bestoffer').checked ? 'Y' : 'N'},this.websitedetailsupdate)}
              />
              <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/admin/websites', state:{detail:this.props.location.state.detail,websiteids:this.props.location.state.websiteids,ordersList:this.props.location.state.ordersList,price:this.props.location.state.prices}})}>
                Back
              </button>              
              <button className="edit-website btn-info" onClick={() => this.props.history.push(`/admin/websites/${this.props.match.params.id}/edit`) }>
                Edit                                                                                                                                                                                                                                                
              </button>
              </div>
              </div>
              {websiteDetails.id &&
                <div className="card-body">
                  <div className="row pt-3">
                    <table className="table">
                      <tr>
                      <td>Website Id</td>
                      <td>{websiteDetails.website.id}</td>                      
                      </tr>
                      <tr>
                      <td>Domain</td>
                      <td>{websiteDetails.website.domain}</td>                      
                      </tr>
                      <tr>
                      <td>Disclosure </td>
                      <td>{websiteDetails.disclosure_tag ? 'True' : 'False'}</td>                      
                      </tr>
                      <tr>
                      <td>ad_type</td>
                      <td>{websiteDetails.ad_type}</td>                      
                      </tr>
                      <tr>
                      <td>permanent_article</td>
                      <td>{websiteDetails.permanent_article ? 'True' : 'False'}</td>                      
                      </tr>
                      <tr>
                      <td>writing_included</td>
                      <td>{websiteDetails.writing_included ? 'True' : 'False'}</td>                      
                      </tr>
                      <tr>
                      <td>minimum_word_count</td>
                      <td>{websiteDetails.minimum_word_count}</td>                      
                      </tr>
                      <tr>
                      <td>Original Price</td>
                      <td>{websiteDetails.price}</td>                      
                      </tr>
                      <tr>
                      <td>Caluclated Price</td>
                      <td>{websiteDetails.sell_price}</td>                      
                      </tr>
                      <tr>
                      <td>tat </td>
                      <td>{websiteDetails.tat}</td>                      
                      </tr>
                      <tr>
                      <td>prepaid</td>
                      <td>{websiteDetails.prepaid ? 'True' : 'False'}</td>                      
                      </tr>
                      <tr>
                      <td>Link Type</td>
                      <td>{websiteDetails.links_type}</td>                      
                      </tr>                      
                      <tr>
                      <td>maximum_number_of_links_in_post</td>
                      <td>{websiteDetails.maximum_number_of_links_in_post}</td>                      
                      </tr>
                      <tr>
                      <td>example_post</td>
                      <td>{websiteDetails.example_post}</td>                      
                      </tr>
                      <tr>
                      <td>special_notes</td>
                      <td>{websiteDetails.special_notes}</td>                      
                      </tr>
                      <tr>
                      <td>client_notes</td>
                      <td>{websiteDetails.client_note}</td>
                      </tr>
                      <tr>
                      <td>organic Keywords</td>
                      <td>{websiteDetails.website.organic_keywords}</td>
                      </tr>
                      <tr>
                      <td>Is Vendor Verified</td>
                      <td>{websiteDetails.is_vendor_verified}</td>
                      </tr>
                    </table>                    
                  </div>
                </div>
              }
            </div>
          </div>
        </div>  
      </div> 
    )
  }
}

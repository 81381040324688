import React, { Component,Fragment } from 'react';

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: Number(this.props.currentPage),
      totalPages: Number(this.props.totalPages)
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.state.currentPage !== nextProps.currentPage) {
  //     this.setState({ currentPage: nextProps.currentPage });
  //     this.sendData(nextProps.currentPage);
  //   }
  //   if (this.state.totalPages !== nextProps.totalPages) {
  //     this.setState({ totalPages: nextProps.totalPages });
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("didupdate",this.props.currentPage,prevProps.currentPage)
  //   if(this.props.currentPage !== prevProps.currentPage) {
  //     this.sendData(this.props.currentPage);
  //   }
  // }

  // sendData(currentPage) {
  //   console.log("d1")
  //   this.props.getCurrentPage(Number(currentPage))
  // }

  handleClick(value) {
    if(Number(value) !== 0) {
      this.setState({
        currentPage: Number(value)
      });
      this.props.getCurrentPage(Number(value));
    }
  }

  render() {
    let { currentPage, totalPages } = this.props;
    currentPage = Number(currentPage)
    if(Number(currentPage) > Number(totalPages)){
      currentPage = Number(totalPages)
      this.handleClick(currentPage)
    }
    // Logic for displaying page numbers
    const pageNumbers = [];
    if (currentPage >= 3) {
       pageNumbers.push(currentPage-2) 
    }
    if (currentPage  > 1) {
      pageNumbers.push(currentPage - 1)
    }

    for (let i = currentPage; i <=totalPages; i++) {
      if (pageNumbers.length < 5) {
        pageNumbers.push(i);
      }
      else { break; }
    }
    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li key={number} className={(number === currentPage) ? "page-item active" : "page-item"}>
          <a 
            className="page-link" 
            href
            // href="#"
            key={number}
            id={number}
            onClick={()=>this.handleClick(number)}
            // className={(number === currentPage) ? "active" : ""}
          >
            {number}
            <span className="sr-only">(current)</span>
          </a>
        </li>
      );
    });
    // const isLast = currentPage === totalPages
    // const isFirst = (currentPage === 1)
    return (
      <div className="pagination justify-content-end mb-0">
        {currentPage > 1 &&
          // <a
          //   key={"key" + currentPage - 1}
          //   id={currentPage - 1}
          //   onClick={this.handleClick}
          //   // className={isFirst ? 'disabled' : ''}
          // >
          //   &laquo;
          // </a>
          <Fragment>
          <li className="page-item">
            <a 
              href
              className="page-link" 
              key={"key" + currentPage - 1}
              id={currentPage - 1}
              onClick={() => this.handleClick("1")}
            >
              <i className="fas fa-angle-double-left"></i>
              <span className="sr-only">first</span>
            </a>
          </li>          
          <li className="page-item">
            <a 
              href
              className="page-link" 
              key={"key" + currentPage - 1}
              id={currentPage - 1}
              onClick={() => this.handleClick(currentPage - 1)}
            >
              <i className="fas fa-angle-left"></i>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          </Fragment>
        }
        {renderPageNumbers}
        {currentPage !== totalPages &&
          // <a
          //   key={"key" + currentPage + 1}
          //   id={Number(currentPage) + 1}
          //   onClick={this.handleClick}
          //   // className={isLast ? 'disabled' : ''}
          // >
          //   &raquo;
          // </a> 
          <Fragment>
          <li className="page-item">
            <a 
              href
              className="page-link" 
              key={"key" + currentPage + 1}
              id={Number(currentPage) + 1}
              onClick={() => this.handleClick(Number(currentPage) + 1)}
            >
              <i className="fas fa-angle-right"></i>
              <span className="sr-only">Next</span>
            </a>
          </li>
          <li className="page-item">
            <a 
              href
              className="page-link" 
              key={"key" + currentPage + 1}
              id={Number(currentPage) + 1}
              onClick={() => this.handleClick(Number(totalPages))}
            >
              <i className="fas fa-angle-double-right"></i>
              <span className="sr-only">Next</span>
            </a>
          </li> 
          </Fragment>         
        }
      </div>
    );
  }
}
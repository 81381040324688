import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FORGOT_PASSWORD, DOMAIN_NAME, Alert } from '../common';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      errors: {}
    };
    this.handleKeyPress = this.handleKeyPress.bind(this)
  } 

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmit()
    }
  }
 
  handleSubmit() {
    // event.preventDefault();
    const { username } = this.state;
    let data = new FormData()
    data.append('username', username)
    fetch(DOMAIN_NAME+FORGOT_PASSWORD, {
      method: 'post',
      body: data
    })
    .then(response => response.json())
    .then((response) => {
      if(response.error) {
        this.setState({ errors: response.errors })
      } else {
        // alert('An Email will be sent to your mail')
        let a = document.getElementById('success')
        a.click()

        // this.props.history.push('/signin')
      }
    })
    .catch(() => {
      console.log('Something went wrong, Please try again');
    });
  }

  render(){
    const { errors } = this.state;
    return( 
      <Fragment>
        <div className="login_rc">
          <Alert msg={"An Email will be sent to your mail"} forgotpassword="true" history={this.props.history}/>
          <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
              <div className="container px-4 justify-content-center">
                <a className="navbar-brand" href="../index.html">
                  <img src="/img/brand/logo.png" alt=""  style={{height:"100px"}} />
                </a>
                <div className="collapse navbar-collapse" id="navbar-collapse-main">
                  <div className="navbar-collapse-header d-md-none">
                    <div className="row">
                      <div className="col-6 collapse-brand">
                        <a href="../index.html">
                          <img src="/img/brand/blue.png" alt="" />
                        </a>
                      </div>
                      <div className="col-6 collapse-close">
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="header bg-gradient-primary py-7 py-lg-9">
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>
            <div className="container mt--5 pb-5">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div className="card border-0">
                    <div className="card-header bg-transparent text-center">
                      <small>Forgot Password</small>
                    </div>
                    <div className="card-body px-lg-5 py-lg-5">
                      <form>
                        <div className="form-group mb-3">
                          <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}
                              className="form-control" 
                              placeholder="Username" 
                              type="text" 
                              value={this.state.username} 
                              onChange={(event)=>{this.setState({username:event.target.value})}}
                            />
                          </div>
                          <div className="text-danger">
                            {errors.username && errors.username[0] }
                            {errors.non_field_errors && errors.non_field_errors[0] }
                          </div>                        
                        </div>
                        <span data-toggle="modal" data-target="#alert" id="success"/>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-primary my-4" 
                            onClick={() => this.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <Link to={"/signin"} className="text-light"><small>Sign in</small></Link>
                    </div>
                    <div className="col-6 text-right">
                      <Link to={"/signup"}>Create new account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}      
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get, fetch_post, DOMAIN_NAME, Alert_model } from '../common';
import LiveurlModel from './LiveurlModel';
import PricechangeModel from './PricechangeModel';
import Select from 'react-select';
import DeleteUser from './DeleteUser';

let websiteSelectedVendors = '';
let websiteSelectedCreator = '';
export default class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      orderId: '',
      id: '',
      liveurl: '',
      clientinfo: '',
      ordercost: '',
      ordervalue: '',
      userorderid: '',
      propsprice: '',
      discountType: '',
      discountvalue: '',
      is_percent: '',
      errors: {},
      error: '',
      totalamount: '',
      orderstatus: '',
      vendor_paymentId: '',
      creator_paymentId: '',
      user_order_id: '',
      payment_to: '',
      creators_list: [],
      vendor_data: '',
      vendor_staus: '',
      cost_price: '',
      sellingprice: '',
      creator__paymentId: '',
      vendor__paymentId: '',
      order_complete: false,
      client_followup: false,
      potential: false,
      complete_order_status: '',
      invoiced: false,
      client_message_id: '',
      client_msgs: '',
      vendor_msgs: '',
      replyClientMsg: '',
      replyVendorMsg: '',
      clientSubject: '',
      vendorSubject: '',
      clientEmailTo: '',
      clientEmailFrom: '',
      vendorEmailTo: '',
      vendorEmailFrom: '',
      clientFromMsg: '',
      vendorFromMsg: '',
      clientThreadId: '',
      vendorThreadId: '',
      clientMessageId: '',
      vendorMessageId: '',
      chatResponse: '',
      clientChatUpdate: false,
      vendorChatUpdate: false
    }
    this.sendEmail = this.sendEmail.bind(this)
    this.discountDetails = this.discountDetails.bind(this)
  }


  sendEmail(id) {
    let formData = new FormData()
    formData.append('send_email', true)
    formData.append('user_order_id', id)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          document.getElementById('mail').click()
          // alert("email successfully sended")
        }
      })
  }

  sendClientEmail(id) {
    let formData = new FormData()
    if (this.state.potential) {
      formData.append('send_public_client_email', true)
    }
    else {
      formData.append('send_client_email', true)
    }
    // formData.append('user_order_id',id)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (response && !response.error) {
          document.getElementById('mail').click()
          // alert("email successfully sended")
        }
      })
  }

  sendFollowupEmail(id) {
    let formData = new FormData()

    formData.append('send_confirm_email', true)

    // formData.append('user_order_id',id)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          document.getElementById('mail').click()
          if (response && response.order_details && response.order_details.is_client_follow_up_mail_sent) {
            this.setState({ "client_followup": true })
          }

          // alert("email successfully sended")
        }
      })
  }

  markInvoiced() {
    let formData = new FormData()
    formData.append('is_invoiced', true)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          document.getElementById('mail').click()
          this.orderDetails()
          // alert("email successfully sended")
        }
      })
  }


  componentDidMount() {
    localStorage.removeItem('orderid')
    this.orderDetails()

  }

  updateStatus(userOrderId, status) {
    let formData = new FormData()
    formData.append('user_order_id', userOrderId)
    formData.append('status', status)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          // orderStatus = response.order_details.user_order_details[0].status
          this.setState({
            orderDetails: response.order_details,
          })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.state != nextProps.location.state) {
      this.orderDetails()
    }
  }

  orderDetails(id = this.props.match.params.id) {

    fetch_get(`${ORDER}/${id}/details/ `)
      .then((response) => {
        response.order_details.created_by.tags.map((tag) => {
          if (tag.name == 'Potential Client') {
            this.setState({ potential: true })
          }
        })
        if (!response.error) {
          const clientRes = response.client_msgs
          const vendorRes = response.vendor_msgs
          this.setState({
            orderDetails: response.order_details,
            orderId: response.order_details.order_id,
            clientinfo: response.order_details.created_by.username,
            ordercost: response.order_details.order_cost,
            ordervalue: response.order_details.amount,
            totalamount: response.order_details.total_amount,
            discountvalue: response.order_details.discount_value,
            creators_list: response.creators_list,
            // is_percent:response.order_details.discount_is_percentage,
            // discountType:response.order_details.discount_is_percentage ? 'percentage' :  'fixed',
            orderstatus: response.order_details.order_status,
            order_complete: response.order_details.is_completed,
            order_pending_publish: response.order_details.is_pending_publish,
            complete_order_status: response.order_details.order_status,
            client_followup: response.order_details.is_client_follow_up_mail_sent,
            client_message_id: response.order_details.Client_Message_ID,
            client_msgs: response.client_msgs,
            vendor_msgs: response.vendor_msgs,
          })
          clientRes.length && clientRes[0].threadId && this.setState({ clientThreadId: clientRes[0].threadId });
          vendorRes.length && vendorRes[0].threadId && this.setState({ vendorThreadId: vendorRes[0].threadId });
          clientRes.length && clientRes[clientRes.length - 1].payload && clientRes[clientRes.length - 1].payload.headers.length && clientRes[clientRes.length - 1].payload.headers.forEach((item) => {
            if (item.name === "From") {
              let str = (item.value.split("@").length - 1)
              let value = (str === 1 && item.value.match(/<(.*?)>/) !== null) ? item.value.match(/<(.*?)>/)[1] : item.value
              this.setState({ clientEmailFrom: value, clientFromMsg: value === 'orders@rankcastle.com' ? 'vendor' : 'client' });
            } else if (item.name === "To") {
              let str = (item.value.split("@").length - 1)
              this.setState({ clientEmailTo: str === 1 && item.value.match(/<(.*?)>/) !== null ? item.value.match(/<(.*?)>/)[1] : item.value });
            } else if (item.name === "Subject") {
              // this.setState({ clientSubject: item.value.substring(1, item.value.length - 1) });
              this.setState({ clientSubject: item.value });
            } else if (item.name === "Message-ID") {
              this.setState({ clientMessageId: item.value.substring(1, item.value.length - 1) });
            }
          })
          vendorRes.length && vendorRes[vendorRes.length - 1].payload && vendorRes[vendorRes.length - 1].payload.headers.length && vendorRes[vendorRes.length - 1].payload.headers.forEach((item) => {
            if (item.name === "From") {
              const str = (item.value.split("@").length - 1);
              const value = (str === 1 && item.value.match(/<(.*?)>/) !== null) ? item.value.match(/<(.*?)>/)[1] : item.value
              this.setState({ vendorEmailFrom: value, vendorFromMsg: value === 'orders@rankcastle.com' ? 'vendor' : 'client' })
            } else if (item.name === "To") {
              const str = (item.value.split("@").length - 1)
              this.setState({ vendorEmailTo: str === 1 && item.value.match(/<(.*?)>/) !== null ? item.value.match(/<(.*?)>/)[1] : item.value });
            } else if (item.name === "Subject") {
              // this.setState({ vendorSubject: item.value.substring(1, item.value.length - 1) });
              this.setState({ vendorSubject: item.value });
            } else if (item.name === "Message-ID") {
              this.setState({ vendorMessageId: item.value.substring(1, item.value.length - 1) });
            }
          })


          if (response.order_details.discount_value && response.order_details.discount_is_percentage) {
            this.setState({ discountType: 'percentage' })
          }
          else if (response.order_details.discount_value && response.order_details.discount_is_percentage == false) {
            this.setState({ discountType: 'fixed' })
          }
          else {
            this.setState({ discountType: '' })
          }
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  discountDetails(id = this.props.match.params.id) {
    let formData = new FormData()
    formData.append('discount_value', this.state.discountvalue)
    formData.append('discount_type', this.state.discountType)
    fetch_post(`${ORDER}/${id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({
            discountvalue: response.order_details.discount_value,
            is_percent: response.order_details.discount_is_percentage,
            orderId: response.order_details.order_id,
            clientinfo: response.order_details.created_by.username,
            ordercost: response.order_details.order_cost,
            ordervalue: response.order_details.amount,
            totalamount: response.order_details.total_amount,
            orderDetails: response.order_details,
            error: response.error,
          })
        }
        else {
          this.setState({ errors: response.message, error: response.error })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  assignVendors(vId, wId) {
    websiteSelectedVendors = vId
    let formData = new FormData()
    formData.append('user_website', vId)
    formData.append('user_order_id', wId)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({ orderDetails: response.order_details, orderId: response.order_details.order_id, ordervalue: response.order_details.amount, ordercost: response.order_details.order_cost })
          let b = document.getElementById('cancel_assign')
          b.click()
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  assignCreators(vId, wId) {
    websiteSelectedCreator = vId
    let formData = new FormData()
    formData.append('creator_id', vId)
    formData.append('user_order_id', wId)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
      .then((response) => {
        if (!response.error) {
          this.setState({ orderDetails: response.order_details, orderId: response.order_details.order_id, ordervalue: response.order_details.amount, ordercost: response.order_details.order_cost })
          let b = document.getElementById('cancel_assign')
          b.click()
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  removeErrors() {
    this.setState({ creator_paymentId: '', vendor_paymentId: '', errors: {} })
  }

  sendPaymentDetails() {
    let formData = new FormData()
    this.state.creator_paymentId && formData.append('creator_transaction_id', this.state.creator_paymentId)
    this.state.vendor_paymentId && formData.append('vendor_transaction_id', this.state.vendor_paymentId)
    fetch_post(`/api/payment_details/${this.state.user_order_id}/update/ `, formData)
      .then((response) => {
        if (!response.error) {
          let a = document.getElementById("success1");
          a.click();
          let b = document.getElementById('cancelPay');
          b.click();
          this.setState({ creator_paymentId: '', vendor_paymentId: '' })
          this.orderDetails()
        }
        else {
          this.setState({ errors: response.message, error: response.error })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  sendDeleteDetails() {
    let formData = new FormData()
    formData.append('user_order_price', this.state.propsprice)
    formData.append('user_order_id', this.state.userorderid)
    fetch_post(`/api/order/${this.state.orderId}/details/`, formData)
      .then((response) => {
        if (!response.error) {
          let a = document.getElementById("deleteModel");
          a.click();
          if (response.order_details) {
            this.orderDetails()
          }
          else {
            this.props.history.push(`/admin/orders/`)
          }
        }
        else {
          this.setState({ errors: response.message, error: response.error })
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  paymentDetails(id, user, payment_id) {
    if (user == 'vendor') {
      this.setState({ user_order_id: id, payment_to: user, vendor__paymentId: payment_id, vendor_paymentId: payment_id })
    }
    else {
      this.setState({ user_order_id: id, payment_to: user, creator__paymentId: payment_id, creator_paymentId: payment_id })
    }
    document.getElementById('payment_details').click()

  }

  assignUser(userOrderDetails, status) {
    websiteSelectedVendors = userOrderDetails.user_website_details.assigned_website_vendor ? userOrderDetails.user_website_details.assigned_website_vendor.id : ''
    this.setState({ userOrderDetails: userOrderDetails, vendor_staus: status, assignTo: 'vendor' })
    document.getElementById('assign_user').click()
  }

  assignCreator(userOrderDetails) {
    websiteSelectedCreator = userOrderDetails.content_upload_creator ? userOrderDetails.content_upload_creator.id : ''
    this.setState({ userOrderDetails: userOrderDetails, assignTo: 'creator' })
    document.getElementById('assign_user').click()
  }

  deleteUserOrder(userorderid, propsprice) {
    this.setState({ userorderid: userorderid, propsprice: propsprice })
    document.getElementById('delete_user_order').click()
  }


  timeAgo(prevDate) {
    // moment(previousDate).fromNow();

    const diff = Number(new Date()) - prevDate;
    const minute = 60 * 1000;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = day * 365;
    switch (true) {
      case diff < minute:
        const seconds = Math.round(diff / 1000);
        return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`
      case diff < hour:
        return Math.round(diff / minute) + ' minutes ago';
      case diff < day:
        return Math.round(diff / hour) + ' hours ago';
      case diff < month:
        return Math.round(diff / day) + ' days ago';
      case diff < year:
        return Math.round(diff / month) + ' months ago';
      case diff > year:
        return Math.round(diff / year) + ' years ago';
      default:
        return "";
    }
  };

  sendReply(from) {
    if (from === 'client') {
      this.setState({ clientChatUpdate: true })
    } else if (from === 'vendor') {
      this.setState({ vendorChatUpdate: true })
    }
    let formData = new FormData()
    formData.append('order_id', this.props.match.params.id)
    formData.append('subject', from === 'client' ? this.state.clientSubject : this.state.vendorSubject)
    formData.append('message', from === 'client' ? this.state.replyClientMsg : this.state.replyVendorMsg)
    formData.append('from_email', from === 'client' ? 'orders@rankcastle.com' : 'publishers@rankcastle.com')
    formData.append('messageid', from === 'client' ? this.state.clientMessageId : this.state.vendorMessageId)
    formData.append('to_email', from === 'client' ? this.state.clientEmailTo : this.state.vendorEmailTo)

    fetch_post(`/api/order/${this.props.match.params.id}/details/reply/`, formData)

      .then(response => {
        // console.log(response)
        if (response && response.message === "Email sent successfully") {
          this.setState({ chatResponse: response.message })
          this.orderDetails()
          this.setState({ clientChatUpdate: false, vendorChatUpdate: false })
          if (from === 'client') {
            this.setState({ replyClientMsg: '' })
          } else if (from === 'vendor') {
            this.setState({ replyVendorMsg: '' })
          }
          document.getElementById("replyEmailModal").click()
        } else if (response && response.error) {
          this.setState({ chatResponse: response.error })
          document.getElementById("replyEmailModal").click()
          this.setState({ clientChatUpdate: false, vendorChatUpdate: false })

        }

      })
      .catch(error => { console.error(error) })

  }

  render() {
    return (
      <div className="main-content">
        <TopHeader />
        {this.state.invoiced ? <Alert_model msg={'OrderStatus has been changed Successfully'} /> : <Alert_model msg={'Email has been sent Successfully'} />}
        <span data-toggle="modal" data-target="#Alert_model" id="mail"></span>
        <span data-toggle="modal" data-target="#exampleModal2" id="payment_details"></span>
        <span data-toggle="modal" data-target="#exampleModal3" id="assign_user"></span>
        <span data-toggle="modal" data-target="#exampleModal4" id="delete_user_order"></span>
        <span data-toggle="modal" data-target="#exampleModal5" id="replyEmailModal"></span>
        <LiveurlModel orderid={this.state.orderId} id={this.state.id} history={this.props.history} liveurl={this.state.liveurl} />
        <PricechangeModel orderid={this.state.orderId} id={this.state.id} costprice={this.state.cost_price} sellingprice={this.state.sellingprice} history={this.props.history} />
        <div className="modal fade col-md-6 m-auto" id="exampleModal5" tabIndex="-1" role="dialog" aria-labelledby="replyEmailModal" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content m-auto">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.chatResponse}
                {/* Email Sent successfully */}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal"
                >Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">Send Payment Details</h3>
                <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    {this.state.payment_to == 'vendor' &&
                      <div className="col-md-12 form-group">
                        <label>Vendor PaymentId</label>
                        <div className="input-group input-group-alternative mb-3">
                          <input
                            className="form-control"
                            placeholder="Vendor PaymentId"
                            type="text"
                            onChange={(event) => { this.setState({ vendor_paymentId: event.target.value }) }} value={this.state.vendor_paymentId}
                          />
                        </div>
                        <div className="text-danger" style={{ fontSize: '1rem' }}>
                          {this.state.errors && this.state.errors.vendor_payment_id}
                        </div>
                      </div>
                    }
                    {this.state.payment_to == 'creator' &&
                      <div className="col-md-12 form-group">
                        <label>Creator PaymentId</label>
                        <div className="input-group input-group-alternative mb-3">
                          <input
                            className="form-control"
                            placeholder="Creator PaymentId"
                            type="text"
                            onChange={(event) => { this.setState({ creator_paymentId: event.target.value }) }} value={this.state.creator_paymentId}
                          />
                        </div>
                        <div className="text-danger" style={{ fontSize: '1rem' }}>
                          {this.state.errors && this.state.errors.creator_paymentId}
                        </div>
                      </div>
                    }
                  </div>
                </form>
                <div className="col-md-12 text-center pb-4">
                  <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                  {this.state.payment_to == 'vendor' ? this.state.vendor__paymentId ?
                    <button type="button" disabled className="btn btn-info" onClick={() => this.sendPaymentDetails()} >Send</button> :
                    <button type="button" className="btn btn-info" onClick={() => this.sendPaymentDetails()} >Send</button> : null}
                  {this.state.payment_to == 'creator' ? this.state.creator__paymentId ?
                    <button type="button" disabled className="btn btn-info" onClick={() => this.sendPaymentDetails()} >Send</button> :
                    <button type="button" className="btn btn-info" onClick={() => this.sendPaymentDetails()} >Send</button> : null}
                  <button type="button" className="btn btn-default" id="cancelPay" data-dismiss="modal" onClick={() => this.removeErrors()} >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal3" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
                <h3 className="mb-4 col-md-12 text-center">{this.state.assignTo == 'vendor' ? 'Assign Vendor' : 'Assign Creator'}</h3>
                <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    {this.state.assignTo == 'vendor' &&
                      <div className="col-md-12 form-group">
                        <label>Assign Vendor</label>
                        <div className="input-group input-group-alternative mb-3">
                          <select
                            className="form-control"
                            value={websiteSelectedVendors}
                            onChange={(e) => this.assignVendors(e.target.value, this.state.userOrderDetails.id)}
                            disabled={this.state.vendor_status === 'Approved' ? true : false}
                          >
                            <option value="0">select vendor</option>
                            {this.state.userOrderDetails && this.state.userOrderDetails.user_website_details.website_vendors_list.length > 0 && this.state.userOrderDetails.user_website_details.website_vendors_list.map((vendor) =>
                              <option value={vendor.id}>{vendor.user.username} ({vendor.price}) ({vendor && vendor.order_count ? vendor.order_count : 0} {vendor && vendor.order_count <= 1 ? 'order' : 'orders'})</option>
                            )}
                          </select>
                        </div>
                      </div>
                    }
                    {this.state.assignTo == 'creator' &&
                      <div className="col-md-12 form-group">
                        <label>Assign Creator</label>
                        <div className="input-group input-group-alternative mb-3">
                          <select
                            className="form-control"
                            style={{ width: "140px" }}
                            value={websiteSelectedCreator}
                            onChange={(e) => this.assignCreators(e.target.value, this.state.userOrderDetails.id)}
                          >
                            <option value="0">select creator</option>
                            {this.state.creators_list.map((creator) =>
                              <option value={creator.id}>{creator.username}  </option>
                            )}
                          </select>
                        </div>
                      </div>
                    }
                  </div>
                </form>
                <div className="col-md-12 text-center pb-4">
                  <button type="button" className="btn btn-default" id="cancel_assign" data-dismiss="modal" >Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade col-md-6 m-auto" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content m-auto">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteModalLabel">UserOrder Delete</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Are you Sure ? You want to delete UserOrder ?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">Close</button>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={() => this.sendDeleteDetails()}>delete</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
                <center>paymentId sent Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content">
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <div className="container-fluid mt--7">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-12">
                <div className="card shadow  profile_card order_list_detail">
                  <div className="row card-header marl">
                    <div className="col-md-6">
                      <h3 className="mb-0 mt-2  ml-2">Order ID : <span className="order_id"> {this.state.orderDetails.order_id}</span></h3>
                    </div>
                    <div className="col-md-6 text-right">
                      {this.state.order_complete ? <button className="edit-website btn-info mt-2 mr-4" onClick={() => this.setState({ invoiced: true }, this.markInvoiced)}>
                        Mark Invoiced
                      </button> : null}
                      {this.state.order_complete ? <button className="edit-website btn-info mt-2 mr-4" onClick={() => this.setState({ invoiced: false }, this.sendClientEmail)}>
                        Send Client Email
                      </button> : null}
                      {this.state.order_pending_publish && !this.state.client_message_id ? <button className="edit-website btn-info mt-2 mr-4" onClick={() => this.setState({ invoiced: false }, this.sendFollowupEmail)}>
                        Send Confirm Email
                      </button> : null}

                      {this.props.location.state ?
                        this.props.location.state.source == 'tasklist' ?
                          <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/admin/tasks', state: { details: this.props.location.state } })}>
                            Back
                          </button> :
                          <button className="edit-website btn-info mt-2 mr-4" onClick={() => this.props.history.goBack()}>
                            Back
                          </button> :
                        <button className="edit-website btn-info mt-2 mr-4" onClick={() => this.props.history.goBack()}>
                          Back
                        </button>
                      }
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-3">
                        <label className="client_info">Name:</label>
                        <span>{this.state.clientinfo}</span>
                      </span>
                      <span className="col-md-3">
                        <label className="order_value">Ordervalue:</label>
                        <span>{this.state.ordervalue}</span>
                      </span>
                      <span className="col-md-3">
                        <label className="order_value">OrderCost:</label>
                        <span>{this.state.ordercost}</span>
                      </span>
                      <span className="col-md-3">
                        <label className="invoice_link">InvoiceLink:</label>
                        {this.state.orderDetails.order_status === 'Completed' ?
                          <button type="button" className="update_api btn btn-primary" onClick={() => this.props.history.push(`/admin/invoices/${this.state.orderId}/details`, { created_on: moment(this.state.orderDetails.created_on).format('DD-MMM-YY'), username: this.state.orderDetails.created_by.username })}>invoice link</button> :
                          "----"
                        }
                      </span>
                    </div>
                    <div className="card-header row no_border_bg">
                      {this.state.discountvalue !== 0 && this.state.orderstatus == 'Completed' ?
                        <span className="col-md-3">
                          <label className="client_info">Discount:</label>
                          <span>{this.state.is_percent ? this.state.discountvalue + "%" : this.state.discountvalue + "$"}</span>
                        </span>
                        : null}
                      {this.state.discountvalue != 0 && this.state.discountvalue != '' ?
                        <span className="col-md-3 ">
                          <label className="order_value">TotalAmount:</label>
                          <span>{this.state.totalamount}</span>
                        </span> : null}
                      {this.state.orderstatus !== 'Completed' ?
                        <span className="col-md-2">
                          <select
                            value={this.state.discountType}
                            onChange={(e) => this.setState({ discountType: e.target.value })}
                            placeholder="Discount"
                            className="form-control"
                          >
                            <option value="">DiscountType</option>
                            <option value="percentage">percentage</option>
                            <option value="fixed">fixed</option>
                          </select>
                        </span> : null}
                      {this.state.discountType == 'percentage' && this.state.orderstatus !== 'Completed' ?
                        <div className="col-md-2">
                          <input
                            style={{ display: "inline-block", width: "80%" }}
                            type="text"
                            className="form-control"
                            value={this.state.discountvalue}
                            onChange={(e) => this.setState({ discountvalue: e.target.value })}
                            placeholder="percentage"
                          />
                          <span className="pt-2"><i class="fas fa-percent"></i></span>
                          <div className="text-danger" style={{ display: "block" }}>
                            {this.state.error ? this.state.errors : ''}
                          </div>
                        </div>
                        : this.state.discountType == 'fixed' && this.state.orderstatus !== 'Completed' ?
                          <div className="col-md-2">
                            <input
                              type="text"
                              style={{ display: "inline-block", width: "80%" }}
                              className="form-control"
                              value={this.state.discountvalue}
                              onChange={(e) => this.setState({ discountvalue: e.target.value })}
                              placeholder="fixed"
                            />
                            <span className="pt-2"><i class="fas fa-dollar-sign"></i></span>
                            <div className="text-danger">
                              {this.state.error ? this.state.errors : ''}
                            </div>
                          </div> : null

                      }
                      {(this.state.discountvalue !== '' && this.state.discountvalue != 0 && this.state.orderstatus !== 'Completed') || (this.state.discountvalue !== '' && this.state.discountvalue != 0 && this.state.orderstatus !== 'Completed') ?
                        <span className="col-md-2">
                          <button type="button" className="btn btn-primary btn-xs" onClick={() => this.discountDetails()}>apply</button>
                        </span> : null}
                    </div>
                    <form className="pt-3">
                      <div className="order_table">
                        <table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th>S.No</th>
                              <th>Website</th>
                              <th>Ad type</th>
                              <th>Price</th>
                              <th>articlewriting price</th>
                              <th>View Content</th>
                              <th>Content Upload</th>
                              <th>Content Status</th>
                              <th>Order Status</th>
                              <th>Live Url</th>
                              {localStorage.getItem('role') === 'admin' ?
                                <th style={{ paddingLeft: "4rem" }}>Actions</th> :
                                null
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.orderDetails.user_order_details && this.state.orderDetails.user_order_details.map((website, index) =>
                              <tr key={website.user_website_details.id}>
                                <td>
                                  {index + 1}
                                </td>
                                <td>
                                  {website.user_website_details.website}
                                </td>
                                <td>
                                  {website.user_website_details.ad_type ? website.user_website_details.ad_type : '--'}
                                </td>
                                <td>{website.user_website_details.website_vendors_list.length && website.user_website_details.website_vendors_list[0].promo_price > 0 ? website.user_website_details.website_vendors_list[0].promo_price : website.user_website_details.price }
                                  <span style={{ paddingLeft: 10 }} data-toggle="modal" data-target="#pricechangeModel" onClick={() => this.setState({ id: website.id, cost_price: website.user_website_details.cost_price, sellingprice: website.user_website_details.website_vendors_list.length && website.user_website_details.website_vendors_list[0].promo_price > 0 ? website.user_website_details.website_vendors_list[0].promo_price : website.user_website_details.price })}><i className="fa fa-edit"></i></span>
                                </td>
                                <td style={{ paddingLeft: "5rem" }}>
                                  {website.article_writing_price == null ? 0 : website.article_writing_price}
                                </td>
                                <td>
                                  {website.user_website_details.text_content_status ?
                                    <Fragment>
                                      <span>
                                        {website.user_website_details.text_content.file_content ? <a href={DOMAIN_NAME + website.user_website_details.text_content.file_content} download ><i className="ni ni-cloud-download-95"></i></a> : null}
                                        {website.user_website_details.text_content.link_url ? <a href={website.user_website_details.text_content.link_url} target="_blank"><i className="fas fa-eye"></i></a> : null}

                                      </span>
                                      <span style={{ paddingLeft: 40 }} onClick={() => this.props.history.push('/admin/websites/orders/upload-content', { id: this.state.orderDetails.order_id, userOrderId: website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}><i className="fa fa-edit"></i></span>
                                    </Fragment>
                                    :
                                    website.post_url == '' ?
                                      <span><i className="fas fa-times"></i></span> :
                                      <Fragment>
                                        <span>{website.post_url ? <a href={website.post_url} target="_blank"><i className="fas fa-eye"></i></a> : null} </span>
                                        <span style={{ paddingLeft: 40 }} onClick={() => this.props.history.push('/admin/websites/orders/upload-content', { id: this.state.orderDetails.order_id, userOrderId: website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}><i className="fa fa-edit"></i></span>
                                      </Fragment>
                                  }
                                </td>
                                <td>
                                  {website.user_website_details.text_content_status ?
                                    website.status === 'Pending Publish' ?
                                      <span>
                                        <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                        <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                      </span>
                                      :
                                      website.status == 'Rejected' ?
                                        <span>
                                          <button type="button" disabled className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                          <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                        </span>
                                        :
                                        website.status == 'Accepted' ?
                                          <span>
                                            <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                            <button type="button" disabled className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                          </span>
                                          :
                                          website.status == 'Completed' ? 'Completed'
                                            :
                                            website.status == 'Pending' ?
                                              <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={() =>
                                                  this.props.history.push('/admin/websites/orders/upload-content',
                                                    { id: this.state.orderDetails.order_id, userOrderId: website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}
                                              >
                                                Upload
                                              </button>
                                              : null

                                    :
                                    website.post_url == "" ?
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={() =>
                                          this.props.history.push('/admin/websites/orders/upload-content',
                                            { id: this.state.orderDetails.order_id, userOrderId: website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}
                                      >
                                        Upload
                                      </button>

                                      :
                                      website.status === 'Pending Publish' ?
                                        <span>
                                          <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                          <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                        </span>
                                        :
                                        website.status == 'Rejected' ?
                                          <span>
                                            <button type="button" disabled className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                            <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                          </span>
                                          :
                                          website.status == 'Accepted' ?
                                            <span>
                                              <button type="button" className="edit-website btn-primary mt-1 mr-2 edit-accept" onClick={() => this.updateStatus(website.id, 'Accepted')}>Accept</button>
                                              <button type="button" disabled className="edit-website btn-primary mt-1 mr-2 edit-reject" onClick={() => this.updateStatus(website.id, 'Rejected')}>Reject</button>
                                            </span>
                                            :
                                            website.status == 'Completed' ? 'Completed'
                                              :
                                              website.status == 'Pending' ?
                                                <button
                                                  type="button"
                                                  className="btn btn-info"
                                                  onClick={() =>
                                                    this.props.history.push('/websites/orders/upload-content',
                                                      { id: this.state.orderDetails.order_id, userOrderId: website.id, website: website.user_website_details.website, websiteId: website.user_website_details.website_id, edit: website.upload_content ? website.upload_content : null })}
                                                >
                                                  Upload
                                                </button>
                                                : null
                                  }
                                </td>
                                <td>
                                  {website.status}
                                </td>
                                <td>
                                  {this.state.complete_order_status}
                                </td>
                                <td>
                                  {website.status === 'Accepted' || website.status === 'Completed' ?
                                    website.post_url ?
                                      <Fragment>
                                        <div className="profile_pc">
                                          <span style={{ position: 'relative' }} className="pic_actions">
                                            <span className="picture" style={{ whiteSpace: "nowrap", width: "150px", overflow: "hidden", textoverFlow: "ellipsis", display: "block" }}> </span>
                                            <span id="actions" className="actions">
                                              <button type="button" htmlFor="imgUpload" className="btn btn-default btn-sm"><i data-toggle="modal" data-target="#liveurlModel" onClick={() => this.setState({ id: website.id, liveurl: website.post_url })} className="fas fa-pencil-alt"></i>
                                              </button>
                                              <button type="button" style={{ backgroundColor: "#ffc107" }} className="btn btn-warning btn-sm"><i onClick={() => window.open(website.post_url)} className="fas fa-eye"></i></button>
                                            </span>
                                          </span>
                                        </div>
                                      </Fragment> :
                                      <button type="button" className="btn btn-info" data-toggle="modal" data-target="#liveurlModel" onClick={() => this.setState({ id: website.id, liveurl: website.post_url })} >Upload Url</button> : <span style={{ paddingLeft: "30px" }}>----</span>}
                                </td>
                                {/*{this.state.orderstatus == 'Completed' ? 
                                  websiteSelectedVendors !== '' ?
                                  <td>
                                  {(website.vendor_payment_id == null) ? <button type="button" className="update_api btn btn-primary" onClick={()=>{this.paymentDetails(website.id,'vendor')}}>Send VendorId</button>  : <a href><i className="fas fa-check" style={{ color: 'green',paddingLeft:"3rem" }}></i></a>}
                                  </td> : <td style={{paddingLeft:"3rem"}}>----</td> : null
                                }
                                {this.state.orderstatus == 'Completed' ? 
                                  website.user_website_details.ad_type !== 'Article' ? <td style={{paddingLeft:"3rem"}}>----</td> :
                                          </div>
                                        </div>
                                      </div>
                                      <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right" style={{overflowY:'auto',height:'6rem'}}>
                                        <div className=" dropdown-header noti-title pb-3">
                                          <h6 className="text-overflow m-0">Actions</h6>
                                        </div>
                                        <Link to={ this.state.selectedNav === 'admin' ? '/admin/profile': this.state.selectedNav === 'vendors' ? '/vendors/profile' : '/profile' } className="dropdown-item">
                                          <span>Assign Vendor</span>
                                        </Link>
                                        <Link to={this.state.selectedNav === 'admin' ? `/admin/${this.state.userid}/change-password` : this.state.selectedNav === 'vendors' ? `/vendors/${this.state.userid}/change-password` : `/user/${this.state.userid}/change-password` } className="dropdown-item">
                                          <span>Assign Creator</span>
                                        </Link>
                                        <span className="dropdown-
                                  websiteSelectedCreator !== '' ?
                                  <td>
                                  {(website.creator_payment_id == null) ? <button type="button" className="update_api btn btn-primary" onClick={()=>{this.paymentDetails(website.id,'creator')}}>Send CreatorId</button>  : <a href><i className="fas fa-check" style={{ color: 'green',paddingLeft:"3rem" }}></i></a>}
                                  </td> : <td style={{paddingLeft:"3rem"}}>----</td>: null
                                }                                
                                <td style={{ cursor: 'pointer' }}>
                                  {localStorage.getItem('role') === 'admin' ?
                                  <div>
                                    <Fragment>
                                    <DeleteUser userRole="orderwebsite" id={this.state.orderId} price={this.state.propsprice} userorderid={this.state.userorderid}  updateList={this.orderDetails.bind(this)} history={this.props.history} />
                                    <span style={{ marginLeft:"25px" }} data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ userorderid: website.id, propsprice:website.user_website_details.price })}>
                                      <i className="fa fa-trash"></i>
                                    </span>
                                    </Fragment> 
                                    {websiteSelectedVendors &&
                                      <span style={{ paddingLeft:10 }} >
                                        <button type="button" className="update_api btn btn-primary" onClick={() => this.sendEmail(website.id)} >send email </button>
                                      </span>                                                                                     
                                    } 
                                  </div>:
                                  null}                                
                                </td>*/}
                                <td style={{ textAlign: 'center' }}>
                                  <div class="dropdown">
                                    <div id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ cursor: 'pointer' }}>
                                      <i class="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ overflowY: 'auto', height: 'auto', left: 'auto', right: '0rem' }}>
                                      <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { this.assignUser(website, website.status) }}>
                                        <span>Assign Vendor</span>
                                      </span>
                                      {website.user_website_details.ad_type == 'Article' ?
                                        <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { this.assignCreator(website, website.status) }}>
                                          <span>Assign Creator</span>
                                        </span> : null}
                                      {website.user_website_details.assigned_website_vendor ? this.state.orderstatus == 'Completed' ?
                                        <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { this.paymentDetails(website.id, 'vendor', website.vendor_payment_id) }}>
                                          <span>Vendor Payment</span>
                                        </span> : null : null}
                                      {website.user_website_details.ad_type == 'Article' ? website.content_upload_creator ? this.state.orderstatus == 'Completed' ?
                                        <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { this.paymentDetails(website.id, 'creator', website.creator_payment_id) }}>
                                          <span>Creator Payment</span>
                                        </span> : null : null : null}
                                      {website.user_website_details.assigned_website_vendor ?
                                        <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.setState({ invoiced: false }, this.sendEmail(website.id))}>
                                          <span>Send Vendor Email</span>
                                        </span> : null}
                                      <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.deleteUserOrder(website.id, website.user_website_details.price)}>
                                        <span>Delete</span>
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                            }
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content mt-5 mb-5">
          <div className="container-fluid mt--7 mt-5">
            <div className="row mt-5">
              <div className="col-md-6 justify-content-center">
                <div className="card shadow  profile_card order_list_detail">
                  <div className="row card-header marl">
                    <h3>Client Gmail Communication</h3>
                  </div>
                  {this.state.clientChatUpdate ? 'loading ...' :
                    <div className="card-body" style={{ height: '500px', maxHeight: '500px', overflowY: 'auto', backgroundColor: '#e9ecef', overflowX: 'hidden' }}>
                      {this.state.client_msgs && this.state.client_msgs.length ? this.state.client_msgs.map((msg, ind) =>
                        <div key={msg.id}>
                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(item => {
                            if (item.name === "From") {
                              const str = (item.value.split("@").length - 1);
                              const type = (str === 1 && item.value.match(/<(.*?)>/) !== null) ? item.value.match(/<(.*?)>/)[1] : item.value
                              return <div>{
                                type === 'orders@rankcastle.com' ?
                                  <div className='col-md-10 card shadow mb-2' style={{ overflowX: 'hidden' }}>
                                    <div className='mt-3'>
                                      <h6>Rankcastle</h6>
                                      {msg.payload && msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                        value.body && value.body.data && value.mimeType === "text/html" ?
                                          <>
                                            <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                          </>
                                          : value.parts && value.parts.length ? value.parts.map(val => val.mimeType === "text/html" ?
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </> : ''
                                          ) : ''
                                      )} </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className='col-md-10 card shadow mb-2' style={{ marginLeft: '130px', overflowX: 'hidden' }} >
                                    <div className='mt-3'>
                                      <h6>{type}</h6>
                                      {msg.payload && msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                        value.body && value.body.data && value.mimeType === "text/html" ? 
                                          <>
                                            <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                          </>
                                          : value.parts && value.parts.length ? value.parts.map(val => val.mimeType === "text/html" ?
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </> : ''
                                          ) : ''
                                      )}</div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                              }</div>
                            }
                          })}
                        </div>
                      )
                        : <p>{this.state.client_msgs && this.state.client_msgs.message}</p>}
                    </div>}
                </div>
                <div className='col-md-12 card shadow mt-2 mb-2 reply_container'>
                  <textarea placeholder='Reply' rows='4' cols='100' className='reply_ta' value={this.state.replyClientMsg} onChange={(e) => this.setState({ replyClientMsg: e.target.value })} />
                  <button className='reply_btn' onClick={() => this.sendReply('client')}>submit</button>
                </div>
              </div>
              <div className="col-md-6 justify-content-center">
                <div className="card shadow  profile_card order_list_detail">
                  <div className="row card-header marl">
                    <h3>Vendor Gmail Communication</h3>
                  </div>
                  {this.state.vendorChatUpdate ? 'loading ...' :
                    <div className="card-body" style={{ height: '500px', maxHeight: '500px', overflowY: 'auto', backgroundColor: '#e9ecef', overflowX: 'hidden' }}>
                      {this.state.vendor_msgs && this.state.vendor_msgs.length ? this.state.vendor_msgs.map((msg, ind) =>
                        <div key={msg.id}>
                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(item => {
                            if (item.name === "From") {
                              const str = (item.value.split("@").length - 1);
                              const type = (str === 1 && item.value.match(/<(.*?)>/) !== null) ? item.value.match(/<(.*?)>/)[1] : item.value
                              return <div>
                                {type === 'publishers@rankcastle.com' ?
                                  <div className='col-md-10 card shadow mb-2' style={{ overflowX: 'hidden' }}>
                                    <div className='mt-3'>
                                      <h6>Rankcastle</h6>
                                      {msg.payload &&
                                        msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                          value.body && value.body.data && value.mimeType === "text/html" ?  
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </>
                                            : value.parts && value.parts.length ?
                                              value.parts.map(val => val.mimeType === "text/html" ?
                                                <>
                                                  {val.body.data && val.body.data ? <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                                    : val.body.attachmentId && val.body.attachmentId ? <img src={<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.attachmentId.replace(/-/g, '+').replace(/_/g, '/').replace(/\r\n/g, "</br>").replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />} /> : ''}
                                                </> : ''
                                              ) : ''
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  : <div className='col-md-10 card shadow mb-2' style={{ marginLeft: '130px', overflowX: 'hidden' }}>
                                    <div className='mt-3'>
                                      <h6>{type}</h6>
                                      {msg.payload &&
                                        msg.payload.parts && msg.payload.parts.length && msg.payload.parts.map(value =>
                                          value.body && value.body.data && value.mimeType === "text/html" ? 
                                            <>
                                              <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(value.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                            </>
                                            : value.parts && value.parts.length ?
                                              value.parts.map(val => val.mimeType === "text/html" ?
                                                <>
                                                  {val.body.data && val.body.data ? <p>{<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.data.replace(/-/g, '+').replace(/_/g, '/').replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />}</p>
                                                    : val.body.attachmentId && val.body.attachmentId ? <img src={<div dangerouslySetInnerHTML={{ __html: window.atob(val.body.attachmentId.replace(/-/g, '+').replace(/_/g, '/').replace(/\r\n/g, "</br>").replace('&#39;', "'").replace('&lt;', "<").replace('&gt;', ">")) }} />} /> : ''}
                                                </> : ''
                                              ) : ''
                                        )}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p>
                                          {msg.payload && msg.payload.headers && msg.payload.headers.length && msg.payload.headers.map(val =>
                                            val.name === 'Date' && this.timeAgo(new Date(val.value).getTime())
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>}
                              </div>
                            }
                          })}
                        </div>
                      ) : <p>{this.state.vendor_msgs && this.state.vendor_msgs.message}</p>}
                    </div>}
                </div>
                <div>
                </div>
                <div>

                </div>
                <div className='col-md-12 card shadow mt-2 mb-2 reply_container'>
                  <textarea placeholder='Reply' rows='4' cols='100' value={this.state.replyVendorMsg} onChange={(e) => this.setState({ replyVendorMsg: e.target.value })} />
                  <button className='reply_btn' onClick={() => this.sendReply('vendor')}>submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
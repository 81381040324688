import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
class Alert_selfmodel extends Component{
  constructor(props) {
    super(props);
    this.state = {
      message:'',
      orderid:this.props.orderid
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      message:nextProps.msg,
      // orderid:nextProps.orderid
    })

  }
 
  render() {
    return (
      <div className="modal fade" id="alert_selfmodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
            </div>
            <div className="modal-body">
            <center>{this.state.message}</center>
            </div>
            <div className="modal-footer">
            <span data-dismiss="modal" id="alert_selfmodell"></span>
            {localStorage.getItem('role') !== 'admin' ? 
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="alert_selfmodel" onClick={() => {this.props.history.push(`/orders/${localStorage.getItem('orderid')}/details`);}} >Close</button>:
            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="alert_selfmodel" onClick={() => {this.props.history.push(`/admin/orders/${localStorage.getItem('orderid')}/details`);}} >Close</button>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Alert_selfmodel)
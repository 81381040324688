 import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { Link } from 'react-router-dom';
import {ADMIN, Alert_model, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

let orderStatus = ""
export default class Tags extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      tags:[],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      filter:false, 
      tag:'',
      svalue:'',
      idtoggle:false,
      tagtoggle:false,
    }
    this.tags = this.tags.bind(this);
  }

  componentDidMount() {
    if(this.props.location.state){
      this.setState({ 
        filter:this.props.location.state.detail.filter,
        tag:this.props.location.state.detail.tag,
        numberofrows:this.props.location.state.detail.numberofrows,
        currentPage:this.props.location.state.detail.currentPage,
      }, this.tagFilter)        
    }
    else{   
      this.tags()
    }
  }

  tags(page=this.state.currentPage) {
    fetch_get(`/api/tags/list/?page=${page}`)
    .then((response) => {
      if(!response.error) {
        this.setState({ tags: response.tags_list, numOfPages: response.active_num_pages,tag:''  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearStates(){
    this.setState({tag:''},this.tagFilter)
  }

  permanentDelete(id){
    fetch_get(`/api/tag/${id}/delete/`)
    .then((response) => {
      if(!response.error){
        if(response.error == false){
          let d = document.getElementById('success')
          d.click()        
          this.tags()
        }
      }
      else{
        this.tags()        
      }
    })
  }  

  tagFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    this.state.tag && formData.append('name',this.state.tag)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'tag'){
      if(this.state.tagtoggle){
        formData.append('column_name_order','name')
      }
      else{
        formData.append('column_name_order','-name')
      }
    }       
    fetch_post('/api/tags/list/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          tags: response.tags_list, 
          numOfPages: response.active_num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.tagFilter)
  }

  createTag(){
    let formData = new FormData()
    this.state.tag && formData.append('name',this.state.tag)
    fetch_post('/api/tags/create/',formData)
    .then((response) => {
      if(!response.error){
        this.tags();
        this.setState({tag:'',errors:{}})
        let a = document.getElementById("success1");
        a.click();         
        let model = document.getElementById("createModel");
        model.click();
        // this.setState({
        //   tags: response.tags_list, 
        //   numOfPages: response.active_num_pages,
        // })                   
      }
      else{
        this.setState({errors:response.errors})
      }     
    })    
  }

  render(){
    let carryProps={
      filter:this.state.filter,
      tag:this.state.tag,
      numberofrows:this.state.numberofrows,
      currentPage:this.state.currentPage
    }
    return(
      <div className="main-content">
        <TopHeader />
        <div class="col-md-6 m-auto modal fade" id="example_model" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>Tag added Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push({ pathname: '/admin/tags'})}>Close</button>
              </div>
            </div>
          </div>
        </div>        
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>tag Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/tags')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>          
        <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Create tag</h3>
               <div className="row justify-content-center">
                </div>
                <form id="form1">
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>Tag Name <div className="error-text">*</div></label>
                        <input 
                          type="text" 
                          className="form-control" 
                          value={this.state.tag}
                          onChange={(e) => this.setState({ tag: e.target.value })}
                          placeholder="tag"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.name}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>
                  <div className="row justify-content-center">
                    <span data-toggle="modal" data-target="#example_model" id="success1"></span>
                    <button type="button" className="btn btn-info" onClick={() => this.createTag()} >Create</button>
                    <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal">Cancel</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>tag Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/categories')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="success"> </span>    
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.tagFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Tags</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal2">Create tag</button>                  
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>                  
                </div>
                  {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.tag}
                                      onChange={(e) => this.setState({ tag: e.target.value }, this.tagFilter)}
                                      placeholder="tag"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.tagFilter)} >Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({tagtoggle:!this.state.tagtoggle,svalue:'tag'},this.tagFilter)}>Tag <i className="fas fa-sort"></i></th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.tags.length > 0 ? this.state.tags.map((tag, index) =>
                        <tr key={tag.id}>
                          <td><a href>{tag.id}</a></td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td>{tag.name}</td>
                          <td>
                            <Link to={{pathname:`/admin/tag/${tag.id}/edit/`,state:{tag:tag.name,id:tag.id,detail:carryProps}}}>
                              <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                            </Link>
                            <a onClick={() => this.permanentDelete(tag.id)}>
                              <span style={{paddingRight:20, cursor:'pointer' }}><i className="fa fa-trash"></i></span>
                            </a>                            
                          </td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

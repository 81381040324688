import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { LOGOUT, USER, fetch_get, DOMAIN_NAME } from '../common';

class TopHeader extends Component{
  constructor(props) {
    super(props);
    this.state = {
      selectedNav: window.location.pathname.split('/')[1],
      role: localStorage.getItem('role'),
      text: '',
      username:'',
      userid:localStorage.getItem('UserId'),
      showSearch: this.props.showSearch === false ? false : true,
      subscriptions:[],
      showUpgrade:this.props.isupgrade ? true : false,
    }
  }

  logout() {
    const { role, selectedNav } = this.state;
    fetch_get(LOGOUT)
    .then((response) => {
      if(!response.error) {
        localStorage.clear()
        if(selectedNav === 'vendors' && role === 'vendors') {
          this.props.history.push('/vendors/signin/')
        } else if(selectedNav === 'admin' && role === 'admin'){
          this.props.history.push('/admin/signin/')
        }else if(selectedNav === 'creator' && role === 'creator'){
          this.props.history.push('/creator/signin/')
        }else {
          if(role === 'client') {
            this.props.history.push('/signin/')
          }
        }
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  handleChange(e) {
    this.setState({ text: e.target.value }, 
      () => {this.props.isSearch && this.props.search(this.state.text)}
    )
  }

  upgradeTo(){
    this.props.upgrade(true)
  }

  componentDidMount(){
    const { role, selectedNav } = this.state;
    if(localStorage.getItem('UserName')===null){
      fetch_get(USER+this.state.userid+'/details/')
      .then((response) => {
        if(response){
          if(response.detail === 'Invalid token.') {
            localStorage.clear()
            if(selectedNav === 'vendors' && role === 'vendors') {
              this.props.history.push('/vendors/signin/')
            } else if(selectedNav === 'admin' && role === 'admin'){
              this.props.history.push('/admin/signin/')
            } else if(selectedNav === 'creator' && role === 'creator'){
              this.props.history.push('/creator/signin/')
            } else {
              if(role === 'client') {
                this.props.history.push('/signin/')
              }
            }
          } 
           // else {
           // window.$crisp.push(["set", "user:email", response.user_details.email]);
           // this.setState({ username: response.user_details.username})
           // }
        }
        })
      }
    else{
      this.setState({
        username :localStorage.getItem('UserName')
      })
    }
  }  

  render() { 
    return(
      <nav className="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
        <div className="container-fluid">
          <form className="navbar-search search_bar navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
          {this.state.showSearch &&
            <div className="form-group mb-0">
              <div className="input-group input-group-alternative">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-search"></i></span>
                </div>
                <input className="form-control" placeholder="Search" type="text" value={this.state.text} onChange={this.handleChange.bind(this)} />
              </div>
            </div>
          }
          {(localStorage.getItem('subscription') == 'Free' && this.state.showUpgrade) &&
            <div style={{color:'white',paddingLeft:"1rem",cursor:'pointer'}} onClick={this.upgradeTo.bind(this)}>Upgrade to Pro </div>
          }
        </form>         
          <ul className="navbar-nav align-items-center d-none d-md-flex">
            <li className="nav-item dropdown">
              <Link to={{ pathname:"/profile" }} className="nav-link pr-0" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="media align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img alt="" src={DOMAIN_NAME+localStorage.getItem('profile_Pic')}/>  
                  </span>
                 <span className="text-sm ml-1 font-weight-bold">{this.state.username} </span>
                  <div className="media-body ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm  font-weight-bold"></span>
                  </div>
              </div>
              </Link>
              <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-right">
                <div className=" dropdown-header noti-title pb-3">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </div>
                <Link to={ this.state.selectedNav === 'admin' ? '/admin/profile': this.state.selectedNav === 'vendors' ? '/vendors/profile' : '/profile' } className="dropdown-item">
                  <i className="ni ni-single-02"></i>
                  <span>My profile</span>
                </Link>
                <Link to={this.state.selectedNav === 'admin' ? `/admin/${this.state.userid}/change-password` : this.state.selectedNav === 'vendors' ? `/vendors/${this.state.userid}/change-password` : `/user/${this.state.userid}/change-password` } className="dropdown-item">
                  <i className="ni ni-key-25"></i>
                  <span>Change Password</span>
                </Link>
                <span className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => this.logout()}>
                  <i className="ni ni-user-run"></i>
                  <span>Log out</span>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    )
  }
}

export default withRouter(TopHeader);

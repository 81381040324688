import React, { Component } from 'react';
import Select from 'react-select';
import { DOMAIN_NAME, WEBSITE, fetch_get, fetch_post, ADMINWEBSITE, Alert_model } from '../common';
import TopHeader from '../common/TopHeader';

let nicheList = [];
let UsersList = [];
export default class ManageWebsiteEdit extends Component{
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: [],
      selectedOption: null,
      vendorsList: [],
      selectedUsers: [],
      price: "",
      nicheText: '',
      nicheChoices: [],
      nicheDropDown: false,
      userDropDown: false,
      SelectedVendorsIds: [],
      vendorIds: [],
      categories:[],
      selectedCategories:[],
      language:'',
      linksType:'',
      languages:[],
    }
    this.handleSelectedCategories = this.handleSelectedCategories.bind(this)
  }

  componentDidMount() {
    this.websiteDetails()
  }

  handleChange(selectedUsers){
    this.setState({ selectedUsers });
  }

  websiteDetails() {
    fetch_get(`${WEBSITE}${this.props.match.params.id}/update/ `)
    .then((response) => {
      let SelectedVendorsIds = []
      let vendorIds = []
      let categories = []
      let selectedCategories = []
      response.niche_choices.map((category)=>{
        categories.push({ label: category.categories__name, value: category.categories__id })
      })  
      response.website_details.categories.map((category)=>{
        selectedCategories.push({label:category.name, value:category.id})
      })    
      if((response.website_details.assigned_vendor_details && response.website_details.assigned_vendor_details.length > 0)) {
        for(let i in response.website_details.assigned_vendor_details) {
          SelectedVendorsIds.push(response.website_details.assigned_vendor_details[i].user__id)
        }
      }
      if((response.vendors_list && response.vendors_list.length > 0)) {
        for(let i in response.vendors_list) {
          vendorIds.push(response.vendors_list[i].id)
        }
      }
 
      this.setState({ 
        categories:categories,
        // linksType: response.website_details.links_type,
        blocked:response.website_details.is_blocked,
        // language:response.website_details.language,
        websiteDetails: response.website_details, 
        vendorsList: response.vendors_list,
        vendorIds,
        SelectedVendorsIds,
        nicheChoices: response.niche_choices,
        // nicheText: response.website_details.categories,
        selectedCategories:selectedCategories,
        languages:response.languages,
      })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websiteUpdate() {
    const { websiteDetails, SelectedVendorsIds, nicheText } = this.state;
    let formData = new FormData()
    formData.append('domain', websiteDetails.domain)
    this.state.language && formData.append('language',this.state.language)
    formData.append('links_type', this.state.websiteDetails.links_type)
    formData.append('is_blocked', this.state.blocked)
    let categories = [];
    this.state.selectedCategories.length > 0 && this.state.selectedCategories.map((category) => 
      categories.push(category.value)
    )
    if(categories.length > 0) {
      formData.append('categories', JSON.stringify(categories));
    }  
    // formData.append('categories', nicheText)
    // formData.append('assigned_vendors_list', JSON.stringify(SelectedVendorsIds))
    fetch_post(`${WEBSITE}${this.props.match.params.id}/update/ `, formData)
    .then((response) => {
      if(!response.error) {
        // alert('website updated successfully')
        let model = document.getElementById('success')
        model.click()
        // this.props.history.push('/admin/managewebsites')
      }
      else{
        this.setState({errors:response.errors})
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  onSearch(action, e) {
    if(action === 'user') {
      let value = e.target.value
      this.setState({ userText: value });
      let filteredValues = UsersList.filter(function (user) {
        return (user.username.toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ usersList: filteredValues, userDropDown: true })
    } else {
      let value = e.target.value
      this.setState({ nicheText: value });
      let filteredValues = nicheList.filter(function (niche) {
        return (niche[0].toLowerCase().includes(value.toLowerCase()));
      });
      this.setState({ nicheChoices: filteredValues, nicheDropDown: (filteredValues.length) > 0 ? true : false })
    }
  }

  usersUpdate(user, index) {
    if(this.state.userIds.includes(user.id)) {
      this.state.userIds.splice(this.state.userIds.indexOf(user.id), 1 );
      this.state.selectedUsers.forEach((selectedUser, index) => {
        if(selectedUser.id === user.id) {
          this.state.selectedUsers.splice(index, 1)
        }
      })
      this.setState({ userIds: this.state.userIds, selectedUsers: this.state.selectedUsers})
    } else {
      this.setState({ selectedUsers: [...this.state.selectedUsers, user], userIds: [...this.state.userIds, user.id] })
    }
    // this.setState({ labelSearchText: '', allLabels: labelsList });
  }

  onChangeVendor(id) {
    if(this.state.SelectedVendorsIds.includes(parseInt(id))) {
      let indexx = this.state.SelectedVendorsIds.indexOf(parseInt(id))
      this.state.SelectedVendorsIds.splice(indexx, 1);
      this.setState({ SelectedVendorsIds: this.state.SelectedVendorsIds})
    } else {
      this.setState({ SelectedVendorsIds: [...this.state.SelectedVendorsIds, parseInt(id)] })
    }
  }

  handleSelectedCategories(value){
    this.setState({selectedCategories:value})
  }

  render(){
    const { websiteDetails, nicheChoices, vendorsList } = this.state;
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        {this.state.errors ? <Alert_model msg={"Website Updated successfully"} redirecturl={true} history={this.props.history} managewebsites={true} /> : null}
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          {websiteDetails.id !== null &&
            <div className="col-md-12 m-auto instructions_col website_details">
              <div className="card shadow row mb-6">
                <div className="row">
                  <div className="card-header col-md-6 bg-white border-0">
                    <h3 className="mb-0">Website edit</h3>
                  </div>
                  <div className="col-md-6 text-right">
                    <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.goBack() }>
                      Back
                    </button>              
                  </div>                    
                </div>
                <div className="card-body">
                  <form>
                    <div className="pt-4">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label className="form-control-label" htmlFor="input-email">Domain</label>
                            <input type="email" id="input-email" className="form-control form-control-alternative" defaultValue={websiteDetails.domain} disabled />
                            <div className="text-danger">
                              {this.state.errors && this.state.errors.domain}
                            </div>
                          </div>
                        </div> 
                        <div className="col-lg-6">
                          <div className="form-group mar-bot-5">
                            <label className="form-control-label" htmlFor="input-email">Category<div className="error-text">*</div></label>
                            <div className="input-group mb-3 dropdown no-caret">
                            <Select 
                              className="form-control"
                              isMulti
                              value={this.state.selectedCategories}
                              onChange={this.handleSelectedCategories}
                              options={this.state.categories}
                            />                              
                           </div>
                            <div className="text-danger">
                              {this.state.errors.website_errors && this.state.errors.website_errors.categories}
                            </div>                             
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group focused">
                            <label className="form-control-label" htmlFor="input-username">Website Id</label>
                            <input type="text" id="input-username" className="form-control form-control-alternative" defaultValue={websiteDetails.id} disabled />
                          </div>
                        </div>                                                
                        <div className="col-lg-4">
                          <div className="form-group mar-bot-5">
                            <label className="form-control-label" htmlFor="input-email">Language</label>
                            <select
                              className="form-control"
                              value={this.state.websiteDetails.language}
                              onChange={(e) => this.setState({ language: e.target.value })}
                            >
                            <option value=""> Select Language </option>
                            {this.state.languages.map((lang)=>
                              <option value={lang}>{lang}</option>
                            )}
                            </select>
                          </div>
                        </div>                                               
                      </div>
                    </div>
                    <div>
                      <div className="row pb-3">
                        <div className="col-md-12">
                          <div className="form-group focused">
                            <div className="row no-gutters">
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  value={websiteDetails.accept_gambling}
                                  checked={websiteDetails.accept_gambling || false}
                                  disabled
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Accept Gambling</label>
                              </div>
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  value={websiteDetails.accept_thc}
                                  checked={websiteDetails.accept_thc || false}
                                  disabled
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Accept THC</label>
                              </div>
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  value={websiteDetails.accept_weight_loss}
                                  checked={websiteDetails.accept_weight_loss || false}
                                  disabled
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Accept Weight Loss</label>
                              </div>
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  value={websiteDetails.accept_adult}
                                  checked={websiteDetails.accept_adult || false}
                                  disabled
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Accept Adult</label>
                              </div>
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label" 
                                  value={websiteDetails.accept_link_insertion_in_existing_post}
                                  checked={websiteDetails.accept_link_insertion_in_existing_post || false}
                                  disabled
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Accept Link insertion</label>
                              </div>
                              <div className="col-md-3 mt-3">
                                <input 
                                  type="checkbox" 
                                  className="col-sm-1 col-form-label"
                                  id="blocked"
                                  checked={this.state.blocked  ? true : false}
                                  onChange={(e)=>this.setState({blocked:document.getElementById('blocked').checked ? true : false})}
                                />
                                <label htmlFor="staticEmail" className="col-sm-11 col-form-label p-0">Blocked</label>
                              </div>                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p className="m-3">
                      <button className="btn btn-info" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                      Assigned Vendors
                      </button>
                    </p> */}
                    <div className="collapse" id="collapseExample">
                      <div className="card card-body">
                        <div className="table-responsive">
                          <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col"> 
                                  <input 
                                    type="checkbox" 
                                    className="" 
                                    id={this.state.SelectedVendorsIds.length}
                                    checked={this.state.SelectedVendorsIds.length === this.state.vendorIds.length} 
                                    value={this.state.SelectedVendorsIds.length}
                                    onChange={(e) => this.setState({ SelectedVendorsIds: (this.state.SelectedVendorsIds.length === this.state.vendorIds.length) ? [] : this.state.vendorIds})}
                                  />
                                </th>
                                <th scope="col">S.No</th>
                                <th scope="col">Id</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">User Name</th>
                              </tr>
                            </thead>
                              <tbody>
                              {(vendorsList.length > 0) && vendorsList.map((vendor, index) => 
                                <tr key={vendor.id}>
                                  <td> <input type="checkbox" className="" value={vendor.id} onClick={() => this.onChangeVendor(vendor.id)} checked={this.state.SelectedVendorsIds.includes(vendor.id)}  /> </td> 
                                  <td> {index+1} </td>
                                  <td>{vendor.id}</td>
                                  <td> {vendor.first_name} </td>
                                  <td> {vendor.email} </td>
                                  <td> {vendor.username} </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="col-md-12">
                      <div className="text-center">
                        <span data-toggle="modal" data-target="#Alert_model" id="success"> </span>
                        <button type="button" className="btn btn-info mb-3" onClick={() => this.websiteUpdate()}>Update Details</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>  
            </div>
          }
        </div>
      </div>
    )
  }
}

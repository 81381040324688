import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import { ORDER, fetch_get, fetch_post, Pagination, Alert_model } from '../common';

let msgUsers = ''
let errors={}
let adtype_data={}
let promo_adtype_data={}
let csv_field = []

export default class Csvmap extends Component{
  constructor(props) {
    super(props);
    this.state = {
      mapData:{},
      pre_map_adtype:{},
      pre_map_promo_adtype_prices:{},
      currentPage: 1,
      numOfPages: 0,
      vendor:'',
      domain:'',
      client_note:'',
      price:'',
      categories:'',
      Disclosure:'',
      ad_type:'',
      permanent_article:'',
      writing_included:'',
      minimum_word_count:'',
      maximum_number_of_links_in_post:'',
      example_post:'',
      links_type:'',
      special_notes:'',
      crypto_price:'',
      tat:'',
      errors:{},
      websitecount:'',
      msgusers:'',
      db_fields:'',
      upload_csv:'',
      vendors_list:'',
      form:{},
      link_insertion_price:'',
      gamblin_link_price:'',
      cbd_marijuana_link_price:'',
      adult_link_price:'',
      weight_loss_link_price:'',
      partners_page_link_price:'',
      loading:false,
      csv_fields:[],
      is_verified_vendor:'',
      promo_price:'',
      promo_linkinsert:'',
      promo_gambling:'',
      promo_cbd:'',
      Promo_weight_loss:'',
      promo_adult:'',
      promo_crypto:'',
      promo_adtype_prices:''
    }
  }

  componentWillMount(){   
    if(this.props.location.state){
      this.setState({db_fields:this.props.location.state.db_fields,upload_csv:this.props.location.state.upload_csv,vendors_list:this.props.location.state.vendors_list,csv_fields:this.props.location.state.csv_fields})
    }
    else
      this.props.history.push('/admin/managewebsites')
  }

  componentDidMount(){
    if(this.props.location.state){
      // console.log(this.props.location.state,'csv')
      let csv_fields = this.props.location.state.csv_fields
      // console.log('csv_fields-64',this.props.location.state)
      var tempData = {}
      var adtype_data={}
      var promo_adtype_data={}
      if(csv_fields.length > 0){
        var word
        for(word of csv_fields){ 
         if('domain' == word.toLowerCase()){
          tempData = {
            ...tempData, ['domain']:word,
          }
          this.setState({domain:word})
         }
         else if('price' == word.toLowerCase()){
          tempData = {
            ...tempData, ['price']:word,
          }        
          this.setState({price:word})
         }
         else if('client_note' == word.toLowerCase()){
          tempData = {
            ...tempData, ['client_note']:word,
          }
          this.setState({client_note:word})
         }
         else if('categories' == word.toLowerCase()){
          tempData = {
            ...tempData, ['categories']:word,
          } 
          this.setState({categories:word})
         } 
         else if('disclosure_tag' == word.toLowerCase() || 'disclosure' == word.toLowerCase()){
          tempData = {
            ...tempData, ['disclosure_tag']:word,
          }         
          this.setState({Disclosure:word})
         }
         else if('ad_type' == word.toLowerCase()){
          tempData = {
            ...tempData, ['ad_type']:word,
          }         
          this.setState({ad_type:word})
         }
         else if('permanent_article' == word.toLowerCase()){
          tempData = {
            ...tempData, ['permanent_article']:word,
          }         
          this.setState({permanent_article:word})
         }
         else if(word.toLowerCase().includes('writing')){
          tempData = {
            ...tempData, ['writing_included']:word,
          }
          this.setState({writing_included:word})
         }
         else if('minimum_word_count' == word.toLowerCase()){
          tempData = {
            ...tempData, ['minimum_word_count']:word,
          }         
          this.setState({minimum_word_count:word})
         } 
         else if('maximum_number_of_links_in_post' == word.toLowerCase()){
          tempData = {
            ...tempData, ['maximum_number_of_links_in_post']:word,
          }         
          this.setState({maximum_number_of_links_in_post:word})
         }
         else if('example_post' == word.toLowerCase()){
          tempData = {
            ...tempData, ['example_post']:word,
          }         
          this.setState({example_post:word})
         }
         else if('links_type' == word.toLowerCase() || 'links_type (dofollow nofollow)' == word.toLowerCase() || 'links_type(dofollow nofollow)' == word.toLowerCase() || 'link_type (dofollow nofollow)' == word.toLowerCase()){
          tempData = {
            ...tempData, ['links_type']:word,
          } 
          this.setState({links_type:word})
         } 
         else if('special_notes' == word.toLowerCase() || 'special_note' == word.toLowerCase() ){
          tempData = {
            ...tempData, ['special_notes']:word,
          }         
          this.setState({special_notes:word})
         }   
         else if('tat' == word.toLowerCase()){
          tempData = {
            ...tempData, ['tat']:word,
          }         
          this.setState({tat:word})
         } 
         else if('link insertion price' == word.toLowerCase() || 'link insertion price' == word.toLowerCase()){
          adtype_data = {
            ...adtype_data, ['Link insertion']:word,
          }         
          this.setState({link_insertion_price:word})
         }
         else if('crypto price' == word.toLowerCase() || 'crypto price' == word.toLowerCase()){
          adtype_data = {
            ...adtype_data, ['Crypto']:word,
          }         
          this.setState({crypto_price:word})
         }
         else if('gambling link price' == word.toLowerCase() || 'gamblin link price' == word.toLowerCase() ){
          adtype_data = {
            ...adtype_data, ['Gambling Link']:word,
          }         
          this.setState({gamblin_link_price:word})
         }
         else if('cbd marijuana link price' == word.toLowerCase()){
          adtype_data = {
            ...adtype_data, ['CBD Marijuana Link']:word,
          }         
          this.setState({cbd_marijuana_link_price:word})
         }   
         else if('weight loss link price' == word.toLowerCase()){
          adtype_data = {
            ...adtype_data, ['Weight Loss Link']:word,
          }         
          this.setState({weight_loss_link_price:word})
         }  
         else if('adult link price' == word.toLowerCase() || 'adults link price' == word.toLowerCase() ){
          adtype_data = {
            ...adtype_data, ['Adult Link']:word,
          }         
          this.setState({adult_link_price:word})
         }
         else if('partner page link price' == word.toLowerCase() || 'partners page link price' == word.toLowerCase() ){
          adtype_data = {
            ...adtype_data, ['Partners Page Link']:word,
          }
          this.setState({partners_page_link_price:word})
          }
          else if('vendor' == word.toLowerCase() || 'vendor' == word.toLowerCase() ){
            tempData = {
              ...tempData, ['vendor']:word,
            }
            this.setState({vendor:word})
            }
            else if('promo_price' == word.toLowerCase() || 'promo_price' == word.toLowerCase() ){
              tempData = {
                ...tempData, ['promo_price']:word,
              }
              this.setState({promo_price:word})
              }
              else if('promo_linkinsert' == word.toLowerCase() || 'promo_linkinsert' == word.toLowerCase() ){
                promo_adtype_data = {
                  ...promo_adtype_data, ['Link insertion']:word,
                }
                this.setState({promo_linkinsert:word})
                }
                else if('promo_gambling' == word.toLowerCase() || 'promo_gambling' == word.toLowerCase() ){
                  promo_adtype_data = {
                    ...promo_adtype_data, ['Gambling Link']:word,
                  }
                  this.setState({promo_gambling:word})
                  }
                  else if("promo_cbd" == word.toLowerCase() || "promo_cbd" == word.toLowerCase() ){
                    promo_adtype_data = {
                      ...promo_adtype_data, ['CBD Marijuana Link']:word,
                    }
                    this.setState({"promo_cbd":word})
                    }
                    else if('Promo_weight_loss' == word.toLowerCase() || 'Promo_weight_loss' == word.toLowerCase() ){
                      promo_adtype_data = {
                        ...promo_adtype_data, ['Weight Loss Link']:word,
                      }
                      this.setState({Promo_weight_loss:word})
                      }
                      else if('promo_adult' == word.toLowerCase() || 'promo_adult' == word.toLowerCase() ){
                        promo_adtype_data = {
                          ...promo_adtype_data, ['Adult Link']:word,
                        }
                        this.setState({promo_adult:word})
                        }
              else if('promo_crypto' == word.toLowerCase() || 'promo_crypto' == word.toLowerCase() ){
                promo_adtype_data = {
                  ...promo_adtype_data, ['Crypto']:word,
                }
                this.setState({promo_crypto:word})
              }
            else if('is_verified_vendor' == word.toLowerCase() || 'is_verified_vendor' == word.toLowerCase() ){
              tempData = {
                ...tempData, ['is_verified_vendor']:word,
              }
              this.setState({is_verified_vendor:word})
              }

      }}
      this.setState({
        mapData: {
          ...tempData
        },pre_map_adtype:{...adtype_data},pre_map_promo_adtype_prices:{...promo_adtype_data}
      })
    }
    else
      this.props.history.push('/admin/managewebsites')
  }

  mapping(){
    let map_data = this.state.mapData
    let k=0;
    for(k in this.state.pre_map_adtype){

      if(this.state.pre_map_adtype[k] == ''){
        delete this.state.pre_map_adtype[k]
      } 
    }    
    let i=0;
    for(i in this.state.mapData){
      if(this.state.mapData[i] === ''){
        delete this.state.mapData[i]
      } 
    } 
    if(!(Object.keys(this.state.pre_map_adtype).length === 0)){
      map_data.ad_type = this.state.pre_map_adtype
    }

    let p=0;
    for(p in this.state.pre_map_promo_adtype_prices){

      if(this.state.pre_map_promo_adtype_prices[p] == ''){
        delete this.state.pre_map_promo_adtype_prices[p]
      } 
    }    
   
    if(!(Object.keys(this.state.pre_map_promo_adtype_prices).length === 0)){
      map_data.promo_adtype_prices = this.state.pre_map_promo_adtype_prices
    }
    // if(!(Object.keys(this.state.map_adtype).length === 0)){
    //   map_data.ad_type = {...map_data.ad_type, ...this.state.map_adtype}
    // }

    const data = new FormData();
    data.append('map_fields',JSON.stringify(map_data))
    data.append('upload_csv',this.state.upload_csv)
    // data.append('vendors_list',this.state.vendors_list)
    fetch_post('/api/csv_field_mapping/',data)
    .then((response) => {
      if(!response.error) {
        // msgUsers = response.vendors_websites_count_details.map((name)=>
        // name.user__username + " - " + name.website__count +"\n").join('')
        // this.setState({loading:false, websitecount:response.new_websites_count, msgusers:msgUsers})
        this.setState({loading:false, websitecount:response.new_websites_count})
        let a = document.getElementById('success')
        a.click()
      }
      else{
        if(!('domain' in errors) && this.state.domain === ''){
          errors.domain = 'please select valid option'
        }
        else{
          if(this.state.domain !== '')
          delete errors.domain
        }
        this.setState({errors:errors})
        window.scrollTo(0,120);
      }
    })
    .catch((error) => {
      this.setState({ error });
    });        
  }

  render(){ 
    return(
      <div className="main-content">
        {(this.props.location.state)&&
        <Fragment>
          <TopHeader />
          <Alert_model msg={ "New Websites Count Is - " + this.state.websitecount +"  " +  this.state.msgusers} managewebsites={true} redirecturl={true} history={this.props.history}/>
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <div className="container-fluid mt--5">
            <div className="row main_header card shadow">
              <div className="col-lg-12">
                <div className="">
                  <div className="card-header bg-white border-0">
                      <h3 className="mb-0">Instructions</h3>
                  </div>
                  <div className="card-body">
                    <ul className="instructions">
                      <li>Select fields from your CSV files to map against product field, or to ignore during import</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 card shadow p-4 csv_instruction">
                  <div className="row justify-content-center mb-0">
                    <div className="col-lg-6">
                      <h3 className="map-0">Column Name</h3>
                    </div>
                    <div className="col-lg-6">
                      <h3 className="mb-0">Map to field</h3>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Domain</h6>
                      <a>sample:Micropyramid.com</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.domain}
                          name='domain'
                          onChange={(e)=> this.setState({domain:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                      
                        >
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                     
                        </select>                    
                      </div>
                      <div className="text-danger">
                        {this.state.errors.domain ? this.state.errors.domain:''}
                      </div>                      
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">price</h6>
                      <a>sample:45</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.price}
                          name='price'
                          onChange={(e)=> this.setState({price:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>                    
                      </div>
                      <div className="text-danger">
                        {this.state.errors.price ? this.state.errors.price : ''}
                      </div>                      
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Client_note</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Client_note'
                          value={this.state.client_note}
                          onChange={(e)=> this.setState({client_note:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}
                        >
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">categories</h6>
                      <a>sample:general</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.categories}
                          name='categories'
                          onChange={(e)=> this.setState({categories:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">disclosure_tag</h6>
                      <a>sample:yes/no</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.Disclosure}
                          name='disclosure_tag'
                          onChange={(e)=> this.setState({Disclosure:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">permanent_article</h6>
                      <a>sample:yes/no</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.permanent_article}
                          name='permanent_article'
                          onChange={(e)=> this.setState({permanent_article:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">writing_included</h6>
                      <a>sample:yes/no</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.writing_included}
                          name='writing_included'
                          onChange={(e)=> this.setState({writing_included:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">minimum_word_count</h6>
                      <a>sample:6</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          value={this.state.minimum_word_count}
                          name='minimum_word_count'
                          onChange={(e)=> this.setState({minimum_word_count:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        >   
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">maximum_number_of_links_in_post</h6>
                      <a>sample:6</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='maximum_number_of_links_in_post'
                          value={this.state.maximum_number_of_links_in_post}
                          onChange={(e)=> this.setState({maximum_number_of_links_in_post:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>
                      </div>
                    </div> 
                    <div className="col-lg-6">
                      <h6 className="mt-4">example_post</h6>
                      <a>sample:http://www.micropyramid.com</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='example_post'
                          value={this.state.example_post}
                          onChange={(e)=> this.setState({example_post:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">links_type</h6>
                      <a>sample:dofollow</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='links_type'
                          value={this.state.links_type}
                          onChange={(e)=> this.setState({links_type:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                       
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">special_notes</h6>
                      <a>sample:description</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='special_notes'
                          value={this.state.special_notes}
                          onChange={(e)=> this.setState({special_notes:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                          
                        > 
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>  
                    <div className="col-lg-6">
                      <h6 className="mt-4">tat</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='tat'
                          value={this.state.tat}
                          onChange={(e)=> this.setState({tat:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Link insertion Price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Link insertion'
                          value={this.state.link_insertion_price}
                          onChange={(e)=> this.setState({link_insertion_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div> 
                    <div className="col-lg-6">
                      <h6 className="mt-4">Gambling Link Price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Gambling Link'
                          value={this.state.gamblin_link_price}
                          onChange={(e)=> this.setState({gamblin_link_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div> 
                    <div className="col-lg-6">
                      <h6 className="mt-4">CBD Marijuana Link price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='CBD Marijuana Link'
                          value={this.state.cbd_marijuana_link_price}
                          onChange={(e)=> this.setState({cbd_marijuana_link_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>                     
                    <div className="col-lg-6">
                      <h6 className="mt-4">Weight Loss Link price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Weight Loss Link'
                          value={this.state.weight_loss_link_price}
                          onChange={(e)=> this.setState({weight_loss_link_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Adult Link price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Adult Link'
                          value={this.state.adult_link_price}
                          onChange={(e)=> this.setState({adult_link_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Partners Page Link price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Partners Page Link'
                          value={this.state.partners_page_link_price}
                          onChange={(e)=> this.setState({partners_page_link_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}
                        >
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">vendor</h6>
                      <a>sample:sample@gmail.com</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='vendor'
                          value={this.state.vendor}
                          onChange={(e)=> this.setState({vendor:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}
                        >
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Crypto Price</h6>
                      <a>sample:5</a>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Crypto'
                          value={this.state.crypto_price}
                          onChange={(e)=> this.setState({crypto_price:e.target.value, pre_map_adtype:{
                            ...this.state.pre_map_adtype,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_price</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_price'
                          value={this.state.promo_price}
                          onChange={(e)=> this.setState({promo_price:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_linkinsert</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_linkinsert'
                          value={this.state.promo_linkinsert}
                          onChange={(e)=> this.setState({promo_linkinsert:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_gambling</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_gambling'
                          value={this.state.promo_gambling}
                          onChange={(e)=> this.setState({promo_gambling:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_cbd</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_cbd'
                          value={this.state.promo_cbd}
                          onChange={(e)=> this.setState({promo_cbd:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Promo_weight_loss</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='Promo_weight_loss'
                          value={this.state.Promo_weight_loss}
                          onChange={(e)=> this.setState({Promo_weight_loss:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_adult</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_adult'
                          value={this.state.promo_adult}
                          onChange={(e)=> this.setState({promo_adult:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">promo_crypto</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='promo_crypto'
                          value={this.state.promo_crypto}
                          onChange={(e)=> this.setState({promo_crypto:e.target.value, pre_map_promo_adtype_prices:{
                            ...this.state.pre_map_promo_adtype_prices,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6 className="mt-4">Is Vendor Verified</h6>
                      <a>sample:5</a>
                    </div> 
                    <div className="col-lg-6">
                      <div className="form-group mt-4">
                        <select className="form-control"
                          name='is_verified_vendor'
                          value={this.state.is_verified_vendor}
                          onChange={(e)=> this.setState({is_verified_vendor:e.target.value, mapData:{
                            ...this.state.mapData,
                            [e.target.name]: e.target.value
                          }})}                                                
                        >  
                        <option value="">select</option>
                        {this.state.csv_fields.map((val)=>
                          <option value={val}>{val}</option>
                        )}                      
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-12 text-right mt-3">
                      <span data-toggle="modal" data-target="#Alert_model" id="success"></span>
                        {this.state.loading == false ? 
                        <button type="button" class="btn btn-primary" onClick={()=>{this.setState({loading:true},this.mapping())}}>Run the importer</button>:
                        <div style={{paddingLeft:"30rem"}}>
                          <div className="spinner task">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
  }
}
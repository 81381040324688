import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import TopHeader from '../common/TopHeader';
import { VENDORS, CREATORS, fetch_get, Pagination, fetch_post, USER, Alert, Alert_model } from '../common';
import { Link } from 'react-router-dom';
import AuthenticationModel from './AuthenticationModel';
import DeleteUser from './DeleteUser';

export default class Creators extends Component{
  constructor(props) {
    super(props);
    this.state = {
      activeCreatorsList: [],
      inActiveCreatorsList: [],
      activecurrentPage: 1,
      activenumOfPages: 0,
      inActivecurrentPage: 1,
      inActivenumOfPages: 0,
      deleteId: null,
      currentPage: 1,
      numOfPages: 0,
      active: true,
      filter: false,
      restore: false,
      permanentDelete:false,  
      alertmessage:'',
      numberofrows:100,
      count:0,
      username_count:0,
      email_count:0,
      first_name_count:0,
      date_joined_count:0,
      last_login_count:0,
      tags:[],
      selectedTags:[]               
    }
    this.creatorsList = this.creatorsList.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.creatorsList()
    this.clearStates()
  }

  clearStates(filter=false) {
    this.setState({
      id: '',
      email: '',
      firstname: '',
      lastname: '',
      searchText: '',
      selectedTags:[]
    }, () => { filter && this.creatorFilter()})
  }

  creatorsList(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage, searchText=this.state.searchText) {
    fetch_get(`${CREATORS}list/?page1=${page1}&page2=${page2}`)
    .then((response) => {
        if(!response.error) {
        // let tags=[]
        // response.tags.map((tag)=>{
        //   tags.push({ label: tag.name, value: tag.id })
        // })        
        this.setState({ 
          activeCreatorsList: response.active_creators_list, 
          inActiveCreatorsList: response.inactive_creators_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inActivenumOfPages: response.inactive_num_pages,
          // tags:tags 
        })
        let model = document.getElementById("filterModel");
        model.click();
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
  //   let formData = new FormData()
  //   this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   formData.append('page1',this.state.activecurrentPage)
  //   formData.append('page2',this.state.inactivecurrentPage)    
  //   fetch_post(`${VENDORS}list/`,formData)
  //   .then((response) => {
  //     if(!response.error){
  //       this.setState({
  //         activeVendorsList: response.active_vendors_list, 
  //         inActiveVendorsList: response.inactive_vendors_list, 
  //         deleteId: null,
  //         activenumOfPages: response.active_num_pages, 
  //         inActivenumOfPages: response.inactive_num_pages 
  //       })                   
  //     }
  //   })
  // }  

  creatorFilter(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage, searchText=this.state.searchText) {
    let formData = new FormData()
    formData.append('page1', page1)
    formData.append('page2', page2)
    this.state.searchText && formData.append('username', searchText)
    this.state.email && formData.append('email', this.state.email)
    this.state.firstname && formData.append('first_name', this.state.firstname)
    this.state.lastname && formData.append('last_name', this.state.lastname)
    // formData.append('user_id', this.state.id)
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows) 

    if(this.state.svalue == 'id'){
      if(this.state.count%2){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'username'){
      if(this.state.username_count%2){
        formData.append('column_name_order','username')
      }
      else{
        formData.append('column_name_order','-username')
      }
    }
    if(this.state.svalue == 'email'){
      if(this.state.email_count%2){
        formData.append('column_name_order','email')
      }
      else{
        formData.append('column_name_order','-email')
      }
    }   
    if(this.state.svalue == 'first_name'){
      if(this.state.first_name_count%2){
        formData.append('column_name_order','first_name')
      }
      else{
        formData.append('column_name_order','-first_name')
      }
    }
    if(this.state.svalue == 'date_joined'){
      if(this.state.date_joined_count%2){
        formData.append('column_name_order','date_joined')
      }
      else{
        formData.append('column_name_order','-date_joined')
      }
    }  
    if(this.state.svalue == 'last_login'){
      if(this.state.last_login_count%2){
        formData.append('column_name_order','last_login')
      }
      else{
        formData.append('column_name_order','-last_login')
      }
    }

    fetch_post(`${CREATORS}list/`, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          activeCreatorsList: response.active_creators_list, 
          inActiveCreatorsList: response.inactive_creators_list, 
          deleteId: null,
          activenumOfPages: response.active_num_pages, 
          inActivenumOfPages: response.inactive_num_pages 
        },window.scroll(0,0))
        let model = document.getElementById("filterModel");
        model.click();
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // getCurrentPage(currentPage) {
  //   this.setState({currentPage}, this.creatorFilter)
  // }

    getCurrentPage(currentPage) {
      // let formData = new FormData()
      // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
      // formData.append('page1',currentPage)
      // formData.append('page2',currentPage)
      // fetch_post(`${VENDORS}list/`,formData)
      // .then((response) => {
      //   if(!response.error){
      //     this.setState({
      //     activeVendorsList: response.active_vendors_list, 
      //     inActiveVendorsList: response.inactive_vendors_list, 
      //     deleteId: null,
      //     activenumOfPages: response.active_num_pages, 
      //     inActivenumOfPages: response.inactive_num_pages 
      //     })                   
      //   }
      // })      
    if(this.state.active) {
      this.setState({activecurrentPage: currentPage},this.creatorFilter)
    } else {
      this.setState({ inActivecurrentPage:currentPage},this.creatorFilter)
    }
  }

  search(searchText) {
    this.setState({ searchText }, this.creatorFilter(this.state.activecurrentPage, this.state.inActivecurrentPage, searchText))
  }

  rendorCreators() {
    if (this.state.activeCreatorsList && this.state.inActiveCreatorsList) {
      let creators = this.state.active ? this.state.activeCreatorsList : this.state.inActiveCreatorsList
      let page = this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage
      return(
        <div className="table-responsive">
          <table className="table align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({count:this.state.count + 1,svalue:'id'},this.creatorFilter)}>Id <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({username_count:this.state.username_count + 1,svalue:'username'},this.creatorFilter)}>Username <i className="fas fa-sort"></i></th> 
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({email_count:this.state.email_count + 1,svalue:'email'},this.creatorFilter)}>Email <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({first_name_count:this.state.first_name_count + 1,svalue:'first_name'},this.creatorFilter)}>First Name <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({date_joined_count:this.state.date_joined_count + 1,svalue:'date_joined'},this.creatorFilter)}>Created <i className="fas fa-sort"></i></th>
                <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({last_login_count:this.state.last_login_count + 1,svalue:'last_login'},this.creatorFilter)}>Last Login <i className="fas fa-sort"></i></th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {creators.length > 0 ? creators.map((creator, index) => 
                <tr key={creator.id}>
                  <th 
                    scope="row"
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.id}
                  </th>
                  <td 
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.username}
                  </td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.email}
                  </td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.first_name}
                  </td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.date_joined}
                  </td>
                  <td
                    onClick={() => this.state.active ? this.props.history.push(`/admin/creators/${creator.id}/profile`) : {}}
                    style={{ cursor: this.state.active ? 'pointer' : ''}}
                  >
                    {creator.last_login ? creator.last_login : '--'}
                  </td>
                  <td>
                    {this.state.active ?
                      <Fragment>
                        <Link to={{pathname:`/admin/creators/${creator.id}/profile`}}>
                          <span style={{paddingRight:20 }}><i className="fa fa-edit"></i></span>
                        </Link>
                        <DeleteUser userRole="Creator" id={this.state.deleteId} updateList={this.creatorsList} />
                        <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: creator.id, restore:false })}>
                          <i className="fa fa-trash"></i>
                        </span>
                      </Fragment>
                    :
                      <Fragment>
                        <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(creator.id)}>
                          <span style={{paddingRight:20 }}><i className="fa fa-sync"></i></span>
                        </a>
                        {localStorage.getItem('SuperUser') === 'true' ? 
                        <span data-toggle="modal" data-target="#alert">
                        <a onClick={() => this.permanentDelete(creator.id)}>
                          <span style={{paddingRight:20 }}><i className="fa fa-trash"></i></span>
                        </a>
                        </span> : null}  
                      </Fragment>                     
                    }
                  </td>
                </tr>
              )
              :
                <tr>
                  <th scope="row">No records Found</th>
                </tr>
              }
            </tbody>
          </table>
        </div>
      )
    }
  }

  changeStatus(id) {
    fetch_get(`${USER}${id}/status/`) 
    .then((response) => {
      if(!response.error) {
        // alert("Vendor restored successfully")
        this.setState({restore:true,permanentDelete:false})
        this.creatorsList()
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  permanentDelete(id){
    let formData = new FormData()
    formData.append('permanent_delete',true)
    fetch_post(`${USER}${id}/delete/`, formData)
    .then((response) => {
      if(response.error){
        // alert(response.message)
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.creatorsList()
      }
      else{
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.creatorsList()        
      }      
    })
  }  

  render(){
    return(
      <div className="main-content">
        {this.state.restore && this.state.permanentDelete ? <Alert msg={this.state.alertmessage}/> : this.state.restore ? <Alert msg={"Creator restored Successfully"}/> : <Alert msg={"Creator Deleted Successfully"}/>}         
        <Alert_model msg={'Creator Created Successfully' }/>
        <TopHeader search={this.search} isSearch={true} />
        <div class="col-md-6 m-auto modal fade" id="example_model5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>websites Deletd Successfully</center>
              </div>
              <div class="modal-footer">
                {
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1" onClick={() => this.props.history.push('/admin/creators')}>Close</button>}
              </div>
            </div>
          </div>
        </div>
        <span data-toggle="modal" data-target="#example_model5" id="final"> </span>        
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
         
          <div className="row main_list_container">
            <div className="col-lg-12">
            <div className="col">
              <div className="card shadow website_detail_profile">
                <div className="card-header row bg-white border-0">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.creatorFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 d-flex">Creators
                  <div className="">
                      <label className="custom-toggle mb-0 ml-3">
                    <input type="checkbox"/> 
                    <span className="custom-toggle-slider" onClick={() => this.setState({ active: !this.state.active})}> {this.state.active ? 'Active' : 'Inactive'} </span>
                  </label>
                  </div>
                  </h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">Create Creator</button>
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                  </div>
                   {this.state.filter &&
                    <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12 p-0">
                      <div className="filter_result">
                        <form>
                          <div className="row">
                            {/*<div className="col-md-2">
                              <div className="form-group">
                                <input 
                                  type="number" 
                                  className="form-control" 
                                  placeholder="ID"
                                  value={this.state.id}
                                  onChange={(e) => this.setState({ id: e.target.value }, this.creatorFilter)}
                                />
                              </div>
                            </div>*/}
                            <div className="col-md-3">
                              <div className="form-group">
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  value={this.state.searchText}
                                  onChange={(e) => this.setState({ searchText: e.target.value }, this.creatorFilter)}
                                  placeholder="Username"
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <input 
                                  type="email" 
                                  className="form-control" 
                                  placeholder="Email" 
                                  value={this.state.email}
                                  onChange={(e) => this.setState({ email: e.target.value }, this.creatorFilter)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group mar-bot-5">
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  placeholder="First name" 
                                  value={this.state.firstname}
                                  onChange={(e) => this.setState({ firstname: e.target.value }, this.creatorFilter)}
                                />
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  placeholder="Last name"
                                  value={this.state.lastname}
                                  onChange={(e) => this.setState({ lastname: e.target.value }, this.creatorFilter)}
                                />
                              </div>
                            </div>                            
                          </div>
                        </form>
                        <div className="col-lg-12 text-center">
                            <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
          }
                </div>
                {this.rendorCreators()}
                <AuthenticationModel userRole="Creator" updateList={this.creatorsList} />
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage}
                          totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { INVOICES_LIST, Pagination, USER, DOMAIN_NAME } from '../common';

  // var url_string = window.location.href
  // var url = new URL(url_string);
  // var domain = url.searchParams.get("domain");
  // var token = url.searchParams.get("token");
export default class Websites extends Component{
  constructor(props) {
    super(props);
    this.state = {
      websites:[],
      email:'',
      websiteId:'',
      domain:'',
      token:'',
      errors:{},
      code:'',
      codes:[],
      ordered:''
     
    }
  }

  componentDidMount() {
    // this.invoices()
    this.userWebsiteData();
  }

  userWebsiteData(){
    var url_string = window.location.href
    var url = new URL(url_string);
    var domain = url.searchParams.get("domain");
    var token = url.searchParams.get("token"); 
    // console.log("url",c)
    let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    formData.append('domain',domain)
    fetch(`${DOMAIN_NAME}/api/get_user_websites_data/`, {
      method: 'post',
      body: formData
    })
    .then(response => response.json())
    .then((response) => {
      if(!response.error){
        this.setState({
          websites:response.websites_data, domain:domain, token:token
        })                   
      }
    })
  }

  sendData(){
    let formData = new FormData()
    formData.append('client_email',this.state.email)
    formData.append('user_website',this.state.websiteId)
    document.getElementById(this.state.websiteId+'in').value && formData.append('discount_code_id',document.getElementById(this.state.websiteId+'in').value)
    // console.log("rrrt",formData.get('discount_code_id'))
    fetch(`${DOMAIN_NAME}/api/public/order/create/`, {
      method: 'post',
      body: formData
    })
    .then(response => response.json())
    .then((response) => {    
      if(!response.error){
        // this.props.history.push('/public/upload-content',{user_order_id:response.user_order_id})
        let close = document.getElementById('createModel');
        close.click();
        this.props.history.push(`/public/upload-content/?token=${this.state.token}&order_id=${response.order_id}/`,{userOrderId:response.user_order_id,websiteId:this.state.websiteId})
        // this.setState({})
        // state:{userOrderId:response.user_order_id,websiteId:this.state.websiteId}
        // this.setState({
        //   websites:response.websites_data
        // })                   
      }
      else{
        this.setState({errors:response.errors})
      }
    })  
  }

    sendCode(){
    let check =  this.state.codes.find(id => id == this.state.websiteId)
    if(check == undefined){
      let formData = new FormData()
      this.state.code && formData.append('code',this.state.code)
      this.state.websiteId && formData.append('user_website_id',this.state.websiteId)
      fetch(`${DOMAIN_NAME}/api/code/apply/`, {
        method: 'post',
        body: formData
      })
      .then(response => response.json())
      .then((response) => {   
        if(!response.error){
          let lt = document.getElementById(this.state.websiteId+'old')
          lt.setAttribute('style','color:red')
          lt.setAttribute('style','text-decoration:line-through')
          let a = document.getElementById(this.state.websiteId);
          let b = document.createElement('div');
          b.innerText = '$' + response.new_price;
          let ip = document.getElementById(this.state.websiteId+'in')
          ip.setAttribute('value',response.discount_code_id)
          a.appendChild(b)

          let close = document.getElementById('createCode');
          close.click(); 
          this.setState({code:'',codes:[...this.state.codes,this.state.websiteId]})                 
        }
        else{
          this.setState({errors:response.errors})
        }
      }) 
    }
    else{
      let close = document.getElementById('createCode');
      close.click(); 
      alert('you cannot apply discount code simultaneously')
    } 
  }

  render(){
    return(
     <div className="main-content client_web">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div>
        <section class="pricing_section">
          <div class="pricing_container container">
            <div class="row justify-content-center steps">
              <div class="col-lg-10">
                <h2 style={{textAlign:'center',color:'#ffff',position:'relative',top:'-0.75rem'}}>Order your spot on {this.state.domain} </h2>
                <ul role="tablist">
                  <li role="tab" class="current" aria-disabled="false" aria-selected="true">
                    <a id="form-total-t-0" aria-controls="form-total-p-0">
                      <div class="title">
                        <span class="step-icon"><i class="fas fa-shopping-basket"></i></span>
                        <span class="step-text">Order Placed</span>
                        </div>
                      </a>
                  </li>


                <li role="tab" class="disabled" aria-disabled="true">
                  <a id="form-total-t-1" aria-controls="form-total-p-1">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-upload"></i></span>
                      <span class="step-text">Uplaod Content</span>
                    </div>
                  </a>
                 </li>


                <li role="tab" class="disabled" aria-disabled="true">
                  <a id="form-total-t-2" aria-controls="form-total-p-2">
                    <div class="title">
                      <span class="step-icon"><i class="fas fa-money-bill"></i></span>
                      <span class="step-text">Payment</span>
                    </div>
                  </a>
                </li>

              </ul>
              </div>
            </div>
          </div>
        </section>  
        <div className="modal fade" id="sendEmail" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Enter your email to create order</h3>
               <div className="row justify-content-center">
                </div>
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>Email <div className="error-text">*</div></label>
                        <input 
                          type="email" 
                          className="form-control" 
                          value={this.state.email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                          placeholder="Email"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.client_email}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>
                  <div className="row justify-content-center">
                    <button type="button" className="btn btn-info" onClick={() => this.sendData()} >Create order</button>
                    <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal">Cancel</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="sendCode" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
          <div className="modal-dialog col-md-12" role="document">
            <div className="col-lg-12">
              <div className="filter_result mt-5">
               <h3 className="mb-4 col-md-12 text-center">Enter your code</h3>
               <div className="row justify-content-center">
                </div>
                  <div className="row">
                    <div className="col-md-10" style={{paddingLeft:'7rem'}}>
                      <div className="form-group">
                        <label>Code <div className="error-text">*</div></label>
                        <input 
                          type="email" 
                          className="form-control" 
                          value={this.state.code}
                          onChange={(e) => this.setState({ code: e.target.value })}
                          placeholder="Code"
                        />
                        <div className="text-danger">
                          {this.state.errors && this.state.errors.code}
                        </div>                       
                      </div>
                    </div>                                                                                          
                  </div>
                  <div className="row justify-content-center">
                    <button type="button" className="btn btn-info" onClick={() => this.sendCode()} >Apply Code</button>
                    <button type="button" className="btn btn-default" id="createCode" data-dismiss="modal" onClick={()=>this.setState({code:''})}>Cancel</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid mt--7">
          <section class="pricing_section m-0">
            <div class="pricing_container container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="row">
                  {this.state.websites.map((data)=>
                    <div class="col-lg-4">
                      <div class="card shadow-lg">
                        <div class="card-header text-center">
                          {data.ad_type}
                        </div>
                        <div class="card-body">
                          <div class="text-center amount" id={data.id + 'old'} >${data.sell_price}</div>
                          <div class="text-center amount" id={data.id}></div>
                          <input
                            style={{display:'none'}}
                            id={data.id+'in'}
                            type="email" 
                            className="form-control" 
                          />
                          <ul class="features text-left">
                            <li><span><i class="fas fa-link"></i></span>{data.links_type}</li>
                            { this.state.codes.length >0 && this.state.codes.map(id =>{ 
                              if(id == data.id){
                                return(
                                <li style={{color:'blue',cursor:'pointer'}}>Apply discount code</li>)
                              }
                              else{
                                return(
                                  <li style={{color:'blue',cursor:'pointer'}}data-toggle="modal" data-target="#sendCode"  data-backdrop="static" data-keyboard="false" onClick={()=>this.setState({websiteId:data.id})}>Apply discount code</li>
                                )
                              }

                            })}
                            { this.state.codes.length < 1 ? <li style={{color:'blue',cursor:'pointer'}}data-toggle="modal" data-target="#sendCode"  data-backdrop="static" data-keyboard="false" onClick={()=>this.setState({websiteId:data.id})}>Apply discount code</li>:null}
                          </ul>
                          <div class="text-center"><button class="btn" data-toggle="modal" data-target="#sendEmail"  data-backdrop="static" data-keyboard="false" onClick={()=>this.setState({websiteId:data.id})}>Order Now</button></div>
                        </div>
                      </div>
                    </div>
                          )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          </div>
        </div>
      </div>   
    )
  }
}

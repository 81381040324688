import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import { ORDER, Alert_model,DOMAIN_NAME } from '../common';

let i=0;
let j=1;
let k;
var order_id=''
var token=''
export default class UpdateContent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      driveLink: '',
      specialrequirements:'',
      Paragraph:'',
      files: [],
      errors: {},
      edit: false,
      addType:'',
      domain:'',
      id:'',
      Post_title:'',
      Post_url:'',
      Anchor_text:'',
      inputsCount:0,
      inputsDataCount:1,
      loading:false,
      data:{},
      rows:2,
      error:0,
      error2:0,
      errordivcount:0,
      user_order_id:this.props.location.state ? this.props.location.state.userOrderId : localStorage.getItem('user_order_id'),
      order_id:'',
      token:'',
    }
  }

  componentWillMount() {
    // if(this.props.location.state.edit) {
    //   this.setState({ edit: true })
    //   if(this.props.location.state.edit.drive_url) {
    //     this.setState({ driveLink: this.props.location.state.edit.drive_url })
    //   } else {
    //     this.setState({ files: [this.props.location.state.edit.file_content] })
    //   }
    // } 
    if(this.props.location.state){
      localStorage.setItem('user_order_id',this.props.location.state.userOrderId); 
    }   
    this.getDetails()
  }

  componentDidMount(){
    var url_string = window.location.href
    var url = new URL(url_string);
    var order_id = url.searchParams.get("order_id");
    var orderId = order_id.slice(0, -1)
    var token = url.searchParams.get("token");
    this.setState({order_id:orderId,token:token})
  }

  removeErrors(){
    this.setState({ errors:{} })
  }

  addTargetUrls(){
    // var i=0;
    let a = document.getElementById('target');
    let b = document.createElement('input');
    b.setAttribute('class','form-control form-control-alternative')
    b.setAttribute('type','text');
    b.setAttribute('id',(++this.state.inputsCount));
    b.setAttribute('placeholder','Target URL')
    b.setAttribute('style','margin-top:1rem')
    b.setAttribute('style','margin-bottom:1rem')
    a.appendChild(b)                                                                                                 
  }

  addTargetData(){
    ++this.state.errordivcount
    let a = document.getElementById('target1');
    let b = document.createElement('input');
    let c = document.createElement('input');
    let error = document.createElement('div');
    let error2 = document.createElement('div');
    b.setAttribute('class','form-control form-control-alternative')
    b.setAttribute('type','text');
    b.setAttribute('id',(++this.state.inputsDataCount));
    b.setAttribute('placeholder','Target URL')
    b.setAttribute('style','margin-top:1rem')
    b.setAttribute('style','margin-bottom:1rem')
    a.appendChild(b)
    error.setAttribute('id',"error"+(++this.state.error))
    // error.setAttribute('style','margin-top:1rem')
    // error.setAttribute('class','error-text')
    // error.setAttribute('style','margin-bottom:1rem')
    // error.setAttribute('style','color:#FF0000')
    // error.setAttribute('style','font-size:0.84rem') 
    error.style.color = "red";
    error.style.fontSize = "0.84rem";
    error.style.marginTop = "1rem"; 
    a.append(error)    
    c.setAttribute('class','form-control form-control-alternative')
    c.setAttribute('type','text');
    c.setAttribute('id',(++this.state.inputsDataCount));
    c.setAttribute('placeholder','Anchor text')
    c.setAttribute('style','margin-top:1rem')
    c.setAttribute('style','margin-bottom:1rem')
    // a.appendChild(b)
    a.appendChild(c) 
    error2.setAttribute('id',"error2"+(++this.state.error2))
    error2.style.color = "red";
    error2.style.fontSize = "0.84rem";
    error2.style.marginTop = "1rem";
    a.append(error2)     
  }

  showError(errors){
    if(errors.target_links){
      errors.target_links.map((error, index) => {
        if(Object.keys(error)[0].split("_")[0] == 'target'){
          document.getElementById("error"+parseInt(Object.keys(error)[0].split("_")[2])).innerHTML = error[Object.keys(error)[0]]
        }
        else if(Object.keys(error)[0].split("_")[0] == 'anchor'){
          document.getElementById("error2"+parseInt(Object.keys(error)[0].split("_")[2])).innerHTML = error[Object.keys(error)[0]]            
        }
        if(Object.keys(error)[1] && Object.keys(error)[1].split("_")[0] == 'anchor'){
          document.getElementById("error2"+parseInt(Object.keys(error)[1].split("_")[2])).innerHTML = error[Object.keys(error)[1]]
        }          
      })
    }
    if(errors.non_field_errors){
      for(i=0;i<=this.state.errordivcount; ++i){
        document.getElementById('error'+i).innerHTML=''
      }      
      this.setState({errors:errors})
    }
    else{
      this.setState({errors:{}})
    }              
  }

  getDetails(){
    // fetch_get(`/api/user_order/${this.state.user_order_id}/details/`)
    // .then((response) => {
    fetch(`${DOMAIN_NAME}/api/user_order/${this.state.user_order_id}/details/`, {
      method: 'get',
    })
    .then(response => response.json())
    .then((response) => { 
      if(!response.error){
        if(response.order_website_details.user_website_details.ad_type == 'Article'){
          if(response.order_website_details.target_links.length > 1){
            this.setState({errordivcount:(response.order_website_details.target_links.length)-1})
          }
          for(j=1; j<response.order_website_details.target_links.length; j++){
            let c = document.getElementById('target1');
            let d = document.createElement('input');
            let e = document.createElement('input');
            let error = document.createElement('div');
            let error2 = document.createElement('div');
            let target = response.order_website_details.target_links[j].link
            let anchor = response.order_website_details.target_links[j].anchor_text
            d.setAttribute('class','form-control form-control-alternative')
            d.setAttribute('type','text');
            d.setAttribute('id',(++this.state.inputsDataCount));
            d.setAttribute('placeholder','Target URL')
            d.setAttribute('value',target)
            d.setAttribute('style','margin-top:1rem')
            d.setAttribute('style','margin-bottom:1rem')
            error.setAttribute('id',"error"+(++this.state.error))
            error.setAttribute('style','margin-top:1rem')
            error.setAttribute('class','error-text')
            error.setAttribute('style','margin-bottom:1rem') 
            error2.setAttribute('id',"error2"+(++this.state.error2))
            error2.setAttribute('style','margin-top:1rem')
            error2.setAttribute('class','error-text')
            error2.setAttribute('style','margin-bottom:1rem')                        
            e.setAttribute('class','form-control form-control-alternative')
            e.setAttribute('type','text');
            e.setAttribute('id',(++this.state.inputsDataCount));
            e.setAttribute('placeholder','Anchor_Text')
            e.setAttribute('value',anchor)
            e.setAttribute('style','margin-top:1rem')
            e.setAttribute('style','margin-bottom:1rem')
            c.appendChild(d)
            c.appendChild(e)
            c.appendChild(error)
            c.appendChild(error2)
          }
        }
          else{
            for(j=1;j<response.order_website_details.target_links.length; j++){
              let value = response.order_website_details.target_links[j].link
              let a = document.getElementById('target');
              let b = document.createElement('input');
              b.setAttribute('class','form-control form-control-alternative')
              b.setAttribute('type','text');
              b.setAttribute('id',(++this.state.inputsCount));
              b.setAttribute('placeholder','Target URL')
              b.setAttribute('value',value)
              b.setAttribute('style','margin-top:1rem')
              b.setAttribute('style','margin-bottom:1rem')
              a.appendChild(b)          
            }
          }

        this.setState({addType:response.order_website_details.user_website_details.ad_type, 
                      domain:response.order_website_details.user_website_details.website, 
                      id:response.order_website_details.order_details.order_id,
                      Post_url:response.order_website_details.post_url == 'null' ? '' : response.order_website_details.post_url,
                      Anchor_text:response.order_website_details.user_website_details.ad_type == 'Article' || 'Link insertion' ? response.order_website_details.target_links.length >0 ?  response.order_website_details.target_links[0].anchor_text : '' : '',
                      Paragraph:response.order_website_details.paragraph_to_add == 'null' ? '' : response.order_website_details.paragraph_to_add,
                      Post_title:response.order_website_details.post_title == 'null' ? '' : response.order_website_details.post_title,
                      Target_url:response.order_website_details.target_links .length > 0 ? response.order_website_details.target_links[0].link:'',
                      specialrequirements:response.order_website_details.special_requirements === 'null' ? '' : response.order_website_details.special_requirements,
                      loading:true,
                      driveLink:response.order_website_details.user_website_details.text_content ? (response.order_website_details.user_website_details.text_content.link_url ? response.order_website_details.user_website_details.text_content.link_url : '') : ''})
      }
    })
  }

  saveDetails() {
    const { data } = this.state
    let arr=[]
    let arr1=[]
    let pair = {}
    if(document.getElementById('target')){
      if(document.getElementById("0").value != ''){
        arr.push(document.getElementById("0").value)
      }      
      for(i=1;i<=document.getElementById('target').childElementCount;i++){
        if(document.getElementById(i).value != ''){
          arr.push(document.getElementById(i).value)
        }
      }
    }
    if(this.state.addType == 'Article'){
      if(document.getElementById('target1')){
        // if(document.getElementById("0").value != ''){
          pair['target_link'] = document.getElementById("0").value
        // }
        // if(document.getElementById("1").value != ''){
          pair['anchor_text'] = document.getElementById("1").value
        // }
        // if(document.getElementById("0").value != '' && document.getElementById("1").value != ''){
          arr1.push(pair)
          pair={}
        // }
      }
      if(document.getElementById('target1')){
        for(k=2;k<=(document.getElementById('target1').childElementCount)-2*(this.state.errordivcount);k=k+2){
          // if(document.getElementById(k).value != '' && document.getElementById(k+1).value != ''){
            pair['target_link'] = document.getElementById(k).value;
            pair['anchor_text'] = document.getElementById(k+1).value
            arr1.push(pair)
            pair={}
          // }
        }
      } 
    }
    if(this.state.edit && (this.props.location.state.edit.file_content === this.state.files) && this.props.location.state.edit.drive_url === "") {
      alert('content uploaded successfully')
      this.props.history.push('/public/payment')
    } 
    else {
      let data = new FormData();
      if(this.state.files) {
        data.append('file_content', this.state.files)
      }
      this.state.addType != 'Link insertion' && data.append('link_url', this.state.driveLink)
      data.append('post_title', this.state.Post_title)
      if(this.state.addType == 'Link insertion'){
        data.append('post_url', this.state.Post_url)
        data.append('anchor_text', this.state.Anchor_text)
      }
      this.state.addType == 'Link insertion' && data.append('paragraph_to_add', this.state.Paragraph)
      arr.length > 0  && data.append('target_links', JSON.stringify(arr)) 
      this.state.addType == 'Article' && data.append('target_links',JSON.stringify(arr1))
      data.append('special_requirements', this.state.specialrequirements)
      // fetch_post(`/api/user_order/${this.state.user_order_id}/details/`, data)
      // .then((response) => {
      fetch(`${DOMAIN_NAME}/api/user_order/${this.state.user_order_id}/details/`, {
      method: 'post',
      body: data
      })
      .then(response => response.json())
      .then((response) => {
        if(!response.error) {
          let d = document.getElementById('success')
          d.click()
          this.removeErrors()
          // alert(response.message)
          // this.props.history.goBack()
          // this.setState({ orderDetails: response.clients_list })
        } else {
          if(this.state.addType == 'Article'){
            for(i=0;i<=this.state.errordivcount; ++i){
              document.getElementById('error'+i).innerHTML=''
              document.getElementById('error2'+i).innerHTML=''
            }
            this.showError(response.errors)
            // this.setState({errors:response.errors})
            // this.showError()
          }
          else{
          this.setState({ errors: response.errors })
          }
        }
      })
      .catch((error) => {
        this.setState({ error });
      });
    }
  }

  render(){
    return(
      <div className="main-content">
        { <Alert_model msg={"Content Uploaded Successfully"} uploadcontent={true} public={true} token={this.state.token} order_id={this.state.order_id}  history={this.props.history} /> }
        <div className="main-content">
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <section class="pricing_section">
            <div class="pricing_container container">
              <div class="row justify-content-center steps">
                <div class="col-lg-10">
                  <ul role="tablist">
                    
                    <li role="tab" class="closed" aria-disabled="false" aria-selected="true">
                      <a id="form-total-t-0" aria-controls="form-total-p-0">
                        <div class="title">
                          <span class="step-icon"><i class="fas fa-shopping-basket"></i></span>
                          <span class="step-text">Order Placed</span>
                          </div>
                        </a>
                    </li>


                  <li role="tab" class="current" aria-disabled="true">
                    <a id="form-total-t-1" aria-controls="form-total-p-1">
                      <div class="title">
                        <span class="step-icon"><i class="fas fa-upload"></i></span>
                        <span class="step-text">Uplaod Content</span>
                      </div>
                    </a>
                   </li>


                  <li role="tab" class="disabled" aria-disabled="true">
                    <a id="form-total-t-2" aria-controls="form-total-p-2">
                      <div class="title">
                        <span class="step-icon"><i class="fas fa-money-bill"></i></span>
                        <span class="step-text">Payment</span>
                      </div>
                    </a>
                  </li>

                </ul>
                </div>
              </div>
            </div>
          </section> 
          <div className="container-fluid mt--7 ">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-11">
                <div className="card shadow  profile_card">
                <div className="row card-header marl">
                  <div className="col-md-6">
                    <h3 className="mb-0 mt-2  ml-2">Order ID : <span className="order_id"> {this.state.id}</span></h3>
                  </div>
                  <div className="col-md-6 text-right">
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.goBack()}>
                    Back
                  </button>
                  </div>
                  </div>
                  <div className="card-body">
                    <form className="pt-3">
                      <div className="">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group text-left">
                            <label>Add Type</label>
                              <input type="text" className="form-control form-control-alternative" placeholder="Add Type" disabled value={this.state.addType} />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group text-left">
                             <label>Domain</label>
                              <input type="text" className="form-control form-control-alternative" placeholder="Website Name" value={this.state.domain} disabled />
                            </div>
                          </div>
                        </div>
                         <hr className="mtb_1" />
                         {this.state.addType == 'Link insertion' ? 
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Post url <div className="error-text">*</div></label>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <input 
                                className="form-control form-control-alternative" 
                                placeholder="Post url" 
                                type="text" 
                                value={this.state.Post_url}
                                onChange={(e) => this.setState({ Post_url: e.target.value })}
                              />
                              <div className="text-danger">{this.state.errors.post_url && this.state.errors.post_url}</div>
                            </div>
                          </div> :
                          this.state.loading ?
                          <Fragment>
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="form-control-label" htmlFor="input-address">File</label>
                                </div>
                              </div>
                              <div className="col-lg-9">
                                <input 
                                  className="form-control form-control-alternative" 
                                  placeholder="Enter url" 
                                  type="file"
                                  onChange={(e) => this.setState({ files: e.target.files[0] })} 
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3">
                              </div>
                              <div className="col-lg-9 text-left">
                              {this.state.files.length > 0 &&
                                <div className="state_files">{this.state.files.name ? this.state.files.name : this.state.files}{'  '}<span onClick={() => this.setState({ files: [] })}><i className="fas fa-times" style={{ color: 'red' }}></i></span></div> 
                              } 
                              </div>
                            </div>
                             
                            <div className="drag_drop_or">
                              <span>Or</span>
                            </div>
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="form-group">
                                  <label className="form-control-label" htmlFor="input-address">Drive Link <div className="error-text">*</div></label>
                                </div>
                              </div>
                              <div className="col-lg-9">
                                <input 
                                  className="form-control form-control-alternative" 
                                  placeholder="Drive Link" 
                                  type="text" 
                                  value={this.state.driveLink}
                                  onChange={(e) => this.setState({ driveLink: e.target.value })}
                                />
                                <div className="text-danger">{this.state.errors.link_url && this.state.errors.link_url}</div>                                 
                              </div>                             
                            </div>
                          </Fragment>: null }
                        <div className="text-danger text-center">{this.state.errors.non_field_errors && this.state.errors.non_field_errors}</div>                    
                        { this.state.addType != 'Link insertion' ?                      
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" htmlFor="input-address">Post Title</label>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <input 
                              className="form-control form-control-alternative" 
                              placeholder="Post Title" 
                              type="text" 
                              value={this.state.Post_title}
                              onChange={(e) => this.setState({ Post_title: e.target.value })}
                            />
                          </div>
                        </div> : null}
                        {this.state.addType != 'Article' ? 
                        <Fragment>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Target Url {this.state.addType == 'Link insertion' || this.state.addType == 'Article' ? <div className="error-text">*</div> : null} </label>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <input 
                                className="form-control form-control-alternative" 
                                placeholder="Target Url" 
                                type="text"
                                id="0" 
                                value={this.state.Target_url}
                                onChange={(e) => this.setState({ Target_url: e.target.value })}
                              />
                            </div>
                            { this.state.loading == true && this.state.addType != 'Link insertion' ?
                              <div className="col-lg-0" style={{cursor:"pointer"}}>
                                <a className="add_target" onClick={() => this.addTargetUrls()}>
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div> : null
                            }                          
                          </div>
                          <div className="row">
                            <div className="col-lg-3">
                            </div>
                            <span className="col-lg-8" id="target">
                            </span>
                            <span className="col-lg-3">
                            </span>
                            <span className="text-danger col-lg-9" style={{paddingBottom:"1rem"}}>{this.state.errors.target_links && this.state.errors.target_links}</span>                      
                          </div>
                        </Fragment>:
                        <Fragment>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Target Url {this.state.addType == 'Link insertion' || this.state.addType == 'Article' ? <div className="error-text">*</div> : null} </label>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <input 
                                className="form-control form-control-alternative" 
                                placeholder="Target Url" 
                                type="text"
                                name="target_link"
                                id="0" 
                                value={this.state.Target_url}
                                onChange={(e) => this.setState({ Target_url: e.target.value})}
                              />
                            </div>
                            { this.state.loading == true && this.state.addType != 'Link insertion' ?
                              <div className="col-lg-0" style={{cursor:"pointer"}}>
                                <a className="add_target" onClick={() => this.addTargetData()}>
                                  <i className="fas fa-plus"></i>
                                </a>
                              </div> : null
                            }                          
                          </div>
                          <div className="row">
                          <span className="col-lg-3"></span>
                          <span className="col-lg-9 error-text" id="error0"></span>
                          </div>                        
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <input 
                                className="form-control form-control-alternative" 
                                style={{marginBottom:'1rem'}}
                                placeholder="Anchor text" 
                                type="text"
                                id="1" 
                                value={this.state.Anchor_text}
                                onChange={(e) => this.setState({ Anchor_text: e.target.value })}
                              />
                            </div>                         
                          </div> 
                          <div className="row">
                            <span className="col-lg-3"></span>
                            <span className="col-lg-9 error-text" id="error20"></span>
                          </div>                                                                                                          
                        </Fragment>                                                                         
                      } 
                      <Fragment>
                      <div className="row">
                        <div className="col-lg-3">
                        </div>
                        <span className="col-lg-8" id="target1">
                        </span>
                        <span className="col-lg-3">
                        </span>
                        {this.state.addType == 'Article' ?
                        <span className="text-danger col-lg-9" style={{paddingBottom:"1rem"}}>{this.state.errors.target_links && this.state.errors.target_links}</span> : null}                    
                      </div>
                      </Fragment>
                        {this.state.addType == 'Link insertion' ?
                        <Fragment>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Anchor text <div className="error-text">*</div></label>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <input 
                                className="form-control form-control-alternative" 
                                placeholder="Anchor text" 
                                type="text"
                                value={this.state.Anchor_text}
                                onChange={(e) => this.setState({ Anchor_text: e.target.value })}
                              />
                              <div className="text-danger">{this.state.errors.anchor_text && this.state.errors.anchor_text}</div>                              
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label className="form-control-label" htmlFor="input-address">Paragraph to add</label>
                              </div>
                            </div>
                            <div className="col-lg-9">
                              <textarea 
                                type="text"
                                className="form-control form-control-alternative"
                                cols="55"
                                value={this.state.Paragraph}
                                onChange={(e) => this.setState({ Paragraph: e.target.value })}
                              />        
                            </div>                  
                          </div>
                        </Fragment>:null}                                                                                                       
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" htmlFor="input-address">Special Requirements</label>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <textarea 
                              type="text"
                              className="form-control form-control-alternative"
                              cols="55"
                              value={this.state.specialrequirements}
                              onChange={(e) => this.setState({ specialrequirements: e.target.value })}
                            />        
                          </div>                  
                        </div>                        
                        <div className="row">
                          <div className="col-lg-12 text-center">
                            <span data-toggle="modal" data-target="#Alert_model" id="success"> </span>
                            <button type="button" className="btn btn-primary mt-3" onClick={() => this.saveDetails()}>Save Details</button>
                          </div>
                        </div>    
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

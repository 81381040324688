import React, { Component, Fragment } from 'react';
import { CHANGE_PASSWORD, DOMAIN_NAME, Alert } from '../common';
import TopHeader from '../common/TopHeader';

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: '',
      password1: '',
      password2: '',
      errors: {}
    };
    this.handleKeyPress = this.handleKeyPress.bind(this)
  } 


  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  } 

  handleSubmit(event) {
    const { password1, password2, oldPassword } = this.state;
      let data = new FormData()
      data.append('old_password', oldPassword)
      data.append('new_password', password1)
      data.append('confirm_password', password2)
      fetch(DOMAIN_NAME+CHANGE_PASSWORD, {
        method: 'post',
        body: data,
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('UserToken')
        }
      })
      .then(response => response.json())
      .then((response) => {
        if(response.error) {
          this.setState({ errors: response.errors })
        } else {
          // alert("Password Updated Successfully. Please login")
          let a = document.getElementById('success')
          a.click()
          
          // if(localStorage.getItem('role') === 'vendors'){
          //   this.props.history.push('/vendors/signin')
          // }
          // else if(localStorage.getItem('role') === 'admin'){
          //   this.props.history.push('/admin/signin')
          // }
          // else
          //   this.props.history.push('/signin')
        }
      })  
      .catch(() => {
      });
  }

  render(){
    const { errors } = this.state;
    return( 
      <Fragment>
        <div className="main-content">
          <TopHeader showSearch={false} />
          <Alert msg={"Password Updated Successfully. Please login"} ChangePassword="true" history={this.props.history}/>
            <div className="header bg-gradient-primary pb-8">
            </div>
            <div className="container fluid mt-4">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div className="card shadow profile_card">
                    <div className="text-center p-3">
                      <small>Change Password</small>
                    </div>
                    <div className="card-body px-lg-4 py-lg-3">
                      <form>
                        <div className="form-group mb-3">
                          <div className="form-group">
                            <div className="input-group input-group-alternative">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                              </div>
                              <input 
                                onKeyPress={this.handleKeyPress} 
                                className="form-control" 
                                type="password" 
                                placeholder="Enter Old password" 
                                value={this.state.oldPassword} 
                                onChange={(event)=>{this.setState({oldPassword:event.target.value})}}
                              />
                              <div className="error-text">*
                              </div>                              
                            </div>
                            <div className="text-danger">
                              {errors.old_password ? errors.old_password[0]: null}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group input-group-alternative">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                              </div>
                              <input 
                                onKeyPress={this.handleKeyPress} 
                                className="form-control" 
                                type="password" 
                                placeholder="Enter new password" 
                                value={this.state.password1} 
                                onChange={(event)=>{this.setState({password1:event.target.value})}}
                              />
                              <div className="error-text">*
                              </div>                                
                            </div>
                            <div className="text-danger">
                              {errors.new_password ?  errors.new_password[0]:null}
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group input-group-alternative">
                              <div className="input-group-prepend">
                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                              </div>
                              <input 
                                onKeyPress={this.handleKeyPress} 
                                className="form-control" 
                                placeholder="Enter Confirm Password" 
                                type="password" 
                                value={this.state.password2} 
                                onChange={(event)=>{this.setState({password2:event.target.value})}}
                              />
                              <div className="error-text">*
                              </div>                                
                            </div>
                            <div className="text-danger">
                              <div className="text-danger">
                                {errors.confirm_password ?  errors.confirm_password[0] : null}
                                {errors.non_field_errors && errors.non_field_errors}
                              </div>
                            </div> 
                          </div>                       
                        </div>
                        <span data-toggle="modal" data-target="#alert" id="success"/>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-primary my-4" 
                            onClick={() => this.handleSubmit()}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> 
            </div>
        </div>
      </Fragment>
    )
  }
}      
import React, { Component } from 'react';
import { USER, fetch_get, fetch_post, WEBSITE } from '../common';

export default class DeleteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
      name: '',
      lastName: '',
      alertmsg:'',
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    let url = ( this.props.userRole === 'service' ? `/api/service/${this.props.id}/delete/` : this.props.userRole === 'order' ? `/api/order/${this.props.id}/delete/` : this.props.userRole === 'website' && this.props.component === 'website' ?   `${USER}website/${this.props.id}/delete/` :
    this.props.userRole === 'website' ? `${WEBSITE}${this.props.id}/delete/` : `${USER}${this.props.id}/delete/`)
    if(this.props.userRole === 'orderwebsite')
    {
      let formData = new FormData()
      formData.append('user_order_price', this.props.price)
      formData.append('user_order_id', this.props.userorderid)
      fetch_post(`/api/order/${this.props.id}/details/`,formData)
      .then((response) => {
        let a = document.getElementById("deleteModel");
        a.click();
        if(response.order_details){
          this.props.updateList(this.props.id)
        }
        else{
          this.props.history.push(`/admin/orders`)
        }
      })
    }
    else{
      fetch_get(url)
      .then((response) => {
        if (!response.error) {
          let a = document.getElementById("deleteModel");
          a.click();
          this.props.updateList()
          this.setState({alertmsg:response.message})
          if(this.props.userRole == 'service'){
            let b = document.getElementById('service_alert')
            b.click()
          }
          // alert(response.message);
        } else {
          // this.setState({ errors: response.errors });
        }
      });
    }
  }

  render() {
    return (
      <div className="modal fade col-md-6 m-auto" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content m-auto">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLabel">{this.props.userRole} Delete</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you Sure ? You want to delete {this.props.userRole} ?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">Close</button>
              <span data-toggle="modal" data-target="#example_model5" id="service_alert"></span>
              <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#alert" onClick={this.handleSubmit.bind(this)}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

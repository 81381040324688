import React, { Component } from 'react';
import { fetch_post, SUBSCRIPTION_THANKS } from '../common';

export default class SubscriptionRedirect extends Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.postDetails()
  }

  postDetails() {
    let formData = new FormData()
    formData.append('checkout_id', this.props.location.search.split('=')[1])
    fetch_post(`${SUBSCRIPTION_THANKS}`, formData) 
    .then((response) => {
      if(response.error){
        // alert(response.message)
        this.props.history.push('/subscription/failure',{error:response.message})
      }
      else{
        // alert('Subscription Changed successfully')
        localStorage.setItem('subscription',response.current_subscription.name)
        this.props.history.push('/subscription/success')
        window.location.reload()
      }
    })
  }

  render(){
    return(
      <div className="main-content">
      </div>
    )
  }
}


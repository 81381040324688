import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';
import { Link } from 'react-router-dom';
import {ADMIN, INVOICES_LIST, fetch_get, fetch_post, Pagination, USER } from '../common';

export default class orders extends Component{
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      orders:[],
      currentPage: 1,
      numOfPages: 1,
      redirectUrl: '',
      numberofrows:100,
      filter:false, 
      category:'',
      svalue:'',
      idtoggle:false,
      categorytoggle:false,
    }
    this.payments = this.payments.bind(this);
  }

  componentDidMount() { 
    this.payments()
  }

  payments(page=this.state.currentPage) {
    fetch_get('/api/payments/')
    .then((response) => {
      if(!response.error) {
        this.setState({ orders: response.user_orders, numOfPages: response.active_num_pages  })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  clearStates(){
    this.setState({category:''},this.categoryFilter)
  }  

  paymentFilter(){
    let formData = new FormData()
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    this.state.category && formData.append('name',this.state.category)
    formData.append('page',this.state.currentPage)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'category'){
      if(this.state.categorytoggle){
        formData.append('column_name_order','name')
      }
      else{
        formData.append('column_name_order','-name')
      }
    }       
    fetch_post('/api/orders/',formData)
    .then((response) => {
      if(!response.error){
        this.setState({
          orders: response.user_orders, 
          numOfPages: response.active_num_pages,
        },window.scroll(0,0))
      }     
    })
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post('/api/category/list/',formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //       categories: response.category_list, 
    //       numOfPages: response.active_num_pages
    //       // createdUsersList:created_users,
    //     })                   
    //   }
    // })     
    this.setState({currentPage},this.paymentFilter)
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
                <div className="row card-header bg-white border-0 d-flex">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.categoryFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">payments</h3>                  
                </div>                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()} >Unique Id</th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()}>order id</th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()}>transcation id</th>                        
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()}>domain</th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()}>adtype</th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.categoryFilter()}>price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.length > 0 ? this.state.orders.map((order, index) =>
                        <tr key={order.id}>
                          <td><a href>{order.id}</a></td>
                          {/*<td><a href={this.state.redirectUrl} ref={this.redirectPage}>{invoice.amount}</a></td>*/}
                          <td>{order.order}</td>
                          <td>{order.payment_id}</td>
                          <td>{order.user_website.domain}</td>
                          <td>{order.user_website.ad_type}</td>
                          <td>{order.user_website.price}</td>
                        </tr>
                      ):                 
                        <tr>
                          <th scope="row">No Records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}

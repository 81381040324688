import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { fetch_get } from '../common';

class LeftNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: window.location.pathname.split('/')[1],
    };
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  // componentDidMount() {
  //   if(!localStorage.getItem('UserToken')) {
  //     this.props.history.push('/signin');
  //   }
  //   fetch_get(`/api/user/subscription/list/`) 
  //   .then((response) => {
  //     if(!response.error) {
  //     }
  //   })
  //   .catch((error) => {
  //     this.setState({ error });
  //   });
  // }

  static getDerivedStateFromProps(nextProps, prevState){
    const menu = nextProps.location.pathname.split('/')[1];
    if(menu !== prevState.selectedMenu) {
      return { selectedMenu: menu };
    }
    return null;
  }

  isActiveTab(value) {
    return (value === this.state.selectedMenu) ? "nav-link active" : "nav-link";
  }

  render() {
    return (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to={"/dashboard"} className={this.isActiveTab('dashboard')} onClick={() => {this.setState({ tabActive: 'Dashboard'});this.props.show(false)}}>
            <i className="ni ni-tv-2 text-primary"></i>Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/websites"} className={this.isActiveTab('websites')} onClick={() => {this.setState({ tabActive: 'websites'});this.props.show(false)}}>
            <i className="ni ni-world text-teal"></i>Websites
          </Link>
        </li>
        <li className="nav-item">
          <Link to={"/bestoffers"} className={this.isActiveTab('bestoffers')} onClick={() => {this.setState({ tabActive: 'bestoffers'});this.props.show(false)}}>
            <i className="ni ni-notification-70 text-green"></i>Best Offers
          </Link>
        </li>        
        <li className="nav-item">
          <Link to={"/orders"} className={this.isActiveTab('orders')} onClick={() => {this.setState({ tabActive: 'orders'});this.props.show(false)}}>
            <i className="ni ni-cart text-red"></i>Orders
          </Link>
        </li>
        { localStorage.getItem('subscription') == 'Pro' &&
        <li className="nav-item">
          <Link to={"/projects"} className={this.isActiveTab('projects')} onClick={() => {this.setState({ tabActive: 'projects'});this.props.show(false)}}>
            <i className="fa fa-certificate text-primary"></i>Projects
          </Link>
        </li>}
        <li className="nav-item">
          <Link to={"/invoices"} className={this.isActiveTab('invoices')} onClick={() => {this.setState({ tabActive: 'invoices'});this.props.show(false)}}>
            <i className="ni ni-paper-diploma text-info"></i>Invoices
          </Link>
        </li>        
      </ul>
    );
  }
}

export default withRouter(LeftNav);

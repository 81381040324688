import React, { Component, Fragment } from 'react';
import {  fetch_post, ORDER }  from '../common';
import  { Redirect } from 'react-router-dom'

export default class LiveurlModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveurl:'',
      errors:{},
      orderid:''
    };
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      liveurl:nextProps.liveurl,
      orderid:nextProps.orderid
    })

  }

  handleSubmit(event) {
    let formData = new FormData()
    formData.append('post_url', this.state.liveurl)
    formData.append('user_order_id', this.props.id)
    fetch_post(`${ORDER}/${this.state.orderid}/details/ `,formData)
    .then((response) => {
      if (!response.error) {
        let a = document.getElementById('createModel')
        a.click(); 
        if(this.props.role === 'vendor'){
          this.props.history.push(`/vendors/orders/${this.state.orderid}/details`, {url:this.state.liveurl})
        }
        else if(this.props.role === 'creator'){
          this.props.history.push(`/creator/order/${this.state.orderid}/details`, {url:this.state.liveurl})
        }        
        else{
          this.props.history.push(`/admin/orders/${this.state.orderid}/details`, {url:this.state.liveurl})
        }
      } else {
        this.setState({ errors: response.errors });
      }
    });
  }

  onCancel(){
    this.setState({errors:{}})
    if(this.props.role === 'vendor'){
      this.props.history.push(`/vendors/orders/${this.props.orderid}/details`, {url:this.state.liveurl})
    }
    else if(this.props.role === 'creator'){
      this.props.history.push(`/creator/order/${this.props.orderid}/details`, {url:this.state.liveurl})
    }    
    else{
      this.props.history.push(`/admin/orders/${this.props.orderid}/details` , {url:this.state.liveurl})
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="modal fade" id="liveurlModel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="col-md-12 m-auto">
            <div className="modal-content">
              <h5 className="modal-title text-center pt-3 pb-3" id="exampleModalLabel">LIVEURL</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              </button>
              <div className="modal-body">
                <div className="col-md-12 text-center pb-4">
                  <input 
                  className="form-control" 
                  placeholder="Liveurl" 
                  type="text" 
                  onChange={(event)=>{ this.setState({ liveurl: event.target.value }) }} value={this.state.liveurl}
                  />
                  <div className="text-danger" style={{ fontSize: '1rem' }}>
                    {errors.post_url ? errors.post_url : null}
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center pb-4">
                <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={()=>this.onCancel()}>Cancel</button>
                <button type="button" className="btn btn-primary" onClick={this.handleSubmit.bind(this)} >upload</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

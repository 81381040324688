import React, { Component } from 'react';

export default class PageNotFound extends Component {
  render() {
    return (
      <div>
        <nav className="navbar fixed-left navbar-light bg-white" id="sidenav-main">
          <div className="container-fluid">
            <a href className="navbar-brand pt-0">
              <img src="/img/brand/logo.png" className="navbar-brand-img" alt="..."/>
            </a>
          </div>
        </nav>
        <i style={{color:"#144571",fontSize:80,display:"flex",justifyContent: "center"}} className="far fa-frown"></i>
        <h1 style={{fontSize:60, color:"#94c11f",textAlign:"center"}}>404</h1>
        <h4 style={{textAlign:"center"}}>Page Not Found</h4>
      </div>
    );
  }
}
import React, { Component } from 'react';
import TopHeader from '../common/TopHeader';

export default class Dashboard extends Component{

  render() {
    return(
      <div className="main-content">
        <TopHeader showSearch={false} />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--7">
          <div className="col mt-5">
          <div className="card shadow">
          <div className="pt-3 pb-3 pl-3">
            Coming soon
            </div>
          </div>
          </div>
        </div>
      </div>
    )
  }
}
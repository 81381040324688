import React, { Component } from 'react';
import { fetch_post, PAYPAL, DOMAIN_NAME } from '../common';

export default class PaymentCancel extends Component{
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  componentDidMount() {
    this.postDetails()
  }

  postDetails() {
    let formData = new FormData()
    formData.append('token', this.props.location.search.split('=')[1])
    // fetch_post(`${PAYPAL}cancel/`, formData) 
    // .then((response) => {
    fetch(`${DOMAIN_NAME}${PAYPAL}cancel/`, {
      method: 'post',
      body: formData
    })
    .then(response => response.json())
    .then((response) => { 
      if(response.error){
        alert(response.message)
        if(response.is_public_order){
          this.props.history.push(`/public/payment?token=${response.static_token}&order_id=${response.order_id}/`)
        }
        else{
          this.props.history.push('/invoices')
        }
      }
      // else{
      //   alert('Subscription Changed successfully')
      //   this.props.history.push('/profile')
      // }
    })
  }

  render(){
    return(
      <div className="main-content">
      </div>
    )
  }
}


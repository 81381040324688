import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN, DOMAIN_NAME } from '../common';
import ReactGA from 'react-ga';

export default class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: '',
      errors: {},
      selectedNav: window.location.pathname.split('/')[1],
      signin: window.location.pathname.split('/')[2],
      role: localStorage.getItem('role'),
      token: localStorage.getItem('UserToken'),
      passwordClass:'fa fa-eye'
    };
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount() {
    const { token, role, selectedNav } = this.state;
    if(token) {
      if(selectedNav === 'vendors' && role === 'vendors') {
        this.props.history.push('/vendors/dashboard/')
      } else if(selectedNav === 'admin' && role === 'admin'){
        this.props.history.push('/admin/dashboard/')
      }else if(selectedNav === 'creator' && role === 'creator'){
        this.props.history.push('/creator/dashboard/')
      }else {
        if(role === 'client') {
          if(localStorage.getItem('payment_page')){
            this.props.history.push(localStorage.getItem('payment_page'))
          }
          else if(localStorage.getItem('websites_page')){
            this.props.history.push(localStorage.getItem('websites_page'))
          }
          else{
            this.props.history.push('/dashboard/')
          }
        }
      }
    }
  }
  
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit() {
    // event.preventDefault();
    const { username, password, selectedNav } = this.state;
    let role = selectedNav === 'vendors' ? 'VENDOR' : (selectedNav === 'admin' ? 'ADMIN' : selectedNav === 'creator' ?  'CREATOR' : 'CLIENT')
    let data = new FormData()
    data.append('username', username)
    data.append('password', password)
    data.append('user_role', role)
    fetch(DOMAIN_NAME+LOGIN, {
      method: 'post',
      body: data
    })
    .then(response => response.json())
    .then((response) => {
      if (response.token) {
        localStorage.setItem('UserToken',response.token);
        localStorage.setItem('UserId',response.user_id);
        localStorage.setItem('subscription',response.subscription_name)
        // window.gtag('set', {'user_id': response.user_id});
        // ReactGA.initialize('UA-162412038-1')
        // ReactGA.set({ userId: response.user_id });
        ReactGA.initialize('UA-162412038-1', {
          gaOptions: {
            userId: response.user_id
          }
        });
        ReactGA.pageview(window.location.pathname + window.location.search)            
        if(response.profile_pic_path === ""){
          localStorage.setItem('profile_Pic',"/img/brand/default.png")
        }
        else{
          localStorage.setItem('profile_Pic',response.profile_pic_path)
        }
        if(selectedNav === 'vendors') {
          localStorage.setItem('role', 'vendors');
          {response.first_time_login === true ? this.props.history.push(`/vendors/${localStorage.getItem('UserId')}/change-password`) : this.props.history.push('/vendors/dashboard/')}
          } else if(selectedNav === 'admin'){
          localStorage.setItem('role', 'admin');
          localStorage.setItem('SuperUser', response.is_superuser);
          {response.first_time_login === true ? this.props.history.push(`/admin/${localStorage.getItem('UserId')}/change-password`) : this.props.history.push('/admin/dashboard/', { superUser: response.is_superuser })}
          }
        else if(selectedNav === 'creator') {
          localStorage.setItem('role', 'creator');
          {response.first_time_login === true ? this.props.history.push(`/creator/${localStorage.getItem('UserId')}/change-password`) : this.props.history.push('/creator/dashboard/')}
          }
         else {
          localStorage.setItem('role', 'client');
          this.props.history.push('/dashboard/')
          }
        // const redirectUrl = `//rank.io:3000/`;
        // const redirectUrl = `//rankcastle.micropyramid.com/`;
        // window.location.href = redirectUrl;
      }
      else {
        this.setState({ errors: response.errors,error:response.error });
      }
    })
    .catch(() => {
      this.setState({ error: 'Something went wrong, Please try again' });
    });
  }

  showPassword(){
   let a = document.getElementById('password')
   if(a.type == 'password'){
    a.setAttribute('type','text')
    this.setState({passwordClass:'fa fa-eye-slash'})
   }
   else{
    a.setAttribute('type','password')
    this.setState({passwordClass:'fa fa-eye'})
   }
  }

  render(){
    const { errors,error } = this.state;
    return( 
      <Fragment>
        <div className="login_rc">
          <div className="main-content">
            <nav className="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
              <div className="container px-4 justify-content-center">
                <a className="navbar-brand" href="../index.html">
                  <img src="/img/brand/logo.png" alt=""  style={{height:"100px"}} />
                </a>
                <div className="collapse navbar-collapse" id="navbar-collapse-main">
                  <div className="navbar-collapse-header d-md-none">
                    <div className="row">
                      <div className="col-6 collapse-brand">
                        <a href="../index.html">
                          <img src="../assets/img/brand/blue.png" alt="" />
                        </a>
                      </div>
                      <div className="col-6 collapse-close">
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse-main" aria-controls="sidenav-main" aria-expanded="false" aria-label="Toggle sidenav">
                          <span></span>
                          <span></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="header bg-gradient-primary py-9 py-lg-9">
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <polygon className="fill-default" points="2560 0 2560 100 0 100"></polygon>
                </svg>
              </div>
            </div>
            <div className="container mt--9 pb-1">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-7">
                  <div className="card border-0">
                    <div className="card-body px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Sign in with Credentials</small>
                      </div>
                      <form>
                        <div className="form-group mb-3">
                          <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}                      
                              className="form-control" 
                              placeholder="Username/Email" 
                              type="text" 
                              value={this.state.username} 
                              onChange={(event)=>{this.setState({username:event.target.value})}}
                            />
                          </div>
                            <div className="text-danger">                            
                              {error === true ?(errors.username ? errors.username[0] : null):null}
                            </div>                  
                        </div>
                        <div className="form-group">
                          <div className="input-group input-group-alternative">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                            </div>
                            <input 
                              onKeyPress={this.handleKeyPress}
                              className="form-control" 
                              placeholder="Password" 
                              type="password"
                              id="password"
                              value={this.state.password} 
                              onChange={(event)=>{this.setState({password:event.target.value})}}
                            />
                            <span style={{paddingTop:"10px", cursor:'pointer'}} onClick={()=>this.showPassword()} class={this.state.passwordClass}></span>
                          </div>
                            <div className="text-danger">
                              {error === true ?(errors.password ? errors.password[0] : errors.__all__ ? errors.__all__[0] : null):error }
                            </div>   
                        </div>
                        <div className="text-danger">
                          {error === true ? !errors.username && !errors.password ? errors : null :null}
                        </div>
                        <div className="text-center">
                          <button 
                            type="button" 
                            className="btn btn-primary my-4" 
                            onClick={() => this.handleSubmit()}
                          >
                            Sign in
                          </button>                  
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-6">
                      <Link to={"/forgot-password"} className="text-light"><small>Forgot password?</small></Link>
                    </div>
                  {/*this.state.selectedNav === 'signin' &&
                    <div className="col-6 text-right">
                      <Link to={'/signup'}>Create new account</Link>
                    </div>*/
                  }
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </Fragment>
    )
  }
}      
import React, { Component } from 'react';
import moment from 'moment';
import { DOMAIN_NAME, WEBSITE, USERWEBSITE, fetch_get } from '../common';

export default class WebsiteDetails extends Component{
    constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: []
    }
  }

  componentDidMount() {
    this.websitesDetails()
  }

  websitesDetails() {
    fetch_get(`${USERWEBSITE}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      this.setState({ websiteDetails: response.user_website_serializer })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    const { websiteDetails } = this.state;
    const websiteCategories = websiteDetails.website && websiteDetails.website.hasOwnProperty('categories') ?  websiteDetails.website.categories.map((category)=> category.name): []
    return(
      <div className="main-content">
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow mb-6">
              <div className="card-header row bg-white border-0 mb-3">
                <h3 className="mb-0 col-md-6">Website Details</h3>
                <div className="col-md-6 text-right">
                {this.props.location.state ? this.props.location.state.sourcepage == 'websites' ?
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/websites', state:{detail:this.props.location.state}}) }>
                    Back
                  </button>:
                  <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.push({ pathname: '/bestoffers'}) }>
                    Back
                  </button> : null
                }                           
                </div>
              </div>
              {websiteDetails.id &&
              <div className="card-body">
                <div className="row pt-3">
                  <table className="table">
                  <tr>
                  <td>Website Id</td>
                  <td>{websiteDetails.id}</td>
                  </tr>
                  <tr>
                  <td>domain</td>
                  <td>{websiteDetails.website.domain}</td>
                  </tr>                  
                  <tr>
                  <td>Categories</td>
                  <td>{websiteCategories.join(', ')}</td>
                  </tr>
                  <tr>
                  <td>Links Type</td>
                  <td>{websiteDetails.links_type}</td>
                  </tr>                  
                  <tr>
                  <td>Moz DA</td>
                  <td>{websiteDetails.website.moz_da}</td>
                  </tr>
                  <tr>
                  <td>Moz PA</td>
                  <td>{websiteDetails.website.moz_pa}</td>
                  </tr>
                  <tr>
                  {/* <td>Alexa Rank</td>
                  <td>{websiteDetails.website.alexa_rank}</td> */}
                  </tr> 
                  <tr>
                  <td>Ahrefs Domain rating DR</td>
                  <td>{websiteDetails.website.ahrefs_domain_rating_dr}</td>
                  </tr>                  
                  <tr>
                  <td>Ahrefs URL rating URl</td>
                  <td>{websiteDetails.website.ahrefs_url_rating_ur}</td>
                  </tr>
                  <tr>
                  <td>Ahrefs Rank of Domain</td>
                  <td>{websiteDetails.website.ahrefs_rank_of_domain}</td>
                  </tr>
                  <tr>
                  <td>Accept Adult</td>
                  <td>{websiteDetails.website.accept_adult ? 'True' : 'False'}</td>
                  </tr>
                  <tr>
                  <td>Accept Link insertion</td>
                  <td>{websiteDetails.website.accept_link_insertion_in_existing_post ? 'True' : 'False'}</td>
                  </tr> 
                  <tr>
                  <td>Accept Weight Loss</td>
                  <td>{websiteDetails.website.accept_weight_loss}</td>
                  </tr>                                                     
                  <tr>
                  <td>Accept Gambling</td>
                  <td>{websiteDetails.website.accept_gambling ? 'True' : 'False'}</td>
                  </tr>
                  <tr>                 
                  <td>Accept THC</td>
                  <td>{websiteDetails.website.accept_thc ? 'True' : 'False'}</td>
                  </tr>
                  <tr>
                  <td>Ahref Organic monthly traffic </td>
                  <td><div>Total:{websiteDetails.website.organic_traffic}</div> {websiteDetails.website.historical_data ? websiteDetails.website.historical_data.map((country)=> <div>{country.country == 'United States'? 'US' : country.country == 'Great Britain'? 'GB' : country.country == 'Australia' ? 'AU' : country.country == 'Canada' ? 'CA' : country.country == 'Germany' ? 'DE' : country.country == 'India'? 'IN' : country.country} : {country.traffic_data__traffic}</div>): null}</td>
                  </tr>
                  <tr>
                  <td>Language</td>
                  <td>{websiteDetails.website.language}</td>
                  </tr> 
                  <tr>
                  <td>Protocols</td>
                  <td>{websiteDetails.website.protocols}</td>
                  </tr>
                  <tr>
                  <td>Date inserted</td>
                  <td>{websiteDetails.website.date_added && moment(websiteDetails.date_added).format('YYYY-MM-DD')}</td>
                  </tr>                                                                                         
                  <tr>
                  <td>Date updated</td>
                  <td>{websiteDetails.website.date_updated && moment(websiteDetails.date_updated).format('YYYY-MM-DD')}</td>
                  </tr>                                 
                  <tr>
                  <td>Price</td>
                  <td>{websiteDetails.sell_price ? websiteDetails.sell_price  : 'not specified'}</td>
                  </tr>
                  <tr>
                  <td>Ad Type</td>
                  <td>{websiteDetails.ad_type}</td>
                  </tr>
                  <tr>
                  <td>Link Type</td>
                  <td>{websiteDetails.links_type}</td>
                  </tr>
                  <tr>
                  <td>Client_note</td>
                  <td>{websiteDetails.client_note}</td>
                  </tr>
                  <tr>
                  <td>organic position</td>
                  <td>{websiteDetails.website.organic_keywords}</td>
                  </tr>
                  </table>
                </div>
              </div>
              }
            </div>
          </div>
        </div>  
      </div> 
    )
  }
}

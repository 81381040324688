import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import Dashboard from './Dashboard';
import Websites from './Websites';
import PaymentSuccessPage from './PaymentSuccessPage';
import PaymentFailurePage from './PaymentFailurePage';
import UploadContent from './UploadContent'
import PublicPayment from './PublicPayment'
import PageNotFound from '../../components/common/PageNotFound';

const Component = () => (
  <Switch>
    <Route exact path='/public/websites' component={Websites} />
    <Route exact path='/public/paymentsuccess' component={PaymentSuccessPage} />
    <Route exact path='/public/paymentfailure' component={PaymentFailurePage} />
    <Route exact path='/public/upload-content' component={UploadContent} />
    <Route exact path='/public/payment' component={PublicPayment} />
    <Route component={PageNotFound}/>
  </Switch>
)

export default Component

import React, { Component } from 'react';
import moment from 'moment';
import { USERWEBSITE, fetch_get } from '../common';
import TopHeader from '../common/TopHeader';

export default class WebsiteDetails extends Component{
    constructor(props) {
    super(props);
    this.state = {
      errors: {},
      websiteDetails: []
    }
  }

  componentDidMount() {
    this.websitesDetails()
  }

  websitesDetails() {
    fetch_get(`${USERWEBSITE}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      this.setState({ websiteDetails: response.user_website_serializer })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  render(){
    const { websiteDetails } = this.state;
    const websiteCategories = websiteDetails.website && websiteDetails.website.hasOwnProperty('categories') ?  websiteDetails.website.categories.map((category)=> category.name): []
    return(
      <div className="main-content">
        <TopHeader showSearch={false}/>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow mb-6">
              <div className="card-header row bg-white border-0">
                <h3 className="mb-0 col-md-6 ">Website Details</h3>
                <div class="col-md-6 text-right">
                <button className="edit-website btn-primary mt-2 mr-2" onClick={() => this.props.history.goBack() }>
                  Back
                </button>              
                <button className="edit-website btn-info" onClick={() => this.props.history.push(`/vendors/website/${this.props.match.params.id}/edit`) }>
                  Edit
                </button>
              </div>
              </div>
                {websiteDetails.id &&
                <div className="card-body">
                  <div className="row pt-3">
                    <div className="col-md-4">
                      <div className="row p-2">
                       <label className="form-control-label" htmlFor="input-username">Website Id  </label>
                        <span className="">: {websiteDetails.website.id}</span>
                      </div>
                      <div className="row p-2">
                      <label className="form-control-label" htmlFor="input-username">Disclosure  </label>
                        <span className="">: {websiteDetails.disclosure_tag ? 'True' : 'False'} </span>
                      </div>
                      <div className="row p-2">
                      <label className="form-control-label" htmlFor="input-username">Categories  </label>
                        <span className="">:{websiteCategories.join(', ')}</span>
                      </div>
                       <div className="row p-2">
                      <label className="form-control-label" htmlFor="input-username">Gambling  </label>
                        <span className="">: {websiteDetails.website.accept_gambling ? 'True' : 'False'} </span>
                      </div>
                       <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Link Insertion  </label>
                        <span className="">: {websiteDetails.website.accept_link_insertion_in_existing_post ? 'True' : 'False'}</span>
                      </div>
                       <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Status  </label>
                        <span className="">: {websiteDetails.website.status}</span>
                      </div>
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Active  </label>
                        <span className="">: {websiteDetails.website.active ? 'True' : 'False'}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Moz DA  </label>
                        <span className="">: {websiteDetails.website.moz_da}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Moz PA  </label>
                        <span className="">: {websiteDetails.website.moz_pa}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Accept THC</label>
                        <span className="">: {websiteDetails.website.accept_thc ? 'True' : 'False'}</span>
                      </div> 
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Ad Type</label>
                        <span className="">: {websiteDetails.ad_type}</span>
                      </div> 
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Google Indexed</label>
                        <span className="">: {websiteDetails.website.google_indexed ? 'True' : 'False'}</span>
                      </div> 
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Language  </label>
                        <span className="">: {websiteDetails.website.language}</span>
                      </div>                 
                    </div>
                    <div className="col-md-4">
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Price  </label>
                        <span className="">: {websiteDetails.price.original_price ? websiteDetails.price.original_price  : 'not specified'}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Moz Linking Domains  </label>
                        <span className="">: {websiteDetails.website.moz_linking_domains ? websiteDetails.website.moz_linking_domains : '--'}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Moz Inbound Links  </label>
                        <span className="">: {websiteDetails.website.moz_inbound_links}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Moz Spam Score  </label>
                        <span className="">: {websiteDetails.website.moz_spam_score}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Domain Age </label>
                        <span className="">: {websiteDetails.website.domain_age ? websiteDetails.website.domain_age : '--'}</span>
                      </div>
                      {/* <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Alexa Rank </label>
                        <span className="">: {websiteDetails.website.alexa_rank}</span>
                      </div> */}
                      {/* <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Alexa Country  </label>
                        <span className="">: {websiteDetails.website.alexa_country ? websiteDetails.website.alexa_country : '--'}</span>
                      </div> */}
                      {/* <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Alexa Country Rank  </label>
                        <span className="">: {websiteDetails.website.alexa_country_rank}</span>
                      </div> */}
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Link Type  </label>
                        <span className="">: {websiteDetails.website.links_type}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Minimum word count  </label>
                        <span className="">: {websiteDetails.minimum_word_count}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Maximum number of links </label>
                        <span className="">: {websiteDetails.maximum_number_of_links_in_post}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Protocols </label>
                        <span className="">: {websiteDetails.website.protocols}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Organic traffic </label>
                        <span className="">: {websiteDetails.website.organic_traffic}</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Domain  </label>
                        <span className=""> : {websiteDetails.website.domain}</span>
                      </div>
                      {/* <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Alexa Speed  </label>
                        <span className="">: {websiteDetails.website.alexa_speed} </span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Alexa Contains Adult Content  </label>
                        <span className="">: {websiteDetails.website.alexa_contains_adult_content ? 'True' : 'False'}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Alexa SiteData  </label>
                        <span className="">: {websiteDetails.website.alexa_sitedata ? websiteDetails.website.alexa_sitedata : '--'}</span>
                      </div> */}
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Ahrefs URL rating URl </label>
                        <span className="">: {websiteDetails.website.ahrefs_url_rating_ur}</span>
                      </div>
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Ahrefs Rank of Domain </label>
                        <span className="">: {websiteDetails.website.ahrefs_rank_of_domain}</span>
                      </div>
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Ahrefs Referring Domains </label>
                        <span className="">: {websiteDetails.website.ahrefs_referring_domains}</span>
                      </div>
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Ahrefs Backlinks </label>
                        <span className="">: {websiteDetails.website.ahrefs_backlinks} </span>
                      </div>
                        <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Accept Adult </label>
                        <span className="">: {websiteDetails.website.accept_adult ? 'True' : 'False'}</span>
                      </div>
                       <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username"> Accept Link insertion  </label>
                        <span className="">: {websiteDetails.website.accept_link_insertion_in_existing_post ? 'True' : 'False'}</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Special Note  </label>
                        <span className="">: {websiteDetails.special_notes }</span>
                      </div>
                      <div className="row p-2">
                        <label className="form-control-label" htmlFor="input-username">Time Stamp  </label>
                        <span className="">: {websiteDetails.timestamp && moment(websiteDetails.timestamp).format("YYYY-MM-DD")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>  
      </div> 
    )
  }
}
